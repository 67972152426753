import React, { useEffect, useRef } from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper } from "swiper/react";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Autoplay, Pagination]);

function Slider2(props) {
  const swiperRef = useRef(null);
  useEffect(() => {
    swiperRef.current.swiper.slideTo(props?.swiper);
    console.log(swiperRef.current.swiper.slideTo(props.swiper));
    // if (props.swiper === 50) {
    //   console.log(swiperRef.current.swiper.slideTo(1));
    // }
  }, [props.swiper]);

  return (
    <Swiper
      ref={swiperRef}
      // onSwiper={(e) => e.slideTo(500)}
      autoplay={props.autoplay}
      pagination={
        props.homeMenu
          ? {
              clickable: true,
            }
          : false
      }
      navigation={{
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      }}
      preventClicks={false}
      breakpoints={props.breakpoints}
      spaceBetween={20}
      // spaceBetween={props.gap}
      // slidesPerView={3} // or 'auto'
      slidesPerColumn={props.homeMenu ? 2 : 1}
      // slidesPerGroup={props.homeMenu && 2}
      // spaceBetween={5}
      hashNavigation={{
        watchState: true,
      }}
      slidesPerColumnFill="row"
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {props.children}
    </Swiper>
  );
}

export default Slider2;
