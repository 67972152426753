import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
// import OutsideClickHandler from "react-outside-click-handler";
import { BiMenu } from "react-icons/bi";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../../settings/config";
import moment from "moment";
import { db } from "../../../util/firebaseDB";
import { onValue, ref, update } from "firebase/database";

const Header = ({ isSidebarOpen, setIsSidebar, notifications, user }) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebar(!isSidebarOpen);
  };

  const toggleNotificationbar = () => {
    console.log("enterrrrrrrrrrrrrrrrrrrrrrrrrr");
    const filterL = notifications?.filter((item) => !item.is_read).length;

    console.log(filterL);

    if (user?.id && filterL > 0 && isNotificationOpen) {
      const query = ref(db, `notifications/${user.id}`);
      onValue(query, (snapshot) => {
        snapshot.forEach((snap) => {
          const key = snap.key;
          const query2 = ref(db, `notifications/${user.id}/${key}`);
          update(query2, { is_read: true });
        });
      });
    }

    // if (outside) {
    //   setIsNotificationOpen(false)
    // } else {

    setIsNotificationOpen(!isNotificationOpen);
    // }
  };

  // console.log(isSidebarOpen);

  return (
    <div className="shadow-md w-full">
      <nav className="flex items-center justify-between p-4 relative">
        <div className=" flex items-center ">
          <button
            onClick={toggleSidebar}
            className="lg:hidden absolute left-0 top-3 text-gray-400 z-20"
          >
            {isSidebarOpen ? "" : <BiMenu className="text-3xl mx-2"></BiMenu>}
          </button>

          <div className="lg:flex lg:items-center hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500 hover:text-black "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>

            <a
              href="#responsive-header"
              className="  inline-block mt-0 text-gray-500 hover:text-black mr-4"
            >
              Admin
            </a>
            <Link
              to="/admin"
              href="#responsive-header"
              className=" inline-block mt-0 text-gray-500 hover:text-black mr-4"
            >
              Dashboard
            </Link>
          </div>
        </div>
        <div className="flex justify-end items-center">
          {/* <OutsideClickHandler onOutsideClick={() =>toggleNotificationbar("outside")}> */}
          <div className="sm:relative static">
            <button
              type="button"
              className="relative"
              onClick={toggleNotificationbar}
            >
              <svg
                className="w-6 h-6  text-gray-500 hover:text-black"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                {" "}
                <path
                  stroke="currentColor"
                  strokeWidth="1"
                  stroke-height="1"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
              <span className="absolute top-1 right-0 inline-flex items-center justify-center px-1 py-1 text-xs leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-orange-400 rounded-full">
                {notifications?.filter((item) => !item.is_read).length > 0
                  ? notifications?.filter((item) => !item.is_read).length
                  : null}
              </span>
            </button>
            {isNotificationOpen ? (
              <div className="bg-white shadow-themeShadow absolute top-full right-0 left-auto sm:w-[300px] w-full z-30">
                <div className="msg-header bg-black p-3 rounded-tl-md rounded-tr-md flex items-center justify-between gap-3">
                  <p className="msg-header-title text-base text-white font-oswald font-light">
                    Notifications
                  </p>
                  <p className="msg-header-clear text-sm text-white font-oswald font-light">
                    Marks all as read
                  </p>
                </div>
                <PerfectScrollbar>
                  <div className="header-notifications-list max-h-[350px]">
                    {notifications?.reverse()?.map((item) => (
                      <Link
                        key={item?.id}
                        className="dropdown-item block bg-opacity-30 hover:bg-blue-200 transition-all p-3"
                        to={`/vendor/order-details/${item?.order_number}`}
                      >
                        <div className="flex items-start gap-3">
                          <div className="notify w-[70px]">
                            <img
                              className="w-12 h-12 rounded-full bg-sky-300"
                              src={`${IMAGE_URL}${item?.from_notification?.profile?.profile_pic}
                                `}
                              alt=""
                            />
                          </div>
                          <div className="w-full">
                            <div className="flex justify-between items-center">
                              <h6 className="msg-name text-base font-oswald font-normal">
                                {item?.from_notification?.first_name}
                              </h6>
                              <span className="msg-time text-xs text-[#838383] font-oswald font-light">
                                {moment(item?.created_at).fromNow()}
                              </span>
                            </div>
                            <p className="msg-info text-sm leading-[12px] text-[#838383] font-oswald font-light">
                              {item?.message}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </PerfectScrollbar>

                <a href="#0">
                  <div className="text-center msg-footer bg-black p-3 rounded-bl-md rounded-br-md text-white text-base font-oswald font-normal">
                    View All Notifications
                  </div>
                </a>
              </div>
            ) : null}
          </div>
          {/* </OutsideClickHandler> */}
          <div className="mx-8 w-8 h-8 ">
            <img
              src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
              className="rounded-full"
              alt=""
            />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
