import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import VoucherCreateUpdateForm from "../../../components/admin/Forms/Voucher/VoucherCreateUpdateForm";
import { adminAddVoucherAction } from "../../../redux/actions/AdminActions/adminVoucherAction";
import Layout from "../Layout/layout";

function VendorAddVoucher() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data) => {
    console.log(data.restaurantBranch.value);
    const body = {
      restaurant_branch: data.restaurantBranch.value,
      code: data.code,
      description: data.description,
      max_uses: data.max_uses,
      max_uses_user: data.max_uses_user,
      discount_amount: data.discount_amount,
      is_fixed: data.is_fixed,
      starts_at: data.starts_at,
      expires_at: data.expires_at,
      min_order_price: data.min_order_price,
      status: data.status,
      type: data.type,
    };
    console.log(body);
    dispatch(adminAddVoucherAction(body, history, "vendor"));
    // console.log(data.starts_at);
  };

  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <VoucherCreateUpdateForm
            onSubmit={onSubmit}
            restaurantBranchData={user?.restaurant_branch}
            vendor
          />
        </div>
      </div>
    </Layout>
  );
}

export default VendorAddVoucher;
