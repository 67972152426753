import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "../Layout/layout";
import ModalContainer from "../../../components/common/ModalContainer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  adminDeleteVoucherAction,
  adminVoucherDetailAction,
} from "../../../redux/actions/AdminActions/adminVoucherAction";
const VendorVoucherDetails = () => {
  const { voucherDetail } = useSelector((state) => state.adminVoucher);
  // console.log(voucherDetail);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  // console.log(id);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedDetail, setselectedDetail] = useState(null);

  function closeModal() {
    setIsOpen(false);
  }
  const handleDelete = () => {
    dispatch(
      adminDeleteVoucherAction(selectedDetail, history, "details", "vendor")
    );
    closeModal();
    setselectedDetail(null);
  };
  const handleDeleteClick = (delete_id) => {
    console.log(delete_id);
    setIsOpen(true);
    setselectedDetail(delete_id);
  };

  useEffect(() => {
    dispatch(adminVoucherDetailAction(id));
  }, [dispatch, id]);
  return (
    <Layout>
      <div className="my-3 mx-2 lg:mx-24 shadow p-3 h-max ">
        <p className="text-center mb-8">Voucher Details</p>
        <div className="md:mx-24">
          {" "}
          <div className="grid grid-cols-1 gap-2  ">
            <p>
              Restaurant Branch:{" "}
              <strong>{voucherDetail?.restaurant_branch?.name}</strong>
            </p>
            <p>
              Code: <strong>{voucherDetail.code}</strong>
            </p>
            <p>
              Description: <strong>{voucherDetail.description}</strong>
            </p>
            <p>
              Uses: <strong>{voucherDetail.uses}</strong>
            </p>
            <p>
              Max uses: <strong>{voucherDetail.max_uses}</strong>
            </p>
            <p>
              Max uses user: <strong>{voucherDetail.max_uses_user}</strong>
            </p>
            <p>
              Type: <strong>{voucherDetail.type}</strong>
            </p>
            <p>
              Discount amount: <strong>{voucherDetail.discount_amount}</strong>
            </p>
            <p>
              Fixed:{" "}
              <strong>
                {voucherDetail?.is_fixed?.toString().toUpperCase()}
              </strong>
            </p>
            <p>
              Starts at:{" "}
              <strong>
                {voucherDetail.starts_at
                  ? moment(voucherDetail.starts_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )
                  : null}
              </strong>
            </p>
            <p>
              Expires at:{" "}
              <strong>
                {voucherDetail.expires_at
                  ? moment(voucherDetail.expires_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )
                  : null}
              </strong>
            </p>
            <p>
              Min order price:
              <strong>{voucherDetail.min_order_price}</strong>
            </p>
            <p>
              Status:{" "}
              <strong>{voucherDetail.status?.toString().toUpperCase()}</strong>
            </p>
            <p>
              Admin code:{" "}
              <strong>
                {voucherDetail.is_admin_code?.toString().toUpperCase()}
              </strong>
            </p>
          </div>
        </div>

        <div className="flex justify-center my-8">
          <Link to={`/vendor/voucher/edit/${id}`}>
            <button className="rounded py-2 px-5 text-white mr-2 bg-yellow-400">
              Edit
            </button>
          </Link>
          <button
            onClick={() => handleDeleteClick(voucherDetail?.id)}
            className="rounded py-2 px-5 text-white  bg-red-600"
          >
            Delete
          </button>
          <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
            <div id="modal-background">
              <div id="modal" className="w-80 md:w-full">
                <h2 className="my-3">
                  Are you sure you want to delete this item?
                </h2>
                <div className=" text-sm flex gap-8">
                  <button
                    className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                  <button
                    className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ModalContainer>
        </div>
      </div>
    </Layout>
  );
};

export default VendorVoucherDetails;
