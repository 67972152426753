import {
  SET_USER,
  LOADING_USER,
  SET_UNAUTHENTICATED,
  SET_AUTHENTICATED,
  ADD_ITEM,
  DELETE_ITEM,
  EDIT_ITEM,
  SET_AUTH_ERROR,
  FETCH_USER_ADDRESS,
  PASSWORD_CHANGE_MESSAGE,
  PASSWORD_CHANGE_LOADING,
  PASSWORD_CHANGE_MESSAGE_CLEAR,
  PROFILE_UPDATE_SUCCESS,
  USER_ACTIVATION_SUCCESS,
  USER_ACTIVATION_ERROR,
  FETCH_ADMIN_NOTIFICATIONS,
  FETCH_VENDOR_NOTIFICATIONS,
} from "../types";
import auth from "../../util/auth";

function getActivationIdLocalStorage() {
  return localStorage.getItem("activation_id")
    ? JSON.parse(localStorage.getItem("activation_id"))
    : null;
}

const initialState = {
  authenticated: false,
  loading: false,
  user: auth.getUserInfo(),
  authError: null,
  userAddress: [],
  passChangeMsg: "",
  passChangeLoading: false,
  profileUpdateSuccess: false,
  activationError: null,
  activationSuccess: false,
  activationUserId: getActivationIdLocalStorage(),
  adminNotifications: [],
  vendorNotificatinos: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        loading: false,
        authError: null,
      };

    case USER_ACTIVATION_ERROR:
      return {
        ...state,
        activationError: action.payload,
        activationSuccess: false,
        loading: false,
      };
    case USER_ACTIVATION_SUCCESS:
      return {
        ...state,
        activationSuccess: true,
        activationError: null,
        loading: false,
      };

    case SET_AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
        passChangeLoading: false,
      };
    case FETCH_USER_ADDRESS:
      return {
        ...state,
        userAddress: action.payload,
        authError: null,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profileUpdateSuccess: true,
        authError: null,
      };
    case PASSWORD_CHANGE_MESSAGE:
      return {
        ...state,
        passChangeMsg: action.payload,
        passChangeLoading: false,
        authError: null,
      };
    case PASSWORD_CHANGE_LOADING:
      return {
        ...state,
        passChangeLoading: true,
        passChangeMsg: "",
        authError: null,
      };
    case PASSWORD_CHANGE_MESSAGE_CLEAR:
      return {
        ...state,
        passChangeLoading: false,
        profileUpdateSuccess: false,
        passChangeMsg: "",
        authError: null,
      };
    case SET_UNAUTHENTICATED:
      auth.clearAppStorage();
      return {
        ...state,
        authenticated: false,
        user: {},
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case FETCH_ADMIN_NOTIFICATIONS:
      return {
        ...state,
        adminNotifications: action.payload,
      };

    case FETCH_VENDOR_NOTIFICATIONS:
      return {
        ...state,
        vendorNotificatinos: action.payload,
      };

    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case ADD_ITEM:
      return {
        ...state,
        loading: false,
        items: [...state.items, action.payload],
      };
    case DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload),
      };

    case EDIT_ITEM:
      return {
        ...state,
        items: state.items.map((item) =>
          item._id === action.payload._id ? { ...action.payload } : item
        ),
      };

    default:
      return state;
  }
}
