//data reducers
export const SET_RESTAURANTS = "SET_RESTAURANTS";
export const SET_RESTAURANT = "SET_RESTAURANT";
export const LOADING_DATA = "LOADING_DATA";

export const SET_CATEGORIES = "SET_CATEGORIES";

export const SET_RESTAURANT_DETAILS = "SET_RESTAURANT_DETAILS";
export const SET_RESTAURANT_ERROR = "SET_RESTAURANT_ERROR";

// restaurant rating
export const GET_RESTAURANT_RATING_DATA = "GET_RESTAURANT_RATING_DATA";
export const SET_REVIEW_RATING_DATA = "SET_REVIEW_RATING_DATA";

export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAIL = "ADD_CART_FAIL";
export const SET_CART = "SET_CART";
export const DELETE_ITEM_CART = "DELETE_ITEM_CART";
export const SET_ORDERS = "SET_ORDERS";
export const EDIT_STATUS = "EDIT_STATUS";

export const SET_FOOD_CATEGORIES = "SET_FOOD_CATEGORIES";

//UI reducers
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SERVER_ERROR = "SERVER_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SET_ERROR = "SET_ERROR";
export const SET_ERRORS_SIGNUP_SELLER = "SET_ERRORS_SIGNUP_SELLER";
export const SET_ERROR_ITEM = "SET_ERROR_ITEM";

//user reducers
export const SET_RIDER_INSTRUCTION = "SET_RIDER_INSTRUCTION";
export const FETCH_USER_ADDRESS = "FETCH_USER_ADDRESS";
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const LOADING_USER = "LOADING_USER";
export const SET_USER = "SET_USER";
export const USER_ACTIVATION_SUCCESS = "USER_ACTIVATION_SUCCESS";
export const USER_ACTIVATION_ERROR = "USER_ACTIVATION_ERROR";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";

// export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";
export const PASSWORD_CHANGE_MESSAGE = "PASSWORD_CHANGE_MESSAGE";
export const PASSWORD_CHANGE_LOADING = "PASSWORD_CHANGE_LOADING";
export const PASSWORD_CHANGE_MESSAGE_CLEAR = "PASSWORD_CHANGE_MESSAGE_CLEAR";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";

export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const EDIT_ITEM = "EDIT_ITEM";

export const REQUEST_CART_PENDING = "REQUEST_CART_PENDING";
export const REQUEST_CART_SUCCESS = "REQUEST_CART_SUCCESS";
export const REQUEST_CART_FAILED = "REQUEST_CART_FAILED";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const INCREASE_CART_ITEM = "INCREASE_CART_ITEM";
export const DECREASE_CART_ITEM = "DECREASE_CART_ITEM";
export const ADD_TO_CART = "ADD_TO_CART";

export const ADD_EXTRA_PRODUCTS_TO_CART = "ADD_EXTRA_PRODUCTS_TO_CART";
export const REMOVE_EXTRA_PRODUCTS_CART_ITEM =
  "REMOVE_EXTRA_PRODUCTS_CART_ITEM";

export const REMOVE_All_EXTRA_PRODUCTS_CART_ITEM =
  "REMOVE_All_EXTRA_PRODUCTS_CART_ITEM";

// Common reducers
export const SET_SPONSOR = "SET_SPONSOR";

// food
export const SET_FOOD_VARIATION = "SET_FOOD_VARIATION";
export const SET_FOOD_VARIATION_EMPTY = "SET_FOOD_VARIATION_EMPTY";
export const INCREASE_FOOD_VARIATIONS = "INCREASE_FOOD_VARIATIONS";

export const INDIVIDUAL_ITEM_INCREASE = "INDIVIDUAL_ITEM_INCREASE";
export const INDIVIDUAL_ITEM_DECREASE = "INDIVIDUAL_ITEM_DECREASE";

export const TOGGLE_INGREDIENTS_PRICE = "TOGGLE_INGREDIENTS_PRICE";

export const SET_SERVICE_FEE = "SET_SERVICE_FEE";

export const SET_FOOD_MODAL_OPEN = "SET_FOOD_MODAL_OPEN";
export const SET_FOOD_MODAL_CLOSE = "SET_FOOD_MODAL_CLOSE";

export const SET_FOOD_INGREDIENTS_HOME_MADE = "SET_FOOD_INGREDIENTS_HOME_MADE";

// order
export const LOADING_ORDER = "LOADING_ORDER";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_LOADING = "PLACE_ORDER_LOADING";
export const GET_ORDER_SUMMERY = "GET_ORDER_SUMMERY";
export const GET_ORDER_SUMMERY_LOADING = "GET_ORDER_SUMMERY_LOADING";
export const FETCH_ORDER_HISTORY = "FETCH_ORDER_HISTORY";
export const SUBMIT_PAYMENT_CARD_SUCCESS = "SUBMIT_PAYMENT_CARD_SUCCESS";
export const SUBMIT_PAYMENT_CARD_LOADING = "SUBMIT_PAYMENT_CARD_LOADING";
export const SAVE_PAYMENT_INFO_LOADING = "SAVE_PAYMENT_INFO_LOADING";
export const GET_VOUCHER_DISCOUNT = "GET_VOUCHER_DISCOUNT";
export const GET_ORDER_TRACK = "GET_ORDER_TRACK";
export const GET_VOUCHER_DISCOUNT_ERROR = "GET_VOUCHER_DISCOUNT_ERROR";

// wishlist

export const FETCH_WISHLIST = "FETCH_WISHLIST";
export const TOGGLE_WISHLIST_SUCCESS = "TOGGLE_WISHLIST_SUCCESS";
export const TOGGLE_WISHLIST_NOTIFICATION = "TOGGLE_WISHLIST_NOTIFICATION";

// meta
export const FETCH_ABOUT_US = "FETCH_ABOUT_US";
export const SUBMIT_CONTACT_US = "SUBMIT_CONTACT_US";
export const CLEAR_CONTACT_US = "CLEAR_CONTACT_US";
export const FETCH_PRIVACY_POLICY = "FETCH_PRIVACY_POLICY";

// search
export const SEARCH_RESTAURANT_FOOD = "SEARCH_RESTAURANT_FOOD";

export const SEARCH_RESTAURANT = "SEARCH_RESTAURANT";

// products
export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST";
export const FETCH_PRODUCT_LOADING = "FETCH_PRODUCT_LOADING";
export const FETCH_PRODUCT_ERROR = "FETCH_PRODUCT_ERROR";

// Admin Panel

//user
export const ADMIN_USER_LIST = "ADMIN_USER_LIST";
export const ADMIN_USER_LOADING = "ADMIN_USER_LOADING";
export const ADMIN_USER_ERROR = "ADMIN_USER_ERROR";
export const ADMIN_USER_DETAILS = "ADMIN_USER_DETAILS";
export const ADMIN_REGISTER_SINGLE_USER_DETAILS =
  "ADMIN_REGISTER_SINGLE_USER_DETAILS";
export const ADMIN_USER_DETAILS_WITH_ADDRESS =
  "ADMIN_USER_DETAILS_WITH_ADDRESS";
export const ADMIN_USER_ADDRESS_DELETE = "ADMIN_USER_ADDRESS_DELETE";
export const ADMIN_USER_ADDRESS_DELETE_CLEAN =
  "ADMIN_USER_ADDRESS_DELETE_CLEAN";
export const ADMIN_LIMIT_USER_LIST = "ADMIN_LIMIT_USER_LIST";

// restaurant
export const ADMIN_RESTAURANT_LIST = "ADMIN_RESTAURANT_LIST";
export const ADMIN_RESTAURANT_NAME_ONLY_LIST =
  "ADMIN_RESTAURANT_NAME_ONLY_LIST";
export const ADMIN_RESTAURANT_BRANCH_NAME_ONLY_LIST =
  "ADMIN_RESTAURANT_BRANCH_NAME_ONLY_LIST";
export const ADMIN_RESTAURANT_DETAILS = "ADMIN_RESTAURANT_DETAILS";
export const ADMIN_RESTAURANT_DETAILS_WITH_BRANCH =
  "ADMIN_RESTAURANT_DETAILS_WITH_BRANCH";
export const ADMIN_RESTAURANT_CATEGORY = "ADMIN_RESTAURANT_CATEGORY";
export const ADMIN_RESTAURANT_ERROR = "ADMIN_RESTAURANT_ERROR";
export const ADMIN_RESTAURANT_LOADING = "ADMIN_RESTAURANT_LOADING";

export const ADMIN_RESTAURANT_BRANCH_DETAILS_EDIT =
  "ADMIN_RESTAURANT_BRANCH_DETAILS_EDIT";

// Menu
export const ADMIN_MENU_LOADING = "ADMIN_MENU_LOADING";
export const ADMIN_MENU_ERROR = "ADMIN_MENU_ERROR";
export const ADMIN_MENU_CATEGORY_LIST = "ADMIN_MENU_CATEGORY_LIST";
export const ADMIN_MENU_CATEGORY_NAME_LIST = "ADMIN_MENU_CATEGORY_NAME_LIST";
export const ADMIN_MENU_CATEGORY_DETAILS = "ADMIN_MENU_CATEGORY_DETAILS";

// Menu items
export const ADMIN_MENU_ITEMS_LIST = "ADMIN_MENU_ITEMS_LIST";
export const ADMIN_MENU_ITEMS_NAME_ONLY_LIST =
  "ADMIN_MENU_ITEMS_NAME_ONLY_LIST";
export const ADMIN_MENU_ITEM_DETAILS = "ADMIN_MENU_ITEM_DETAILS";

// Menu variations
export const ADMIN_MENU_VARIATION_LIST = "ADMIN_MENU_VARIATION_LIST";
export const ADMIN_MENU_VARIATION_DETAILS = "ADMIN_MENU_VARIATION_DETAILS";
export const ADMIN_MENU_VARIATION_NAME_ONLY_LIST =
  "ADMIN_MENU_VARIATION_NAME_ONLY_LIST";

// Menu Ingredients
export const ADMIN_MENU_INGREDIENTS_LIST = "ADMIN_MENU_INGREDIENTS_LIST";
export const ADMIN_MENU_INGREDIENTS_DETAILS = "ADMIN_MENU_INGREDIENTS_DETAILS";

// Order
export const ADMIN_ORDER_LOADING = "ADMIN_ORDER_LOADING";
export const ADMIN_ORDER_ERROR = "ADMIN_ORDER_ERROR";
export const ADMIN_ORDER_DETAILS = "ADMIN_ORDER_DETAILS";
export const ADMIN_ORDER_LIST = "ADMIN_ORDER_LIST";
export const ADMIN_CUSTOMER_PAYABLE_INFO_LIST =
  "ADMIN_CUSTOMER_PAYABLE_INFO_LIST";

// Sponsor
export const ADMIN_SPONSOR_LOADING = "ADMIN_SPONSOR_LOADING";
export const ADMIN_SPONSOR_LIST = "ADMIN_SPONSOR_LIST";
export const ADMIN_SPONSOR_DETAILS = "ADMIN_SPONSOR_DETAILS";
export const ADMIN_SPONSOR_ERROR = "ADMIN_SPONSOR_ERROR";

// voucher
export const ADMIN_VOUCHER_LOADING = "ADMIN_VOUCHER_LOADING";
export const ADMIN_VOUCHER_LIST = "ADMIN_VOUCHER_LIST";
export const ADMIN_VOUCHER_DETAIL = "ADMIN_VOUCHER_DETAIL";
export const ADMIN_CUSTOMER_USED_VOUCHER = "ADMIN_CUSTOMER_USED_VOUCHER";
export const ADMIN_VOUCHER_ERROR = "ADMIN_VOUCHER_DETAIL";

// Meta
export const ADMIN_META_LOADING = "ADMIN_META_LOADING";
export const ADMIN_CONTACT_LIST = "ADMIN_CONTACT_LIST";
export const ADMIN_CONTENT_SETTINGS_DETAILS = "ADMIN_CONTENT_SETTINGS_DETAILS";
export const ADMIN_CONTENT_SETTINGS_SAVE = "ADMIN_CONTENT_SETTINGS_SAVE";
export const ADMIN_CONTENT_SETTINGS_SAVE_CLEAR =
  "ADMIN_CONTENT_SETTINGS_SAVE_CLEAR";
export const ADMIN_GENERAL_SETTINGS_DETAILS = "ADMIN_GENERAL_SETTINGS_DETAILS";
export const ADMIN_GENERAL_SETTINGS_SAVE = "ADMIN_GENERAL_SETTINGS_SAVE";
export const ADMIN_GENERAL_SETTINGS_SAVE_CLEAR =
  "ADMIN_GENERAL_SETTINGS_SAVE_CLEAR";
export const ADMIN_META_ERROR = "ADMIN_META_ERROR";

// Vendor Registration
export const ADMIN_VENDOR_REGISTRATION_LIST = "ADMIN_VENDOR_REGISTRATION_LIST";

// Review Rating
export const ADMIN_REVIEW_RATING_LIST = "ADMIN_REVIEW_RATING_LIST";

// Vendor Details
export const ADMIN_VENDOR_DETAILS = "ADMIN_VENDOR_DETAILS";

// Vendor Panel

// Menu items
export const VENDOR_MENU_LOADING = "VENDOR_MENU_LOADING";
export const VENDOR_MENU_ITEMS_LIST = "VENDOR_MENU_ITEMS_LIST";
export const VENDOR_MENU_ERROR = "VENDOR_MENU_ERROR";

// menu variations
export const VENDOR_MENU_VARIATION_LIST = "VENDOR_MENU_VARIATION_LIST";

// menu ingredients
export const VENDOR_MENU_INGREDIENTS_LIST = "VENDOR_MENU_INGREDIENTS_LIST";

// order
export const VENDOR_ORDER_LOADING = "VENDOR_ORDER_LOADING";
export const VENDOR_ORDER_ERROR = "VENDOR_ORDER_ERROR";
export const VENDOR_ORDER_LIST = "VENDOR_ORDER_LIST";

// voucher
export const VENDOR_VOUCHER_LOADING = "VENDOR_VOUCHER_LOADING";
export const VENDOR_VOUCHER_LIST = "VENDOR_VOUCHER_LIST";
export const VENDOR_VOUCHER_DETAIL = "VENDOR_VOUCHER_DETAIL";
export const VENDOR_VOUCHER_ERROR = "VENDOR_VOUCHER_DETAIL";

// vendor registration
export const VENDOR_SIGNUP_SUCCESS = "VENDOR_SIGNUP_SUCCESS";

// home made
export const HOME_MADE_INGREDIENTS_LIST = "HOME_MADE_INGREDIENTS_LIST";

// driver reg
export const DRIVER_REGISTRATION = "DRIVER_REGISTRATION";

// chat
export const FETCH_ALL_CHAT_USERS = "FETCH_ALL_CHAT_USERS";
export const FETCH_SINGLE_CHAT_MESSAGES = "FETCH_SINGLE_CHAT_MESSAGES";

// Driver
export const FETCH_ALL_DRIVER_LIST = "FETCH_ALL_DRIVER_LIST";
export const FETCH_ALL_ASSIGNED_LIST = "FETCH_ALL_ASSIGNED_LIST";

// export const DRIVER_SEARCH = "DRIVER_SEARCH";

// Notification
export const FETCH_ADMIN_NOTIFICATIONS = "FETCH_ADMIN_NOTIFICATIONS";
export const FETCH_VENDOR_NOTIFICATIONS = "FETCH_VENDOR_NOTIFICATIONS";
