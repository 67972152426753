import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import moment from "moment";
import { IMAGE_URL } from "../settings/config";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteAllPastOrder,
  fetchOrderHistory,
} from "../redux/actions/orderAction";
import Loading from "../components/Loading";
import ActiveOrder from "../components/order/ActiveOrder";
import PastOrder from "../components/order/PastOrder";
import ModalContainer from "../components/common/ModalContainer";
import DeleteOrderImg from "../images/payment/delete-history.png";

export function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const Order = () => {
  const { order_history, loading } = useSelector((state) => state.order);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  function closeDeleteModal() {
    setDeleteModalOpen(false);
  }
  function closeDetailsModal() {
    setDetailsModalOpen(false);
  }
  console.log(order_history);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderHistory());
  }, [dispatch]);

  const [toggleState, setToggleState] = useState(1);
  function handleToggleTab(index) {
    setToggleState(index);
  }

  const handleModalPreview = (id) => {
    const orderDetails = order_history.filter((item) => item.id === id);
    console.log(orderDetails);
    setOrderDetails(orderDetails);
    return orderDetails;
  };

  return (
    <Layout>
      <section className={loading ? "my-order py-8 h-screen" : "my-order py-8"}>
        <div className="container">
          <div className="section-header pb-6">
            <h3 className="text-lg font-semibold text-black">My Order</h3>
          </div>
          <div className="section-wrapper">
            <div className="history-content">
              <div className="sm:flex items-center">
                <ul className="nav nav-tabs flex flex-wrap justify-start text-base gap-4">
                  <li
                    className={toggleState === 1 ? "active" : "cursor-pointer"}
                    onClick={() => handleToggleTab(1)}
                  >
                    <button>Active Orders</button>
                  </li>
                  <li
                    className={toggleState === 2 ? "active" : "cursor-pointer"}
                    onClick={() => handleToggleTab(2)}
                  >
                    <button>Past Orders</button>
                  </li>
                </ul>
              </div>
              {loading ? (
                <Loading />
              ) : (
                <div className="tab-content mt-8">
                  {/* {order_history.order_status !== "SHIPPED" ? ( */}
                  <div
                    className={
                      toggleState === 1 ? "tab-pane active" : "tab-pane"
                    }
                    id="tab-1"
                  >
                    <ActiveOrder order_history={order_history} />
                  </div>

                  <div
                    className={
                      toggleState === 2
                        ? "tab-pane mt-4 active"
                        : "tab-pane mt-4"
                    }
                    id="tab-2"
                  >
                    <div className="track-btn text-right">
                      {order_history?.filter(
                        (order) =>
                          order.order_status === "DELIVERED" ||
                          order.order_status === "CANCELLED"
                      ).length > 0 && (
                        <button
                          onClick={() => setDeleteModalOpen(true)}
                          // onClick={() => {
                          //   dispatch(deleteAllPastOrder());
                          // }}
                          type="button"
                          className="bg-transparent px-4 md:px-8 py-2 text-primaryColor mt-6 sm:mt-0"
                        >
                          Delete All
                        </button>
                      )}
                    </div>
                    <PastOrder
                      order_history={order_history}
                      handleModalOpen={setDetailsModalOpen}
                      handleModalPreview={handleModalPreview}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ModalContainer open={deleteModalOpen} closeModal={closeDeleteModal}>
          <div className="p-6">
            <div className="payment-thumb text-center">
              <img src={DeleteOrderImg} className="mx-auto" alt="empty order" />
              <h4 className="text-xl font-semibold text-black pt-10">
                You have no active order!
              </h4>
              <p className="text-sm text-gray-500 font-normal pt-1">
                Thank you for choosing us
              </p>

              <button
                onClick={() => {
                  dispatch(deleteAllPastOrder());
                  setDeleteModalOpen(false);
                }}
                type="button"
                className="mr-4 px-8 py-3 rounded-md text-white text-xs font-normal cursor-pointer bg-primaryColor mt-6"
              >
                Delete
              </button>
              <button
                onClick={() => setDeleteModalOpen(false)}
                type="button"
                className="px-8 py-3 border border-black rounded-md text-black text-xs font-normal cursor-pointer bg-transparent mt-6"
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalContainer>

        <ModalContainer open={detailsModalOpen} closeModal={closeDetailsModal}>
          {orderDetails && (
            <div id="modal2">
              <button
                id="close-btn1"
                onClick={() => setDetailsModalOpen(false)}
              >
                &times;
              </button>
              <div className="order-details-modal bg-white">
                <div className="order-item rounded-md px-6 py-8">
                  <div className="order-id">
                    <div className="md:flex justify-between">
                      <div className="id-info text-left">
                        <h5 className="text-black font-medium text-base">
                          Order ID#
                          <span className="text-primaryColor">
                            {orderDetails[0]?.order_number}
                          </span>
                        </h5>
                        <h4 className="text-black font-medium text-base">
                          <span className="text-btnBg">
                            {orderDetails[0]?.restaurant_branch.name} -{" "}
                            {orderDetails[0]?.restaurant_branch.postcode}
                          </span>
                        </h4>
                        <p className="text-gray-500 text-sm font-normal pt-1">
                          {moment(orderDetails[0]?.created_at).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex justify-between items-start mt-4">
                    <div>
                      {orderDetails[0]?.items.map((foodItem) => (
                        <div
                          className="md:flex justify-between"
                          key={foodItem.id}
                        >
                          <div className="md:flex items-start w-10/12 md:w-full">
                            <div className="thumb h-20 w-14 flex items-center">
                              <img
                                src={`${IMAGE_URL}${foodItem.food_items.image}`}
                                alt={foodItem.food_items.name}
                                width="42"
                                height="56"
                              />
                            </div>
                            <div className="text md:pl-3 text-left">
                              <h4 className="text-black text-base">
                                {foodItem.food_items.name}
                              </h4>
                              <h6 className="text-black text-sm">
                                QTY: {foodItem.quantity}
                              </h6>
                              <span className="text-black text-base">
                                Price:{" "}
                                <strong>
                                  £{foodItem.food_items.total_price}
                                </strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="order-status pt-5 md:pt-0 text-left md:text-right">
                      <h4 className="text-black text-sm font-semibold">
                        Order Status
                      </h4>
                      <p className="text-black text-sm font-medium">
                        {/* 5:20 PM */}
                        {orderDetails[0]?.delivery_time === "schedule" ? (
                          <>
                            {moment(orderDetails[0]?.schedule_date).format(
                              "dddd, MMMM Do YYYY"
                            )}
                            , {tConvert(orderDetails[0]?.schedule_time)}
                          </>
                        ) : (
                          moment(orderDetails[0]?.order_delivery_time).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )
                        )}
                      </p>
                      <p className=" text-red-500 text-base font-normal inline-block">
                        {orderDetails[0]?.order_status}
                      </p>
                      <br />
                    </div>
                  </div>
                  <div className="border-t border-dashed mt-4 pt-4 text-left">
                    <p className="text-black text-xs">
                      Delivery Fee:{" "}
                      <strong className="font-semibold">
                        £{orderDetails[0]?.restaurant_branch.delivery_charge}
                      </strong>
                    </p>
                    <p className="text-primaryColor text-xs">
                      Voucher Discount:{" "}
                      <strong className="font-semibold">
                        £{orderDetails[0]?.discount_amount}
                      </strong>
                    </p>
                    <p className="text-xs">
                      <strong className="text-black font-semibold">
                        Delivery to
                      </strong>
                      : {orderDetails[0]?.shipping_address.others} Post Code:{" "}
                      {orderDetails[0]?.shipping_address.postcode}{" "}
                      {orderDetails[0]?.shipping_address.city}
                    </p>
                  </div>
                </div>
                <div className="order-total py-4 px-4 text-right">
                  <span className="text-lg font-medium text-black">
                    Total:{" "}
                    <strong className="font-semibold">
                      £{orderDetails[0].total_price}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          )}
        </ModalContainer>
      </section>
    </Layout>
  );
};

export default Order;
