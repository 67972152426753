import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

const AddressContainer = ({
  toggleState,
  toggleTab,
  closeAddressModal,
  userAddress,
  handleToggle,
  selectAddress,
  handleSaveAddress,
  addressData,
  setAddressData,
  handleSubmit,
  handleDataChange,
  handleSelect,
}) => {
  return (
    <div id="address-modal">
      <div id="modal2">
        <div className="modal-content flex flex-col">
          <button id="close-btn1" onClick={closeAddressModal}>
            &times;
          </button>
          <div className="address-content">
            <ul className="nav nav-tabs flex flex-wrap justify-start text-base gap-4 sm:divide-x-2 ml-4">
              <li
                className={toggleState === 1 ? "active" : ""}
                onClick={() => toggleTab(1)}
              >
                <div className="cursor-pointer">Shipping address</div>
              </li>
              <li
                className={toggleState === 2 ? " sm:pl-4 active" : "sm:pl-4"}
                onClick={() => toggleTab(2)}
              >
                <div className="cursor-pointer">Add New Address</div>
              </li>
            </ul>
            <div className="tab-content">
              <div
                id="tab-1"
                className={toggleState === 1 ? "tab-pane active" : "tab-pane"}
              >
                <div className="order-content">
                  <ul className="order-list list-none">
                    <li className="">
                      <div className="grid-cols-6 gap-6 text-base p-4 bg-gray-100 mt-6 hidden md:grid text-left">
                        {/* <div className="pr-name">
                          <p>Name</p>
                        </div> */}
                        <div className="pr-address">
                          <p>Address</p>
                        </div>
                        <div className="pr-region">
                          <p>Appartment / Street..</p>
                        </div>
                        <div className="pr-region">
                          <p>Postal Code</p>
                        </div>
                        <div className="pr-number">
                          <p>Phone number</p>
                        </div>
                        <div className="pr-status">
                          <p></p>
                        </div>
                        <div className="pr-select">
                          <p></p>
                        </div>
                      </div>
                      <div className="pr-item">
                        {userAddress.map((address) => (
                          <div
                            className="grid grid-cols-1 md:grid-cols-6 lg:gap-6 md:gap-4 gap-3 text-sm p-4 py-6 text-left"
                            key={address.id}
                          >
                            {/* <div className="item-content">
                            <p className="text-sm">Md Sayem</p>
                          </div> */}
                            <div className="item-content">
                              <p className="text-sm">{address?.full_address}</p>
                            </div>
                            <div className="item-content">
                              <p className="text-sm">{address?.others}</p>
                            </div>
                            <div
                              className="item-content"
                              onClick={() => {
                                console.log(address?.postcode);
                              }}
                            >
                              <p className="text-sm">{address?.postcode}</p>
                            </div>
                            <div className="item-content">
                              <p>{address.phone_number}</p>
                            </div>
                            <div className="item-content">
                              <p className="text-xs">
                                {address.default_address
                                  ? "Default Address"
                                  : ""}
                              </p>
                            </div>

                            <label
                              htmlFor="pr1"
                              className="flex items-center cursor-pointer text-xl"
                            >
                              <span
                                className={
                                  selectAddress === address.id
                                    ? "w-6 h-6 inline- mr-2 rounded-sm border border-grey flex-no-shrink radioButton"
                                    : "w-6 h-6 inline- mr-2 rounded-sm border border-grey flex-no-shrink"
                                }
                                onClick={() =>
                                  handleToggle(address.id, "address")
                                }
                              ></span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </li>
                  </ul>
                  <div className="flex sm:justify-end justify-center sm:gap-4 gap-3 mt-6">
                    <button
                      type="button"
                      // onClick={closeAddressModal}
                      onClick={handleSaveAddress}
                      className="inline-flex justify-center py-2 px-4 mr-2 sm:mr-3 border shadow-sm text-sm font-medium rounded-md border-black hover:bg-[#89e488] hover:text-white hover:border-[#89e488] hover:scale-110 ease-in-out duration-100 delay-150 sm:w-32 w-24"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      // onClick={() => {setSelectAddress(null) closeAddressModal()}}
                      onClick={closeAddressModal}
                      className="inline-flex justify-center py-2 px-4 mr-2 sm:mr-3 shadow-sm text-sm font-medium rounded-md border border-black hover:bg-[#89e488] hover:text-white hover:border-[#89e488] hover:scale-110 ease-in-out duration-100 delay-150 sm:w-32 w-24"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div
                id="tab-2"
                className={toggleState === 2 ? "tab-pane active" : "tab-pane"}
              >
                <div className="sm:mt-0">
                  <div className="md:col-span-2">
                    <form method="POST" onSubmit={handleSubmit}>
                      <div className="overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="relative col-span-6 sm:col-span-3">
                              <PlacesAutocomplete
                                value={addressData.full_address}
                                onChange={(e) =>
                                  setAddressData({
                                    ...addressData,
                                    full_address: e,
                                  })
                                }
                                onSelect={handleSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Search Places ...",
                                        // className: "location-search-input",
                                        className:
                                          "mt-1 focus:outline-none border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm text-sm rounded-md h-10 p-2",
                                      })}
                                    />
                                    <div className="absolute top-10 left-0 p-5 rounded-md w-full z-20">
                                      {loading && (
                                        <div className="shadow-md bg-white">
                                          Loading...
                                        </div>
                                      )}
                                      {suggestions.map((suggestion, index) => {
                                        // const className = suggestion.active
                                        //   ? "suggestion-item--active"
                                        //   : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#1E40AF",
                                              cursor: "pointer",
                                              color: "#fff",
                                              padding: "10px 0",
                                              borderRadius: "5px",
                                              fontSize: "16px",
                                            }
                                          : {
                                              backgroundColor: "#fff",
                                              cursor: "pointer",
                                              padding: "10px 0",
                                              fontSize: "16px",
                                            };
                                        return (
                                          <div
                                            key={index}
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              { style }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                              {/* <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                placeholder="Street, apartment/house/unit"
                                className="mt-1 focus:outline-none border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm text-sm rounded-md h-10 p-2"
                              /> */}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <input
                                type="text"
                                name="city"
                                id="last-name"
                                value={addressData.city}
                                onChange={handleDataChange}
                                placeholder="City/Town"
                                className="mt-1 focus:outline-none border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm text-sm rounded-md h-10 p-2"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <input
                                type="text"
                                name="postcode"
                                id="post-code"
                                value={addressData.postcode}
                                onChange={handleDataChange}
                                placeholder="Post Code"
                                className="mt-1 focus:outline-none focus:ring-indigo-500 w-full focus:border-indigo-500  shadow-sm text-sm border border-gray-300 rounded-md h-10 p-2"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <input
                                type="number"
                                id="number"
                                name="phone_number"
                                placeholder="Phone Number"
                                value={addressData.phone_number}
                                onChange={handleDataChange}
                                className="mt-1  w-full appearance-none py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                              />
                            </div>

                            <div className="col-span-6">
                              <input
                                type="text"
                                name="others"
                                id="others"
                                value={addressData.others}
                                onChange={handleDataChange}
                                className="mt-1 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm text-sm border border-gray-300 rounded-md p-2"
                                placeholder="Apartment/Street or others"
                              />
                            </div>

                            <div className="col-span-6">
                              <textarea
                                name="rider_instruction"
                                id="riderInstructions"
                                cols="30"
                                rows="5"
                                value={addressData.rider_instruction}
                                onChange={handleDataChange}
                                className="mt-1 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm text-sm border border-gray-300 rounded-md p-2"
                                placeholder="Instructions for you rider"
                              ></textarea>
                            </div>
                          </div>
                          <div className="py-3 md:text-right text-center sm:px-6 px-2">
                            <button
                              type="submit"
                              className="inline-flex justify-center py-2 px-4 mr-2 sm:mr-3 border shadow-sm text-sm font-medium rounded-md border-black hover:bg-[#89e488] hover:text-white hover:border-[#89e488] hover:scale-110 ease-in-out duration-100 delay-150 sm:w-32 w-24"
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={closeAddressModal}
                              className="inline-flex justify-center py-2 px-4 mr-2 sm:mr-3 border shadow-sm text-sm font-medium rounded-md border-black hover:bg-[#89e488] hover:text-white hover:border-[#89e488] hover:scale-110 ease-in-out duration-100 delay-150 sm:w-32 w-24"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressContainer;
