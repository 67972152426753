import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { adminRestaurantCategoryAdd } from "../../../../redux/actions/AdminActions/adminRestaurantActions";

function RestaurantCategoriesCreateUpdateForm() {
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {},
  });

  const dispatch = useDispatch();

  const handleSubmitData = (data) => {
    console.log(data);
    dispatch(adminRestaurantCategoryAdd(data));
    reset();
  };

  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">Add Restaurant Categories</h1>
      <div>
        <form action="" onSubmit={handleSubmit(handleSubmitData)}>
          <div className="md:grid md:grid-cols-1 md:gap-3 pt-2">
            <div className="md:w-1/3">
              <label htmlFor="name" className=" text-gray-700">
                Name
              </label>

              <input
                type="text"
                {...register("name", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                name="name"
                id="name"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.name?.message}</p>
            </div>
            <div className="form-check flex items-center my-1">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                defaultChecked
                {...register("status")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="flexCheckChecked"
              >
                Status
              </label>
            </div>
            <div className=" mt-2">
              <button className="bg-[#77C9F2] rounded text-white px-4 py-2">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RestaurantCategoriesCreateUpdateForm;
