import React from "react";
import Rating from "react-rating";

import ModalContainer from "../common/ModalContainer";

const RestaurantRatings = ({
  modalIsOpen,
  closeModal,
  name,
  image,
  categories,
  resRating,
  setIsOpen,
}) => {
  const avgRating = resRating
    .map((r) => r.rating)
    .reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue / resRating.length,
      0
    );
  // console.log(avgRating);
  return (
    <ModalContainer open={modalIsOpen} closeModal={closeModal}>
      {/* <div id="modal-background"> */}
      <div id="" className="w-full my-20">
        {/* <div className="flex justify-center items-center lg:py-[60px] py-10"> */}

        <div className="bg-white rounded-md ">
          <div className="relative ">
            <img className="rounded-md w-full" src={image} alt="" />
            <button
              onClick={closeModal}
              type="button"
              className="absolute top-2 right-2 bg-white bg-opacity-70 rounded-full h-[25px] w-[25px] leading-[25px]"
            >
              <svg
                width="10"
                height="10"
                className="mx-auto"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.22251 4.97678C6.38674 5.12602 6.50617 5.23794 6.62561 5.34986C7.43926 6.16318 8.25292 6.9765 9.06657 7.78981C9.35769 8.08082 9.4622 8.40913 9.32037 8.79713C9.12629 9.33437 8.45446 9.53583 7.97672 9.20752C7.89461 9.14783 7.81996 9.08068 7.75278 9.00606C6.87941 8.13305 6.0135 7.2675 5.14013 6.39449C5.09534 6.34972 5.05055 6.30495 4.9983 6.26018C4.94605 6.31241 4.90126 6.35718 4.85647 6.40195C3.96817 7.28989 3.07987 8.17782 2.19157 9.06575C1.90044 9.35676 1.56453 9.45376 1.18383 9.31199C0.646372 9.11052 0.45229 8.43898 0.788202 7.96889C0.840455 7.89428 0.907637 7.82712 0.97482 7.75997C1.84819 6.88696 2.7141 6.01395 3.58747 5.1484C3.63226 5.10363 3.69198 5.07378 3.75169 5.02155C3.67705 4.93947 3.63226 4.89471 3.58 4.84247C2.6917 3.95454 1.81087 3.07407 0.922567 2.1936C0.638908 1.90259 0.534402 1.56682 0.676231 1.17882C0.870314 0.64158 1.54214 0.440116 2.01988 0.768428C2.10199 0.828121 2.17664 0.895275 2.24382 0.969891C3.11719 1.8429 3.9831 2.70845 4.85647 3.58146C4.90126 3.62623 4.94605 3.671 4.9983 3.71577C5.05055 3.66354 5.09534 3.61877 5.14013 3.574C6.02843 2.68606 6.91673 1.79813 7.80503 0.910198C8.09616 0.619195 8.43207 0.522194 8.81277 0.663965C9.35023 0.865429 9.54431 1.53697 9.2084 2.00706C9.15615 2.08167 9.08896 2.14883 9.02178 2.21598C8.14841 3.08899 7.2825 3.962 6.40913 4.82755C6.36434 4.88724 6.30463 4.91709 6.22251 4.97678Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
          <div className=" licence_content bg-white bg-opacity-80 rounded-md p-4 text-center max-w-[300px] mx-auto shadow-themeShadow -mt-[70px] relative">
            <h4 className="text-black lg:text-lg text-base font-medium font-body">
              {name}
            </h4>
            <div className="flex items-center justify-center gap-3">
              <div className="flex items-center justify-center gap-1 py-2">
                <Rating
                  emptySymbol="fa fa-star-o text-[#817d7d]"
                  fullSymbol="fa fa-star text-[#FBD336]"
                  initialRating={avgRating}
                  readonly
                  fractions={2}
                />
              </div>
              <p className="text-black md:text-base text-sm font-light font-body">
                {avgRating?.toFixed(2)}
              </p>
            </div>
            <div className="grid grid-cols-3 mx-auto">
              {categories?.map((category, index) => (
                <p key={index} className="text-start ">
                  {category.name}
                  {index === categories.length - 1 ? "" : "."}
                </p>
              ))}
            </div>
          </div>
          <div className="rating_content my-4 p-6">
            <h4 className="text-black text-center text-base font-medium font-body">
              All Rating
            </h4>
            <ul className="rating_list pt-6 max-h-[450px] overflow-y-auto pr-2">
              {resRating.map((rating, i) =>
                i === resRating.length - 1 ? (
                  <li
                    key={i}
                    className=" border-[#8b8b8b] border-opacity-60 pb-3 pt-3"
                  >
                    <div className="flex items-start justify-between gap-3">
                      <div className="pr_text">
                        <h4 className="text-black lg:text-base text-sm font-body font-medium">
                          {rating.user?.identifier}
                        </h4>
                        <div className="flex items-center gap-2">
                          <span>
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.95009 10.7125C9.99671 11.0595 9.79161 11.2233 9.48396 11.0595L6.93889 9.73894C6.63124 9.57508 6.12782 9.57508 5.8295 9.7293L3.2751 11.0306C2.96746 11.1848 2.76236 11.0306 2.80897 10.6739L3.23781 7.76296C3.29375 7.41596 3.13526 6.91474 2.9022 6.66413L0.916477 4.5532C0.674089 4.30259 0.757991 4.04234 1.09361 3.98451L3.89972 3.5122C4.23533 3.45436 4.64553 3.15556 4.80401 2.83747L6.12782 0.234963C6.28631 -0.0831218 6.54734 -0.0734829 6.70583 0.234963L8.01099 2.84711C8.16947 3.1652 8.57034 3.464 8.90596 3.53148L11.7121 4.0327C12.0477 4.09053 12.1316 4.35078 11.8892 4.6014L9.88484 6.69304C9.64245 6.94366 9.48396 7.43524 9.5399 7.78224L9.95009 10.7125Z"
                                fill="#FF953A"
                              />
                              <path
                                opacity="0.5"
                                d="M6.42854 0.169974C6.48448 0.169974 6.54041 0.227808 6.59635 0.333836L7.90151 2.94598C8.07864 3.30262 8.51681 3.63035 8.89903 3.70746L11.7051 4.20868C11.8077 4.22796 11.8823 4.26652 11.9009 4.32435C11.9196 4.37255 11.8823 4.4593 11.8077 4.53641L9.80333 6.62805C9.53297 6.90758 9.35584 7.44736 9.41177 7.84256L9.82197 10.7535C9.83129 10.8499 9.82197 10.927 9.794 10.9656C9.78468 10.9752 9.76603 10.9945 9.71942 10.9945C9.67281 10.9945 9.61687 10.9752 9.56094 10.9463L7.01586 9.62576C6.84805 9.53901 6.62431 9.49081 6.39125 9.49081C6.15818 9.49081 5.94377 9.53901 5.77596 9.62576L3.22156 10.927C3.16562 10.9559 3.10969 10.9752 3.06307 10.9752C3.00714 10.9752 2.98849 10.9559 2.98849 10.9463C2.9512 10.9077 2.94188 10.8306 2.96053 10.7342L3.38936 7.82328C3.4453 7.42808 3.28681 6.8883 3.01646 6.60878L1.03074 4.49785C0.956163 4.42074 0.918874 4.34363 0.937519 4.2858C0.956164 4.2376 1.03074 4.18941 1.13329 4.17013L3.9394 3.69782C4.32163 3.63035 4.75979 3.30262 4.94624 2.94598L6.27006 0.343475C6.31667 0.227808 6.38193 0.169974 6.42854 0.169974ZM6.42854 0.0253906C6.32599 0.0253906 6.22344 0.102502 6.13954 0.256725L4.81572 2.85923C4.65724 3.16768 4.24705 3.47613 3.91144 3.53396L1.10533 4.00627C0.769712 4.0641 0.685806 4.31471 0.928194 4.57496L2.91391 6.68589C3.1563 6.9365 3.30546 7.43772 3.24952 7.78473L2.82068 10.6957C2.78339 10.9559 2.88594 11.1102 3.06307 11.1102C3.12833 11.1102 3.20292 11.0909 3.2775 11.0523L5.83189 9.75107C5.98105 9.67395 6.18615 9.6354 6.38192 9.6354C6.58702 9.6354 6.79212 9.67395 6.94129 9.7607L9.48636 11.0812C9.57026 11.1198 9.64484 11.1391 9.7101 11.1391C9.88723 11.1391 9.98978 10.9848 9.95249 10.7342L9.54229 7.82328C9.49568 7.47628 9.65416 6.97505 9.88723 6.73408L11.8916 4.64244C12.134 4.39182 12.0501 4.13157 11.7145 4.07374L8.90835 3.57251C8.57274 3.51468 8.16254 3.20624 8.01338 2.88815L6.70822 0.276003C6.64296 0.102502 6.54041 0.0253906 6.42854 0.0253906Z"
                                fill="#FF953A"
                              />
                            </svg>
                          </span>
                          <span className="text-[#8b8b8b] text-xs">
                            {rating?.rating}
                          </span>
                        </div>
                        <div className="text-[#8b8b8b] text-sm text-start">
                          {rating?.review}
                        </div>
                      </div>
                      <div className="rating_date">
                        <span className="text-[#8b8b8b] text-xs">
                          10.12.2021
                        </span>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li className="border-b border-[#8b8b8b] border-opacity-60 pb-3 pt-3">
                    <div className="flex items-start justify-between gap-3">
                      <div className="pr_text">
                        <h4 className="text-black lg:text-base text-sm font-body font-medium">
                          {rating.user?.identifier}
                        </h4>
                        <div className="flex items-center gap-2">
                          <span>
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.95009 10.7125C9.99671 11.0595 9.79161 11.2233 9.48396 11.0595L6.93889 9.73894C6.63124 9.57508 6.12782 9.57508 5.8295 9.7293L3.2751 11.0306C2.96746 11.1848 2.76236 11.0306 2.80897 10.6739L3.23781 7.76296C3.29375 7.41596 3.13526 6.91474 2.9022 6.66413L0.916477 4.5532C0.674089 4.30259 0.757991 4.04234 1.09361 3.98451L3.89972 3.5122C4.23533 3.45436 4.64553 3.15556 4.80401 2.83747L6.12782 0.234963C6.28631 -0.0831218 6.54734 -0.0734829 6.70583 0.234963L8.01099 2.84711C8.16947 3.1652 8.57034 3.464 8.90596 3.53148L11.7121 4.0327C12.0477 4.09053 12.1316 4.35078 11.8892 4.6014L9.88484 6.69304C9.64245 6.94366 9.48396 7.43524 9.5399 7.78224L9.95009 10.7125Z"
                                fill="#FF953A"
                              />
                              <path
                                opacity="0.5"
                                d="M6.42854 0.169974C6.48448 0.169974 6.54041 0.227808 6.59635 0.333836L7.90151 2.94598C8.07864 3.30262 8.51681 3.63035 8.89903 3.70746L11.7051 4.20868C11.8077 4.22796 11.8823 4.26652 11.9009 4.32435C11.9196 4.37255 11.8823 4.4593 11.8077 4.53641L9.80333 6.62805C9.53297 6.90758 9.35584 7.44736 9.41177 7.84256L9.82197 10.7535C9.83129 10.8499 9.82197 10.927 9.794 10.9656C9.78468 10.9752 9.76603 10.9945 9.71942 10.9945C9.67281 10.9945 9.61687 10.9752 9.56094 10.9463L7.01586 9.62576C6.84805 9.53901 6.62431 9.49081 6.39125 9.49081C6.15818 9.49081 5.94377 9.53901 5.77596 9.62576L3.22156 10.927C3.16562 10.9559 3.10969 10.9752 3.06307 10.9752C3.00714 10.9752 2.98849 10.9559 2.98849 10.9463C2.9512 10.9077 2.94188 10.8306 2.96053 10.7342L3.38936 7.82328C3.4453 7.42808 3.28681 6.8883 3.01646 6.60878L1.03074 4.49785C0.956163 4.42074 0.918874 4.34363 0.937519 4.2858C0.956164 4.2376 1.03074 4.18941 1.13329 4.17013L3.9394 3.69782C4.32163 3.63035 4.75979 3.30262 4.94624 2.94598L6.27006 0.343475C6.31667 0.227808 6.38193 0.169974 6.42854 0.169974ZM6.42854 0.0253906C6.32599 0.0253906 6.22344 0.102502 6.13954 0.256725L4.81572 2.85923C4.65724 3.16768 4.24705 3.47613 3.91144 3.53396L1.10533 4.00627C0.769712 4.0641 0.685806 4.31471 0.928194 4.57496L2.91391 6.68589C3.1563 6.9365 3.30546 7.43772 3.24952 7.78473L2.82068 10.6957C2.78339 10.9559 2.88594 11.1102 3.06307 11.1102C3.12833 11.1102 3.20292 11.0909 3.2775 11.0523L5.83189 9.75107C5.98105 9.67395 6.18615 9.6354 6.38192 9.6354C6.58702 9.6354 6.79212 9.67395 6.94129 9.7607L9.48636 11.0812C9.57026 11.1198 9.64484 11.1391 9.7101 11.1391C9.88723 11.1391 9.98978 10.9848 9.95249 10.7342L9.54229 7.82328C9.49568 7.47628 9.65416 6.97505 9.88723 6.73408L11.8916 4.64244C12.134 4.39182 12.0501 4.13157 11.7145 4.07374L8.90835 3.57251C8.57274 3.51468 8.16254 3.20624 8.01338 2.88815L6.70822 0.276003C6.64296 0.102502 6.54041 0.0253906 6.42854 0.0253906Z"
                                fill="#FF953A"
                              />
                            </svg>
                          </span>
                          <span className="text-[#8b8b8b] text-xs">
                            {rating?.rating}
                          </span>
                        </div>
                        <div className="text-[#8b8b8b] text-sm text-start">
                          {rating?.review}
                        </div>
                      </div>
                      <div className="rating_date">
                        <span className="text-[#8b8b8b] text-xs">
                          10.12.2021
                        </span>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </ModalContainer>
  );
};

export default RestaurantRatings;
