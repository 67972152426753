import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CashDelivery from "../components/payment/CashDelivery";
import Jcb from "../components/payment/Jcb";
import Maestro from "../components/payment/Maestro";
import MasterCard from "../components/payment/MasterCard";
import NetBanking from "../components/payment/NetBanking";
import Paypal from "../components/payment/Paypal";
import Visa from "../components/payment/Visa";
import loadingImg from "../images/app/loading.gif";
import deliveryImg from "../images/payment-card/delivery.png";
import jcbImg from "../images/payment-card/jcb.png";
import maestroImg from "../images/payment-card/maestro.png";
import masterImg from "../images/payment-card/master.png";
import NetImg from "../images/payment-card/net-banking.png";
import paypalImg from "../images/payment-card/paypal.png";
import visaImg from "../images/payment-card/visa.png";
import { getOrderSummery } from "../redux/actions/orderAction";
import Layout from "./Layout";

const Payment = () => {
  const { order_summery, loading } = useSelector((state) => state.order);
  console.log(order_summery);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (order_summery.length === 0) {
  //     history.push("/");
  //   }
  // }, [order_summery]);

  useEffect(() => {
    dispatch(getOrderSummery());
  }, [dispatch]);

  let currentTab;
  if (params.type === "delivery" || !params.type) {
    currentTab = <CashDelivery />;
  }
  if (params.type === "jcb") {
    currentTab = (
      <Jcb
      // amount={parseInt(amount).toFixed(2)}
      />
    );
    // currentTab = <Jcb order_summery={order_summery} />;
  }
  if (params.type === "visa") {
    currentTab = <Visa />;
  }
  if (params.type === "maestro") {
    currentTab = <Maestro />;
  }
  if (params.type === "master-card") {
    currentTab = <MasterCard />;
  }
  if (params.type === "paypal") {
    currentTab = <Paypal />;
  }
  if (params.type === "net-banking") {
    currentTab = <NetBanking />;
  }

  return (
    <Layout>
      <section className="checkout-area py-10">
        <div className="container">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-9/12 md:pr-5">
              <div className="payment-wrapper">
                <div className="wrap-title">
                  <h4 className="text-black text-base font-medium pb-3 pl-3">
                    Select Your Payment Method
                  </h4>
                </div>
                <div className="card-list flex gap-3 p-3 pb-8">
                  <button
                    type="button"
                    className={
                      params.type === "delivery"
                        ? "card-item card-active bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md border "
                        : "card-item bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md"
                    }
                    onClick={() => history.push("/payment/delivery")}
                  >
                    <div className="item-thumb">
                      <img
                        src={deliveryImg}
                        className="mx-auto h-6 object-scale-down"
                        alt="delivery"
                      />
                    </div>
                    <div className="item-text mt-2">
                      <span className="text-gray-700 text-xs">
                        Cash On Delivery
                      </span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={
                      params.type === "jcb"
                        ? "card-item card-active bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md border border-[#89e488]"
                        : "card-item bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md"
                    }
                    // className="card-item bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md"
                    onClick={() => history.push("/payment/jcb")}
                  >
                    <div className="item-thumb">
                      <img
                        src={jcbImg}
                        className="mx-auto h-6 object-scale-down"
                        alt="jcb"
                      />
                    </div>
                    <div className="item-text mt-2">
                      <span className="text-gray-700 text-xs">JCB</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={
                      params.type === "master-card"
                        ? "card-item card-active bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md border border-[#89e488]"
                        : "card-item bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md"
                    }
                    onClick={() => history.push("/payment/master-card")}
                  >
                    <div className="item-thumb">
                      <img
                        src={masterImg}
                        className="mx-auto h-6 object-scale-down"
                        alt="master"
                      />
                    </div>
                    <div className="item-text mt-2">
                      <span className="text-gray-700 text-xs">MasterCard</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={
                      params.type === "visa"
                        ? "card-item card-active bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md border border-[#89e488]"
                        : "card-item bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md"
                    }
                    onClick={() => history.push("/payment/visa")}
                  >
                    <div className="item-thumb">
                      <img
                        src={visaImg}
                        className="mx-auto h-6 object-scale-down"
                        alt="visa"
                      />
                    </div>
                    <div className="item-text mt-2">
                      <span className="text-gray-700 text-xs">Visa</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={
                      params.type === "maestro"
                        ? "card-item card-active bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md border border-[#89e488]"
                        : "card-item bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md"
                    }
                    onClick={() => history.push("/payment/maestro")}
                  >
                    <div className="item-thumb">
                      <img
                        src={maestroImg}
                        className="mx-auto h-6 object-scale-down"
                        alt="maestro"
                      />
                    </div>
                    <div className="item-text mt-2">
                      <span className="text-gray-700 text-xs">Maestro</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={
                      params.type === "paypal"
                        ? "card-item card-active bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md border border-[#89e488]"
                        : "card-item bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md"
                    }
                    onClick={() => history.push("/payment/paypal")}
                  >
                    <div className="item-thumb">
                      <img
                        src={paypalImg}
                        className="mx-auto h-6 object-scale-down"
                        alt="paypal"
                      />
                    </div>
                    <div className="item-text mt-2">
                      <span className="text-gray-700 text-xs">PayPal</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={
                      params.type === "net-banking"
                        ? "card-item card-active bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md border border-[#89e488]"
                        : "card-item bg-white shadow-lg px-2 pt-4 pb-3 text-center rounded-md"
                    }
                    onClick={() => history.push("/payment/net-banking")}
                  >
                    <div className="item-thumb">
                      <img
                        src={NetImg}
                        className="mx-auto h-6 object-scale-down"
                        alt="Net Banking"
                      />
                    </div>
                    <div className="item-text mt-2">
                      <span className="text-gray-700 text-xs">Net Banking</span>
                    </div>
                  </button>
                </div>
                {/* <div className="card-content shadow-lg mt-4 px-4 py-5 bg-white sm:p-6 rounded-md">
                  <div className="grid lg:grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        placeholder="Bank Transaction ID"
                        className="mt-1 focus:outline-none border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm rounded-md h-10 p-2"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        placeholder="Order Number"
                        className="mt-1 focus:outline-none border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm rounded-md h-10 p-2"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="text"
                        name="account-number"
                        id="account-number"
                        placeholder="Account Number"
                        className="mt-1 focus:outline-none focus:ring-indigo-500 w-full focus:border-indigo-500  shadow-sm sm:text-sm border border-gray-300 rounded-md h-10 p-2"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="number"
                        id="number"
                        name="number"
                        placeholder="Payment Reference Number"
                        className="mt-1  w-full appearance-none py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="text"
                        name="sort-code"
                        id="Short Code"
                        placeholder="Account Number"
                        className="mt-1 focus:outline-none focus:ring-indigo-500 w-full focus:border-indigo-500  shadow-sm sm:text-sm border border-gray-300 rounded-md h-10 p-2"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="number"
                        id="amount"
                        name="amount"
                        placeholder="Amount"
                        className="mt-1  w-full appearance-none py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6">
                      <div className="flex items-start">
                        <input
                          type="checkbox"
                          className="card-checkbox"
                          id=""
                        />
                        <div className="pl-2">
                          <h6 className="text-base font-medium text-black leading-3 pb-1">
                            Save input details
                          </h6>
                          <p className="text-sm text-gray-500">
                            I acknowledge that my card information is saved in
                            my Daraz account for subsequent transactions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 pt-6 sm:px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-8 border-0 shadow-sm text-sm font-medium rounded-md text-white bg-gradient-to-r from-indigo-900 to-pink-700 hover:from-pink-700 hover:to-indigo-900 w-32"
                    >
                      Pay Now
                    </button>
                  </div>
                </div> */}
                {currentTab}
                {/* <CashDelivery /> */}
                {/* <div className="card-content style-one shadow-lg mt-8 px-4 py-5 bg-white sm:p-6 rounded-md">
                  <div className="grid lg:grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <div className="">
                        <h6 className="text-base font-medium text-black leading-3 pb-2">
                          Cash On Deliverys
                        </h6>
                        <p className="text-sm text-gray-500">
                          Cash on delivery (COD) is when a recipient pays for a
                          good or service at the time of delivery.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="py-3 pt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-8 border-0 shadow-sm text-sm font-medium rounded-md text-white bg-gradient-to-r from-indigo-900 to-pink-700 hover:from-pink-700 hover:to-indigo-900 w-32"
                    >
                      Pay Now
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="md:w-3/12">
              <div className="order-summery bg-white p-4 rounded-md">
                <div className="flex items-center pb-6">
                  <i>
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 38.1"
                      className="h-5 w-5 fill-[#facd1b]"
                    >
                      <path
                        className="cls-1"
                        d="M42.51,33.51H16.24a1.3,1.3,0,0,1,0-2.59H41.47l.83-3.8H14.84a1.29,1.29,0,0,1-1.26-1l-1.4-6.39a1.28,1.28,0,0,1,.26-1.08,1.26,1.26,0,0,1,1-.48H44.27l.83-3.8H12a1.3,1.3,0,0,1-.91-.38l-6-6A1.48,1.48,0,0,0,3,10.07,1.29,1.29,0,0,1,1.19,11.9,4.07,4.07,0,0,1,4.07,5,4,4,0,0,1,7,6.14l5.63,5.63H46.71A1.31,1.31,0,0,1,48,13.34l-1.4,6.38a1.29,1.29,0,0,1-1.26,1H15.05l.83,3.8h28a1.32,1.32,0,0,1,1,.48,1.3,1.3,0,0,1,.25,1.09l-1.4,6.38A1.29,1.29,0,0,1,42.51,33.51Z"
                        transform="translate(0 -4.95)"
                      />
                      <path
                        className="cls-1"
                        d="M22.39,43.05A3.61,3.61,0,1,1,26,39.44,3.61,3.61,0,0,1,22.39,43.05Zm0-5a1.39,1.39,0,1,0,1.39,1.39A1.39,1.39,0,0,0,22.39,38.05Z"
                        transform="translate(0 -4.95)"
                      />
                      <path
                        className="cls-1"
                        d="M37.63,43.05a3.61,3.61,0,1,1,3.61-3.61A3.61,3.61,0,0,1,37.63,43.05Zm0-5A1.39,1.39,0,1,0,39,39.44,1.39,1.39,0,0,0,37.63,38.05Z"
                        transform="translate(0 -4.95)"
                      />
                    </svg>
                  </i>
                  <h4 className="text-lg text-black font-semibold ml-2">
                    Basket
                  </h4>
                </div>
                {loading ? (
                  <img
                    className="rounded-lg w-full"
                    src={loadingImg}
                    alt="not available"
                  />
                ) : (
                  <ul className="list-none">
                    {order_summery?.items?.map((item) => (
                      <li
                        key={item.id}
                        className="flex justify-between items-center my-4"
                      >
                        <p className="text-black font-normal text-sm">
                          {item.quantity} x {item.food_items.name}
                        </p>
                        <p className="text-black font-normal text-sm">
                          £{item.food_items.total_price}
                        </p>
                      </li>
                    ))}

                    <li className="flex justify-between items-center my-4 border-t">
                      <p className="text-gray-400 font-normal text-sm">
                        Subtotal
                      </p>
                      <p className="text-gray-400 font-normal text-sm">
                        £{order_summery?.subtotal_price}
                      </p>
                    </li>
                    <li className="flex justify-between items-center my-4">
                      <p className="text-gray-400 font-normal text-sm">
                        Delivery fee
                      </p>
                      <p className="text-gray-400 font-normal text-sm">
                        £{order_summery?.shipping_price}
                      </p>
                    </li>
                    <li className="flex justify-between items-center my-4 pb-4 border-b">
                      <p className="text-gray-400 font-normal text-sm">
                        Service fee
                      </p>
                      <p className="text-gray-400 font-normal text-sm">
                        £{order_summery?.service_fee}
                      </p>
                    </li>
                    <li className="flex justify-between items-center my-4 pb-4 border-b">
                      <p className="text-gray-400 font-normal text-sm">
                        Discount
                      </p>
                      <p className="text-gray-400 font-normal text-sm">
                        £{order_summery?.discount_amount}
                      </p>
                    </li>
                    <li className="flex justify-between items-center">
                      <p className="text-black-400 font-semibold text-sm">
                        Total
                      </p>
                      <p className="text-black-400 font-semibold text-sm">
                        £{order_summery?.total_price}
                      </p>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Payment;
