import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import MenuVariationsCreateUpdateForm from "../../../components/admin/Forms/MenuVariations/MenuVariationsCreateUpdateForm";
import {
  adminMenuVariationsDetails,
  adminMenuVariationsEdit,
} from "../../../redux/actions/AdminActions/adminMenuAction";
import { adminRestaurantBranchNameOnlyList } from "../../../redux/actions/AdminActions/adminRestaurantActions";
import Layout from "../Layout/layout";

function EditMenuVariations() {
  const { menuVariationsDetails, menuItemNameList } = useSelector(
    (state) => state.adminMenu
  );
  const { resBranchNameOnlyList } = useSelector(
    (state) => state.adminRestaurant
  );
  console.log(menuVariationsDetails);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(adminMenuVariationsDetails(params.id));
    dispatch(adminRestaurantBranchNameOnlyList());
  }, [dispatch, params]);

  const handleSubmitData = (data) => {
    console.log(data);
    const body = {
      restaurant_branch_id: data.restaurantBranch.value,
      food_items_id: data.foodItems.value,
      name: data.name,
      choose_item_number: data.itemsNumber,
      is_free: data.is_free,
      is_mandatory: data.is_mandatory,
      status: data.status,
    };
    dispatch(adminMenuVariationsEdit(body, params.id, history));
  };

  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <MenuVariationsCreateUpdateForm
            edit
            resBranchNameOnlyList={resBranchNameOnlyList}
            menuItemNameList={menuItemNameList}
            menuVariationsDetails={menuVariationsDetails}
            handleSubmitData={handleSubmitData}
          />
        </div>
      </div>
    </Layout>
  );
}

export default EditMenuVariations;
