import {
  VENDOR_ORDER_LIST,
  VENDOR_ORDER_ERROR,
  VENDOR_ORDER_LOADING,
  HOME_MADE_INGREDIENTS_LIST,
} from "../../types";

const initialState = {
  loading: false,
  orderList: [],
  orderHomeMadeIngredients: [],
  error: null,
};

export default function vendorOrderReducer(state = initialState, action = {}) {
  switch (action.type) {
    case VENDOR_ORDER_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VENDOR_ORDER_LIST:
      return {
        ...state,
        loading: false,
        orderList: action.payload,
        error: null,
      };

    case HOME_MADE_INGREDIENTS_LIST:
      return {
        ...state,
        loading: false,
        orderHomeMadeIngredients: action.payload,
        error: null,
      };

    case VENDOR_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
