import React from "react";

const TopCategories = () => {
  return (
    <div className="top_categories">
      <div className="item_head py-6">
        <h4 className="text-black lg:text-xl md:text-lg text-base font-medium font-body">
          Top Categories
        </h4>
      </div>
      <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-4">
        <div className="cate_item_thumb relative">
          <img
            className="xl:h-[150px] lg:h-[100px] h-[130px] xl:w-[150px] lg:w-[100px] w-[130px] mx-auto object-fill rounded-full"
            src="https://images.unsplash.com/photo-1565958011703-44f9829ba187?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80"
            alt=""
          />
          <div className="thumb_text absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <h4 className="text-white lg:text-xl md:text-lg sm:text-base text-sm uppercase font-medium font-body line-clamp-1">
              Oil
            </h4>
          </div>
        </div>

        <div className="cate_item_thumb relative">
          <img
            className="xl:h-[150px] lg:h-[100px] h-[130px] xl:w-[150px] lg:w-[100px] w-[130px] mx-auto object-fill rounded-full"
            src="https://images.unsplash.com/photo-1565958011703-44f9829ba187?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80"
            alt=""
          />
          <div className="thumb_text absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <h4 className="text-white lg:text-xl md:text-lg sm:text-base text-sm uppercase font-medium font-body line-clamp-1">
              Cheeses
            </h4>
          </div>
        </div>

        <div className="cate_item_thumb relative">
          <img
            className="xl:h-[150px] lg:h-[100px] h-[130px] xl:w-[150px] lg:w-[100px] w-[130px] mx-auto object-fill rounded-full"
            src="https://images.unsplash.com/photo-1565958011703-44f9829ba187?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80"
            alt=""
          />
          <div className="thumb_text absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <h4 className="text-white lg:text-xl md:text-lg sm:text-base text-sm uppercase font-medium font-body line-clamp-1">
              Ketchup
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCategories;
