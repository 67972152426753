import {
  ADMIN_RESTAURANT_LIST,
  ADMIN_RESTAURANT_LOADING,
  ADMIN_RESTAURANT_ERROR,
  ADMIN_RESTAURANT_DETAILS,
  ADMIN_RESTAURANT_DETAILS_WITH_BRANCH,
  ADMIN_RESTAURANT_NAME_ONLY_LIST,
  ADMIN_RESTAURANT_BRANCH_NAME_ONLY_LIST,
  ADMIN_RESTAURANT_CATEGORY,
  ADMIN_RESTAURANT_BRANCH_DETAILS_EDIT,
  ADMIN_REVIEW_RATING_LIST,
  GET_RESTAURANT_RATING_DATA,
} from "../../types";

const initialState = {
  loading: false,
  resList: [],
  resNameOnlyList: [],
  resBranchNameOnlyList: [],
  resCategoryList: [],
  resDetails: {},
  resBranchDetailsEdit: {},
  resDetailsWithBranch: {},
  reviewRating: [],
  adminResError: null,
};

export default function adminRestaurantReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case ADMIN_RESTAURANT_LOADING:
      return {
        ...state,
        loading: true,
        adminResError: null,
      };

    case ADMIN_RESTAURANT_LIST:
      return {
        ...state,
        loading: false,
        resList: action.payload,
        adminResError: null,
      };

    case ADMIN_RESTAURANT_NAME_ONLY_LIST:
      return {
        ...state,
        loading: false,
        resNameOnlyList: action.payload,
        adminResError: null,
      };

    case ADMIN_RESTAURANT_BRANCH_NAME_ONLY_LIST:
      return {
        ...state,
        loading: false,
        resBranchNameOnlyList: action.payload,
        adminResError: null,
      };

    case ADMIN_REVIEW_RATING_LIST:
      return {
        ...state,
        loading: false,
        reviewRating: action.payload,
        adminResError: null,
      };

    case ADMIN_RESTAURANT_CATEGORY:
      return {
        ...state,
        loading: false,
        resCategoryList: action.payload,
        adminResError: null,
      };
    case ADMIN_RESTAURANT_DETAILS:
      return {
        ...state,
        loading: false,
        resDetails: action.payload,
        adminResError: null,
      };
    case ADMIN_RESTAURANT_BRANCH_DETAILS_EDIT:
      return {
        ...state,
        loading: false,
        resBranchDetailsEdit: action.payload,
        adminResError: null,
      };
    case ADMIN_RESTAURANT_DETAILS_WITH_BRANCH:
      return {
        ...state,
        loading: false,
        resDetailsWithBranch: action.payload,
        adminResError: null,
      };

    case ADMIN_RESTAURANT_ERROR:
      return {
        ...state,
        loading: true,
        adminResError: null,
      };

    default:
      return state;
  }
}
