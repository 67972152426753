import React from 'react';
import feeThumb from "../../images/banner/faq_fees_thumb.png";
const FaqFees = () => {
  return (
    <div className="container relative">
      <div className='absolute xl:top-20 xl:-left-[40px] lg:top-0 lg:-left-[30px]'>
      <svg className='hidden lg:block' width="135" height="268" viewBox="0 0 135 268" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="1" cy="134" r="134" fill="#D7F7EE"/>
</svg>

      </div>
        <div
  className="
   flex justify-between items-center xl:gap-20 lg:gap-12 md:h-bg-height h-[400px] rounded-md "
>
<div className="banner-thumb xl:w-[40%] lg:w-[40%] lg:block hidden lg:mt-20 ">
    <img className="mt-0 ml-auto" src={feeThumb} alt="" />
  </div>
  <div className="banner-tex lg:w-[60%] w-full lg:pt-16">
    <h2 className="xl:text-3xl md:text-2xl text-xl text-[#002138] md:pb-3 pb-2 ">
    Fees on British Marketplace 
    </h2>
    <p className="md:text-lg text-base text-black font-normal font-oswald">
    How do fees work on British Marketplace?
    </p>
    <p className="text-base text-black font-oswald pb-5 text-[#4F4F4F]">
    The delivery fee is variable, based on your location. Some restaurants use their own delivery drivers. In that case, the restaurant sets its own delivery fee.
You pay less for nearby restaurants and you'll always know how much before selecting a restaurant.
    <br />
    With thousands of choices and a fleet of our own delivery riders, we’ll have your order with you in as little as 30 minutes
    </p>
    <p className="md:text-lg text-base text-black font-normal font-oswald">
    Service fee
    </p>
    <p className="text-base text-black font-oswald pb-5 text-[#4F4F4F]">
    This allows us to power your experience with British Marketplace, including adding new features and providing great customer service. A minimum or maximum amount may apply to the service fee, but you will always see the exact amount at checkout.    <br />
    Eight years on, we now operate in over 200 cities and towns across the UK, working with 50,000 restaurant partners, major grocery partners and more than 50,000 self-employed riders. Deliveroo is headquartered in London, with over 2,000 employees in offices around the globe.
    <br />
    The service fee is calculated based on the order amount prior to any promotions or discounts which may apply to your order.
    </p>
    <p className="md:text-lg text-base text-black font-normal font-oswald">
    Small order fee
    </p>
    <p className="text-base text-black font-oswald pb-5 text-[#4F4F4F]">
    When your total is less than the restaurant's minimum, you can still place the order but we'll top it up with a fee. To remove the fee, just add more to your basket.
    </p>
   
  </div>
</div>
    </div>
  )
}

export default FaqFees