import { Tooltip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ModalContainer from "../../../components/common/ModalContainer";
import Pagination from "../../../components/common/Pagination";
import {
    adminMenuVariationsDelete,
    adminMenuVariationsList,
} from "../../../redux/actions/AdminActions/adminMenuAction";
import Layout from "../Layout/layout";

function MenuVariationsList() {
  const { menuVariationsList } = useSelector((state) => state.adminMenu);
  console.log(menuVariationsList);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [deleleState, setDeleleState] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminMenuVariationsList());
  }, [dispatch]);

  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = async () => {
    dispatch(adminMenuVariationsDelete(deleleState));
    console.log(deleleState);
    closeModal();
    setDeleleState(null);
  };

  const handleDeleteClick = (delete_id) => {
    setIsOpen(true);
    setDeleleState(delete_id);
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    dispatch(adminMenuVariationsList({ page: currentPage }));
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    dispatch(adminMenuVariationsList({ page: 1, search: e.target.value }));
  };

  return (
    <Layout>
      <div className="m-3">
        <p className="text-center">Menu Variation List</p>
        <div className="flex items-center">
          {/* search bar */}
          <div className="relative md:w-1/3 my-2">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              value={search}
              onChange={handleSearch}
              id="simple-search"
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
              placeholder="Search here.."
            />
          </div>
          {/*  status select box*/}
          <div className="mx-2">
            <div className="flex justify-center">
              <div className="">
                <select
                  // value={status}
                  // onChange={handleStatusChange}
                  className="form-select 
      text-gray-700
      bg-white 
      border border-solid border-gray-300
      rounded
      focus:ring-gray-900 focus:border-gray-900 "
                  aria-label="Default select example"
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-scroll">
          <table className="table-auto  w-full border-collapse custom-table-data my-2">
            <thead className="text-center">
              <tr className="bg-gray-800 text-gray-300 ">
                <th className="custom-table-data"> Name</th>
                <th className="custom-table-data"> Food items</th>
                <th className="custom-table-data">Restaurant branch</th>
                <th className="custom-table-data"> Free</th>
                <th className="custom-table-data"> Mandatory</th>
                <th className="custom-table-data"> Choose item number</th>
                <th className="custom-table-data"> Status</th>
                <th className="custom-table-data"> Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {menuVariationsList?.data?.map((variation) => (
                <tr key={variation?.id}>
                  <td className="custom-table-data">{variation?.name}</td>
                  <td className="custom-table-data">
                    {variation?.food_items?.name}
                  </td>
                  <td className="custom-table-data">
                    {variation?.restaurant_branch?.name}
                  </td>
                  <td className="custom-table-data">
                    {variation?.is_free ? "True" : "False"}
                  </td>
                  <td className="custom-table-data">
                    {variation?.is_mandatory ? "True" : "False"}
                  </td>
                  <td className="custom-table-data">
                    {variation?.choose_item_number}
                  </td>
                  <td className="custom-table-data">
                    {variation?.status ? "True" : "False"}
                  </td>
                  <td className="custom-table-data text-xl">
                    <div className="flex justify-center ">
                      <Tooltip content="Edit">
                        <Link
                          to={`/admin/menu-variations/edit/${variation?.id}`}
                        >
                          <button>
                            {" "}
                            <AiOutlineEdit className="mx-2 text-yellow-500" />
                          </button>
                        </Link>
                      </Tooltip>

                      <Tooltip content="Delete">
                        <button
                          onClick={() => handleDeleteClick(variation?.id)}
                        >
                          <RiDeleteBin6Line className="mx-2 text-red-500" />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          pageCount={
            menuVariationsList?.meta?.last_page > 0
              ? menuVariationsList?.meta?.last_page
              : 0
          }
          handlePageClick={handlePageClick}
        />
      </div>
      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Are you sure you want to delete this item?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default MenuVariationsList;
