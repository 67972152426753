import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import AllIngredients from "../components/homemadeFoodIngredients/AllIngredients";
import FoodIngredientsSidebar from "../components/homemadeFoodIngredients/FoodIngredientsSidebar";
import TopCategories from "../components/homemadeFoodIngredients/TopCategories";
import {
    addHomeMadeProductCart,
    addServiceFee,
    decreaseCart,
    increaseCart,
    removeHomeMadeProductCart,
} from "../redux/actions/cartActions";
import { fetchProducts } from "../redux/actions/productActions";
import { fetchRestaurantDetails } from "../redux/actions/restaurantAction";
import Layout from "./Layout";

const FoodIngredients = (props) => {
  console.log(props);
  const { products } = useSelector((state) => state.product);
  const location = useLocation();
  console.log(location);
  // console.log(location.state.item_id);
  const { homeMadeCart, cart, serviceFee } = useSelector((state) => state.cart);
  const { restaurantDetails } = useSelector((state) => state.restaurant);
  const [cartModalOpen, setCartModalOpen] = useState(false);

  const history = useHistory();

  let ex_sum = 0;
  for (let item of cart) {
    let extraTotalWithPrice = item.extra.reduce((total, cartItem) => {
      return (total += parseFloat(cartItem.price));
    }, 0);
    ex_sum += extraTotalWithPrice * item.quantity;
  }

  let newTotalWithPrice = cart.reduce((total, cartItem) => {
    return (total +=
      parseFloat(cartItem.quantity) * parseFloat(cartItem.total_price));
  }, 0);

  let newTotalItem = cart.reduce((total, cartItem) => {
    return (total += parseFloat(cartItem.quantity));
  }, 0);
  // console.log(
  //   homeMadeCart.findIndex((object) => {
  //     console.log(object);
  //     return object.id === 1;
  //   })
  // );
  console.log({ products });
  const [search, setsearch] = useState("");
  const [brand, setbrand] = useState([]);
  const [categories, setcategories] = useState([]);
  const handleSearch = (e) => {
    setsearch(e.target.value);
    console.log(e.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleAddCart = (product) => {
    console.log(location.state.item_name);
    dispatch(
      addHomeMadeProductCart({
        product: { product, item_id: location.state.item_id },
        cartItem: location.state.cartItem,
        // item_id: location.state.item_id,
        // food_item_home: location.state.food_item,
        // item_id: location.state.item_id,
        // item_name: location.state.item_name,
        // item_price: location.state.item_price,
        // extra: location.state.extra,
      })
    );
  };
  const handleRemoveCart = (product) => {
    dispatch(
      removeHomeMadeProductCart({
        product,
        cartItem: location.state.cartItem,
        item_id: location.state.item_id,
      })
    );
  };
  useEffect(() => {
    // console.log(cart[0]);
    if (cart.length > 0) dispatch(fetchRestaurantDetails(cart[0].res_id));
  }, [dispatch, cart]);

  let newHomeMadeWithPrice3 = cart?.reduce((total, cartItem) => {
    console.log(cartItem.home_made_total);
    console.log(cartItem.home_made_change_total);
    if (cartItem.home_made_change_total)
      return (total +=
        parseFloat(cartItem.home_made_total) +
        parseFloat(cartItem.home_made_change_total));
    else return (total += parseFloat(cartItem.home_made_total));
  }, 0);

  useEffect(() => {
    console.log(newTotalWithPrice);
    if (newTotalWithPrice > 0) {
      dispatch(
        addServiceFee(
          (newTotalWithPrice + ex_sum + newHomeMadeWithPrice3).toFixed(2)
        )
      );
    }
  }, [newTotalWithPrice, ex_sum, dispatch, newHomeMadeWithPrice3]);

  const handleCheckout = () => {
    // const total = newTotalWithPrice + serviceFee + delevery_charge;
    // dispatch(placeOrder(cart, total, res_id));
    history.push("/checkout");
  };
  return (
    <Layout>
      {/* filter ingredients bt brand and category */}
      <div className="res-list bg-gray-50 py-14">
        <div className="container mx-auto">
          <div className="text-gray-600 overflow-hidden">
            <div className="-my-8 divide-y-2 divide-gray-100">
              <div className="py-8 flex flex-wrap lg:flex-nowrap">
                <FoodIngredientsSidebar
                  brand={brand}
                  setbrand={setbrand}
                  categories={categories}
                  setcategories={setcategories}
                />
                <div className="lg:flex-grow md:pl-5 lg:w-4/5 w-full mt-4 lg:mt-0">
                  {/* search */}
                  <div className="Pr_search">
                    <div className="input_serarch grid lg:grid-cols-2 grid-cols-1 relative">
                      <input
                        value={search}
                        onChange={handleSearch}
                        type="text"
                        id="searchIcon"
                        className="w-full h-10 p-1 pl-8 rounded-md border border-[#0DC1D9] focus:ring-0 text-[#464956] md:text-sm text-xs font-light font-body md:placeholder:text-sm placeholder:text-xs placeholder:font-light placeholder:text-[#464956] focus:border-[#0DC1D9]"
                        placeholder="Search by products..."
                      />
                      <label
                        htmlFor="searchIcon"
                        className="absolute top-1/2 left-3 -translate-y-1/2 cursor-pointer"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.37265 10.0739C6.83045 12.1547 3.27561 11.6979 1.31008 9.34139C-0.566266 7.09138 -0.412141 3.73823 1.67634 1.66529C3.77652 -0.419339 7.10839 -0.560912 9.35403 1.32023C11.6915 3.27844 12.1577 6.82252 10.0913 9.35135C10.129 9.39075 10.1671 9.43318 10.2073 9.47344C11.4027 10.6692 12.598 11.8655 13.7951 13.0591C13.9561 13.2197 14.0445 13.3972 13.9774 13.6262C13.8717 13.9847 13.4457 14.1172 13.1556 13.8821C13.1098 13.8449 13.0691 13.8007 13.0271 13.7587C11.843 12.5746 10.6585 11.391 9.47525 10.206C9.43715 10.1679 9.40858 10.1211 9.37265 10.0739ZM10.3701 5.70682C10.3732 3.1334 8.27732 1.03535 5.70308 1.03492C3.13577 1.03449 1.04166 3.12171 1.0343 5.68907C1.02694 8.26163 3.11975 10.3644 5.69312 10.3696C8.2665 10.3748 10.3675 8.28024 10.3701 5.70682Z"
                            fill="#464956"
                          />
                        </svg>
                      </label>
                    </div>
                  </div>

                  <TopCategories />
                  <AllIngredients
                    products={products}
                    handleAddCart={handleAddCart}
                    handleRemoveCart={handleRemoveCart}
                    homeMadeCart={homeMadeCart}
                  />
                </div>
                <div
                  className={`${
                    cartModalOpen ? "hidden" : ""
                  } fixed w-[100px] h-[100px] rounded-tl-lg rounded-bl-lg top-1/2 left-auto right-0 -translate-y-1/2 flex justify-center items-center flex-col bg-green-400 z-[101]`}
                  onClick={() => setCartModalOpen(true)}
                >
                  <div>
                    {/* <img className="mx-auto" src={WhiteCart} alt="" /> */}
                    <span className=" text-white md:text-2xl text-xl font-normal">
                      <CountUp end={newTotalItem} /> items
                      {/* <CountUp /> items */}
                    </span>
                  </div>
                  <button
                    type="button"
                    className=" lg:text-xl text-lg rounded-xl border-0 "
                  >
                    {/* &#2547; £ */}£{" "}
                    <CountUp
                      decimals={2}
                      end={
                        cart.length > 0
                          ? (
                              newTotalWithPrice +
                              restaurantDetails?.delivery_charge +
                              serviceFee +
                              ex_sum +
                              newHomeMadeWithPrice3
                            ).toFixed(2)
                          : 0
                      }
                    />
                  </button>
                </div>
                <div
                  className={`${
                    cartModalOpen ? "right-0" : "-right-[350px]"
                  } fixed top-0  w-[350px] h-full `}
                >
                  <div
                    className={` order-history bg-white shadow-lg p-5 sm:p-10 h-screen duration-900`}
                  >
                    <div className="history-title pb-5 relative">
                      <span
                        className="
                      absolute
                      right-0
                      top-0
                      h-8
                      w-8
                      leading-8
                      rounded-full
                      text-center
                      border border-gray-800
                    "
                        // id="closeOrder"
                        onClick={() => setCartModalOpen(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 mx-auto mt-1"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </span>
                      <h3 className="text-black uppercase font-normal leading-6 text-lg">
                        Order Summery
                      </h3>
                    </div>
                    <div className="max-h-[500px] overflow-y-auto">
                      {cart.length > 0
                        ? cart.map((cartItem, index) => (
                            <div key={index} className="order-item flex pb-5">
                              <div className="order-item flex pb-5">
                                <div className="order-item flex pb-5">
                                  <div className="item-text">
                                    <h4 className="text-black text-md leading-6 font-medium pt-0 lg:pt-2 xl:pt-0  line-clamp-2">
                                      {cartItem.name}
                                    </h4>
                                    {cartItem?.extra.map((item) => (
                                      <span
                                        className="text-gray-500 text-sm flex items-center gap-2 pb-2"
                                        key={item.id}
                                      >
                                        {item.name}
                                      </span>
                                    ))}
                                    {cartItem?.home_made.map((item) => (
                                      <span
                                        className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                                        key={item.id}
                                      >
                                        <div>{item.name}</div>
                                        <div>{item.price}</div>
                                        <Link
                                          to={{
                                            pathname: "/food/ingredients/",
                                            search: `?search=${item.name}`,
                                            state: {
                                              cartItem: cartItem,
                                              item_id: item.id,
                                            },
                                          }}
                                        >
                                          Change
                                        </Link>
                                      </span>
                                    ))}
                                    {cartItem?.home_made_change.map((item) => (
                                      <span
                                        className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                                        key={item.id}
                                      >
                                        <div>{item.product.name}</div>
                                        <div>{item.product.price}</div>
                                      </span>
                                    ))}
                                    <div className="flex justify-start mt-2 xl:mt-4">
                                      <h6 className="font-semibold text-lg leading-5 text-[#E64A19] mr-5">
                                        €{cartItem.total_price}
                                      </h6>
                                      <div className="flex justify-between gap-3  h-5 w-20 text-center">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            dispatch(increaseCart(cartItem._id))
                                          }
                                          className="
                                  bg-white shadow-themeShadow rounded-md px-2 h-6
                                "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                            />
                                          </svg>
                                        </button>
                                        <p className="w-6 h-6 px-2 border-none bg-white">
                                          {cartItem.quantity}
                                        </p>

                                        <button
                                          type="button"
                                          onClick={() =>
                                            dispatch(
                                              decreaseCart(
                                                cartItem._id,
                                                cartItem.quantity
                                              )
                                            )
                                          }
                                          className="bg-white shadow-themeShadow rounded-md px-2 h-6"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M18 12H6"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                    <div className="max-h-[500px] overflow-y-auto"></div>
                    {cart?.length > 0 ? (
                      <div className="total-price">
                        <ul className="p-0 m-0 list-none">
                          <li
                            className="
                        flex flex-wrap
                        w-full
                        justify-between
                        items-center
                        mb-3
                      "
                          >
                            <h6 className="text-black text-base font-normal leading-4">
                              Subtotal
                            </h6>
                            <p className="text-black text-base font-normal leading-4">
                              £
                              {(
                                newTotalWithPrice +
                                ex_sum +
                                newHomeMadeWithPrice3
                              ).toFixed(2)}
                            </p>
                          </li>
                          <li
                            className="
                        flex flex-wrap
                        w-full
                        justify-between
                        items-center
                        mb-3
                      "
                          >
                            <h6 className="text-black text-base font-normal leading-4">
                              Delivery Fee
                            </h6>
                            <p className="text-black text-base font-normal leading-4">
                              £{restaurantDetails?.delivery_charge}
                            </p>
                          </li>
                          <li
                            className="
                        flex flex-wrap
                        w-full
                        justify-between
                        items-center
                        mb-3
                      "
                          >
                            <h6 className="text-black text-base font-normal leading-4">
                              Service Fee
                            </h6>
                            <p className="text-black-600 text-base font-normal leading-4">
                              £{serviceFee?.toFixed(2)}
                            </p>
                          </li>
                        </ul>
                        <div className="flex justify-between border-t-2 border-dashed pt-5">
                          <h6 className="text-black text-base font-medium leading-4">
                            Total
                          </h6>
                          <p className="text-black text-base font-normal leading-4">
                            £
                            {(
                              newTotalWithPrice +
                              restaurantDetails?.delivery_charge +
                              serviceFee +
                              ex_sum +
                              newHomeMadeWithPrice3
                            ).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={`check-btn pt-6 ${
                        cart?.length === 0 ? "disabled" : ""
                      }`}
                    >
                      <button
                        onClick={handleCheckout}
                        type="submit"
                        className="
                      bg-[#0DC1D9]
                      w-full
                      py-2
                      rounded-lg
                      text-white text-base
                      font-medium
                    "
                        // disabled={cart?.length === 0}
                      >
                        Go To Checkout
                      </button>
                    </div>
                  </div>
                  {/* // </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FoodIngredients;
