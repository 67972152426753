import {
  CLEAR_ERRORS,
  FETCH_ADMIN_NOTIFICATIONS,
  // GOOGLE_LOGIN,
  // FACEBOOK_LOGIN,
  FETCH_USER_ADDRESS,
  FETCH_VENDOR_NOTIFICATIONS,
  LOADING_UI,
  LOADING_USER,
  PASSWORD_CHANGE_LOADING,
  PASSWORD_CHANGE_MESSAGE,
  PASSWORD_CHANGE_MESSAGE_CLEAR,
  PROFILE_UPDATE_SUCCESS,
  RESEND_EMAIL_SUCCESS,
  // SET_ERRORS,
  SERVER_ERROR,
  // SET_RIDER_INSTRUCTION,
  SET_AUTHENTICATED,
  SET_AUTH_ERROR,
  SET_ERRORS_SIGNUP_SELLER,
  // SET_ERROR,
  SET_UNAUTHENTICATED,
  SET_USER,
  SIGNUP_SUCCESS,
  USER_ACTIVATION_ERROR,
  // VENDOR REGISTRATION
  // VENDOR_SIGNUP_SUCCESS,
  USER_ACTIVATION_SUCCESS,
} from "../types";

import axiosNewInstance from "axios";
import axios from "../../util/axios";
// import axios from "axios";
import { toast } from "react-hot-toast";
import { API_URL } from "../../settings/config";
import auth from "../../util/auth";

export const authError = (payload) => {
  return {
    type: SET_AUTH_ERROR,
    payload: payload,
  };
};

export const passChangeMessageClear = () => {
  return {
    type: PASSWORD_CHANGE_MESSAGE_CLEAR,
  };
};

export const signupUser = (newUserData, history) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  const body = {
    identifier: newUserData.identifier,
    password: newUserData.password,
    role: newUserData.role,
    fName: newUserData.first_name,
    lName: newUserData.last_name
  };

  await axios
    .post(`${API_URL}/users/registration/`, body)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: SIGNUP_SUCCESS,
      });
      if (res.data.user_id) {
        localStorage.setItem("activation_id", res.data.user_id);
      }
      history.push("/account/activation");
    })
    .catch((err) => {
      console.log(err.response);
      const data = err.response?.data?.error;
      const commonErr = [];
      if (data) {
        for (const [i, j] of Object.entries(data)) {
          console.log(`${i}: ${j}`);
          commonErr.push(`${j}`);
        }
      }

      console.log(commonErr);
      if (data) {
        dispatch(authError(commonErr));
        // dispatch({
        //     type: SET_ERRORS,
        //     payload: commonErr,
        // });
      } else {
        dispatch({
          type: SERVER_ERROR,
        });
      }
    });
};

export const userAccountActivation = (code) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .post(`${API_URL}/users/account/confirm/`, { code })
    .then((res) => {
      console.log(res);
      dispatch({ type: USER_ACTIVATION_SUCCESS });
      // dispatch(clearUserError());
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: USER_ACTIVATION_ERROR,
        payload: err.response.data.message,
      });
    });
};

export const userAccountCodeResend = (id) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .post(`${API_URL}/users/account/resend/code/`, { new_user_id: id })
    .then((res) => {
      console.log(res);
      dispatch({ type: RESEND_EMAIL_SUCCESS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ACTIVATION_ERROR, payload: err.response.message });
    });
};

export const loginUser = (userData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  const body = {
    identifier: userData.identifier,
    password: userData.password,
  };
  axios
    .post(`${API_URL}/users/login/`, body)
    .then((res) => {
      const jwt = `Bearer ${res.data.data.access}`;
      localStorage.setItem("jwt", jwt);
      auth.setToken(jwt);
      axios.defaults.headers.common["Authorization"] = jwt;
      dispatch(getUserData());
      dispatch({ type: SET_AUTHENTICATED });
      // dispatch({type: CLEAR_ERRORS});
      console.log("Authenticated, check localStorage", jwt);
      // history.push("/");
    })
    .catch((err) => {
      // if (err?.response?.data?.user_id) {
      //   localStorage.setItem("activation_id", err?.response?.data?.user_id);
      // }
      // history.push("/account/activation");
      console.log(err);
      toast.error("Email/Phone or password is not correct", { duration: 5000 })
      
      // const data = err.response?.data?.message;
      // console.log(data);
      // if (data) {
      //   dispatch(authError(data));
      //   // dispatch({
      //   //     type: SET_ERRORS,
      //   //     payload: data,
      //   // });
      // } else {
      //   dispatch({
      //     type: SERVER_ERROR,
      //   });
      // }
    });
};

export const googleLogin = (accesstoken, history) => async (dispatch) => {
  console.log({access_token: accesstoken});
  await fetch(`${process.env.REACT_APP_PRO_URL}/dj-rest-auth/google/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    // body: {
    //   access_token: accesstoken,
    // },
    body: JSON.stringify({
      access_token: accesstoken,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      const jwt = `Bearer ${res.access_token}`;
      localStorage.setItem("jwt", jwt);
      auth.setToken(jwt);
      axios.defaults.headers.common["Authorization"] = jwt;
      dispatch(getUserData());
      console.log("Authenticated, check localStorage", jwt);
      // history.push("/");
    });
    
};

export const facebookLogin = (accesstoken, history) => async (dispatch) => {
  await fetch(`${process.env.REACT_APP_PRO_URL}/dj-rest-auth/facebook/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    // body: {
    //   access_token: accesstoken,
    // },
    body: JSON.stringify({
      access_token: accesstoken,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      const jwt = `Bearer ${res.access_token}`;
      localStorage.setItem("jwt", jwt);
      auth.setToken(jwt);
      axios.defaults.headers.common["Authorization"] = jwt;
      dispatch(getUserData());
      console.log("Authenticated, check localStorage", jwt);
      history.push("/");
    });
};

export const getUserData = () => async (dispatch) => {
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  dispatch({ type: LOADING_USER });
  await axios
    .get(`${API_URL}/users`, config)
    .then((res) => {
      auth.setUserInfo(res.data);
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const editUserData = (data) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  dispatch({ type: LOADING_USER });
  await axios
    .patch(`${API_URL}/users/`, data, config)
    .then((res) => {
      console.log(res);
      dispatch(getUserData());
      dispatch({ type: PROFILE_UPDATE_SUCCESS });
    })
    .catch((err) => console.log(err));
};
export const changeProfilePic = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .patch(`${API_URL}/users/details/${id}/`, data, config)
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
};

export const fetchUserAddress = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .get(`${API_URL}/users/address/`, config)
    .then((res) => {
      dispatch({ type: FETCH_USER_ADDRESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_AUTH_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const changeUserDefaultAddr = (id) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .post(
      `${API_URL}/users/address/default/change/`,
      {
        addrId: id,
      },
      config
    )
    .then((res) => {
      console.log(res);
      dispatch(fetchUserAddress());
    })
    .catch((err) => console.log(err));
};

export const deleteUserAddress = (id) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .delete(`${API_URL}/users/address/${id}/`, config)
    .then((res) => {
      console.log(res);
      dispatch(fetchUserAddress());
    })
    .catch((err) => console.log(err));
};

export const setUserAddress = (data) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .post(`${API_URL}/users/address/`, data, config)
    .then((res) => {
      // dispatch({ type: SET_USER_ADDRESS, payload: res.data });
      dispatch(fetchUserAddress());
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_AUTH_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const passChangeAction = (data) => async (dispatch) => {
  dispatch({ type: PASSWORD_CHANGE_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .put(`${API_URL}/users/password/change`, data, config)
    .then((res) => {
      console.log(res);
      dispatch({
        type: PASSWORD_CHANGE_MESSAGE,
        payload: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_AUTH_ERROR,
        payload: err.response.data.message,
      });
    });
};

export const changeRiderInstruction = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .patch(
      `${API_URL}/users/address/${id}/`,
      {
        rider_instruction: data,
      },
      config
    )
    .then((res) => {
      // dispatch({ type: SET_USER_ADDRESS, payload: res.data });
      console.log(res.data);
      dispatch(fetchUserAddress());
    })
    .catch((err) => {
      // console.log(err.statusCode);
      if (err.response.status === 401) {
        // router.push("/login");
        // window.location.pathname = "/login";
        dispatch(logoutAction());
        // console.log(window.location.pathname);
      }
      dispatch({
        type: SET_AUTH_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const signupSeller = (newSellerData, history) => (dispatch) => {
  const location = `+${newSellerData.get("aptName")},+${newSellerData.get(
    "locality"
  )},+${newSellerData.get("street")},+${newSellerData.get("zip")}`;
  axiosNewInstance
    .get("https://maps.googleapis.com/maps/api/geocode/json", {
      params: {
        address: location,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    })
    .then((result) => {
      if (
        Array.isArray(result.data.results) &&
        result.data.results.length > 0
      ) {
        const formattedAddress = result.data.results[0].formatted_address;
        const lat = result.data.results[0].geometry.location.lat;
        const lng = result.data.results[0].geometry.location.lng;
        newSellerData.append("lat", lat);
        newSellerData.append("lng", lng);
        newSellerData.append("formattedAddress", formattedAddress);
      }

      dispatch(signupSellerFinal(newSellerData, history));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const signupSellerFinal = (newSellerData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/auth/signup-seller", newSellerData)
    .then((res) => {
      dispatch({
        type: SIGNUP_SUCCESS,
      });
      dispatch({ type: CLEAR_ERRORS });
      history.push("/login");
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: SET_ERRORS_SIGNUP_SELLER,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: SERVER_ERROR,
        });
      }
    });
};

export const logoutAction = (history) => (dispatch) => {
  console.log("enter........");
  localStorage.removeItem("jwt");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
  if (history) history.push("/login");
};

// VENDOR
export const signupVendor = (body) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .post(`${API_URL}/users/vendor/registration/`, body, config)
    .then((res) => {
      console.log(res);
      // history.push("/login");
    });
};

// notifications
export const adminNotificationsAction = (data) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  dispatch({ type: FETCH_ADMIN_NOTIFICATIONS, payload: data });
};

export const vendorNotificationsAction = (data) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  dispatch({ type: FETCH_VENDOR_NOTIFICATIONS, payload: data });
};
