import React from "react";
import appleStore from "../../../images/driver/apple-store.png";
import thumbnail from "../../../images/driver/mobile-app.png";
import playStore from "../../../images/driver/play-store.png";
import qrCode from "../../../images/driver/qr.png";
const RiderAppSetUp = () => {
  return (
    <div className="bg-gradient-to-b from-[#171D35] to-[#1D4234] grid md:grid-cols-12 grid-cols-1 place-items-center lg:gap-8 gap-3 xl:h-[340] lg:h-[350px] h-[330] text-white md:py-2">
      <div className="lg:col-span-5 col-span-7 lg:ml-10 mx-10 order-2 md:order-1 lg:-mt-12 md:0">
        <h1 className="text-xl pb-1">How you can give a ride?</h1>
        <p className="text-xs">
          Being with British MarketPlace means being on the highest earning
          platform! So, what are you waiting for? Join us to earn the most!
          Focus on growing your business, and let British MarketPlace handle
          delivery for you.
        </p>
        <div className="pl-4">
          <ul className="list-disc text-[#0DC1D9] text-2xl">
            <li className="my-3">
              <h2 className="text-base text-white">Download the Driver App</h2>
              <p className="text-xs text-white">
                Be sure to download the British MarketPlace Drive app.
              </p>
            </li>
            <li className="my-3">
              <h2 className="text-base text-white">
                Sign Up and keep your status active
              </h2>
              <p className="text-xs text-white">
                Sign up on the drive app and keep your status online.
              </p>
            </li>
            <li className="my-3">
              <h2 className="text-base text-white">Get ride request</h2>
              <p className="text-xs text-white">
                Make sure your GPS is at high accuracy and wait for a ride
                request.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="lg:col-span-7 col-span-5  grid lg:grid-cols-7 md:grid-cols-1 place-items-center gap-4 order-1 md:order-2">
        <div className="lg:col-span-2 order-2 lg:order-1 ">
          <img
            src={qrCode}
            alt=""
            className=" mx-auto mb-4 w-2/3 hidden lg:block"
          />
          <div className="grid grid-cols-2 col-span-12 gap-2 mx-auto md:pr-10 lg:pr-0 pr-0">
            <img src={playStore} alt="" className="" />
            <img src={appleStore} alt="" className="" />
          </div>
        </div>
        <div className="lg:col-span-5 lg:-mt-9 md:-mt-24 -mt-20 order-1 lg:order-2 ml-7 md:ml-0">
          {" "}
          <img src={thumbnail} alt="" className="" />
        </div>
      </div>
    </div>
  );
};

export default RiderAppSetUp;
