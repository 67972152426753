import React from "react";
import img5 from "../../../images/restaurantPartner/08.png";
const HowCanHelp = () => {
  return (
    <section className="bg-[#DE3500] md:flex justify-center items-center md:px-28 px-5 ">
      <div className="text-white py-5">
        <h1 className="text-xl">
          How can British Marketplace help your business?
        </h1>
        <p className="text-xs py-3">
          When you become a British Marketplace partner, we’ll send you
          everything you need to get started, including signage, a tablet and a
          wireless printer.
        </p>
        <ul className="list-disc pl-4 ">
          <li>24 hr tablet support</li>
          <li>Flexible payment plan</li>
          <li>Use Deliveroo's rider network or your own</li>
          <li>
            Help marketing your business with savings on photography and
            websites
          </li>
          <li>Create loyal customers with Hub insights and tools</li>
        </ul>
      </div>
      <div className="md:inline hidden">
        <img src={img5} alt="" className="w-full md:h-52" />
      </div>
    </section>
  );
};

export default HowCanHelp;
