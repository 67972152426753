import React from "react";
import { Link } from "react-router-dom";
import submitModal from "../../images/submitModal.webp";

const SuccessApplyMessage = () => {
  return (
    <div
      className="bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:w-[600px] md:w-[400px] w-[300px] rounded-lg p-10"
      id="modal_content"
    >
      <div className="e_modal_body relative">
        <div className="modal-thumb absolute  lg:bottom-[40%] md:bottom-[60%] bottom-[80%] left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img className="mx-auto " src={submitModal} alt="" />
        </div>
        <div className="modal-text text-center pt-6">
          <h4 className="lg:text-xl text-lg text-black font-medium">
            Your Account has been Successfully Activated
          </h4>
          <span className="text-black text-opacity-80 md:text-sm text-xs font-light">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magina aliqua. Quis
            ipsum suspendise ultrces gravida. Risus commodo viverra maecenas
            accumsan lacus vel facilisis
          </span>
          <div className="flex justify-center items-center gap-3 pt-6">
            <Link
              // onClick={() => setcount(5)}
              to="/login"
              className="text-sm font-normal w-[100px] py-2 border border-[#028FF0] border-opacity-70 rounded-md hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9]"
            >
              Please Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessApplyMessage;
