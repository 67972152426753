import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import FoodIngredientsCreateUpdateForm from "../../../components/admin/Forms/FoodIngredients/FoodIngredientsCreateUpdateForm";
import { adminMenuIngredientsAdd } from "../../../redux/actions/AdminActions/adminMenuAction";
import { adminRestaurantBranchNameOnlyList } from "../../../redux/actions/AdminActions/adminRestaurantActions";
import Layout from "../Layout/layout";

const AddFoodIngredients = () => {
  const { resBranchNameOnlyList } = useSelector(
    (state) => state.adminRestaurant
  );
  const { menuVariationsNameList } = useSelector((state) => state.adminMenu);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminRestaurantBranchNameOnlyList());
  }, [dispatch]);

  const onSubmit = async (data) => {
    console.log(data);
    const body = {
      restaurant_branch_id: data?.restaurantBranch?.value,
      variation_id: data?.variation?.value,
      name: data.name,
      price: data.price,
      quantity: data.quantity,
    };
    dispatch(adminMenuIngredientsAdd(body, history));
  };

  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <FoodIngredientsCreateUpdateForm
            resBranchData={resBranchNameOnlyList}
            variationData={menuVariationsNameList}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </Layout>
  );
};

export default AddFoodIngredients;
