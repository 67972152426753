import {
  ADMIN_META_LOADING,
  ADMIN_CONTACT_LIST,
  ADMIN_CONTENT_SETTINGS_DETAILS,
  ADMIN_GENERAL_SETTINGS_DETAILS,
  ADMIN_CONTENT_SETTINGS_SAVE,
  ADMIN_GENERAL_SETTINGS_SAVE,
  ADMIN_CONTENT_SETTINGS_SAVE_CLEAR,
  ADMIN_GENERAL_SETTINGS_SAVE_CLEAR,
  ADMIN_META_ERROR,
} from "../../types";

const initialState = {
  loading: false,
  contactList: [],
  contentSettings: {},
  contentSave: false,
  generalSettings: {},
  generalSettingsSave: false,
  error: null,
};

export default function adminMetaReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADMIN_META_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADMIN_CONTACT_LIST:
      return {
        ...state,
        loading: false,
        contactList: action.payload,
        error: null,
      };
    case ADMIN_CONTENT_SETTINGS_DETAILS:
      return {
        ...state,
        loading: false,
        contentSettings: action.payload,
        error: null,
      };
    case ADMIN_CONTENT_SETTINGS_SAVE:
      return {
        ...state,
        loading: false,
        contentSave: true,
        error: null,
      };
    case ADMIN_CONTENT_SETTINGS_SAVE_CLEAR:
      return {
        ...state,
        loading: false,
        contentSave: false,
        error: null,
      };
    case ADMIN_GENERAL_SETTINGS_DETAILS:
      return {
        ...state,
        loading: false,
        generalSettings: action.payload,
        error: null,
      };
    case ADMIN_GENERAL_SETTINGS_SAVE:
      return {
        ...state,
        loading: false,
        generalSettingsSave: true,
        error: null,
      };
    case ADMIN_GENERAL_SETTINGS_SAVE_CLEAR:
      return {
        ...state,
        loading: false,
        generalSettingsSave: false,
        error: null,
      };
    case ADMIN_META_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
