import {
  SET_SPONSOR,
  FETCH_ABOUT_US,
  SUBMIT_CONTACT_US,
  CLEAR_CONTACT_US,
  FETCH_PRIVACY_POLICY,
  SET_REVIEW_RATING_DATA,
  FETCH_ALL_CHAT_USERS,
  FETCH_SINGLE_CHAT_MESSAGES,
} from "../types";

const initialState = {
  sponsor: [],
  aboutUs: "",
  contactUsSuccess: false,
  privacyPolicy: "",
  error: "",
  reviewRating: {},
  allChatUsers: [],
  allMessages: [],
};

export const commonReucers = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SPONSOR:
      return { ...state, sponsor: action.payload };
    case FETCH_ABOUT_US:
      return { ...state, aboutUs: action.payload };
    case SUBMIT_CONTACT_US:
      return { ...state, contactUsSuccess: true };
    case CLEAR_CONTACT_US:
      return { ...state, contactUsSuccess: false };
    case FETCH_PRIVACY_POLICY:
      return { ...state, privacyPolicy: action.payload };
    case SET_REVIEW_RATING_DATA:
      return { ...state, reviewRating: action.payload };
    case FETCH_ALL_CHAT_USERS:
      return { ...state, allChatUsers: action.payload };
    case FETCH_SINGLE_CHAT_MESSAGES:
      return { ...state, allMessages: action.payload };
    default:
      return state;
  }
};
