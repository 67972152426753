import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
//redux
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-quill/dist/quill.snow.css";
import { Provider } from "react-redux";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
    <GoogleOAuthProvider clientId="382129473346-0q7pg4q7bf3rgcr6ibpfgdq06krs7mrk.apps.googleusercontent.com">
  <Provider store={store}>
    <App />
  </Provider>
    </GoogleOAuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
