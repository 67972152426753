import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/admin/Layout/Header";
import Sidebar from "../../../components/admin/Layout/Sidebar/Sidebar";
import "./layout.css";
import { db } from "../../../util/firebaseDB";
import { onValue, ref } from "firebase/database";
import { adminNotificationsAction } from "../../../redux/actions/authActions";

const Layout = ({ children }) => {
  const { user, adminNotifications } = useSelector((state) => state.auth);
  console.log(user);
  const [isSidebarOpen, setIsSidebar] = useState(false);
  const dispatch = useDispatch();
  const closeBtnHandler = () => {
    // setIsCloseBtn(!isCloseBtnOpen);
    setIsSidebar(false);
    console.log(isSidebarOpen);
  };

  useEffect(() => {
    if (user.id) {
      const query = ref(db, `notifications`);
      onValue(query, (snapshot) => {
        const data = [];
        // console.log(snapshot.val());
        snapshot.forEach((snap) => {
          const query2 = ref(db, `notifications/${snap.key}`);
          onValue(query2, (item) => {
            item.forEach((i) => {
              data.push(i.val());
            });
          });
          // console.log(snap.val());
          // console.log(snap.val());
          // data.push(snap.val());
          // data.push(snap.key);
          // snap.val().map((item) => {
          //   console.log(item.val());
          // });
        });
        const falseFirst = data.sort(
          (a, b) => Number(a.is_read) - Number(b.is_read)
        );
        dispatch(adminNotificationsAction(falseFirst));
      });
    }
  }, [user, dispatch]);

  return (
    <div className="lg:flex relative ">
      <div
        className={
          isSidebarOpen
            ? "w-[300px] lg:static fixed top-0 h-screen left-0 transition-all ease-in z-10 "
            : "w-[300px] lg:static fixed top-0 -left-[300px] h-screen  transition-all ease-out z-10"
        }
      >
        {user.user.role === "ADMIN" ? (
          <Sidebar closeBtnHandler={closeBtnHandler} />
        ) : null}
      </div>
      <div
        onClick={closeBtnHandler}
        className={
          isSidebarOpen ? "backdrop backdropOpen w-screen h-screen z-[9]" : ""
        }
      >
        {" "}
      </div>

      <div className="page_wrapper">
        <Header
          isSidebarOpen={isSidebarOpen}
          setIsSidebar={setIsSidebar}
          user={user}
          notifications={adminNotifications}
        />
        {/* <div className={isSidebarOpen ? "md:opacity-30 " : ""}> */}
        {children}

        {/* </div> */}
      </div>
    </div>
  );
};

export default Layout;
