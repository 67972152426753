import React from "react";
import { Link } from "react-router-dom";
import { tConvert } from "../../../pages/order";

const RestaurantBranchInfo = ({ resBranchDetailsEdit, handleDeleteClick }) => {
  return (
    <div>
      <div className="my-3 mx-2 lg:mx-24 shadow p-3 h-max ">
        <img
          className=" mb-8 border md:w-[300px] md:h-[300px] w-[200px] h-[200px] rounded border-yellow-600 mx-auto"
          src={
            resBranchDetailsEdit?.image
              ? resBranchDetailsEdit.image
              : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
          }
          alt=""
        />
        <div className="md:mx-24">
          {" "}
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2  ">
            <p>
              Restaurant:{" "}
              <strong>{resBranchDetailsEdit?.restaurant?.name}</strong>
            </p>
            <p>
              Categories:{" "}
              {resBranchDetailsEdit?.categories?.map((item, index) => (
                <strong key={item.id} className="mr-2">
                  {item.name}
                  {index === resBranchDetailsEdit?.categories.length - 1
                    ? ""
                    : ","}
                </strong>
              ))}
            </p>
            <p>
              Name: <strong>{resBranchDetailsEdit?.name}</strong>
            </p>
            <p>
              Listed hours:{" "}
              <strong>{resBranchDetailsEdit?.listed_hours}</strong>
            </p>
            <p>
              Open time:{" "}
              <strong>
                {resBranchDetailsEdit?.open_time
                  ? tConvert(resBranchDetailsEdit?.open_time)
                  : ""}
              </strong>
            </p>
            <p>
              Close time:{" "}
              <strong>
                {resBranchDetailsEdit?.close_time
                  ? tConvert(resBranchDetailsEdit?.close_time)
                  : ""}
              </strong>
            </p>
            <p>
              Min delivery time:{" "}
              <strong>{resBranchDetailsEdit?.min_delivery_time}</strong>
            </p>
            <p>
              Max delivery time:{" "}
              <strong>{resBranchDetailsEdit?.max_delivery_time}</strong>
            </p>
            <p>
              Delivery charge:
              <strong> &#163;{resBranchDetailsEdit?.delivery_charge}</strong>
            </p>
            {/* <p>
              Shipping price:{" "}
              <strong>{resBranchDetailsEdit?.listed_hours}</strong>
            </p> */}
            <p>
              Pickup{" "}
              <strong>
                {resBranchDetailsEdit?.is_pickup ? "True" : "False"}
              </strong>
            </p>
            <p>
              Delivery{" "}
              <strong>
                {resBranchDetailsEdit?.is_delivery ? "True" : "False"}
              </strong>
            </p>
            <p>
              Table{" "}
              <strong>
                {resBranchDetailsEdit?.is_table ? "True" : "False"}
              </strong>
            </p>
            <p>
              Hygiene rating:{" "}
              <strong>{resBranchDetailsEdit?.hygiene_rating}</strong>
            </p>
            <p>
              Full address:{" "}
              <strong>{resBranchDetailsEdit?.full_address}</strong>
            </p>
            <p>
              Lat: <strong>{resBranchDetailsEdit?.lat}</strong>
            </p>
            <p>
              Lng: <strong>{resBranchDetailsEdit?.lng}</strong>
            </p>
            <p>
              Road: <strong>{resBranchDetailsEdit?.road}</strong>
            </p>
            <p>
              Suburb: <strong>{resBranchDetailsEdit?.suburb}</strong>
            </p>
            <p>
              City: <strong>{resBranchDetailsEdit?.city}</strong>
            </p>
            <p>
              State: <strong>{resBranchDetailsEdit?.state}</strong>
            </p>
            <p>
              Postcode: <strong>{resBranchDetailsEdit?.postcode}</strong>
            </p>
            <p>
              Country: <strong>{resBranchDetailsEdit?.country}</strong>
            </p>
            <p>
              Status:{" "}
              <strong>{resBranchDetailsEdit?.status ? "True" : "False"}</strong>
            </p>
            <p>
              Main:{" "}
              <strong>
                {resBranchDetailsEdit?.is_main ? "True" : "False"}
              </strong>
            </p>
            <p>
              Discount amount percent:{" "}
              <strong>{resBranchDetailsEdit?.discount_amount_percent}%</strong>
            </p>
          </div>
        </div>

        <div className="flex justify-center my-8">
          <Link
            to={`/admin/restaurant-branch/edit/${resBranchDetailsEdit?.id}`}
          >
            <button className="rounded py-2 px-5 text-white mr-2 bg-yellow-400">
              Edit
            </button>
          </Link>
          <button
            onClick={() => handleDeleteClick(resBranchDetailsEdit?.id)}
            className="rounded py-2 px-5 text-white  bg-red-600"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default RestaurantBranchInfo;
