import {
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCT_LOADING,
  FETCH_PRODUCT_ERROR,
} from "../types";

const initialState = {
  products: [],
  error: null,
  loading: false,
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PRODUCT_LIST:
      return {
        ...state,
        loading: false,
        products: action.payload,
        error: null,
      };
    case FETCH_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
