import React, { useEffect } from "react";
import RestaurantCreateUpdateForm from "../../../components/admin/Forms/Restaurant/RestaurantCreateUpdateForm";
import Layout from "../Layout/layout";
import { useSelector, useDispatch } from "react-redux";
import { adminLimitUserListAction } from "../../../redux/actions/AdminActions/adminUserActions";
import { adminAddRestaurant } from "../../../redux/actions/AdminActions/adminRestaurantActions";
import { useHistory } from "react-router";

function AddRestaurant() {
  const { adminLimitUserList } = useSelector((state) => state.adminUser);
  console.log(adminLimitUserList);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    dispatch(adminLimitUserListAction());
  }, [dispatch]);

  const handleSubmitData = (data) => {
    console.log(data);
    if (data.image.length > 0) {
      const formData = new FormData();
      formData.append("image", data.image[0]);
      formData.append("name", data.name);
      formData.append("owner", parseInt(data.owner.value));
      formData.append("description", data.description);
      formData.append("status", data.status);
      // console.log(data.image[0].name);
      dispatch(adminAddRestaurant(formData, history));
    } else {
      dispatch(
        adminAddRestaurant(
          {
            name: data.name,
            owner: parseInt(data.owner.value),
            description: data.description,
            status: data.status,
          },
          history
        )
      );
    }
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <RestaurantCreateUpdateForm
            adminLimitUserList={adminLimitUserList}
            handleSubmitData={handleSubmitData}
          />
        </div>
      </div>
    </Layout>
  );
}

export default AddRestaurant;
