import {
  ADMIN_VOUCHER_LOADING,
  ADMIN_VOUCHER_LIST,
  ADMIN_VOUCHER_DETAIL,
  ADMIN_CUSTOMER_USED_VOUCHER,
  ADMIN_VOUCHER_ERROR,
} from "../../types";

const initialState = {
  loading: false,
  voucherList: [],
  voucherDetail: {},
  customerUsedVoucher: [],
  error: null,
};

export const adminVoucherReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADMIN_VOUCHER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_VOUCHER_LIST:
      return {
        ...state,
        loading: false,
        voucherList: action.payload,
      };
    case ADMIN_VOUCHER_DETAIL:
      return {
        ...state,
        loading: false,
        voucherDetail: action.payload,
      };
    case ADMIN_CUSTOMER_USED_VOUCHER:
      return {
        ...state,
        loading: false,
        customerUsedVoucher: action.payload,
      };
    case ADMIN_VOUCHER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
// export default adminVoucherReducer;
