import React from "react";
import step1Img from "../../images/step/01.png";
import step2Img from "../../images/step/02.png";
import step3Img from "../../images/step/03.png";
import step4Img from "../../images/step/04.png";
import step5Img from "../../images/step/05.png";

const DeliverySteps = () => {
  return (
    <section className="delivery-step lg:py-[60px] py-10">
      <div className="container">
        <div className="section-header text-center md:pb-12 pb-6">
          <h3 className="md:text-3xl sm:text-2xl text-xl text-black font-medium">
            Just Relax at Home, We will take care
          </h3>
          <div className="text-[#979797] flex justify-center pt-2">
          <p className="lg:w-[610px] text-xs">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
          <div className="step-item p-4">
            <div className="flex justify-center">
              <img src={step1Img} alt="not available" />
            </div>
            <div className="text-center pt-5">
              <h4 className="text-lg font-medium text-black leading-6">
                We drive fast & ship faster
              </h4>
              <p className="text-sm max-w-step-text m-auto pt-2">
                Sed semper convallis ultricies. Aliqua erat vol esent friday
                ngilla augue.
              </p>
            </div>
          </div>
          <div className="step-item p-4">
            <div className="flex justify-center">
              <img src={step2Img} alt="not available" />
            </div>
            <div className="text-center pt-5">
              <h4 className="text-lg font-medium text-black leading-6">
                We save your more money
              </h4>
              <p className="text-sm max-w-step-text m-auto pt-2">
                Sed semper convallis ultricies. Aliqua erat vol esent friday
                ngilla augue.
              </p>
            </div>
          </div>
          <div className="step-item p-4">
            <div className="flex justify-center">
              <img src={step3Img} alt="not available" />
            </div>
            <div className="text-center pt-5">
              <h4 className="text-lg font-medium text-black leading-6">
              Easy to order by web & app
              </h4>
              <p className="text-sm max-w-step-text m-auto pt-2">
                Sed semper convallis ultricies. Aliqua erat vol esent friday
                ngilla augue.
              </p>
            </div>
          </div>
          <div className="step-item p-4">
            <div className="flex justify-center">
              <img src={step4Img} alt="not available" />
            </div>
            <div className="text-center pt-5">
              <h4 className="text-lg font-medium text-black leading-6">
                Daily discount coupons
              </h4>
              <p className="text-sm max-w-step-text m-auto pt-2">
                Sed semper convallis ultricies. Aliqua erat vol esent friday
                ngilla augue.
              </p>
            </div>
          </div>
          <div className="step-item p-4">
            <div className="flex justify-center">
              <img src={step5Img} alt="not available" />
            </div>
            <div className="text-center pt-5">
              <h4 className="text-lg font-medium text-black leading-6">
              More than 150+ delivery
              </h4>
              <p className="text-sm max-w-step-text m-auto pt-2">
                Sed semper convallis ultricies. Aliqua erat vol esent friday
                ngilla augue.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliverySteps;
