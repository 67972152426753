import {
  VENDOR_MENU_ITEMS_LIST,
  VENDOR_MENU_LOADING,
  VENDOR_MENU_ERROR,
  VENDOR_MENU_VARIATION_LIST,
  VENDOR_MENU_INGREDIENTS_LIST,
} from "../../types";

const initialState = {
  loading: false,
  menuList: [],
  menuVariationsList: [],
  menuIngredientList: [],
  error: null,
};

export default function vendorMenuReducer(state = initialState, action = {}) {
  switch (action.type) {
    case VENDOR_MENU_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case VENDOR_MENU_ITEMS_LIST:
      return {
        ...state,
        loading: false,
        menuList: action.payload,
        error: null,
      };
    case VENDOR_MENU_VARIATION_LIST:
      return {
        ...state,
        loading: false,
        menuVariationsList: action.payload,
        error: null,
      };

    case VENDOR_MENU_INGREDIENTS_LIST:
      return {
        ...state,
        loading: false,
        menuIngredientList: action.payload,
        error: null,
      };

    case VENDOR_MENU_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
