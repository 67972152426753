import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";

function MenuItemsCreateUpdateForm({
  edit,
  resBranchNameOnlyList,
  menuCategoriesName,
  handleSubmitData,
  detailsText,
  setDetailsText,
  allergenInfoText,
  setAllergenInfoText,
  cookingMethodText,
  setCookingMethodText,
  menuDetails,
  vendor,
}) {
  const [resBranchOption, setResBranchOption] = useState([]);
  const [categoryFoodOption, setCategoryFoodOption] = useState([]);

  const [imagePreview, setimagePreview] = useState("");

  function handleDetailsChange(event) {
    setDetailsText(event);
    console.log(event);
  }
  console.log(detailsText);
  function handleCookingMethodChange(event) {
    setCookingMethodText(event);
  }
  function handleAllergenInfoTextChange(event) {
    setAllergenInfoText(event);
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,

    watch,
  } = useForm({
    defaultValues: {},
  });

  if (watch("image")?.length > 0) {
    try {
      let o = watch("image");
      console.log(o[0]);
      const reader = new FileReader();
      reader.readAsDataURL(o[0]);
      reader.onload = function () {
        setimagePreview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    let option = [];
    if (resBranchNameOnlyList?.length > 0) {
      resBranchNameOnlyList.map((item) =>
        // setValue("owner", { value: item.id, label: item.identifier })
        option.push({ value: item.id, label: item.name })
      );
      setResBranchOption(option);
    }
  }, [resBranchNameOnlyList]);

  if (watch("price") > 0 && watch("discount") > 0) {
    let temp = watch("price") - (watch("price") * watch("discount")) / 100;
    console.log(temp);
    setValue("total_price", temp.toFixed(2));
  }

  useEffect(() => {
    let option = [];
    if (menuCategoriesName?.length > 0) {
      menuCategoriesName.map((item) =>
        // setValue("owner", { value: item.id, label: item.identifier })
        option.push({ value: item.id, label: item.name })
      );
      setCategoryFoodOption(option);
    }
  }, [menuCategoriesName, setValue]);

  useEffect(() => {
    if (menuDetails) {
      setValue("name", menuDetails.name);
      setValue("price", menuDetails.price);
      setValue("discount", menuDetails.discount);
      setValue("review", menuDetails.review);
      setValue("stockcount", menuDetails.countInStock);
      setValue("status", menuDetails.status);
      if (menuDetails.restaurant_branch && resBranchOption.length > 0) {
        console.log(resBranchOption, menuDetails.restaurant_branch.id);
        let a = resBranchOption.find(
          (item) =>
            parseInt(item.value) === parseInt(menuDetails.restaurant_branch.id)
        );
        setValue("restaurantBranch", { value: a.value, label: a.label });
      }
      if (menuDetails.categories && categoryFoodOption.length > 0) {
        setValue(
          "categories",
          menuDetails.categories.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
      if (menuDetails.details) setDetailsText(menuDetails.details);
      if (menuDetails.allergen_info)
        setAllergenInfoText(menuDetails?.allergen_info);
      if (menuDetails.cooking_method)
        setCookingMethodText(menuDetails?.cooking_method);
    }
  }, [
    menuDetails,
    resBranchOption,
    categoryFoodOption,
    setDetailsText,
    setAllergenInfoText,
    setCookingMethodText,
    setValue,
  ]);

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: 32,
    }),
  };
  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">{edit ? "Edit" : "Add"} Menu Item </h1>
      <div>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <div className="md:grid md:grid-cols-2 md:gap-3 pt-2">
            <div className="">
              <label htmlFor="restaurantBranch" className=" text-gray-700">
                Restaturant Branch
              </label>
              <Controller
                name="restaurantBranch"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={resBranchOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.restaurantBranch &&
              errors.restaurantBranch.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div>
              <label htmlFor="categories" className=" text-gray-700">
                Categories
              </label>
              <Controller
                name="categories"
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="mt-1"
                    options={categoryFoodOption}
                    isMulti
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.categories && errors.categories.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-2 pt-2">
            <div className="">
              <label htmlFor="name" className=" text-gray-700">
                Name
              </label>

              <input
                type="text"
                {...register("name", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                name="name"
                id="name"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.name?.message}</p>
            </div>{" "}
            <div className="">
              <label htmlFor="price" className=" text-gray-700">
                Price
              </label>
              <input
                {...register("price", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="number"
                step={0.1}
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.price?.message}</p>
            </div>
            <div className="">
              <label htmlFor="discount" className=" text-gray-700">
                Discount
              </label>
              <input
                {...register("discount", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="number"
                step={0.1}
                className="mt-1 w-full focus:outline-none  block shadow-sm  border-gray-300 rounded-md"
              />{" "}
              <p className="text-sm text-red-600">{errors.discount?.message}</p>
            </div>{" "}
            <div className="">
              <label htmlFor="total_price" className=" text-gray-700">
                Total price
              </label>
              <input
                {...register("total_price", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="number"
                step={0.1}
                disabled
                className="mt-1 w-full block  shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.total_price?.message}
              </p>
            </div>{" "}
            <div className="">
              <label htmlFor="review" className=" text-gray-700">
                Review
              </label>
              <input
                {...register("review", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                className="mt-1 w-full block  shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.review?.message}</p>
            </div>
            <div className="">
              <label htmlFor="" className="text-gray-700">
                Stock Count
              </label>
              <input
                {...register("stockcount", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="number"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.stockcount?.message}
              </p>
            </div>
          </div>

          <div className="md:grid md:grid-cols-1 gap-2">
            <div className="md:my-6 mb-20 mt-4">
              <label htmlFor="details" className=" text-gray-700">
                Details
              </label>
              <ReactQuill
                theme="snow"
                className="mt-1 h-32 "
                value={detailsText}
                onChange={handleDetailsChange}
                modules={MenuItemsCreateUpdateForm.modules}
                formats={MenuItemsCreateUpdateForm.formats}
                bounds={".app"}
                preserveWhitespace
              />
              {/* <p className="text-sm text-red-600">
                {errors.details?.message}
                {errors.emailContent && "Enter valid content"}
              </p> */}
            </div>
            <div>
              <div className="md:my-6 my-24">
                <label htmlFor="cooking_method" className=" text-gray-700">
                  Cooking method
                </label>

                <ReactQuill
                  // {...register("cooking_method", {
                  //   required: "This field is required",
                  //   message: "This field is required",
                  // })}
                  className="mt-1 h-32 "
                  value={cookingMethodText}
                  onChange={handleCookingMethodChange}
                  modules={MenuItemsCreateUpdateForm.modules}
                  formats={MenuItemsCreateUpdateForm.formats}
                  preserveWhitespace
                />
              </div>
            </div>
            {/* <p className="text-sm text-red-600">
              {errors.cooking_method?.message}
            </p> */}
            <div className="md:my-6 my-24">
              <div>
                <label htmlFor="" className=" text-gray-700">
                  Allergen info
                </label>
                <ReactQuill
                  // {...register("allergen_info", {
                  //   required: "This field is required",
                  //   message: "This field is required",
                  // })}
                  className="mt-1 h-32 "
                  value={allergenInfoText}
                  onChange={handleAllergenInfoTextChange}
                  modules={MenuItemsCreateUpdateForm.modules}
                  formats={MenuItemsCreateUpdateForm.formats}
                  preserveWhitespace
                ></ReactQuill>
              </div>
            </div>
            {/* <p className="text-sm text-red-600">
              {errors.allergen_info?.message}
            </p> */}
            <div className="md:grid md:grid-cols-1 gap-2 pt-2">
              <div>
                <label className="block text-sm font-medium text-gray-700 my-3">
                  Photo
                </label>
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="preview"
                    className=" h-20 w-20 mr-3 rounded-full"
                  />
                ) : null}
                {!imagePreview && menuDetails ? (
                  <img
                    src={menuDetails.image}
                    alt="preview"
                    className=" h-20 w-20 mr-3 rounded-full"
                  />
                ) : null}
                <input
                  {...register("image", {
                    required: edit ? "" : "Image is required",
                    message: "Image is required",
                  })}
                  type="file"
                  name="image"
                  id="image"
                  className="mt-1  block w-full border-gray-300 rounded-md"
                />
                <p className="text-sm text-red-600">{errors.image?.message}</p>
              </div>

              {vendor ? null : (
                <div className="form-check flex items-center">
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id="flexCheckChecked"
                    {...register("status")}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    for="flexCheckChecked"
                  >
                    Status
                  </label>
                </div>
              )}

              <div className=" mt-2">
                <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
MenuItemsCreateUpdateForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

MenuItemsCreateUpdateForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
export default MenuItemsCreateUpdateForm;
