import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import SponsorCreateUpdateForm from "../../../components/admin/Forms/Sponsor/SponsorCreateUpdateForm";
import Layout from "../Layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { adminEditSponsor, adminSponsorDetailsAction } from "../../../redux/actions/AdminActions/adminSponsorAction";

const EditSponsors = () => {
  const { sponsorDetails } = useSelector((state) => state.adminSponsor);
  console.log(sponsorDetails);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(adminSponsorDetailsAction(id));
  }, [id, dispatch]);
  //   console.log(id);
  const onSubmit = (data) => {
    console.log(data);
    const formData = new FormData();
    if (data.image.length > 0) formData.append("image", data.image[0]);
    formData.append("name", data.name);
    formData.append("status", data.status);
    dispatch(adminEditSponsor(formData, id, history));
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <SponsorCreateUpdateForm
            edit
            sponsor={sponsorDetails}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </Layout>
  );
};

export default EditSponsors;
