import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import MenuVariationsCreateUpdateForm from "../../../components/admin/Forms/MenuVariations/MenuVariationsCreateUpdateForm";
import { adminMenuVariationsAdd } from "../../../redux/actions/AdminActions/adminMenuAction";
import { adminRestaurantBranchNameOnlyList } from "../../../redux/actions/AdminActions/adminRestaurantActions";
import Layout from "../Layout/layout";

function VendorAddMenuVariations() {
  const { user } = useSelector((state) => state.auth);
  const { menuItemNameList } = useSelector((state) => state.adminMenu);
  console.log(menuItemNameList);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(adminRestaurantBranchNameOnlyList());
  }, [dispatch]);
  const handleSubmitData = (data) => {
    console.log(data);
    const body = {
      restaurant_branch_id: data.restaurantBranch.value,
      food_items_id: data.foodItems.value,
      name: data.name,
      choose_item_number: parseInt(data.itemsNumber),
      is_free: data.is_free,
      is_mandatory: data.is_mandatory,
      status: data.status,
    };
    dispatch(adminMenuVariationsAdd(body, history, "vendor"));
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <MenuVariationsCreateUpdateForm
            resBranchNameOnlyList={user?.restaurant_branch}
            menuItemNameList={menuItemNameList}
            handleSubmitData={handleSubmitData}
          />
        </div>
      </div>
    </Layout>
  );
}

export default VendorAddMenuVariations;
