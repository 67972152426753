import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import MenuCategoriesCreateUpdateForm from "../../../components/admin/Forms/MenuCategories/MenuCategoriesCreateUpdateForm";
import {
  adminEditMenuCategory,
  adminMenuCategoryDetails,
} from "../../../redux/actions/AdminActions/adminMenuAction";
import Layout from "../Layout/layout";

function EditMenuCategories() {
  const { menuCategoryDetails } = useSelector((state) => state.adminMenu);
  console.log(menuCategoryDetails);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  useEffect(() => {
    dispatch(adminMenuCategoryDetails(params.id));
  }, [params, dispatch]);

  const handleSubmitData = (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append("name", data.name);
    if (data.image.length > 0) formData.append("image", data.image[0]);
    formData.append("status", data.status);
    formData.append("featured", data.is_featured);
    dispatch(adminEditMenuCategory(formData, params.id, history));
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <MenuCategoriesCreateUpdateForm
            edit
            menuCategoryDetails={menuCategoryDetails}
            handleSubmitData={handleSubmitData}
          />
        </div>
      </div>
    </Layout>
  );
}

export default EditMenuCategories;
