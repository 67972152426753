import {
  ADMIN_MENU_CATEGORY_LIST,
  ADMIN_MENU_LOADING,
  ADMIN_MENU_ERROR,
  ADMIN_MENU_CATEGORY_DETAILS,
  ADMIN_MENU_ITEMS_LIST,
  ADMIN_MENU_CATEGORY_NAME_LIST,
  ADMIN_MENU_ITEM_DETAILS,
  ADMIN_MENU_VARIATION_LIST,
  ADMIN_MENU_ITEMS_NAME_ONLY_LIST,
  ADMIN_MENU_VARIATION_DETAILS,
  ADMIN_MENU_INGREDIENTS_DETAILS,
  ADMIN_MENU_INGREDIENTS_LIST,
  ADMIN_MENU_VARIATION_NAME_ONLY_LIST,
} from "../../types";

const initialState = {
  loading: false,
  menuCategories: [],
  menuCategoriesName: [],
  menuCategoryDetails: {},
  menuList: [],
  menuIngredientList: [],
  menuItemNameList: [],
  menuDetails: {},
  menuVariationsList: [],
  menuVariationsNameList: [],
  menuVariationsDetails: {},
  menuIngredientsDetails: {},
  error: null,
};

export default function adminMenuReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADMIN_MENU_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ADMIN_MENU_CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        menuCategories: action.payload,
        error: null,
      };

    case ADMIN_MENU_CATEGORY_NAME_LIST:
      return {
        ...state,
        loading: false,
        menuCategoriesName: action.payload,
        error: null,
      };

    case ADMIN_MENU_VARIATION_LIST:
      return {
        ...state,
        loading: false,
        menuVariationsList: action.payload,
        error: null,
      };

    case ADMIN_MENU_INGREDIENTS_LIST:
      return {
        ...state,
        loading: false,
        menuIngredientList: action.payload,
        error: null,
      };

    case ADMIN_MENU_ITEMS_LIST:
      return {
        ...state,
        loading: false,
        menuList: action.payload,
        error: null,
      };

    case ADMIN_MENU_ITEMS_NAME_ONLY_LIST:
      return {
        ...state,
        loading: false,
        menuItemNameList: action.payload,
        error: null,
      };

    case ADMIN_MENU_CATEGORY_DETAILS:
      return {
        ...state,
        loading: false,
        menuCategoryDetails: action.payload,
        error: null,
      };

    case ADMIN_MENU_ITEM_DETAILS:
      return {
        ...state,
        loading: false,
        menuDetails: action.payload,
        error: null,
      };

    case ADMIN_MENU_VARIATION_DETAILS:
      return {
        ...state,
        loading: false,
        menuVariationsDetails: action.payload,
        error: null,
      };
    case ADMIN_MENU_VARIATION_NAME_ONLY_LIST:
      return {
        ...state,
        loading: false,
        menuVariationsNameList: action.payload,
        error: null,
      };

    case ADMIN_MENU_INGREDIENTS_DETAILS:
      return {
        ...state,
        loading: false,
        menuIngredientsDetails: action.payload,
        error: null,
      };

    case ADMIN_MENU_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
