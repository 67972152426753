import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  contentSettingsDetails,
  contentSettingsSave,
} from "../../../redux/actions/AdminActions/adminMetaActions";
import { ADMIN_CONTENT_SETTINGS_SAVE_CLEAR } from "../../../redux/types";
import Layout from "../Layout/layout";

function ContentSettings() {
  const { contentSettings, contentSave } = useSelector(
    (state) => state.adminMeta
  );
  console.log(contentSettings);
  const [aboutText, setAboutText] = useState("");
  const [termsText, setTermsText] = useState("");
  const [privacyPolicyText, setPrivacyPolicyText] = useState("");
  const [ReturnPolicyText, setReturnPolicyText] = useState("");
  const [vendorNoteText, setVendorNoteText] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contentSettingsDetails());
  }, [dispatch]);

  useEffect(() => {
    if (contentSettings?.data?.about) setAboutText(contentSettings.data.about);
    if (contentSettings?.data?.terms) setTermsText(contentSettings.data.terms);
    if (contentSettings?.data?.privacy_policy)
      setPrivacyPolicyText(contentSettings.data.privacy_policy);
    if (contentSettings?.data?.return_policy)
      setReturnPolicyText(contentSettings.data.return_policy);
    if (contentSettings?.data?.vendor_note)
      setVendorNoteText(contentSettings.data.vendor_note);
  }, [contentSettings]);

  useEffect(() => {
    if (contentSave) {
      toast.success("Content settings saved successfully", { duration: 5000 });
      dispatch({ type: ADMIN_CONTENT_SETTINGS_SAVE_CLEAR });
    }
  }, [contentSave, dispatch]);

  const handleAboutChange = (event) => {
    setAboutText(event);
    // console.log(event);
  };
  const handleTermsChange = (event) => {
    setTermsText(event);
  };
  const handlePrivacyPolicyChange = (event) => {
    setPrivacyPolicyText(event);
  };
  const handleReturnPolicyChange = (event) => {
    setReturnPolicyText(event);
  };
  const handleVendorNoteChange = (event) => {
    setVendorNoteText(event);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      about: aboutText,
      terms: termsText,
      privacy_policy: privacyPolicyText,
      return_policy: ReturnPolicyText,
      vendor_note: vendorNoteText,
    };
    dispatch(contentSettingsSave(data));
  };


  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
 
  return (
    <Layout>
      <div className=" m-3 ">
        <p className="text-center mb-8">Content settings</p>
        <Toaster />
        <form onSubmit={handleSubmit}>
          <div className="md:grid md:grid-cols-1 gap-2">
            <div className="md:my-6 mb-20 mt-4">
              <label htmlFor="details" className=" text-gray-700">
                About
              </label>
              <ReactQuill
                className="mt-1 h-32 "
                value={aboutText}
                onChange={handleAboutChange}
                modules={ContentSettings.modules}
                formats={ContentSettings.formats}
                preserveWhitespace
              />
            </div>

            <div className="md:my-6 my-24">
              <label htmlFor="" className=" text-gray-700">
                Terms
              </label>

              {/* <ReactQuill
                value={termsText}
                onChange={handleTermsChange}
                className="mt-1 h-32 "
                modules={ContentSettings.modules}
                formats={ContentSettings.formats}
                preserveWhitespace
              /> */}
            
       <Editor
        apiKey= "vo3fsa69qzonrsdngqpbfb0fel1fl1s7ugmnctriw0ztvtox" // Replace with your TinyMCE API key
        initialValue="<p>This is the initial content of the editor</p>"
        init={{
          selector: 'textarea#open-source-plugins',
          plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
          editimage_cors_hosts: ['picsum.photos'],
          menubar: 'file edit view insert format tools table help',
          toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
          toolbar_sticky: true,
          // toolbar_sticky_offset: isSmallScreen ? 102 : 108,
          autosave_ask_before_unload: true,
          autosave_interval: '30s',
          autosave_prefix: '{path}{query}-{id}-',
          autosave_restore_when_empty: false,
          autosave_retention: '2m',
          image_advtab: true,
          link_list: [
            { title: 'My page 1', value: 'https://www.tiny.cloud' },
            { title: 'My page 2', value: 'http://www.moxiecode.com' }
          ],
          image_list: [
            { title: 'My page 1', value: 'https://www.tiny.cloud' },
            { title: 'My page 2', value: 'http://www.moxiecode.com' }
          ],
          image_class_list: [
            { title: 'None', value: '' },
            { title: 'Some class', value: 'class-name' }
          ],
          importcss_append: true,
          file_picker_callback: (callback, value, meta) => {
            /* Provide file and text for the link dialog */
            if (meta.filetype === 'file') {
              callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
            }
        
            /* Provide image and alt text for the image dialog */
            if (meta.filetype === 'image') {
              callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
            }
        
            /* Provide alternative source and posted for the media dialog */
            if (meta.filetype === 'media') {
              callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
            }
          },
          templates: [
            { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
            { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
            { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
          ],
          template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
          template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
          height: 600,
          image_caption: true,
          quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
          noneditable_class: 'mceNonEditable',
          toolbar_mode: 'sliding',
          contextmenu: 'link image table',
          // skin: useDarkMode ? 'oxide-dark' : 'oxide',
          // content_css: useDarkMode ? 'dark' : 'default',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
        }}
        
      />
     
            </div>
            <div className="md:my-6 my-24">
              <label htmlFor="" className=" text-gray-700">
                Privacy policy
              </label>

              <ReactQuill
                value={privacyPolicyText}
                onChange={handlePrivacyPolicyChange}
                className="mt-1 h-32 "
                modules={ContentSettings.modules}
                formats={ContentSettings.formats}
                preserveWhitespace
              />
            </div>
            <div className="md:my-6 my-24">
              <label htmlFor="cooking_method" className=" text-gray-700">
                Return policy
              </label>

              <ReactQuill
                value={ReturnPolicyText}
                onChange={handleReturnPolicyChange}
                className="mt-1 h-32 "
                modules={ContentSettings.modules}
                formats={ContentSettings.formats}
                preserveWhitespace
              />
            </div>
            <div className="md:my-6 my-24">
              <label htmlFor="" className=" text-gray-700">
                Vendor note
              </label>

              <ReactQuill
                value={vendorNoteText}
                onChange={handleVendorNoteChange}
                className="mt-1 h-32 "
                modules={ContentSettings.modules}
                formats={ContentSettings.formats}
                preserveWhitespace
              />
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 gap-2 pt-2">
            {/* <div className="form-check flex items-center mt-5">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="flexCheckChecked"
              />
              <label
                className="form-check-label inline-block text-gray-800"
                for="flexCheckChecked"
              >
                Status
              </label>
            </div> */}

            <div className=" mt-2">
              <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}
ContentSettings.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
    ["code-block"]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
    
};
export default ContentSettings;
