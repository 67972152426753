import React, { useEffect, useState } from "react";
import Layout from "../Layout/layout";
import RestaurantBranchCreateUpdateForm from "../../../components/admin/Forms/Restaurant/RestaurantBranchCreateUpdateForm";
import { useDispatch, useSelector } from "react-redux";
import {
  adminAddRestaurantBranch,
  adminRestaurantNameOnlyList,
} from "../../../redux/actions/AdminActions/adminRestaurantActions";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useHistory } from "react-router";
import { fetchRestaurantCategories } from "../../../redux/actions/restaurantAction";

export default function AddRestaurantBranchs() {
  const { resNameOnlyList } = useSelector((state) => state.adminRestaurant);
  const { categories } = useSelector((state) => state.restaurant);
  const { user } = useSelector((state) => state.auth);
  console.log(categories);
  const [addressData, setAddressData] = useState({
    full_address: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
    phone_number: "",
    others: "",
    lat: "",
    lng: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(adminRestaurantNameOnlyList());
    dispatch(fetchRestaurantCategories());
  }, [dispatch]);

  console.log(user?.user?.is_home_made_user);

  const handleSubmitData = (data) => {
    console.log(data);

    const formData = new FormData();
    formData.append("restaurant", parseInt(data.restaurant.value));
    data.categories.forEach((category) => {
      formData.append("categories", category.value);
    });
    formData.append("name", data.name);
    formData.append("listed_hours", data.listed_hours);
    formData.append("open_time", data.open_time);
    formData.append("close_time", data.close_time);
    formData.append("min_delivery_time", data.min_delivery_time);
    formData.append("max_delivery_time", data.max_delivery_time);
    formData.append("delivery_charge", data.delivery_charge);
    formData.append("is_pickup", data.is_pickup);
    formData.append("is_delivery", data.is_delivery);
    formData.append("is_table", data.is_table);
    formData.append("hygiene_rating", data.hygiene_rating);
    formData.append("full_address", data.full_address);
    formData.append("lat", data.lat);
    formData.append("lng", data.lng);
    formData.append("road", data.road);
    formData.append("suburb", data.suburb);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("postcode", data.postcode);
    formData.append("country", data.country);
    formData.append("status", false);
    formData.append("is_main", data.is_main);
    formData.append("discount_amount_percent", data.discount_amount_percent);
    formData.append("image", data.image[0]);
    console.log(user?.user?.is_home_made_user);
    if (user?.user?.is_home_made_user) {
      formData.append("is_home_made", true);
    }
    dispatch(
      adminAddRestaurantBranch(
        formData,
        data.restaurant.value,
        history,
        "vendor"
      )
    );
  };
  const handleSelect = async (value) => {
    // setTest(value);

    console.log({ value });
    let city = "";
    let state = "";
    let postCode = "";
    let country = "";
    let lat = "";
    let lng = "";
    const results = await geocodeByAddress(value);
    console.log({ results });
    const latlng = await getLatLng(results[0]);
    if (latlng) {
      lat = latlng.lat;
      lng = latlng.lng;
    }

    for (let i = 0; i < results[0].address_components.length; i++) {
      if (results[0].address_components[i].types[0] === "route") {
        city = results[0].address_components[i].long_name;
      }
      if (results[0].address_components[i].types[0] === "postal_code") {
        postCode = results[0].address_components[i].long_name;
      }
      if (results[0].address_components[i].types[0] === "postal_town") {
        state = results[0].address_components[i].long_name;
      }
      if (
        results[0].address_components[i].types[0] !== "route" &&
        results[0].address_components[i].types[0] === "locality"
      ) {
        city = results[0].address_components[i].long_name;
      }
      if (results[0].address_components[i].types[0] === "country") {
        country = results[0].address_components[i].long_name;
      }
    }
    console.log(state);
    setAddressData({
      ...addressData,
      full_address: value,
      city: city,
      state: state,
      country: country,
      postcode: postCode,
      lat: lat,
      lng: lng,
    });
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <RestaurantBranchCreateUpdateForm
            resNameOnlyList={resNameOnlyList}
            resCategoryList={categories}
            handleSubmitData={handleSubmitData}
            addressData={addressData}
            setAddressData={setAddressData}
            handleSelect={handleSelect}
            res_id={user?.restaurant?.id}
            vendor
          />
        </div>
      </div>
    </Layout>
  );
}
