import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";
import {
  VENDOR_VOUCHER_LOADING,
  VENDOR_VOUCHER_LIST,
  VENDOR_VOUCHER_ERROR,
} from "../../types";

export const vendorVoucherListAction =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: VENDOR_VOUCHER_LOADING });
    const token = auth.getToken();
    // console.log(data);
    await axios
      .get(`${API_URL}/voucher/vendor/`, {
        params: {
          res_branch: data.res_branch,
          search: data.search,
          status: data.status,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) => {
        //   console.log(res.data);
        dispatch({ type: VENDOR_VOUCHER_LIST, payload: res.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: VENDOR_VOUCHER_ERROR, payload: err.response.data });
      });
  };
