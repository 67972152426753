import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import PlacesAutocomplete from "react-places-autocomplete";

const customStyles = {
  input: (provided, state) => ({
    ...provided,
    height: 32,
  }),
};

function RestaurantBranchCreateUpdateForm({
  resNameOnlyList,
  resCategoryList,
  handleSubmitData,
  addressData,
  setAddressData,
  handleSelect,
  resBranchDetailsEdit,
  res_id,
  edit,
  vendor,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {},
  });

  const [resOption, setResOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [imagePreview, setimagePreview] = useState("");

  if (watch("image")?.length > 0) {
    try {
      let o = watch("image");
      const reader = new FileReader();
      reader.readAsDataURL(o[0]);
      reader.onload = function () {
        setimagePreview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (resBranchDetailsEdit) {
      setValue("name", resBranchDetailsEdit.name);
      if (resBranchDetailsEdit.restaurant && resOption.length > 0) {
        let a = resOption.find(
          (item) =>
            parseInt(item.value) ===
            parseInt(resBranchDetailsEdit.restaurant.id)
        );
        setValue("restaurant", { value: a.value, label: a.label });
      }
      if (resBranchDetailsEdit.categories && categoryOption.length > 0) {
        setValue(
          "categories",
          resBranchDetailsEdit.categories.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
      setValue("full_address", resBranchDetailsEdit.full_address);
      setAddressData({
        ...addressData,
        full_address: resBranchDetailsEdit.full_address,
      });
      setValue("lat", resBranchDetailsEdit.lat);
      setValue("lng", resBranchDetailsEdit.lng);
      setValue("road", resBranchDetailsEdit.road);
      setValue("suburb", resBranchDetailsEdit.suburb);
      setValue("city", resBranchDetailsEdit.city);
      setValue("state", resBranchDetailsEdit.state);
      setValue("postcode", resBranchDetailsEdit.postcode);
      setValue("country", resBranchDetailsEdit.country);
      setValue("delivery_charge", resBranchDetailsEdit.delivery_charge);
      setValue("open_time", resBranchDetailsEdit.open_time);
      setValue("close_time", resBranchDetailsEdit.close_time);
      setValue("min_delivery_time", resBranchDetailsEdit.min_delivery_time);
      setValue("max_delivery_time", resBranchDetailsEdit.max_delivery_time);
      setValue("hygiene_rating", resBranchDetailsEdit.hygiene_rating);
      setValue("listed_hours", resBranchDetailsEdit.listed_hours);
      setValue(
        "discount_amount_percent",
        resBranchDetailsEdit.discount_amount_percent
      );
      setValue("is_pickup", resBranchDetailsEdit.is_pickup);
      setValue("is_delivery", resBranchDetailsEdit.is_delivery);
      setValue("is_table", resBranchDetailsEdit.is_table);
      setValue("is_main", resBranchDetailsEdit.is_main);
      setValue("status", resBranchDetailsEdit.status);
    }
    // eslint-disable-next-line
  }, [resBranchDetailsEdit, setValue]);

  useEffect(() => {
    if (res_id && resOption.length > 0) {
      let a = resOption.find(
        (item) => parseInt(item.value) === parseInt(res_id)
      );
      setValue("restaurant", { value: a.value, label: a.label });
    }
  }, [res_id, resOption, setValue]);

  useEffect(() => {
    let option = [];
    if (resNameOnlyList?.length > 0) {
      resNameOnlyList.map((item) =>
        // setValue("owner", { value: item.id, label: item.identifier })
        option.push({ value: item.id, label: item.name })
      );
      setResOption(option);
    }
  }, [resNameOnlyList, setValue]);

  useEffect(() => {
    let option = [];
    if (resCategoryList?.length > 0) {
      resCategoryList.map((item) =>
        // setValue("owner", { value: item.id, label: item.identifier })
        option.push({ value: item.id, label: item.name })
      );
      setCategoryOption(option);
    }
  }, [resCategoryList, setValue]);

  useEffect(() => {
    if (addressData.lat) {
      setValue("lat", addressData.lat.toFixed(5));
      setValue("lng", addressData.lng.toFixed(5));
      setValue("city", addressData.city);
      setValue("state", addressData.state);
      setValue("country", addressData.country);
      setValue("postcode", addressData.postcode);
      setValue("full_address", addressData.full_address);
    }
  }, [addressData, setValue]);

  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">Add Restaurant Branch </h1>
      <div>
        <form action="" onSubmit={handleSubmit(handleSubmitData)}>
          <div className="md:grid md:grid-cols-3 md:gap-3 pt-2">
            <div className="">
              <label htmlFor="name" className=" text-gray-700">
                Name
              </label>

              <input
                type="text"
                {...register("name", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                name="name"
                id="name"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.name?.message}</p>
            </div>
            <div>
              <label htmlFor="restaurantBranch" className=" text-gray-700">
                Select Restaturant
              </label>
              <Controller
                name="restaurant"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={resOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                    isDisabled={res_id || vendor ? true : false}
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.restaurant && errors.restaurant.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    Restaurant is required
                  </span>
                </div>
              ) : null}
            </div>
            <div>
              <label htmlFor="categories" className=" text-gray-700">
                Categories
              </label>
              <Controller
                name="categories"
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="mt-1"
                    options={categoryOption}
                    isMulti
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.categories && errors.categories.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-3 pt-2">
            <div className="relative">
              <label htmlFor="addres" className=" text-gray-700">
                Full Address
              </label>
              <PlacesAutocomplete
                value={addressData.full_address}
                onChange={(e) =>
                  setAddressData({
                    ...addressData,
                    full_address: e,
                  })
                }
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        // {...register("full_address", {
                        //   required: "This field is required",
                        //   message: "This field is required",
                        // })}
                        // type="text"
                        // name="full_address"
                        // id="address"
                        className:
                          "mt-1  block w-full shadow-sm  border-gray-300 rounded-md",
                      })}
                    />
                    <div className="absolute top-14 left-0 rounded-md w-full z-20 mt-4">
                      {loading && (
                        <div className="shadow-md bg-white p-2">Loading...</div>
                      )}
                      <div className=" shadow-themeShadow">
                        {suggestions.map((suggestion, index) => {
                          // const className = suggestion.active
                          //   ? "suggestion-item--active"
                          //   : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#1E40AF",
                                cursor: "pointer",
                                color: "#fff",
                                padding: "10px 10px",
                                borderRadius: "5px",
                                fontSize: "16px",
                              }
                            : {
                                backgroundColor: "#fff",
                                cursor: "pointer",
                                padding: "10px 10px",
                                fontSize: "16px",
                              };
                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <p className="text-sm text-red-600">
                {errors.full_address?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="lat" className=" text-gray-700">
                Lat
              </label>
              <input
                {...register("lat", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="lat"
                id="lat"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.lat?.message}</p>
            </div>
            <div className="">
              <label htmlFor="lng" className=" text-gray-700">
                Lng
              </label>
              <input
                {...register("lng", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="lng"
                id="lng"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.lng?.message}</p>
            </div>
            <div className="">
              <label htmlFor="road" className=" text-gray-700">
                Road
              </label>
              <input
                {...register("road", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="road"
                id="road"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.road?.message}</p>
            </div>
            <div className="">
              <label htmlFor="suburb" className=" text-gray-700">
                Suburb
              </label>
              <input
                {...register("suburb", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="suburb"
                id="suburb"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.suburb?.message}</p>
            </div>
            <div className="">
              <label htmlFor="city" className=" text-gray-700">
                City
              </label>
              <input
                {...register("city", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="city"
                id="city"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.city?.message}</p>
            </div>
            <div className="">
              <label htmlFor="state" className=" text-gray-700">
                State
              </label>
              <input
                {...register("state", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="state"
                id="state"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.state?.message}</p>
            </div>
            <div className="">
              <label htmlFor="postcode" className=" text-gray-700">
                Postcode
              </label>
              <input
                {...register("postcode", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="postcode"
                id="postcode"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.postcode?.message}</p>
            </div>
            <div className="">
              <label htmlFor="country" className=" text-gray-700">
                Country
              </label>
              <input
                {...register("country", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="country"
                id="country"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.country?.message}</p>
            </div>
            <div className="">
              <label htmlFor="delivery_charge" className=" text-gray-700">
                Delivery charge
              </label>
              <input
                {...register("delivery_charge", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="number"
                step={0.1}
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.delivery_charge?.message}
              </p>
            </div>{" "}
            <div className="">
              <label htmlFor="open_time" className="text-gray-700">
                Open time
              </label>
              <input
                {...register("open_time", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="time"
                name="open_time"
                id="open_time"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.open_time?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="close_time" className=" text-gray-700">
                Close time
              </label>
              <input
                {...register("close_time", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="time"
                name="close_time"
                id="close_time"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.close_time?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="min_delivery_time" className="text-gray-700">
                Min delivery time
              </label>
              <input
                {...register("min_delivery_time", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                name="min_delivery_time"
                type="number"
                id="min_delivery_time"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.min_delivery_time?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="max_delivery_time" className=" text-gray-700">
                Max delivery time
              </label>
              <input
                {...register("max_delivery_time", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="number"
                name="max_delivery_time"
                id="max_delivery_time"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.max_delivery_time?.message}
              </p>
            </div>{" "}
            <div className="">
              <label htmlFor="rating" className=" text-gray-700">
                Hygiene rating:
              </label>
              <input
                {...register("hygiene_rating", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="number"
                name="hygiene_rating"
                max={5}
                id="rating"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.hygiene_rating?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="listed_hours" className=" text-gray-700">
                Listed hours
              </label>
              <input
                {...register("listed_hours", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                name="listed_hours"
                id="listed_hours"
                className="mt-1 focus:outline-none  block w-full shadow-sm  border-gray-300 rounded-md"
              />{" "}
              <p className="text-sm text-red-600">
                {errors.listed_hours?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="discount_amount" className=" text-gray-700">
                Discount amount percent
              </label>
              <input
                {...register("discount_amount_percent", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="number"
                name="discount_amount_percent"
                id="discount_amount"
                className="mt-1 focus:outline-none  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.discount_amount_percent?.message}
              </p>
            </div>
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="PickupCheckChecked"
                {...register("is_pickup")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="PickupCheckChecked"
              >
                Pickup
              </label>
            </div>
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="DeliveryCheckChecked"
                {...register("is_delivery")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="DeliveryCheckChecked"
              >
                Delivery
              </label>
            </div>
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                id="TableCheckChecked"
                {...register("is_table")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="TableCheckChecked"
              >
                Table
              </label>
            </div>
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                id="MainCheckChecked"
                {...register("is_main")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="MainCheckChecked"
              >
                Main
              </label>
            </div>
            {vendor ? null : (
              <div className="form-check flex items-center">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  {...register("status")}
                />
                <label
                  className="form-check-label inline-block text-gray-800"
                  htmlFor="flexCheckChecked"
                >
                  Status
                </label>
              </div>
            )}
            <div className="block">
              <label htmlFor="image" className=" text-gray-700">
                Image
              </label>
              <div className="flex gap-3">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="preview"
                    className=" h-20 w-20 mr-3 rounded-full"
                  />
                ) : null}
                {!imagePreview && resBranchDetailsEdit ? (
                  <img
                    src={resBranchDetailsEdit.image}
                    alt="preview"
                    className=" h-20 w-20 mr-3 rounded-full"
                  />
                ) : null}
                <input
                  {...register("image", {
                    required: edit ? "" : "Image is required",
                    message: "Image is required",
                  })}
                  type="file"
                  name="image"
                  id="image"
                  className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                />
              </div>
              <p className="text-sm text-red-600">{errors.image?.message}</p>
            </div>
            <br />
          </div>
          <div className=" mt-2">
            <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RestaurantBranchCreateUpdateForm;
