import React, { useEffect } from "react";
import Layout from "../Layout/layout";
import RestaurantCreateUpdateForm from "../../../components/admin/Forms/Restaurant/RestaurantCreateUpdateForm";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  adminRestaurantDetails,
  adminRestaurantEdit,
} from "../../../redux/actions/AdminActions/adminRestaurantActions";
import { adminLimitUserListAction } from "../../../redux/actions/AdminActions/adminUserActions";

const VendorEditRestaurant = () => {
  const { resDetails } = useSelector((state) => state.adminRestaurant);
  const { adminLimitUserList } = useSelector((state) => state.adminUser);
  console.log(resDetails);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(adminLimitUserListAction());
    dispatch(adminRestaurantDetails(params.id));
  }, [params, dispatch]);

  const handleSubmitData = (data) => {
    console.log(data);
    if (data.image.length > 0) {
      const formData = new FormData();
      formData.append("image", data.image[0]);
      formData.append("name", data.name);
      formData.append("owner", parseInt(data.owner.value));
      formData.append("description", data.description);
      formData.append("status", data.status);
      // console.log(data.image[0].name);
      dispatch(adminRestaurantEdit(formData, params.id, history, "vendor"));
    } else {
      dispatch(
        adminRestaurantEdit(
          {
            name: data.name,
            owner: parseInt(data.owner.value),
            description: data.description,
            status: data.status,
          },
          params.id,
          history,
          "vendor"
        )
      );
    }
  };

  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <RestaurantCreateUpdateForm
            resDetails={resDetails}
            edit
            vendor
            adminLimitUserList={adminLimitUserList}
            handleSubmitData={handleSubmitData}
          />
        </div>
      </div>
    </Layout>
  );
};

export default VendorEditRestaurant;
