import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import "./styles.css";

import { signupUser } from "../redux/actions/authActions";
import Layout from "./Layout";

import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
import { BsGoogle } from "react-icons/bs";
// import { FaFacebookSquare } from "react-icons/fa";
// import Select from 'react-select';
import { googleLogin } from "../redux/actions/authActions";
import "./styles.css";
const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

const isValidPhone = (phone) => {
  // var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{10})$/;
  var re = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/ || /^(?:(?:\+|00)44|0)7(?:[45789]\d{2}|624)\d{6}$/
  ;

  return re.test(phone);
};

// const options = [
//   { value: "USER", label: "Become a user" },
//   { value: "SUPPLIER", label: "Become a supplier" },
// ];

function Registration() {
  // const [role, setRole] = useState("");

  const { authError } = useSelector((state) => state.auth);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const value = {
      ...values,
      // role: role.value,
      role: "USER",
      // formType: formType,
    };
    console.log(value);
    dispatch(signupUser(value, history));
  };
  const [user, setUser] = useState([]);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => (
      setUser(codeResponse),
      dispatch(googleLogin(codeResponse.access_token, history))
    ),
    onError: (error) => console.log("Login Failed:", error),
  });
  console.log(user);
  const responseFacebook = (response) => {
    console.log(response);
    // dispatch(facebookLogin(response?.accessToken, history));
    // let fName = res?.name?.split(" ")[0];
    // let lName = res?.name?.split(" ")[1];
    // setValue("firstName", fName);
    // setValue("lastName", lName);
    // setValue("email", res.email);
  };

  const handleEmailValidation = (email) => {
    console.log("Validate was called with", email);

    const isValidEmail2 = isValidEmail(email);
    const isValidPhone2 = isValidPhone(email);

    const isValid = isValidEmail2 || isValidPhone2;
    console.log(isValid);

    const validityChanged =
      (errors.identifier && isValidEmail2 && isValidPhone2) ||
      (!errors.eidentifiermail && !isValidEmail2 && isValidPhone2);
    if (validityChanged) {
      console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };
  const handlePhoneValidation = (phone) => {
    console.log("Validate was called with", phone);

    // const isValidEmail2 = isValidEmail(phone);
    const isValidPhone2 = isValidPhone(phone);

    const isValid =  isValidPhone2;
    console.log(isValid);

    const validityChanged =
      (errors.phone_no &&  isValidPhone2) 
  
    if (validityChanged) {
      console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };

  console.log(errors);

  const [isViewed, setisViewed] = useState(false);
  const [isConfirmViewed, setisConfirmViewed] = useState(false);
  const togglePassword = () => {
    setisViewed(!isViewed);
  };
  const toggleConfirmPassword = () => {
    setisConfirmViewed(!isConfirmViewed);
  };

  return (
    <Layout loginHeader>
      <div className="">
        {/* font-body bg-login-bg bg-cover object-contain bg-center */}
        <div className="container ">
          <div className="my-20 flex justify-center ">
            <div className="w-[500px] shadow-[0px_0px_15px_rgba(46,49,146,0.08)] rounded-md py-8 ">
              <div>
                <h3 className="text-2xl text-center">Let's get you started!</h3>
                <p className="text-center text-[#464956] text-xs">
                  Sign up by typing your email/phone number, role and password.
                </p>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="md:px-9 md:py-6 p-4 mb-4  rounded"
                >
                  <div className="mb-4 w-full">
                    <input
                      className={
                        errors?.identifier
                          ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                          : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                      }
                      type="email"
                      placeholder="Enter Email "
                      {...register("identifier", {
                        required: true,
                        validate: handleEmailValidation,
                      })}
                    />
                    {errors?.identifier?.type === "required" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        This field is required
                      </p>
                    )}
                    {errors?.identifier?.type === "validate" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        Enter valid email
                      </p>
                    )}
                  </div>
                  <div className="mb-4 w-full">
                    <input
                      className={
                        errors?.phone_no
                          ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                          : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                      }
                      type="text"
                      placeholder="Phone number "
                      {...register("phone_no", {
                        required: true,
                        validate: handlePhoneValidation,
                      })}
                    />

                    {errors?.phone_no?.type === "required" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        This field is required
                      </p>
                    )}
                    {errors?.phone_no?.type === "validate" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        Enter valid phone number
                      </p>
                    )}
                  </div>
                  <div className="mb-4 flex gap-2">
                    <div className="w-full">
                      <input
                        className={
                          errors?.phone_no
                            ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                        }
                        type="text"
                        placeholder="First name"
                        {...register("first_name", {
                          required: true,
                        })}
                      />

                      {errors?.first_name?.type === "required" && (
                        <p className="text-xs italic text-red-500 mt-1">
                          This field is required
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        className={
                          errors?.phone_no
                            ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                        }
                        type="text"
                        placeholder="Last name"
                        {...register("last_name", {
                          required: true,
                        })}
                      />

                      {errors?.last_name?.type === "required" && (
                        <p className="text-xs italic text-red-500 mt-1">
                          This field is required
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="relative w-full">
                      <input
                        className={
                          errors?.password
                            ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                        }
                        id="password"
                        type={isViewed ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        {...register("password", {
                          required: true,
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                        })}
                      />
                      <i
                        onClick={togglePassword}
                        className="absolute  inset-y-0 right-2 mt-3 mr-2"
                        // inset-y-0 right-2 mt-3 mr-2
                      >
                        {isViewed ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            // height={12}
                            // width={20}
                            viewBox="0 0 24 20"
                            className="w-6 h-4"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            className="w-6 h-4"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                          // <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          // <path d="M7.59649 5.61678C5.78519 5.4664 4.241 4.65073 2.75941 3.48874C2.66759 3.60721 2.57577 3.73936 2.47144 3.85328C2.17512 4.18593 1.87462 4.50947 1.56996 4.833C1.42806 4.98338 1.22356 5.00616 1.06914 4.90591C0.91472 4.80566 0.83125 4.60516 0.885505 4.41377C0.91472 4.31352 0.981496 4.21783 1.04827 4.14036C1.39885 3.74848 1.75777 3.36115 2.12921 2.96014C1.99148 2.82344 1.84958 2.70496 1.72438 2.56826C1.19434 1.98043 0.66431 1.38804 0.142622 0.791092C-0.0577057 0.558694 -0.0410117 0.285284 0.155143 0.107567C0.347124 -0.070149 0.58084 -0.0245807 0.772821 0.221488C1.83706 1.58398 3.05573 2.74597 4.49141 3.63C5.38036 4.17682 6.3194 4.55959 7.33356 4.69174C8.32686 4.82389 9.29093 4.65073 10.2258 4.27707C11.7199 3.67557 12.9845 2.68218 14.1238 1.49284C14.5203 1.08273 14.8792 0.627046 15.2549 0.18959C15.3717 0.0528854 15.5011 -0.0291375 15.6722 0.0118739C15.8433 0.0528854 15.9477 0.17592 15.9894 0.358193C16.027 0.531353 15.9602 0.668058 15.86 0.795649C15.2841 1.54297 14.6539 2.23105 13.9653 2.85989C13.936 2.88724 13.9068 2.91458 13.8609 2.96014C14.224 3.35203 14.5788 3.73936 14.9377 4.12669C15.0504 4.24973 15.1338 4.38643 15.1046 4.56871C15.0754 4.74642 14.9836 4.86946 14.8208 4.9287C14.6497 4.99249 14.5078 4.9287 14.3909 4.8011C14.0404 4.42289 13.694 4.04467 13.3476 3.6619C13.3058 3.61633 13.2683 3.55709 13.2307 3.50241C11.77 4.65073 10.2175 5.47552 8.40615 5.62133C8.40615 5.89019 8.40615 6.14993 8.40615 6.40511C8.40615 6.78333 8.41033 7.15699 8.40615 7.5352C8.40198 7.80861 8.23086 8 7.99715 8C7.75926 8 7.59232 7.81317 7.59232 7.5352C7.59649 6.9018 7.59649 6.27296 7.59649 5.61678Z" fill="#B3B3B3"/>
                          // </svg>
                        )}
                      </i>
                    </div>

                    {errors?.password?.type === "required" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        You must specify a password
                      </p>
                    )}
                    {errors?.password?.type === "minLength" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <div className="relative w-full">
                      <input
                        className={
                          errors?.confirmPassword
                            ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                        }
                        id="c_password"
                        type={isConfirmViewed ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm password"
                        {...register("confirmPassword", {
                          validate: (value) =>
                         
                            value === password.current ||
                            "The passwords do not match",
                        })}
                      />
                      <i
                        onClick={toggleConfirmPassword}
                        className="absolute inset-y-0 right-2 mt-3 mr-2"
                      >
                        {isConfirmViewed ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            className="w-6 h-4"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            className="w-6 h-4"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        )}
                      </i>

                      {errors?.confirmPassword && (
                        <p className="text-xs italic text-red-500 mt-1">
                          {errors.confirmPassword.message}
                        </p>
                      )}
                    </div>

                    {errors?.confirmPassword?.type === "required" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        You must specify a password
                      </p>
                    )}
                    {errors?.confirmPassword?.type === "minLength" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        {errors.password.message}
                      </p>
                    )}
                  </div>

                  {/* <div className="mb-4 ">
                     {!Array.isArray(authError) && (
                      <p className="text-xs italic text-red-500">{authError}</p>
                    )} 
                    {serverError && (
                      <p className="text-xs italic text-red-500">
                        You are Currently Offline
                      </p>
                    )} 
                  </div> */}
                  <div className="mb-6 text-center">
                    <button
                      className="w-full px-5 py-3 font-bold text-white bg-[#0DC1D9] rounded-lg hover:bg-[#0DC1D9] focus:outline-none focus:shadow-outline "
                      type="submit"
                    >
                      Create Account
                    </button>
                  </div>
                  <div className="flex mb-4 mx-16">
                    <div className="w-1/2 border-t border-[#8F9394] mt-3"></div>
                    <div className=" flex items-center justify-center px-3">
                      <span className="border-l border-[#8F9394]"></span>
                      <span className="">OR</span>
                      <span className="border-r border-gray-400"></span>
                    </div>
                    <div className="w-1/2 border-t border-[#8F9394] mt-3"></div>
                  </div>

                  <div className="md:flex mb-4 justify-center items-center gap-3">
                    <div className="md:w-1/2 relative md:my-0 my-2 ">
                      <BsGoogle className="absolute text-white inset-y-4 md:inset-x-5 inset-x-20" />
                      <button
                        className="p-3 w-full font-bold text-white bg-[#DF4B38] rounded-lg focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => login()}
                      >
                        Sign in with google
                      </button>
                    </div>

                    <div className="md:w-1/2">
                      {/* <FacebookLogin
                        // appId="4922934877752441"
                        appId="850232235652365"
                        autoLoad={false}
                        fields="name,email,picture"
                        scope="public_profile"
                        icon={<FaFacebookSquare />}
                        // icon="fa-facebook"
                        cssClass="kep-login-facebook2 w-full bg-[#3E5C9A] rounded-lg text-center p-3 rounded-lg"
                        // onClick={componentClicked}
                        callback={responseFacebook}
                      /> */}
                    </div>
                  </div>

                  <div className="text-center text-sm">
                    <p className="text-[#888888]">
                      Already have account?
                      <Link
                        className="inline-block text-[#028FF0] align-baseline "
                        to={`/login`}
                      >
                        Login!
                      </Link>
                    </p>
                  </div>
                </form>
                {/* <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="md:px-8 md:py-6 mb-4 bg-white rounded"
                >
                  <div className="mb-4 md:flex md:justify-between">
                    
                    <div className="md:ml-2 w-full">
                      <label
                        className="block mb-2 text-sm font-bold text-gray-700"
                        htmlFor="userRole"
                      >
                        Role
                      </label>
                      <Select
                        defaultValue={options[1]}
                        className="basic-single"
                        classNamePrefix="select"
                        options={options}
                        value={role}
                        onChange={(val) => setRole(val)}
                      />
                    </div>
                  </div>

                  <div className="mb-2 md:flex md:justify-between">
                    <div className="mb-4 md:mr-2 md:mb-0 w-full">
                      <label
                        className="block mb-2 text-sm font-bold text-gray-700"
                        htmlFor="password"
                      >
                        Password
                      </label>
                    <div className="relative">
                      <input
                        className={
                          errors?.password
                            ? "w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border border-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            : "w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        }
                        id="password"
                        type={isViewed ? "text" : "password"}
                        name="password"
                        placeholder="******************"
                        {...register("password", {
                          required: true,
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                        })}
                      />
                      <i onClick={togglePassword}
                      className="absolute inset-y-0 right-0 mt-2 mr-2 "
                      >
                        {isViewed ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            height={15}
                            width={15}
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            height={15}
                            width={15}
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        )}
                      </i>
                      </div>
                      {errors?.password?.type === "required" && (
                        <p className="text-xs italic text-red-500">
                          You must specify a password
                        </p>
                      )}
                      {errors?.password?.type === "minLength" && (
                        <p className="text-xs italic text-red-500">
                          {errors.password.message}
                        </p>
                      )}
                    </div>
                    <div className="md:ml-2 w-full">
                      <label
                        className="block mb-2 text-sm font-bold text-gray-700"
                        htmlFor="c_password"
                      >
                        Confirm Password
                      </label>
                      <div className="relative">

                      <input
                        className={
                          errors?.confirmPassword
                            ? "w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border border-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            : "w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        }
                        id="c_password"
                        type={isConfirmViewed ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="******************"
                        {...register("confirmPassword", {
                          validate: (value) =>
                            value === password.current ||
                            "The passwords do not match",
                        })}
                      />
                       <i onClick={toggleConfirmPassword}
                      className="absolute inset-y-0 right-0 mt-2 mr-2 "
                      >
                        {isConfirmViewed ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            height={15}
                            width={15}
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            height={15}
                            width={15}
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        )}
                      </i>
                      </div>
                      {errors?.confirmPassword && (
                        <p className="text-xs italic text-red-500">
                          {errors.confirmPassword.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4 -mt-3">
                    {Array.isArray(authError) &&
                      authError.map((err, index) => (
                        <p key={index} className="text-xs italic text-red-500">
                          {err}
                        </p>
                      ))}
                  </div>
                  <div className="mb-6 text-center">
                    <button
                      className="w-full px-4 py-2 font-bold text-white bg-indigo-800 rounded-lg hover:bg-indigo-700 focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      Register Account
                    </button>
                  </div>
                  <hr className="mb-6 border-t" />
                  <div className="text-center">
                    <p>
                      Already have an account?
                      <Link
                        className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                        to={`login`}
                        // href="login.html"
                      >
                        Login!
                      </Link>
                    </p>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Registration;
