import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  addWishlistRes,
} from "../../redux/actions/restaurantAction";


function RestaurantListCard(props) {
console.log({props});
  const dispatch = useDispatch();
  const { id } = useParams();
  const { wishlist } = useSelector((state) => state.restaurant);
  console.log({wishlist});
  
  


 
 
  return (
    <div className="res-card w-full">
      <div className="max-w-lg mx-auto">
        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm group transform hover:-translate-y-1 transition-transform">
         
            <div className="relative">
            <div class="absolute top-3 left-3 bg-[#FF0000] text-white px-2 py-1 rounded-[12.5px] shadow-themeShadow">
    
      
      <p class="text-sm">50% off selected items</p>
  
  </div>
  {/* <Link className="z-[99]" to={`/restaurant/${props.branch_id}`}> */}
              <img
                className="rounded-t-lg w-full xl:h-60 h-32 object-cover"
                src={props.image}
                alt=""
              />
              {/* </Link> */}
               {
          props.wishlist ? (
            <div
              onClick={() =>( dispatch(addWishlistRes(id))
                )}
              className="z-[99999] absolute top-3 right-3 h-8 w-8 flex justify-center items-center rounded-sm rounded-br-2xl cursor-pointer shadow-themeShadow"
            >
            
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                className=" fill-[#0DC1D9]"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <path
                    className="cls-1 fill-white"
                    d="M2.66,0H45.34A2.66,2.66,0,0,1,48,2.66V27A21,21,0,0,1,27,48H2.66A2.66,2.66,0,0,1,0,45.34V2.66A2.66,2.66,0,0,1,2.66,0Z"
                  />
                  <path
                    className="cls-2"
                    d="M13.87,21.41V20.3l.12-.68a5,5,0,0,1,5.75-4A6.76,6.76,0,0,1,23,17c.35.26.68.56,1,.86l.14-.13a8.19,8.19,0,0,1,1.93-1.43,6,6,0,0,1,4-.68,5,5,0,0,1,4,4.91,6.4,6.4,0,0,1-.69,3.13,14.4,14.4,0,0,1-2.8,3.77,32.63,32.63,0,0,1-6.51,4.95.2.2,0,0,1-.25,0c-1-.69-2.11-1.34-3.13-2.07A23.26,23.26,0,0,1,16,25.87a9.73,9.73,0,0,1-1.87-3.43C14,22.1,14,21.75,13.87,21.41Z"
                  />
                </g>
              </svg>
            </div>
          ) : (
            <div
              onClick={() => dispatch(addWishlistRes(id))}
              className="absolute top-3 right-3 h-8 w-8  rounded-2xl cursor-pointer shadow-themeShadow"
            >
          

              <svg className=" " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <g id="Layer_2" data-name="Layer 2">
                  <path
                    className="cls-1 fill-white"
                    d="M2.66,0H45.34A2.66,2.66,0,0,1,48,2.66V27A21,21,0,0,1,27,48H2.66A2.66,2.66,0,0,1,0,45.34V2.66A2.66,2.66,0,0,1,2.66,0Z"
                  />
                  <path
                    className="cls-2 fill-black"
                    d="M13.84,20.48c.06-.35.11-.71.2-1.05a5.24,5.24,0,0,1,4.41-4,6.33,6.33,0,0,1,4.36,1c.41.25.8.56,1.2.86l.7-.55a7.13,7.13,0,0,1,3.34-1.36,5.33,5.33,0,0,1,6,4.33,6.87,6.87,0,0,1-1,4.92,16.16,16.16,0,0,1-3,3.62,32.82,32.82,0,0,1-5.59,4.13.78.78,0,0,1-.9,0,31.31,31.31,0,0,1-6.5-5,12.48,12.48,0,0,1-2.71-4,6.15,6.15,0,0,1-.47-1.79.45.45,0,0,0,0-.11Zm5.42-3.78h-.42a1.6,1.6,0,0,0-.23,0,3.88,3.88,0,0,0-3.18,2.52,5,5,0,0,0,.22,4,12.26,12.26,0,0,0,2.52,3.45,30.06,30.06,0,0,0,5.7,4.41.24.24,0,0,0,.27,0A31.81,31.81,0,0,0,29,27.54,14.94,14.94,0,0,0,32,24.05a5.56,5.56,0,0,0,.86-3.87,4,4,0,0,0-2-3A4.41,4.41,0,0,0,27,17a6.75,6.75,0,0,0-2.49,1.62.67.67,0,0,1-1.1,0,7.17,7.17,0,0,0-.58-.52A5.72,5.72,0,0,0,19.26,16.7Z"
                  />
                </g>
              </svg>
            </div>
          )
       }
       
              <div className="arr-min absolute bottom-4 right-3 bg-white shadow-themeShadow rounded-lg text-center leading-3 px-2 pt-2 pb-1">
                <span className="text-md font-semibold">
                  {props.min_delivery_time}-{props.max_delivery_time}
                </span>
                <span className="text-xs block">min</span>
              </div>
            </div>
         
          <div className="p-5 h-36">
            <Link to={`/restaurant/${props.id}`}>
              <h5 className="text-gray-900 font-bold xl:text-lg md:text-md text-sm tracking-tight mb-2 group-hover:text-indigo-800 transition-colors">
                {props.name}
              </h5>
            
            <p className="font-normal text-xs text-gray-700 flex flex-wrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 currentColor text-indigo-800"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              <span className="text-indigo-800 mr-3">
                {props?.rating ? <>{props.rating} Excellent</> : 0}
              </span>
              {props.suburb}
            </p>
            <div className="font-normal text-xs text-gray-700 flex gap-4
            ">
             <p> {props.distance.toFixed(2)} miles away </p>
             <p>  £{props.delivery_charge}{" "}
              delivery</p>
            
          </div>
          </Link>
        </div>
      </div>
    </div>
    </div>
  );
}

export default RestaurantListCard;
