import axios from 'axios'
import React, { useEffect, useState } from 'react'
import TermConditionsBanner from '../components/TermConditions/TermConditionsBanner'
import TermOfService from '../components/TermConditions/TermOfService'
import { API_URL } from '../settings/config'
import auth from '../util/auth'
import Layout from './Layout'

const TermConditions = () => {
  const [terms, setTerms] = useState('');
   const fetchTerms = async () => {
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/meta/admin/content-settings/`, {
        headers: { Authorization: token },
      })
      .then((res) => {
      //  console.log(res?.data?.data?.terms)
       setTerms(res.data?.data?.terms)
       
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchTerms()
  }, []);
  return (
  <Layout home>
<TermConditionsBanner/>
<div className='container xl:w-[60%] lg:w-[90%] w-full'  >

<ul  className="md:grid grid-cols-2">
  <li className='flex items-center gap-3'>
  <svg width="23" height="11" viewBox="0 0 23 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.00328723 5.37763C0.146142 5.07629 0.403936 4.99782 0.730696 4.99782C7.4082 4.99782 14.0857 4.99782 20.7632 4.99782H21.0916C21.0013 4.90522 20.9455 4.84087 20.8847 4.78594L16.8651 0.926617C16.6056 0.678641 16.5695 0.4134 16.7583 0.187396C16.7977 0.137512 16.847 0.0956125 16.9035 0.0641352C16.9599 0.0326579 17.0224 0.0122341 17.0872 0.004054C17.152 -0.00412606 17.2178 9.99123e-05 17.2809 0.0164879C17.344 0.0328759 17.403 0.0610965 17.4546 0.0995066C17.5368 0.16092 17.6147 0.227509 17.6877 0.29883L22.6138 5.01508C23.0029 5.38547 23.0029 5.61461 22.6138 5.98972L17.6007 10.7813C17.2854 11.0811 16.9127 11.0717 16.714 10.7593C16.6594 10.6821 16.6309 10.5907 16.6324 10.4976C16.6338 10.4044 16.6652 10.3139 16.7222 10.2383C16.7875 10.1513 16.8611 10.0704 16.9423 9.99657C18.2559 8.74099 19.5695 7.48542 20.8831 6.22985C20.9502 6.17277 21.0216 6.12032 21.0965 6.0729L21.0588 6.0007H20.8075C14.1049 6.0007 7.40273 6.0007 0.701141 6.0007C0.372739 6.0007 0.134645 5.91281 0 5.61775L0.00328723 5.37763Z" fill="#0290EF"/>
</svg>

British Marketplace Terms Of Service</li>
  <li className='flex items-center gap-3'>
  <svg width="23" height="11" viewBox="0 0 23 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.00328723 5.37763C0.146142 5.07629 0.403936 4.99782 0.730696 4.99782C7.4082 4.99782 14.0857 4.99782 20.7632 4.99782H21.0916C21.0013 4.90522 20.9455 4.84087 20.8847 4.78594L16.8651 0.926617C16.6056 0.678641 16.5695 0.4134 16.7583 0.187396C16.7977 0.137512 16.847 0.0956125 16.9035 0.0641352C16.9599 0.0326579 17.0224 0.0122341 17.0872 0.004054C17.152 -0.00412606 17.2178 9.99123e-05 17.2809 0.0164879C17.344 0.0328759 17.403 0.0610965 17.4546 0.0995066C17.5368 0.16092 17.6147 0.227509 17.6877 0.29883L22.6138 5.01508C23.0029 5.38547 23.0029 5.61461 22.6138 5.98972L17.6007 10.7813C17.2854 11.0811 16.9127 11.0717 16.714 10.7593C16.6594 10.6821 16.6309 10.5907 16.6324 10.4976C16.6338 10.4044 16.6652 10.3139 16.7222 10.2383C16.7875 10.1513 16.8611 10.0704 16.9423 9.99657C18.2559 8.74099 19.5695 7.48542 20.8831 6.22985C20.9502 6.17277 21.0216 6.12032 21.0965 6.0729L21.0588 6.0007H20.8075C14.1049 6.0007 7.40273 6.0007 0.701141 6.0007C0.372739 6.0007 0.134645 5.91281 0 5.61775L0.00328723 5.37763Z" fill="#0290EF"/>
</svg>

British Marketplace Terms Of Use For Website And Applications</li>
  <li className='flex items-center gap-3'>
  <svg width="23" height="11" viewBox="0 0 23 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.00328723 5.37763C0.146142 5.07629 0.403936 4.99782 0.730696 4.99782C7.4082 4.99782 14.0857 4.99782 20.7632 4.99782H21.0916C21.0013 4.90522 20.9455 4.84087 20.8847 4.78594L16.8651 0.926617C16.6056 0.678641 16.5695 0.4134 16.7583 0.187396C16.7977 0.137512 16.847 0.0956125 16.9035 0.0641352C16.9599 0.0326579 17.0224 0.0122341 17.0872 0.004054C17.152 -0.00412606 17.2178 9.99123e-05 17.2809 0.0164879C17.344 0.0328759 17.403 0.0610965 17.4546 0.0995066C17.5368 0.16092 17.6147 0.227509 17.6877 0.29883L22.6138 5.01508C23.0029 5.38547 23.0029 5.61461 22.6138 5.98972L17.6007 10.7813C17.2854 11.0811 16.9127 11.0717 16.714 10.7593C16.6594 10.6821 16.6309 10.5907 16.6324 10.4976C16.6338 10.4044 16.6652 10.3139 16.7222 10.2383C16.7875 10.1513 16.8611 10.0704 16.9423 9.99657C18.2559 8.74099 19.5695 7.48542 20.8831 6.22985C20.9502 6.17277 21.0216 6.12032 21.0965 6.0729L21.0588 6.0007H20.8075C14.1049 6.0007 7.40273 6.0007 0.701141 6.0007C0.372739 6.0007 0.134645 5.91281 0 5.61775L0.00328723 5.37763Z" fill="#0290EF"/>
</svg>

British Marketplace Pick up Terms of Service</li>
  <li className='flex items-center gap-3'>
  <svg width="23" height="11" viewBox="0 0 23 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.00328723 5.37763C0.146142 5.07629 0.403936 4.99782 0.730696 4.99782C7.4082 4.99782 14.0857 4.99782 20.7632 4.99782H21.0916C21.0013 4.90522 20.9455 4.84087 20.8847 4.78594L16.8651 0.926617C16.6056 0.678641 16.5695 0.4134 16.7583 0.187396C16.7977 0.137512 16.847 0.0956125 16.9035 0.0641352C16.9599 0.0326579 17.0224 0.0122341 17.0872 0.004054C17.152 -0.00412606 17.2178 9.99123e-05 17.2809 0.0164879C17.344 0.0328759 17.403 0.0610965 17.4546 0.0995066C17.5368 0.16092 17.6147 0.227509 17.6877 0.29883L22.6138 5.01508C23.0029 5.38547 23.0029 5.61461 22.6138 5.98972L17.6007 10.7813C17.2854 11.0811 16.9127 11.0717 16.714 10.7593C16.6594 10.6821 16.6309 10.5907 16.6324 10.4976C16.6338 10.4044 16.6652 10.3139 16.7222 10.2383C16.7875 10.1513 16.8611 10.0704 16.9423 9.99657C18.2559 8.74099 19.5695 7.48542 20.8831 6.22985C20.9502 6.17277 21.0216 6.12032 21.0965 6.0729L21.0588 6.0007H20.8075C14.1049 6.0007 7.40273 6.0007 0.701141 6.0007C0.372739 6.0007 0.134645 5.91281 0 5.61775L0.00328723 5.37763Z" fill="#0290EF"/>
</svg>

British Marketplace Voucher and Account Credit Terms</li>
  
    
</ul>
      </div>
      <TermOfService terms={terms}/>
  </Layout>
  )
}

export default TermConditions