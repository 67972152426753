import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

function MenuCategoriesCreateUpdateForm({
  edit,
  handleSubmitData,
  menuCategoryDetails,
  vendor,
}) {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
    watch,
  } = useForm({
    defaultValues: {},
  });

  const [imagePreview, setimagePreview] = useState("");

  if (watch("image")?.length > 0) {
    try {
      let o = watch("image");
      console.log(o[0]);
      const reader = new FileReader();
      console.log(reader);
      reader.readAsDataURL(o[0]);
      reader.onload = function () {
        setimagePreview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (menuCategoryDetails) {
      setValue("name", menuCategoryDetails.name);
      setValue("status", menuCategoryDetails.status);
      setValue("is_featured", menuCategoryDetails.featured);
    }
  }, [menuCategoryDetails, setValue]);

  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">
        {edit ? "Edit" : "Add"} Menu Categories
      </h1>
      <div>
        <form action="" onSubmit={handleSubmit(handleSubmitData)}>
          <div className="md:grid md:grid-cols-1 md:gap-3 pt-2">
            <div className="md:w-1/3">
              <label htmlFor="name" className=" text-gray-700">
                Name
              </label>

              <input
                type="text"
                {...register("name", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                name="name"
                id="name"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.name?.message}</p>
            </div>
            <div className="block">
              <label htmlFor="image" className=" text-gray-700">
                Image
              </label>
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              {!imagePreview && menuCategoryDetails?.image ? (
                <img
                  src={menuCategoryDetails.image}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              <input
                {...register("image", {
                  required: edit ? "" : "Image is required",
                  message: "Image is required",
                })}
                type="file"
                name="image"
                id="image"
                className="mt-1  block w-1/3 border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.image?.message}</p>
            </div>
            {vendor ? null : (
              <div className="form-check flex items-center my-1">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id="flexCheckChecked"
                  {...register("is_featured")}
                />
                <label
                  className="form-check-label inline-block text-gray-800"
                  htmlFor="flexCheckChecked"
                >
                  Featured
                </label>
              </div>
            )}
            {vendor ? null : (
              <div className="form-check flex items-center my-1">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id="flexCheckChecked"
                  {...register("status")}
                  defaultChecked
                />
                <label
                  className="form-check-label inline-block text-gray-800"
                  htmlFor="flexCheckChecked"
                >
                  Status
                </label>
              </div>
            )}

            <div className=" mt-2">
              <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MenuCategoriesCreateUpdateForm;
