import parse from 'html-react-parser';
import React from "react";
import paymentThumb from "../../images/banner/faq_payment_thumb.png";

const TermOfService = ({terms}) => {
  return (
    <div className="container my-10">
      <div  dangerouslySetInnerHTML={{
                      __html: terms?.replace(
                        /\r\n|\r|\n/g,
                        "<br />"
                      ),
                    }}>
   
</div>
<div>{parse(terms)}</div>
<div>{terms}</div>
        <div>
      <h2 className="xl:text-3xl md:text-2xl text-xl text-[#002138] md:pb-3 pb-2 ">
      British Marketplace Terms Of Service
      </h2>


      <p className="text-base text-black font-oswald pb-2 text-[##555555] ">
      Last updated: 22 May 2023
      </p>
      <p className="text-base text-black font-oswald pb-2 text-[##555555] ">
         Welcome to Deliveroo. This page (together with the documents
        referred to in it) tells you the terms (the “Terms”) which apply when
        you order any menu items (the "Items") from our deliveroo.co.uk website
        or mobile applications and related services (each referred to as an
        “Application”).Please read these Terms carefully before creating a
        Deliveroo account or using our Application. If you have any questions
        relating to these Terms please contact support@bmp.co.uk. If you are a
        consumer, you have certain legal rights when you order Items using our
        Application. You can find more information about these rights at: Your
        legal rights are not affected by these Terms, which apply in addition to
        them and do not replace them. By setting up your Deliveroo account, you
        confirm that you accept these Terms.
      </p>
      <ol className="md:grid grid-cols-2 gap-3  ">
       
        <li className="md:text-lg text-base text-black font-normal font-oswald list-decimal ml-3">
         Information About Us
          <br />
          <span className="text-base text-black font-oswald pb-2 text-[#4F4F4F] ">
            BMP.co.uk is operated by Roofoods Ltd, a company incorporated and
            registered in England and Wales, whose registered office is at The
            River Building, Level 1 Cannon Bridge House, 1 Cousin Lane, EC4R
            3TE, London, United Kingdom. Our Company registration number is
            08167130. Our VAT number is 286 7364 55. You may contact us at
            support@BMP.co.uk, or by using the instant messaging facility on our
            Application.
          </span>
        </li>
       
      </ol>
   
 
</div>
        <div className="my-10">
      <h2 className="xl:text-3xl md:text-2xl text-xl text-[#002138] md:pb-3 pb-2 ">
      Deliveroo Terms Of Use For Website And Applications
      </h2>

     <div  className="
   flex justify-between items-center ">
        <div
  className="
   flex justify-between items-center xl:gap-20 lg:gap-12 "
>
 
  <div className="banner-tex lg:w-[70%] w-full ">
    
  <p className="text-base text-black font-oswald pb-2 text-[##555555] ">
        Last updated: 22 May 2023
      </p>
      <p className="text-base text-black font-oswald pb-2 text-[##555555] ">
This page (together with the documents referred to on it) tells you the terms of use on which you may make use of our website deliveroo.co.uk (our "Site") or any application we make available via an app store or otherwise (our "Service"), whether as a guest or a registered user. Please read these terms of use carefully before you start to use or Site or our Service. By accessing our Site or by using our Service, you indicate that you accept these terms of use and that you agree to abide by them. If you do not agree to these terms of use, do not use access our Site or use our Service.
      </p>
      <ol className="">
        <li>
        <li>
          <p className="md:text-lg text-base text-black font-normal font-oswald">
          1. Information About Us
          </p>
          <p className="text-sm text-black font-oswald pb-2 text-[#4F4F4F] ">
         
bmp.co.uk is a website operated by Roofoods Limited ("we", "us" or "bmp"), incorporated and registered in the England and Wales, whose registered office is at 1 Cousin Lane, London, EC4R 3TE, United Kingdom. Our Company registration number is 08167130. Our VAT number is 286 7364 55. bmp is a business where the food is prepared by independent restaurants (our "Partner Restaurants") and delivered by us.
          </p>
        </li>
        <li>
          <p className="md:text-lg text-base text-black font-normal font-oswald">
          2. Accessing Our Service Or Our Services
          </p>
          <p className="text-base text-black font-oswald pb-2 text-[#4F4F4F] ">
Access to our Site and to our Service is permitted on a temporary basis, and we reserve the right to withdraw or amend access to our Site or our Service without notice (see below). You must be aged 18 or older to use our Site and Service. We will not be liable if, for any reason, our Site or our Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts our Site or our Service, or our entire Site or Service to users who have registered with us. You are responsible for maintaining the confidentially of your login details and any activities that occur under your account. If you have any concerns about your login details or think they have been misused, you should contact straight away to let us know. We can deactivate your account at any time.
          </p>
        </li>
        </li>
        <li>
          <p className="md:text-lg text-base text-black font-normal font-oswald">
          3. Acceptable Use
          </p>
          <p className="text-base text-black font-oswald pb-2 text-[#4F4F4F] ">
          
You may use our Service only for lawful purposes. You may not use our Site or our Service in any way that breaches any applicable local, national or international law or regulation or to send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards in clause 5 below. You also agree not to access without authority, interfere with, damage or disrupt any part of our Site or our Service or any network or equipment used in the provision of our Service.
          </p>
        </li>
      </ol>
  
   
  </div>
  <div className="banner-thumb xl:w-[40%] lg:w-[40%] lg:block hidden lg:mt-20 ">
    <img className="mt-0 ml-auto" src={paymentThumb} alt="" />
  </div>
</div>
     </div>
      
</div>


    </div>
  );
};

export default TermOfService;
