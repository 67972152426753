import React from "react";
import thumbnail from "../../../images/driver/join-bmp-group.png";
import icon1 from "../../../images/driver/01.png";
import icon2 from "../../../images/driver/02.png";
import icon3 from "../../../images/driver/03.png";
const WhyJoinBmp = () => {
  return (
    <div className="lg:my-28 md:my-14 my-20 mx-10 grid md:grid-cols-2 grid-cols-1 gap-6 place-items-center">
      <img src={thumbnail} alt="" />
      <div className="xl:my-auto my-5">
        <h1 className="text-xl pb-1">
          Why Join the British MarketPlace Family?
        </h1>
        <p className="text-[#828282] text-xs">
          Being with British MarketPlace means being on the highest earning
          platform! So, what are you waiting for? Join us to earn the most!
          Focus on growing your business, and let British MarketPlace handle
          delivery for you.
        </p>
        <div className="mt-8">
          <div className="grid grid-cols-8 gap-2 my-7">
            <img src={icon1} alt="" className="col-span-1" />
            <div className="col-span-7">
              <h2 className="text-base">Real-time tracking</h2>
              <p className="text-xs text-[#525252]">
                With our express courier web platform, keep track of your parcel
                or food to the last mile of delivery.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-2 my-7">
            <img src={icon2} alt="" className="col-span-1" />
            <div className="col-span-7">
              <h2 className="text-base">Earn More with Bonus</h2>
              <p className="text-xs text-[#525252]">
                With British MarketPlace daily quests and attractive special
                offers, you can extra regularly.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-2 my-7">
            <img src={icon3} alt="" className="col-span-1" />
            <div className="col-span-7">
              <h2 className="text-base">Get Your Payment on Time</h2>
              <p className="text-xs text-[#525252]">
                With British MarketPlace, you will never face a delay in
                payment. Get your payment in the shortest time!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyJoinBmp;
