import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/vendor/Layout/Header";
import Sidebar from "../../../components/vendor/Layout/Sidebar/Sidebar";
import "./layout.css";
import { db } from "../../../util/firebaseDB";
import { onValue, ref } from "firebase/database";
import { vendorNotificationsAction } from "../../../redux/actions/authActions";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useLocation } from "react-router";

const Layout = ({ children }) => {
  const { user, vendorNotificatinos } = useSelector((state) => state.auth);
  console.log(vendorNotificatinos);
  const [isSidebarOpen, setIsSidebar] = useState(false);
  const location = useLocation();
  const closeBtnHandler = () => {
    // setIsCloseBtn(!isCloseBtnOpen);
    setIsSidebar(false);
    console.log(isSidebarOpen);
  };

  let client = new W3CWebSocket(
    `${process.env.REACT_APP_CHAT_URL}/ws/chat/` + user?.user?.id + "/"
  );

  client.onopen = () => {
    console.log("WebSocket Client Connected");
  };

  client.onmessage = (message) => {
    const dataFromServer = JSON.parse(message.data);
    console.log("got reply! ", dataFromServer.type);
    console.log("got reply! ", dataFromServer);

    console.log(location);

    if (location.pathname !== "/chat") {
      console.log("enter....");
    }

    // if (dataFromServer) {
    //   this.setState((state) => ({
    //     isOpen: true,
    //     messages: [
    //       ...state.messages,
    //       {
    //         msg: dataFromServer.message,
    //         name: dataFromServer.name,
    //         id: dataFromServer.username,
    //       },
    //     ],
    //   }));
    // }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      const query = ref(db, `notifications/${user.id}`);
      onValue(query, (snapshot) => {
        const data = [];
        snapshot.forEach((snap) => {
          data.push(snap.val());
        });
        dispatch(vendorNotificationsAction(data));
      });
    }
  }, [user, dispatch]);

  return (
    <div className="lg:flex relative ">
      <div
        className={
          isSidebarOpen
            ? "w-[300px] lg:static fixed top-0 h-screen left-0 transition-all ease-in z-10 "
            : "w-[300px] lg:static fixed top-0 -left-[300px] h-screen  transition-all ease-out z-10"
        }
      >
        <Sidebar closeBtnHandler={closeBtnHandler} user={user} />
      </div>
      <div
        onClick={closeBtnHandler}
        className={
          isSidebarOpen ? "backdrop backdropOpen w-screen h-screen z-[9]" : ""
        }
      >
        {" "}
      </div>

      <div className="page_wrapper">
        <Header
          isSidebarOpen={isSidebarOpen}
          setIsSidebar={setIsSidebar}
          user={user}
          notifications={vendorNotificatinos}
        />
        {/* <div className={isSidebarOpen ? "md:opacity-30 " : ""}> */}
        {children}

        {/* </div> */}
      </div>
    </div>
  );
};

export default Layout;
