import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import MenuCategoriesCreateUpdateForm from "../../../components/admin/Forms/MenuCategories/MenuCategoriesCreateUpdateForm";
import { adminAddMenuCategories } from "../../../redux/actions/AdminActions/adminMenuAction";
import Layout from "../Layout/layout";

function VendorAddMenuCategories() {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmitData = (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append("name", data.name);
    if (data.image.length > 0) formData.append("image", data.image[0]);
    formData.append("status", true);
    formData.append("featured", false);
    dispatch(adminAddMenuCategories(formData, history, "vendor"));
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <MenuCategoriesCreateUpdateForm
            handleSubmitData={handleSubmitData}
            vendor
          />
        </div>
      </div>
    </Layout>
  );
}

export default VendorAddMenuCategories;
