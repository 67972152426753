import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "../Layout/layout";
import ModalContainer from "../../../components/common/ModalContainer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  adminVendorDetails,
  adminVendorRegistrationDelete,
  vendorStatusChange,
} from "../../../redux/actions/AdminActions/adminUserActions";

const VendorDetails = () => {
  const { vendorDetails } = useSelector((state) => state.adminUser);
  // console.log(vendorDetails);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  // console.log(id);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedDetail, setselectedDetail] = useState(null);

  function closeModal() {
    setIsOpen(false);
  }
  const handleDelete = () => {
    dispatch(adminVendorRegistrationDelete(selectedDetail, history));
    closeModal();
    setselectedDetail(null);
  };
  const handleDeleteClick = (delete_id) => {
    console.log(delete_id);
    setIsOpen(true);
    setselectedDetail(delete_id);
  };

  useEffect(() => {
    dispatch(adminVendorDetails(id));
  }, [dispatch, id]);

  const handleStatusChange = (id, status) => {
    dispatch(vendorStatusChange(id, status));
  };
  return (
    <Layout>
      <div className="my-3 mx-2 lg:mx-24 shadow p-3 h-max ">
        <p className="text-center mb-8">Vendor Details</p>
        <div className="md:mx-24">
          {" "}
          <div className="grid grid-cols-1 gap-2  ">
            <p>
              Vendor first name:{" "}
              <strong> {vendorDetails?.vendor_first_name}</strong>
            </p>
            <p>
              Vendor last name:
              <strong> {vendorDetails?.vendor_last_name}</strong>
            </p>
            <p>
              Vendor email:
              <strong> {vendorDetails?.vendor_email}</strong>
            </p>
            <p>
              Vendor phone number:
              <strong> {vendorDetails?.vendor_phone_number}</strong>
            </p>
            <p>
              Vendor password:
              <strong> {vendorDetails?.vendor_password}</strong>
            </p>
            <p>
              Restaurant name:
              <strong> {vendorDetails?.restaurant_name}</strong>
            </p>
            <p>
              Full address:
              <strong> {vendorDetails?.full_address}</strong>
            </p>
            <p>
              Lat:
              <strong> {vendorDetails?.lat}</strong>
            </p>
            <p>
              Lng:
              <strong> {vendorDetails?.lng}</strong>
            </p>
            <p>
              City:
              <strong> {vendorDetails?.city}</strong>
            </p>
            <p>
              Postcode:
              <strong> {vendorDetails?.postcode}</strong>
            </p>
            <p>
              Country:
              <strong> {vendorDetails?.country}</strong>
            </p>
            <p>
              Approve:
              <strong>
                {" "}
                {vendorDetails?.is_approved?.toString().toUpperCase()}
              </strong>
            </p>
            <p>
              Status:
              <strong>
                {" "}
                {vendorDetails?.status?.toString().toUpperCase()}
              </strong>
            </p>
          </div>
        </div>

        <div className="flex justify-center my-8">
          {vendorDetails?.is_approved ? (
            <button
              onClick={() => handleStatusChange(id, false)}
              className="rounded py-2 px-5 text-white mr-2 bg-red-600"
            >
              Block
            </button>
          ) : (
            <button
              onClick={() => handleStatusChange(id, true)}
              className="rounded py-2 px-5 text-white mr-2 bg-green-600"
            >
              Approve
            </button>
          )}

          <button
            onClick={() => handleDeleteClick(id)}
            className="rounded py-2 px-5 text-white  bg-red-600"
          >
            Delete
          </button>
          <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
            <div id="modal-background">
              <div id="modal" className="w-80 md:w-full">
                <h2 className="my-3">
                  Are you sure you want to delete this item?
                </h2>
                <div className=" text-sm flex gap-8">
                  <button
                    className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                  <button
                    className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ModalContainer>
        </div>
      </div>
    </Layout>
  );
};

export default VendorDetails;
