import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import DriverInfo from "../../../components/admin/OrderDetailsInfo/DriverInfo";
import OrderDetailsInfo from "../../../components/admin/OrderDetailsInfo/OrderDetailsInfo";
import OrderItems from "../../../components/admin/OrderDetailsInfo/OrderItems";
import ModalContainer from "../../../components/common/ModalContainer";
import {
    adminOrderDelete,
    adminOrderDetails,
    adminOrderInvoice,
} from "../../../redux/actions/AdminActions/adminOrderActions";
import Layout from "../Layout/layout";

function OrderDetails() {
  const { orderDetails } = useSelector((state) => state.adminOrder);
  const { orderHomeMadeIngredients } = useSelector(
    (state) => state.vendorOrder
  );
  console.log(orderDetails);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminOrderDetails(params.id));
  }, [dispatch, params]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [homeMadeModalOpen, setHomeMadeModalOpen] = useState(false);
  const [deleleState, setDeleleState] = useState(null);
  function closeModal() {
    setIsOpen(false);
  }
  function closeHomeMadeModal() {
    setHomeMadeModalOpen(false);
  }
  const handleDeleteClick = (delete_id) => {
    console.log(delete_id);
    setIsOpen(true);
    setDeleleState(delete_id);
  };

  const handleDelete = async () => {
    dispatch(adminOrderDelete(deleleState, history));
    closeModal();
    setDeleleState(null);
  };

  const handleInvoiceClick = (order_id) => {
    dispatch(adminOrderInvoice(order_id));
  };

  return (
    <Layout>
      <div className="mx-3 my-8 flex">
        <OrderDetailsInfo
          orderDetails={orderDetails}
          handleDeleteClick={handleDeleteClick}
          handleInvoiceClick={handleInvoiceClick}
        />
        <div className=" w-1/2 lg:w-2/3 overflow-x-auto relative flex flex-col justify-between">
          <OrderItems
            orderItems={orderDetails?.items}
            orderId={orderDetails?.id}
            setHomeMadeModalOpen={setHomeMadeModalOpen}
          />
          <DriverInfo />
        </div>
      </div>
      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Are you sure you want to delete this item?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={homeMadeModalOpen}
        onRequestClose={closeHomeMadeModal}
      >
        <div id="modal-background">
          <div id="modal" className="lg:w-[800px] md:w-full">
            {/* <button id="close-btn" onClick={closeHomeMadeModal}>
              &times;
            </button> */}
            <span onClick={closeHomeMadeModal}>
              <div
                id="card_close"
                className="modal-close cursor-pointer z-50 h-8 w-8 leading-8 border text-center border-gray-700 rounded-full absolute top-3 right-3"
              >
                <svg
                  className="fill-current text-gray-500 mx-auto mt-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
              </div>
            </span>
            <table className="table-auto w-full border-collapse custom-table-data my-4 shadow">
              <caption className=" my-1 ">Home Made Ingredients</caption>
              <thead className="text-center">
                <tr className="bg-gray-800 text-gray-300 ">
                  <th className="custom-table-data">ID</th>
                  <th className="custom-table-data">name</th>
                  <th className="custom-table-data">weight</th>
                  <th className="custom-table-data">Price</th>
                  <th className="custom-table-data">quantity</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {orderHomeMadeIngredients?.home_made_variations?.map(
                  (ingredients) => (
                    <tr key={ingredients?.id}>
                      <td className="custom-table-data">{ingredients?.id}</td>
                      <td className="custom-table-data">{ingredients?.name}</td>
                      <td className="custom-table-data">
                        {ingredients?.weight}
                      </td>
                      <td className="custom-table-data">
                        {ingredients?.price}
                      </td>
                      <td className="custom-table-data">
                        {ingredients?.quantity}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <table className="table-auto w-full border-collapse custom-table-data my-2 shadow">
              <caption className=" my-1 ">
                Home Made Changed Ingredients
              </caption>
              <thead className="text-center">
                <tr className="bg-gray-800 text-gray-300 ">
                  <th className="custom-table-data">ID</th>
                  <th className="custom-table-data">name</th>
                  <th className="custom-table-data">weight</th>
                  <th className="custom-table-data">Price</th>
                  <th className="custom-table-data">quantity</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {orderHomeMadeIngredients?.home_made_changed?.map(
                  (ingredients) => (
                    <tr key={ingredients?.id}>
                      <td className="custom-table-data">
                        {ingredients?.product?.id}
                      </td>
                      <td className="custom-table-data">
                        {ingredients?.product?.name}
                      </td>
                      <td className="custom-table-data">
                        {ingredients?.product?.weight}
                      </td>
                      <td className="custom-table-data">
                        {ingredients?.product?.price}
                      </td>
                      <td className="custom-table-data">
                        {ingredients?.product?.quantity}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default OrderDetails;
