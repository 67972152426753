import {
  FETCH_WISHLIST,
  GET_RESTAURANT_RATING_DATA,
  INDIVIDUAL_ITEM_DECREASE,
  // INCREASE_FOOD_VARIATIONS,
  INDIVIDUAL_ITEM_INCREASE,
  LOADING_DATA,
  SEARCH_RESTAURANT,
  SEARCH_RESTAURANT_FOOD,
  SET_CATEGORIES,
  SET_FOOD_CATEGORIES,
  SET_FOOD_MODAL_CLOSE,
  SET_FOOD_MODAL_OPEN,
  SET_FOOD_VARIATION,
  SET_FOOD_VARIATION_EMPTY,
  SET_RESTAURANTS,
  SET_RESTAURANT_DETAILS,
  SET_RESTAURANT_ERROR,
  TOGGLE_WISHLIST_NOTIFICATION,
  TOGGLE_WISHLIST_SUCCESS,
} from "../types";

const initialState = {
  restaurants: [],
  categories: [],
  foodCategories: [],
  restaurantDetails: {},
  foodVariation: {},
  resSuggestion: [],
  foodSuggestions: [],
  wishlist: [],
  wishlistToggleMessage: "",
  wishlistNotifyMessage: "",
  foodModal: false,
  error: "",
  loading: false,
  vari: [],
  resRating: [],
};

export default function restaurantReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_RESTAURANTS:
      return {
        ...state,
        loading: false,
        restaurants: action.payload,
      };
    case SET_FOOD_MODAL_OPEN:
      return {
        ...state,
        loading: false,
        foodModal: true,
      };
    case SET_FOOD_MODAL_CLOSE:
      return {
        ...state,
        loading: false,
        foodModal: false,
      };

    case SET_CATEGORIES:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case SET_FOOD_CATEGORIES:
      return {
        ...state,
        loading: false,
        foodCategories: action.payload,
      };

    case SEARCH_RESTAURANT:
      return {
        ...state,
        loading: false,
        resSuggestion: action.payload,
      };

    case SEARCH_RESTAURANT_FOOD:
      return {
        ...state,
        loading: false,
        foodSuggestions: action.payload,
      };

    case SET_RESTAURANT_DETAILS:
      return {
        ...state,
        loading: false,
        restaurantDetails: action.payload,
      };

    case FETCH_WISHLIST:
      return {
        ...state,
        loading: false,
        wishlist: action.payload,
      };
    case TOGGLE_WISHLIST_NOTIFICATION:
      return {
        ...state,
        loading: false,
        wishlistNotifyMessage: action.payload,
      };

    case TOGGLE_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        wishlistToggleMessage: action.payload,
      };

    case SET_FOOD_VARIATION:
      return {
        ...state,
        loading: false,
        foodVariation: action.payload,
      };

    case SET_FOOD_VARIATION_EMPTY:
      return {
        ...state,
        loading: false,
        foodVariation: {},
      };

    case SET_RESTAURANT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // case INCREASE_FOOD_VARIATIONS:
    //   console.log(action.payload);
    //   let variation = state.foodVariation;
    //   console.log(variation);
    //   const insProd = variation.food_variation.map((i) => {
    //     console.log();
    //     return [
    //       ...i,
    //       i.variation_ingredients.map((item) => {
    //         return [
    //           ...item,
    //           item.id === action.payload
    //             ? { ...item, quantity: (item.quantity += 1) }
    //             : item,
    //         ];
    //       }),
    //     ];
    //   });
    //   console.log(insProd);
    //   return {
    //     ...state,
    //     foodVariation: insProd,
    //   };

    // case INCREASE_FOOD_VARIATIONS:
    //   let veri = state.vari;
    //   let { id, name, quantity, price } = action.payload;
    //   const variationItem = [...state.vari].find((item) => item.id === id);
    //   if (variationItem) {
    //     const incCart = [...state.vari].map((item) => {
    //       return item.id === id
    //         ? { ...item, quantity: item.quantity + 1 }
    //         : { ...item };
    //     });
    //     console.log(incCart);
    //     return { ...state, vari: incCart };
    //   } else {
    //     const newItem2 = {
    //       id,
    //       name,
    //       price: parseFloat(price),
    //       quantity,
    //     };
    //     veri.push(newItem2);
    //     console.log(veri);
    //     return {
    //       ...state,
    //       veri: veri,
    //     };
    //   }

    case INDIVIDUAL_ITEM_INCREASE:
      let foodVariation = state.foodVariation;
      let incProduct;
      if (foodVariation.id === action.payload) {
        incProduct = { ...foodVariation, quantity: foodVariation.quantity + 1 };
      } else {
        incProduct = foodVariation;
      }
      // const insProd = foodVariation.map((item) =>
      //   item.id === action.payload
      //     ? { ...item, quantity: (item.quantity += 1) }
      //     : item
      // );
      // console.log(insProd);
      return {
        ...state,
        foodVariation: incProduct,
      };

    case INDIVIDUAL_ITEM_DECREASE:
      let foodVariationDec = state.foodVariation;
      const { id, amount } = action.payload;
      let decProduct;
      if (amount < 1 && foodVariationDec.id === id) {
        decProduct = { ...foodVariationDec, quantity: 0 };
      } else {
        decProduct = {
          ...foodVariationDec,
          quantity: foodVariationDec.quantity - 1,
        };
      }
      return {
        ...state,
        foodVariation: decProduct,
      };
    // if (amount < 2) {
    //   const decProd = productItems.map((item) =>
    //     item.id === id ? { ...item, amount: 1 } : item
    //   );
    //   return {
    //     ...state,
    //     products: decProd,
    //   };
    // } else {
    //   const decProd = productItems.map((item) =>
    //     item.id === id ? { ...item, amount: (item.amount -= 1) } : item
    //   );
    //   return {
    //     ...state,
    //     products: decProd,
    //   };
    // }
    case GET_RESTAURANT_RATING_DATA:
      return {
        ...state,
        resRating: action.payload,
      };
    default:
      return state;
  }
}

// case INCREASE_FOOD_VARIATIONS:
//       console.log(action.payload);
//       let variation = state.foodVariation;
//       console.log(variation);
//       const insProd = variation.food_variation.map((i) =>
//         i.variation_ingredients.map((item) => {
//           console.log(item);
//           return item.id === action.payload
//             ? { ...item, quantity: (item.quantity += 1) }
//             : item;
//         })
//       );
//       console.log(insProd);
//       return {
//         ...state,
//         foodVariation: insProd,
//       };
