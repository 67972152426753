import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { submitPaymentCard } from "../../redux/actions/orderAction";
import PaymentForm from "./PaymentForm";

const MasterCard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(submitPaymentCard("MASTERCARD"));
  }, [dispatch]);
 
  return (
    <div className="card-content shadow-lg mt-8 px-4 py-5 bg-white sm:p-6 rounded-md">
      <div className="grid">
        <h4 className="col-span-3 text-base lg:text-lg font-semibold text-black pb-2">
          MasterCard Payment Ruls
        </h4>
        <div className="col-span-6">
          <div className="flex items-start">
            <span className="text-base text-black">01.</span>
            <div className="pl-2">
              <p className="text-sm text-black">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt br ut labore et
                <br />
                dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <span className="text-base text-black">02.</span>
            <div className="pl-2">
              <p className="text-sm text-black">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et
                <br />
                dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <span className="text-base text-black">03.</span>
            <div className="pl-2">
              <p className="text-sm text-black">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et
                <br />
                dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
      <PaymentForm brand={"MASTERCARD"} />
      {/* <div className="px-4 py-3 pt-6 sm:px-6">
        <form
          method="post"
          action="https://payments.epdq.co.uk/ncol/prod/orderstandard.asp"
          id="form1"
          name="form1"
        >
          <input
            type="hidden"
            name="AMOUNT"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <input type="hidden" name="BRAND" value="MASTERCARD" />
          <input
            type="hidden"
            name="CN"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <input type="hidden" name="CURRENCY" value="GBP" />
          <input
            type="hidden"
            name="EMAIL"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <input type="hidden" name="LANGUAGE" value="en-US" />
          <input
            type="hidden"
            name="ORDERID"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
          />
          <input type="hidden" name="PM" id="pm" value="CreditCard" />
          <input type="hidden" name="PSPID" value="epdq1603016" />
          <input
            type="hidden"
            name="SHASIGN"
            id="SHASIGN"
            value={ssKey}
            onChange={(e) => setSsKEY(e.target.value)}
          />

          <input
            value="submit"
            id="submit2"
            name="submit2"
            type="submit"
            className="inline-flex justify-center py-2 px-8 border-0 shadow-sm text-sm font-medium rounded-md text-white bg-gradient-to-r from-indigo-900 to-pink-700 hover:from-pink-700 hover:to-indigo-900 w-32"
          />
        </form>
      </div> */}
    </div>
  );
};

export default MasterCard;
