import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import Layout from "../Layout/layout";
import UserCreateUpdateForm from "../../../components/admin/Forms/User/UserCreateUpdateForm";
import { useSelector, useDispatch } from "react-redux";
import {
  adminEditUserDetails,
  AdminNewUserImagePhone,
  adminUserDetails,
} from "../../../redux/actions/AdminActions/adminUserActions";

const EditUser = () => {
  const { userDetails } = useSelector((state) => state.adminUser);
  console.log(userDetails);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [imagePreview, setimagePreview] = useState("");
  const [count, setCount] = useState(1);

  if (image) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = function () {
        setimagePreview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    dispatch(adminUserDetails(params.id));
  }, [params, dispatch]);

  const handleSubmitData = (data) => {
    console.log(data);
    if ((data.additional_phone_number || image) && params.id && count === 2) {
      const uploadData = new FormData();
      uploadData.append(
        "additional_phone_number",
        data.additional_phone_number
      );
      if (image) uploadData.append("profile_pic", image, image.name);

      dispatch(AdminNewUserImagePhone(uploadData, params.id, history));
    } else {
      setCount(2);
      dispatch(adminEditUserDetails(params.id, data));
    }
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <UserCreateUpdateForm
            image={image}
            imagePreview={imagePreview}
            setImage={setImage}
            handleSubmitData={handleSubmitData}
            userDetails={userDetails}
            edit
            count={count}
            setCount={setCount}
          />
        </div>
      </div>
    </Layout>
  );
};

export default EditUser;
