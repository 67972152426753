import queryString from "query-string";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "../components/common/ModalContainer";
import SuccessApplyMessage from "../components/common/SuccessApplyMessage";
import {
  userAccountActivation,
  userAccountCodeResend,
} from "../redux/actions/authActions";
import Layout from "./Layout";

const AccountActivation = () => {
  const { activationError, activationSuccess, activationUserId } = useSelector(
    (state) => state.auth
  );
  const [code, setCode] = useState("");
  const [isModelOpen, setIsModelOpen] = useState(false);
  const closeModal = () => {
    setIsModelOpen(false);
  };
  const urlParams = queryString.parse(window.location.search);
  console.log(urlParams);
  console.log(code);
  const dispatch = useDispatch();
  useEffect(() => {
    if (urlParams.code) {
      setCode(urlParams.code);
    }
  }, [urlParams.code]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userAccountActivation(code));
  };
  useEffect(() => {
    if (activationSuccess) {
      setIsModelOpen(true);
    }
  }, [activationSuccess, dispatch]);

  const emailResend = () => {
    console.log(activationUserId);
    if (activationUserId) {
      dispatch(userAccountCodeResend(activationUserId));
    } else if (urlParams.uu_id) {
      dispatch(userAccountCodeResend(parseInt(urlParams.uu_id)));
    }
  };

  return (
    <Layout>
      <div className=" bg-gray-50 py-14">
        <div className=" relative">
          <div className=" modal-text text-center pt-6 relative top-1/3 m-0">
            <h4 className="lg:text-xl text-lg text-black font-medium">
              Verification
            </h4>
            <span className="text-black text-opacity-80 md:text-sm text-xs font-light">
              Enter OTP code sent to your email
            </span>
            <p className="text-sm text-black font-medium">
              Did’t receive an OTP ?
              <button
                type="button"
                className="text-[#0DC1D9] uppercase cursor-pointer"
                onClick={emailResend}
              >
                Resend
              </button>
            </p>
            {/* {resendActivationSuccess ? "Email Send Successfully" : null} */}
            <div className="mt-6 w-[80%] mx-auto">
              <form onSubmit={handleSubmit}>
                <div className="input-group flex justify-center items-center gap-3">
                  <input
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    type="text"
                    className="h-[50px] w-full lg:w-1/2 xl:w-1/3 text-center inline-block px-3 border border-[#028FF0] rounded-md focus-visible:outline-1 focus-visible:outline-[#028FF0]  placeholder:text-sm text-[#999999]"
                  />
                </div>
                <div className="flex justify-center items-center gap-3 pt-6">
                  <button
                    // onClick={() => setcount(5)}
                    type="submit"
                    className="text-sm font-normal w-[100px] py-2 border border-[#028FF0] border-opacity-70 rounded-md hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9]"
                  >
                    Verify
                  </button>
                </div>
              </form>
            </div>
            <p className="text-sm text-red-600">{activationError}</p>
          </div>
        </div>
      </div>
      <ModalContainer open={isModelOpen} closeModal={closeModal}>
        <SuccessApplyMessage />
      </ModalContainer>
    </Layout>
  );
};

export default AccountActivation;
