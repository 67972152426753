import {
  ADMIN_META_LOADING,
  ADMIN_CONTACT_LIST,
  ADMIN_CONTENT_SETTINGS_DETAILS,
  ADMIN_GENERAL_SETTINGS_DETAILS,
  ADMIN_META_ERROR,
  ADMIN_CONTENT_SETTINGS_SAVE,
  ADMIN_GENERAL_SETTINGS_SAVE,
} from "../../types";
import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";

export const adminContactList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_META_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/meta/admin/contact/`, {
        params: {
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) => dispatch({ type: ADMIN_CONTACT_LIST, payload: res.data }))
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_META_ERROR, payload: err.response });
      });
  };

export const adminContactDelete = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_META_LOADING });
  let token = auth.getToken();
  await axios
    .delete(`${API_URL}/meta/admin/contact/${id}/`, {
      headers: { Authorization: token },
    })
    .then((_) => dispatch(adminContactList()))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_META_ERROR, payload: err.response });
    });
};

export const contentSettingsDetails = () => async (dispatch) => {
  dispatch({ type: ADMIN_META_LOADING });
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/meta/admin/content-settings/`, {
      headers: { Authorization: token },
    })
    .then((res) =>
      dispatch({ type: ADMIN_CONTENT_SETTINGS_DETAILS, payload: res.data })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_META_ERROR, payload: err.response });
    });
};

export const contentSettingsSave = (data) => async (dispatch) => {
  dispatch({ type: ADMIN_META_LOADING });
  let token = auth.getToken();
  await axios
    .patch(`${API_URL}/meta/admin/content-settings/`, data, {
      headers: { Authorization: token },
    })
    .then((_) => dispatch({ type: ADMIN_CONTENT_SETTINGS_SAVE }))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_META_ERROR, payload: err.response });
    });
};

export const generalSettingsDetails = () => async (dispatch) => {
  dispatch({ type: ADMIN_META_LOADING });
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/meta/admin/general-settings/`, {
      headers: { Authorization: token },
    })
    .then((res) =>
      dispatch({ type: ADMIN_GENERAL_SETTINGS_DETAILS, payload: res.data })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_META_ERROR, payload: err.response });
    });
};

export const generalSettingsSave = (data) => async (dispatch) => {
  dispatch({ type: ADMIN_META_LOADING });
  let token = auth.getToken();
  await axios
    .patch(`${API_URL}/meta/admin/general-settings/`, data, {
      headers: { Authorization: token },
    })
    .then((_) => dispatch({ type: ADMIN_GENERAL_SETTINGS_SAVE }))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_META_ERROR, payload: err.response });
    });
};
