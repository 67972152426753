import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

const roleOptions = [
  { value: "USER", label: "User" },
  { value: "ADMIN", label: "Admin" },
  { value: "VENDOR", label: "Vendor" },
  { value: "PARTNER", label: "Partner" },
];

const customStyles = {
  input: (provided, state) => ({
    ...provided,
    height: 32,
  }),
};

const UserCreateUpdateForm = ({
  image,
  imagePreview,
  setImage,
  handleSubmitData,
  userDetails,
  edit,
  count,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (userDetails?.user) {
      setValue("username", userDetails.user.username);
      setValue("first_name", userDetails.user.first_name);
      setValue("last_name", userDetails.user.last_name);
      setValue("email_address", userDetails.user.email);
      setValue("phone_number", userDetails.user.phone_number);
      setValue("status", userDetails.user.is_confirmed);
      setValue("is_active", userDetails.user.is_active);
      setValue("is_staff", userDetails.user.is_staff);
      setValue("additional_phone_number", userDetails.additional_phone_number);
      if (userDetails.user.role) {
        let a = roleOptions.find(
          (item) => item.value === userDetails.user.role
        );
        setValue("roleSelected", { value: a.value, label: a.label });
      }

      // setValue("roleSelected", a.value);
      // setRoleSelected({ value: a.value, label: a.label });
      // setRoleSelected({ value: userDetails.role });
    }
  }, [userDetails, setValue]);
  console.log(edit);
  return (
    <form onSubmit={handleSubmit(handleSubmitData)}>
      <div className="sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <h1 className="py-2 text-center">
            {edit ? "Edit" : "Add New"} Users{" "}
          </h1>
          {count === 1 ? (
            <div className="grid grid-cols-6 gap-4">
              <div className="col-span-12 sm:col-span-2">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Username
                </label>
                <input
                  type="text"
                  // id="username"
                  className="mt-1 block w-full shadow-sm  border-gray-300 rounded-md"
                  {...register("username", { required: edit ? false : true })}
                />
                {errors.username && (
                  <span className=" text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-span-12 sm:col-span-2">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <input
                  type="text"
                  id="first_name"
                  className="mt-1 block w-full shadow-sm  border-gray-300 rounded-md"
                  {...register("first_name", { required: edit ? false : true })}
                />
                {errors.first_name && (
                  <span className=" text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-span-12 sm:col-span-2">
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <input
                  type="text"
                  id="last_name"
                  className="mt-1 block w-full shadow-sm  border-gray-300 rounded-md"
                  {...register("last_name", { required: edit ? false : true })}
                />
                {errors.last_name && (
                  <span className=" text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-span-12 sm:col-span-2">
                <label
                  htmlFor="email_address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  type="email"
                  id="email_address"
                  className="mt-1 block w-full shadow-sm  border-gray-300 rounded-md"
                  {...register("email_address", {
                    required: edit ? false : true,
                  })}
                />
                {errors.email_address && (
                  <span className=" text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-span-12 sm:col-span-2">
                <label
                  htmlFor="phone_number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  id="phone_number"
                  className="mt-1 block w-full shadow-sm  border-gray-300 rounded-md"
                  {...register("phone_number", {
                    required: edit ? false : true,
                  })}
                />
                {errors.phone_number && (
                  <span className=" text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>
              <div className="custom-select col-span-12 sm:col-span-2">
                <label
                  htmlFor="roleSelected"
                  className="basic-single block text-sm font-medium text-gray-700"
                >
                  Role
                </label>
                <Controller
                  name="roleSelected"
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="basic-single focus:ring-0 mt-1"
                      classNamePrefix="select"
                      isClearable
                      isSearchable={true}
                      styles={customStyles}
                      options={roleOptions}
                      // defaultValue={roleOptions[1]}
                      // value={roleSelected}
                      // onChange={(val) => console.log(val)}
                      // onChange={(val) => setRoleSelected(val)}
                    />
                  )}
                  control={control}
                  rules={{ required: true }}
                />
                {/* <Controller
                name="roleSelected"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="basic-single focus:ring-0 "
                    classNamePrefix="select"
                    options={roleOptions}
                    isClearable // enable isClearable to demonstrate extra error handling
                    isSearchable={false}
                    value={roleSelected}
                    // defaultValue={countryOptions[1]}
                    onChange={(val) => setRoleSelected(val)}
                    // styles={customStyles}

                    // {...register("role")}
                  />
                )}
              /> */}
                {errors.roleSelected &&
                errors.roleSelected.type === "required" ? (
                  <div>
                    <span className="text-sm text-red-600">
                      This field is required
                    </span>
                  </div>
                ) : null}
                {/* <span className=" text-red-500 text-xs">
                {errors.roleSelected?.message ||
                  errors.roleSelected?.label.message}
              </span> */}
              </div>
              <div className="col-span-12 sm:col-span-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
                  {...register("status")}
                />
                <label
                  htmlFor="res1"
                  className="
                            text-gray-700
                            lg:text-md
                            text-sm
                            cursor-pointer
                          "
                >
                  Status
                </label>
                <span className=" ml-3 text-red-500 text-xs">
                  {errors.status?.message}
                </span>
              </div>

              <div className="col-span-12 sm:col-span-2 ">
                <input
                  type="checkbox"
                  className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
                  {...register("is_active")}
                />
                <label
                  htmlFor="res1"
                  className="
                            text-gray-700
                            lg:text-md
                            text-sm
                            cursor-pointer
                          "
                >
                  Active
                </label>
                <span className=" ml-3 text-red-500 text-xs">
                  {errors.is_active?.message}
                </span>
              </div>

              <div className="col-span-12 sm:col-span-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
                  {...register("is_staff")}
                />
                <label
                  htmlFor="res1"
                  className="
                            text-gray-700
                            lg:text-md
                            text-sm
                            cursor-pointer
                          "
                >
                  Staff
                </label>
                <span className=" ml-3 text-red-500 text-xs">
                  {errors.is_staff?.message}
                </span>
              </div>
              <div className="col-span-12 sm:col-span-2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="mt-1 block w-full shadow-sm  border-gray-300 rounded-md"
                  {...register("password", { required: edit ? false : true })}
                />
                {errors.password && (
                  <span className=" text-red-500 text-xs">
                    This field is required
                  </span>
                )}
              </div>
              {/* <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="full_address"
                className="block text-sm font-medium text-gray-700"
              >
                Full address
              </label>
              <input
                type="text"
                id="full_address"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                {...register("full_address", { required: true })}
              />
              {errors.full_address && (
                <span className=" text-red-500 text-xs">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-span-12 sm:col-span-2">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <input
                type="text"
                id="city"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                {...register("city", { required: true })}
              />
              {errors.city && (
                <span className=" text-red-500 text-xs">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-span-12 sm:col-span-2">
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                State / Province
              </label>
              <input
                type="text"
                id="state"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                {...register("state", { required: true })}
              />
              {errors.state && (
                <span className=" text-red-500 text-xs">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-span-12 sm:col-span-2">
              <label
                htmlFor="postal_code"
                className="block text-sm font-medium text-gray-700"
              >
                Post Code
              </label>
              <input
                type="text"
                id="postal_code"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                {...register("postal_code", { required: true })}
              />
              {errors.postal_code && (
                <span className=" text-red-500 text-xs">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-span-6 sm:col-span-3 ">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700 my-1"
              >
                Country / Region
              </label>
              <select
                id="country"
                name="country"
                autoComplete="country"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
              </select>
            </div>
            <div className="col-span-6 sm:col-span-3 ">
              <label
                htmlFor="street_address"
                className="block text-sm font-medium text-gray-700 my-1"
              >
                Others
              </label>
              <input
                type="text"
                id="others"
                autoComplete="street-address"
                className="mt-1 focus:ring-black focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                {...register("others", { required: true })}
              />
              {errors.others && (
                <span className=" text-red-500 text-xs">
                  This field is required
                </span>
              )}
            </div> */}
              {/* <div className="col-span-12 sm:col-span-2">
              <label
                htmlFor="add_phone_number"
                className="block text-sm font-medium text-gray-700"
              >
                Additional Phone Number
              </label>
              <input
                type="number"
                id="add_phone_number"
                className="mt-1 block w-full shadow-sm  border-gray-300 rounded-md"
                {...register("additional_phone_number")}
              />
            </div> */}
            </div>
          ) : null}
        </div>

        {count === 2 ? (
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="col-span-12 sm:col-span-2">
              <label
                htmlFor="add_phone_number"
                className="block text-sm font-medium text-gray-700"
              >
                Additional Phone Number
              </label>
              <input
                type="number"
                id="add_phone_number"
                className="mt-1 block w-full shadow-sm  border-gray-300 rounded-md"
                {...register("additional_phone_number")}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 my-3">
                Photo
              </label>

              <div className="flex">
                {image ? (
                  <img
                    src={imagePreview}
                    alt="preview"
                    className=" h-14 w-14 mr-3 rounded-full"
                  />
                ) : (
                  <div
                    className="profile-thumb h-12 w-12 text-center bg-green-400 rounded-full mr-4"
                    style={{ lineHeight: "60px" }}
                  >
                    <label htmlFor="profileThumb" className="inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        />
                      </svg>
                    </label>
                  </div>
                )}

                <label
                  htmlFor="profileThumb"
                  className="w-48 flex items-center px-4 py-2 bg-white text-black rounded-lg shadow-lg tracking-wide capitalize border border-blue cursor-pointer hover:bg-[#89e488] hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="ml-2" style={{ transform: "scale(1)" }}>
                    Upload Image
                  </span>
                </label>
                <input
                  type="file"
                  name="profile-image"
                  id="profileThumb"
                  className="hidden"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
              {/* <input
              type="file"
              name="profile-image"
              id="profileThumb"
              className="hidden"
              onChange={(e) => setImage(e.target.files[0])}
            /> */}
            </div>
          </div>
        ) : null}

        <div className="px-4 py-3 text-right sm:px-6">
          <button type="submit" className="custom-btn2">
            {count === 1 ? "Save and Continue" : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserCreateUpdateForm;
