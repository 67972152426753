import React from "react";
import Rating from "react-rating";
import { Link } from "react-router-dom";

const HomeMadeCard = ({ filteredItem }) => {
  return (
    <div className="">
      <div className="res-card w-full">
        <div className="max-w-lg mx-auto">
          <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm group transform hover:-translate-y-1 transition-transform">
            <Link to={`/restaurant/${filteredItem.branch_id}`}>
              <div className="relative ">
                <img
                  className="rounded-t-lg w-full xl:h-40 h-32 object-cover"
                  src={filteredItem.image}
                  alt=""
                />
                <div className="arr-min absolute -bottom-4 right-2.5 bg-white shadow-md rounded-2xl text-center leading-3 px-2 pt-2 pb-1">
                  <span className="text-md font-semibold">
                    {filteredItem.min_delivery_time}-
                    {filteredItem.max_delivery_time}
                  </span>
                  <span className="text-xs block">min</span>
                </div>
              </div>
            </Link>
            <div className="p-5 h-36">
              <Link to={`/restaurant/${filteredItem.branch_id}`}>
                <h5 className="text-gray-900 font-bold xl:text-lg md:text-md text-sm tracking-tight mb-2 group-hover:text-indigo-800 transition-colors ">
                  {filteredItem.name}
                </h5>
              </Link>
              <div className="font-normal text-xs text-[#848587] flex">
                {filteredItem.categories?.map((category, i) => (
                  <p key={i} className="mr-1">
                    {" "}
                    {category.name}
                    {i === filteredItem?.categories.length - 1 ? "" : ","}
                  </p>
                ))}
              </div>

              <div className="font-normal text-xs text-gray-700 flex items-center justify-between pr-8">
                <Rating
                  emptySymbol="fa fa-star-o text-[#848587] "
                  fullSymbol="fa fa-star  text-[#FBD336]"
                  readonly
                  initialRating={filteredItem?.rating}
                />
                <span className="text-black mx-1 font-bold ">
                  ({filteredItem?.rating})
                </span>
                {/* {props.suburb} */}
              </div>
              <div className="font-normal text-xs text-[#848587] flex items-center justify-between pr-8">
                <span>{filteredItem.distance.toFixed(1)} miles away</span>
                <span>£{filteredItem.delivery_charge} Delivery Fee </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMadeCard;
