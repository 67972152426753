import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../Layout/layout";
import {
  adminRestaurantBranchDelete,
  adminRestaurantBranchDetailsEdit,
} from "../../../redux/actions/AdminActions/adminRestaurantActions";
import ModalContainer from "../../../components/common/ModalContainer";

import RestaurantBranchInfo from "../../../components/admin/RestautantDetails/RestaurantBranchInfo";

function VendorRestaurantBranchDetails() {
  const { resBranchDetailsEdit } = useSelector(
    (state) => state.adminRestaurant
  );

  console.log(resBranchDetailsEdit);

  const history = useHistory();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleleState, setDeleleState] = useState(null);

  const handleDeleteClick = (delete_id) => {
    setIsOpen(true);
    setDeleleState(delete_id);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = () => {
    console.log(deleleState);
    dispatch(
      adminRestaurantBranchDelete(
        deleleState,
        history,
        resBranchDetailsEdit?.restaurant?.id
      )
    );
    closeModal();
    setDeleleState(null);
  };
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminRestaurantBranchDetailsEdit(params.id));
  }, [dispatch, params]);
  return (
    <Layout>
      <RestaurantBranchInfo
        resBranchDetailsEdit={resBranchDetailsEdit}
        handleDelete={handleDelete}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        handleDeleteClick={handleDeleteClick}
      />
      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Are are sure to delete this branch?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default VendorRestaurantBranchDetails;
