import React from "react";
import { Tooltip } from "@material-tailwind/react";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";

const RestaurantBranchList = ({ branches, handleBranchDeleteClick }) => {
  console.log(branches);
  return (
    <div className="overflow-x-scroll shadow w-1/2 lg:w-2/3">
      <table className="table-auto  w-full border-collapse custom-table-data my-2">
        <caption className=" py-1 ">Restaurant Branchs</caption>
        <thead className="text-center">
          <tr className="bg-gray-800 text-gray-300 ">
            <th className="custom-table-data">NAME</th>
            <th className="custom-table-data">OPEN TIME </th>
            <th className="custom-table-data">CLOSE TIME</th>
            <th className="custom-table-data">DELIVERY CHARGE</th>
            <th className="custom-table-data">POST CODE</th>
            <th className="custom-table-data">COUNTRY</th>
            <th className="custom-table-data">MAIN BRANCH</th>
            <th className="custom-table-data">Status</th>
            <th className="custom-table-data">ACTION</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {branches?.map((branch) => (
            <tr key={branch.id}>
              <td className="custom-table-data">{branch.name}</td>
              <td className="custom-table-data">{branch.open_time}</td>
              <td className="custom-table-data">{branch.close_time}</td>
              <td className="custom-table-data">{branch.delivery_charge}</td>
              <td className="custom-table-data">{branch.postcode}</td>
              <td className="custom-table-data">{branch.country}</td>
              <td className="custom-table-data">
                {branch.is_main ? "True" : "False"}
              </td>
              <td className="custom-table-data">
                {branch.status ? "True" : "False"}
              </td>

              <td className="custom-table-data text-xl">
                <Tooltip content="View">
                  <Link to={`/vendor/restaurant-branch-details/${branch.id}`}>
                    <button>
                      {" "}
                      <AiOutlineEye className="mx-2 text-green-500" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip content="Edit">
                  <Link to={`/vendor/restaurant-branch/edit/${branch.id}`}>
                    <button>
                      <AiOutlineEdit className="mx-2 text-yellow-500" />
                    </button>
                  </Link>
                </Tooltip>

                <Tooltip content="Delete">
                  <button onClick={() => handleBranchDeleteClick(branch.id)}>
                    <RiDeleteBin6Line className="mx-2 text-red-500" />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RestaurantBranchList;
