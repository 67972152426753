import React, { useRef, useState } from 'react';

const Accordion = ({ id, title, content, openAccordionId, setOpenAccordionId }) => {
    // const isOpen = openAccordionId === id;
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => {
      // if (isOpen) {
      //   setOpenAccordionId(null);
      // } else {
      //   setOpenAccordionId(id);
      // }
      setIsOpen(prevIsOpen => !prevIsOpen);
    };
    const contentRef = useRef(null);
  const getContentHeight = () => {
    return isOpen ? contentRef.current.scrollHeight + 'px' : '0px';
  };
  
  return (
  
  <div className=' flex justify-center'>
  <div className={`accordion ${isOpen ? 'open' : ''}  w-3/5`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        {title}
        <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
      </div>
      <div
        className="accordion-content"
        ref={contentRef}
        style={{ height: getContentHeight() }}
      >
        <div className="accordion-content-inner">
          {content}
        </div>
      </div>
    </div>
    </div>
  )
}

export default Accordion


