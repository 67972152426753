import React from 'react';
import Hero from "../../components/Home/Hero";
import aboutThumb from "../../images/banner/faq_about_thumb.png";
const About = () => {
  return (
    <Hero banner={`bg-faq-about-banner-bg`}>
<div
  className="
   flex justify-between items-center xl:gap-20 lg:gap-12 md:h-bg-height h-[400px] rounded-md "
>
  <div className="banner-thumb xl:w-[40%] lg:w-[40%] lg:block hidden lg:mt-20 ">
    <img className="mt-0 ml-auto" src={aboutThumb} alt="" />
  </div>
  <div className="banner-tex lg:w-[60%] w-full lg:pt-16">
  
    <h2 className="xl:text-3xl md:text-2xl text-xl text-[#002138] md:pb-3 pb-2 ">
    About British Marketplace  
    </h2>
    <p className="md:text-lg text-base text-black font-normal font-oswald">
    What is British Marketplace?
    </p>
    <p className="text-base text-black font-oswald pb-5 text-[#4F4F4F]">
    British Marketplace is on a mission to transform the way you order food. We partner with the best restaurants in the business – from local hotspots to national favourites – and bring you the food you love, right to your door.
    <br />
    With thousands of choices and a fleet of our own delivery riders, we’ll have your order with you in as little as 30 minutes
    </p>
    <p className="md:text-lg text-base text-black font-normal font-oswald">
    What is the story behind British Marketplace?
    </p>
    <p className="text-base text-black font-oswald pb-5 text-[#4F4F4F]">
    When Will Shu moved to London, British Marketplace founder and CEO, he was surprised to find that it was nearly impossible to get great quality food delivered. He made it his personal mission to bring the best local restaurants direct to customers’ doors. The business was launched in 2013 with Will as our very first rider.
    <br />
    Eight years on, we now operate in over 200 cities and towns across the UK, working with 50,000 restaurant partners, major grocery partners and more than 50,000 self-employed riders. Deliveroo is headquartered in London, with over 2,000 employees in offices around the globe.
    </p>
   
  </div>
</div>
</Hero>

  )
}

export default About