import React, { Component } from "react";
import { FaRocketchat } from "react-icons/fa";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { API_URL } from "../settings/config";

class Chat extends Component {
  state = {
    isLoggedIn: false,
    messages: [],
    value: "",
    name: "",
    room: "vacad",
    isOpen: false,
    thread_id: "",
  };
  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
    this.messagesRef = React.createRef();
  }

  handleOpen = () => {
    // console.log(document.body.style.overflow);
    // if (document.body.style.overflow !== "hidden") {
    //   document.body.style.overflow = "hidden";
    // } else {
    //   document.body.style.overflow = "scroll";
    // }
    this.scrollToBottom();
    fetch(
      `${API_URL}/chat/messages/?` +
        new URLSearchParams({
          from_user: this.props.userId,
          to_user: this.props.ownerId,
        })
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let chatData = data.chat_message;
        console.log(chatData);
        let b = [];
        for (let i = 0; i < chatData.length; i++) {
          b.push({
            msg: chatData[i].message,
            name: chatData[i].user.email,
            id: chatData[i].user.id,
          });
        }
        console.log(b);
        this.setState((state) => ({
          messages: [...state.messages, ...b],
          isOpen: true,
          thread_id: data.id,
        }));
      });
    // this.setState({
    //   ...this.state,
    //   isOpen: true,
    // });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      isOpen: false,
    });
    // if (document.body.style.overflow === "hidden") {
    //   document.body.style.overflow = "scroll";
    // } else {
    //   document.body.style.overflow = "scroll";
    // }
  };

  // messagesEndRef = React.createRef();

  componentDidUpdate() {
    this.scrollToBottom();
  }
  // scrollToBottom = () => {
  //   console.log(this.messagesRef.current?.clientHeight);
  //   // return this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };
  scrollToBottom = () => {
    const chat = document.getElementById("chat-logs");
    console.log(chat);
    chat.scrollTop = chat.scrollHeight;
  };

  // client = new W3CWebSocket("ws://127.0.0.1:8000/chat/");
  client = new W3CWebSocket(
    `${process.env.REACT_APP_CHAT_URL}/ws/chat/` + this.props.userId + "/"
  );

  onButtonClicked = (e) => {
    this.client.send(
      JSON.stringify({
        type: "message",
        message: this.state.value,
        sent_by: this.props.userId,
        send_to: this.props.ownerId,
        thread_id: this.state.thread_id,
      })
    );
    this.setState({
      value: "",
    });
    e.preventDefault();
  };

  componentDidMount() {
    // console.log();
    // if (this.state.isOpen) {
    //   console.log("entertttttttttttt");
    //   document.body.style.overflow = "hidden";
    // }
    this.scrollToBottom();
    //   .catch((err) => console.log(err));

    this.client.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    this.client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      // const dataFromServer2 = JSON.parse(message);
      // console.log(dataFromServer2);
      console.log("got reply! ", dataFromServer.type);
      console.log("got reply! ", dataFromServer);
      if (dataFromServer) {
        this.setState((state) => ({
          isOpen: true,
          messages: [
            ...state.messages,
            {
              msg: dataFromServer.message,
              name: dataFromServer.username,
              id: dataFromServer.username,
            },
          ],
        }));
      }
    };

    //  window.scrollTo({ top: 0, behavior: "smooth" });

    // let chat_logs = document.getElementById("chat-logs");
    // chat_logs.scroll(0, chat_logs[0]);
    // console.log(chat_logs);
    // chat_logs.animate({ scrollTop: "100px" })
  }
  // componentWillUnmount() {
  //   document.body.style.overflow = "unset";
  // }

  render() {
    // console.log(this.state);
    // console.log(this.messagesEndRef);
    return (
      <div className="sm:w-[350px] w-[90%] fixed sm:right-5 right-0 sm:bottom-5 bottom-0 bg-white z-50">
        <div className="relative">
          <div
            onClick={this.handleOpen}
            id={this.state.isOpen ? "scale" : "chat-circle"}
            className="btn btn-raised"
          >
            <div id="chat-overlay"></div>
            {/* <i className="material-icons">speaker_phone</i> */}
            <FaRocketchat
              className={`${
                this.state.isOpen ? "hidden" : ""
              } sm:h-8 h-6 sm:w-8 w-6 mx-auto`}
            />
          </div>

          <div className={this.state.isOpen ? "scale" : "chat-box"}>
            <div className="chat-box-header">
              ChatBox
              <span className="chat-box-toggle" onClick={this.handleClose}>
                <i className="material-icons">close</i>
              </span>
            </div>
            <div className="chat-box-body">
              <div className="chat-box-overlay"></div>
              <div className="chat-logs" id="chat-logs">
                {this.state.messages.map((message) => {
                  return (
                    <div
                      ref={this.messagesRef}
                      className={
                        message.id === this.props.userId
                          ? "chat-msg self"
                          : "chat-msg other"
                      }
                    >
                      <span className="msg-avatar">
                        <img
                          src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8="
                          alt="img"
                        />
                      </span>
                      <div className="cm-msg-text">{message.msg}</div>
                    </div>
                  );
                })}
                {/* <div ref={this.messagesRef} /> */}
                {/* <div ref={this.messagesEndRef} /> */}
              </div>
            </div>
            <div className="chat-input">
              <form onSubmit={this.onButtonClicked}>
                <input
                  type="text"
                  id="chat-input"
                  placeholder="Send a message..."
                  value={this.state.value}
                  onChange={(e) => {
                    this.setState({ value: e.target.value });
                    this.value = this.state.value;
                  }}
                />
                <button type="submit" className="chat-submit">
                  <i className="material-icons">send</i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Chat;
