import { Tooltip } from "@material-tailwind/react";
import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import {
  adminOrderItemDelete,
  adminOrderItemVariationDelete,
} from "../../../redux/actions/AdminActions/adminOrderActions";
import { AiOutlineEye } from "react-icons/ai";
import { vendorHomeMadeIngredients } from "../../../redux/actions/vendorActions/vendorOrderActions";

function OrderItems({ orderItems, orderId, setHomeMadeModalOpen }) {
  const dispatch = useDispatch();
  const handleDelete = (order_id, order_items_id) => {
    dispatch(adminOrderItemDelete(order_id, order_items_id));
  };
  const handleVariationDelete = (order_id, order_items_id, variation_id) => {
    dispatch(
      adminOrderItemVariationDelete(order_id, order_items_id, variation_id)
    );
  };
  console.log(orderItems);
  return (
    <div>
      <table className="table-auto w-full border-collapse custom-table-data my-2 shadow">
        <caption className=" my-1 ">Order Items</caption>
        <thead className="text-center">
          <tr className="bg-gray-800 text-gray-300 ">
            <th className="custom-table-data">Food items</th>
            <th className="custom-table-data">Variations price quantity</th>
            <th className="custom-table-data">Home Made Ingredients</th>
            <th className="custom-table-data">Quantity</th>
            <th className="custom-table-data">Price</th>
            <th className="custom-table-data">Action</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {orderItems?.map((item) => (
            <tr key={item.id}>
              <td className="custom-table-data">{item?.food_items?.name}</td>
              <td className="custom-table-data">
                {item?.variations?.map((variation) => (
                  <div key={variation.id} className="mr-2 flex justify-center">
                    <p className="mr-3">{variation.name}</p>{" "}
                    <p className="mr-3">{variation.price}</p>
                    <p>{variation.quantity}</p>
                    <Tooltip content="Delete">
                      <button
                        onClick={() =>
                          handleVariationDelete(orderId, item?.id, variation.id)
                        }
                      >
                        <RiDeleteBin6Line className="mx-2 text-red-500" />
                      </button>
                    </Tooltip>
                  </div>
                ))}
              </td>
              <td className="custom-table-data text-center">
                {item?.is_home_made ? (
                  <Tooltip content="View">
                    <button
                      onClick={() => {
                        dispatch(vendorHomeMadeIngredients(item?.id));
                        setHomeMadeModalOpen(true);
                      }}
                      className="flex items-center justify-center m-auto"
                    >
                      Details{" "}
                      <AiOutlineEye size={25} className="mx-2 text-green-500" />
                    </button>
                  </Tooltip>
                ) : (
                  "False"
                )}
              </td>
              <td className="custom-table-data">{item?.quantity}</td>
              <td className="custom-table-data">
                {item?.food_items?.total_price}
              </td>
              <td className="custom-table-data text-xl">
                <Tooltip content="Delete">
                  <button onClick={() => handleDelete(orderId, item?.id)}>
                    <RiDeleteBin6Line className="mx-2 text-red-500" />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrderItems;
