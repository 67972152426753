import React from "react";
import { Link } from "react-router-dom";

const PersonalInfo = ({ userDetailsAddr, handleChangeStatus }) => {
  return (
    <div className="mr-3 shadow p-3 h-max w-1/2 lg:w-1/3">
      <img
        className=" w-36 h-36 mb-8 rounded-full border border-yellow-600 mx-auto"
        src={
          userDetailsAddr?.profile_pic
            ? userDetailsAddr?.profile_pic
            : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
        }
        alt=""
      />

      <div className="">
        <p>
          User Name: <strong>{userDetailsAddr?.user?.username}</strong>
        </p>
        <p>
          First Name: <strong>{userDetailsAddr?.user?.first_name}</strong>
        </p>
        <p>
          Last Name: <strong>{userDetailsAddr?.user?.last_name}</strong>
        </p>
        <p>
          Email: <strong>{userDetailsAddr?.user?.email}</strong>
        </p>
        <p>
          Phone Number: <strong>{userDetailsAddr?.user?.phone_number}</strong>
        </p>
        <p>
          Role: <strong>{userDetailsAddr?.user?.role}</strong>
        </p>
        <p>
          Active:{" "}
          <strong>{userDetailsAddr?.user?.is_active ? "True" : "False"}</strong>
        </p>
        <p>
          Confirm:{" "}
          <strong>
            {userDetailsAddr?.user?.is_confirmed ? "True" : "False"}
          </strong>
        </p>
        <p>
          Block:{" "}
          <strong>
            {userDetailsAddr?.user?.is_blocked ? "True" : "False"}
          </strong>
        </p>
        <div className="flex justify-center my-3">
          <Link to={`/admin/user/edit/${userDetailsAddr?.user?.id}`}>
            <button className="rounded py-2 px-5 text-white mr-2 bg-yellow-400">
              Edit
            </button>
          </Link>
          {userDetailsAddr?.user?.is_active ? (
            <button
              onClick={() =>
                handleChangeStatus(userDetailsAddr?.user?.id, false)
              }
              className="rounded py-2 px-5 text-white  bg-red-600"
            >
              Block
            </button>
          ) : (
            <button
              onClick={() =>
                handleChangeStatus(userDetailsAddr?.user?.id, true)
              }
              className="rounded py-2 px-5 text-white bg-green-600"
            >
              Active
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
