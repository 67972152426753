import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import Select from "react-select";
import bicycle from "../../../images/driver/bicycle.png";
import car from "../../../images/driver/car.png";
import banner1 from "../../../images/driver/reg1.png";
import scooter from "../../../images/driver/scooter.png";
import walking from "../../../images/driver/walking.png";
const DriverRegForm = ({ count, onSubmit, otp, setotp }) => {
  // console.log(count);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: { name: "", area: "", license: "no" } });
  const [passwordShown, setPasswordShown] = useState(false);
  const [createPasswordShown, setcreatePasswordShown] = useState(false);
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const toggleCreatePassword = (e) => {
    e.preventDefault();
    setcreatePasswordShown(!createPasswordShown);
  };

  setValue("otp", otp);

  const areaOption = [
    { value: "Mohakhali", label: "Mohakhali" },
    { value: "Banani", label: "Banani" },
    { value: "Mirpur", label: "Mirpur" },
  ];

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: 32,
    }),
  };
  const [vehicleOption, setvehicleOption] = useState("");

  // const handleLicense = (event) => {
  //   console.log(event.target.value);
  // };
  const handleOtpchange = (otp) => {
    setotp(otp);
  };

  console.log(otp);

  return (
    <>
      {count === 1 && (
        <div className=" w-[600px] shadow-themeShadow rounded-md py-8 ">
          <div className="text-center pb-8">
            <img src={banner1} alt="" className="mx-auto mb-4" />
            <p className="font-medium">Start Earning on British MarketPlace</p>
            <p
              className="text-[#464956] text-sm font-light
"
            >
              continue your process
            </p>
          </div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="px-7">
              <div className=" grid grid-cols-1 gap-2">
                <div className="my-2">
                  <input
                    {...register("name", { required: true })}
                    type="text"
                    placeholder="Name"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  {errors.name && (
                    <p className="text-red-500 text-xs">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="my-2">
                  <input
                    {...register("phone_number", { required: true })}
                    type="text"
                    placeholder="Phone Number"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  {errors.phone_number && (
                    <p className="text-red-500 text-xs">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="my-2">
                  <input
                    {...register("email", { required: true })}
                    type="text"
                    placeholder="Email"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="my-2 relative">
                  <input
                    {...register("password", { required: true })}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  <button
                    className="absolute top-3 right-3 text-[#BDBDBD]"
                    onClick={togglePassword}
                  >
                    {passwordShown ? (
                      <i classname="fa-sharp fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fa-sharp fa-solid fa-eye "></i>
                    )}
                  </button>
                  {errors.password && (
                    <p className="text-red-500 text-xs">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="my-2 relative">
                  <input
                    {...register("create_password", { required: true })}
                    type={createPasswordShown ? "text" : "password"}
                    placeholder="Create Password"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  <button
                    className="absolute top-3 right-3 text-[#BDBDBD]"
                    onClick={toggleCreatePassword}
                  >
                    {" "}
                    {createPasswordShown ? (
                      <i classname="fa-sharp fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fa-sharp fa-solid fa-eye "></i>
                    )}
                  </button>
                  {errors.create_password && (
                    <p className="text-red-500 text-xs">
                      This field is required
                    </p>
                  )}
                </div>
              </div>

              <div className="my-4">
                <button
                  type="submit"
                  className="shadow-themeShadow rounded-md border-0 py-3 w-full bg-[#0DC1D9] text-white text-sm font-medium"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {count === 2 && (
        <div className=" w-[600px] shadow-themeShadow rounded-md py-8 mx-auto">
          <div className="">
            <p className=" font-normal pl-8">Where would you like to work?</p>
          </div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="px-7">
              <div className=" grid grid-cols-1 gap-2">
                <div className="my-2">
                  <Controller
                    name="area"
                    render={({ field }) => (
                      <Select
                        placeholder="Select Area"
                        {...field}
                        options={areaOption}
                        styles={customStyles}
                        className="mt-1"
                        isSearchable={true}
                        isClearable
                      />
                    )}
                    control={control}
                    rules={{ required: true }}
                  />
                  {errors.area && errors.area.type === "required" ? (
                    <p className="text-sm text-red-600">
                      This field is required
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="my-4">
                <h1 className="text-sm font-normal pb-4" htmlFor="">
                  Vehicle type
                </h1>
                <div className="grid grid-cols-4 gap-3">
                  <div
                    value="bicycle"
                    onClick={() => {
                      setvehicleOption("BICYCLE");
                    }}
                    className={
                      vehicleOption === "BICYCLE"
                        ? "op-item bg-white p-4 shadow-themeShadow rounded-md text-center border border-[#0DC1D9] cursor-pointer"
                        : "op-item bg-white p-4 shadow-themeShadow rounded-md text-center cursor-pointer"
                    }
                  >
                    <img src={bicycle} className="mx-auto" alt="" />
                    <h6 className="text-xs mt-2 text-[#4A4A4A]">Bicycle</h6>
                  </div>
                  <div
                    value="scooter"
                    onClick={() => {
                      setvehicleOption("SCOOTER");
                    }}
                    className={
                      vehicleOption === "SCOOTER"
                        ? "op-item bg-white p-4 shadow-themeShadow rounded-md text-center border border-[#0DC1D9] cursor-pointer"
                        : "op-item bg-white p-4 shadow-themeShadow rounded-md text-center cursor-pointer"
                    }
                  >
                    <img src={scooter} className="mx-auto" alt="" />
                    <h6 className="text-xs mt-2 text-[#4A4A4A]">Scooter</h6>
                  </div>
                  <div
                    onClick={() => {
                      setvehicleOption("CAR");
                    }}
                    className={
                      vehicleOption === "CAR"
                        ? "op-item bg-white p-4 shadow-themeShadow rounded-md text-center border border-[#0DC1D9] cursor-pointer"
                        : "op-item bg-white p-4 shadow-themeShadow rounded-md text-center cursor-pointer"
                    }
                  >
                    <img src={car} className="mx-auto" alt="" />
                    <h6 className="text-xs mt-2 text-[#4A4A4A]">Car</h6>
                  </div>
                  <div
                    onClick={() => {
                      setvehicleOption("WALK");
                    }}
                    className={
                      vehicleOption === "WALK"
                        ? "op-item bg-white p-4 shadow-themeShadow rounded-md text-center border border-[#0DC1D9] cursor-pointer"
                        : "op-item bg-white p-4 shadow-themeShadow rounded-md text-center cursor-pointer"
                    }
                  >
                    <img src={walking} className="mx-auto" alt="in" />
                    <h6 className="text-xs mt-2 text-[#4A4A4A]">Walking</h6>
                  </div>
                </div>
              </div>
              {vehicleOption === "CAR" || vehicleOption === "SCOOTER" ? (
                <div className="">
                  <h1 className="text-sm font-normal pb-4" htmlFor="">
                    Do you have Driving License?
                  </h1>
                  <div className="grid grid-cols-8">
                    <div className="form-check">
                      <input
                        {...register("license", { required: true })}
                        value="yes"
                        className=" rounded-full h-4 w-4 border border-[#0DC1D9] bg-white checked:bg-[#0DC1D9] checked:border-[#0DC1D9] mt-1  float-left mr-2 cursor-pointer"
                        type="radio"
                        name="license"
                        id="flexRadioDefault1"
                      />
                      <label
                        classname="form-check-label inline-block "
                        htmlFor="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div classname="form-check">
                      <input
                        {...register("license", { required: true })}
                        value="no"
                        className=" rounded-full h-4 w-4 border border-[#0DC1D9] bg-white checked:bg-[#0DC1D9] checked:border-[#0DC1D9] mt-1  float-left mr-2 cursor-pointer"
                        type="radio"
                        name="license"
                        id="flexRadioDefault2"
                      />

                      <label
                        classname="form-check-label inline-block text-gray-800"
                        htmlFor="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  <div>
                    {errors.license && (
                      <p className="text-red-500 text-xs">
                        This field is required
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="my-4 text-[#787878] text-sm">
                Our rider <span className="text-[#0DC1D9]">privacy policy</span>{" "}
                explains how we use and protect your information and that of any
                substitute you use, and explains both of your rights in relation
                to your data. We use rider and substitute data to provide
                efficient services to our customers, improve our operations and
                to give you relevant support.
              </div>
              <div className="my-4">
                <button
                  type="submit"
                  className="shadow-themeShadow rounded-md border-0 py-3 w-full bg-[#0DC1D9] text-white"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      {count === 3 && (
        <div className=" w-[600px] shadow-themeShadow rounded-md p-8 mx-auto ">
          <p className="text-sm font-medium ">Verify your email/phone number</p>
          <p className="text-[#464956] text-xs font-light ">
            Please enter your code
          </p>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center my-4">
              <OtpInput
                inputStyle={
                  "p-5  text-lg rounded-md m-3 focus:border-[#0DC1D9] w-12 h-12"
                }
                value={otp}
                onChange={handleOtpchange}
                numInputs={6}
              />
            </div>

            <div>
              <button
                type="submit"
                className="shadow-themeShadow rounded-md border-0 py-3 w-full bg-[#0DC1D9] text-white text-xs font-medium"
              >
                VERIFY
              </button>
              <div className="text-center my-4">
                <button className=" text-[#464956]">Resend Code</button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default DriverRegForm;
