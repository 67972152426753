import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchFoodVariation,
  foodModalOpenAction,
  searchRestaurantFood,
} from "../../redux/actions/restaurantAction";
import { IMAGE_URL } from "../../settings/config";

const FoodSuggestionInput = () => {
  const { foodSuggestions } = useSelector((state) => state.restaurant);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState("");

  const params = useParams();

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  const onChange = (e) => {
    const userInput = e.currentTarget.value;
    setUserInput(userInput);
    setShowSuggestions(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      dispatch(
        searchRestaurantFood({ search: userInput, res_branch: params.id })
      );

      // setFilteredSuggestions(data);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [userInput, dispatch, params.id]);

  const handleFoodClick = (id) => {
    dispatch(fetchFoodVariation(id));
    dispatch(foodModalOpenAction());
  };

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (foodSuggestions?.length > 0) {
      suggestionsListComponent = (
        <div className="res-search-result absolute bg-white rounded-md mt-3 py-3 top-full left-0 w-full shadow-themeShadow z-[100] md:block hidden max-h-[80vh] overflow-y-auto">
          <h4 className="text-black lg:text-base text-sm font-medium pt-4 px-3">
            {foodSuggestions.length} results for "{userInput}"
          </h4>
          <ul className="mt-2">
            {foodSuggestions.map((food, index) => (
              <li
                key={index}
                className="border-gray-300 py-3 group hover:bg-[#E6E6E6] cursor-pointer px-3"
                onClick={() => handleFoodClick(food.id)}
              >
                <div className="flex flex-wrap gap-4">
                  <div className="pr-thumb w-14 h-14">
                    <img
                      src={`${IMAGE_URL}${food.image}`}
                      alt={food.name}
                      className="w-full h-full"
                    />
                  </div>
                  <div className="pr-text">
                    <h6 className="text-black font-semibold text-sm pb-1">
                      {food.name}
                    </h6>
                    <div className="flex items-center gap-2 divide-x">
                      <span className="text-black text-xs">£{food.price}</span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      suggestionsListComponent = (
        <div className="res-search-result absolute bg-white rounded-md mt-3 p-3 top-full left-0 w-full shadow-themeShadow z-50">
          <em>No suggestions, you're on your own!</em>
        </div>
      );
    }
  }

  console.log(suggestionsListComponent);

  return (
    <div className="relative">
      <div className="resturant-search xl:min-w-[600px] lg:min-w-[500px] md:min-w-[300px] relative md:block hidden">
        <input
          type="text"
          name="res-search"
          onChange={onChange}
          onFocus={handleFocus}
          autoComplete="new-password"
          // onKeyDown={onKeyDown}
          value={userInput}
          className="bg-transparent w-full px-12 py-2 rounded-md border border-[#0DC1D9] placeholder:text-[#464956] placeholder:text-sm focus-visible:outline-[#0DC1D9] focus-visible:outline-search_outline"
          placeholder="Write some things"
        />
        <span className="absolute top-1/2 left-2 -translate-y-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-[#464956]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
      </div>
      {showSuggestions ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowSuggestions(false);
          }}
        >
          {suggestionsListComponent}
          {/* </div> */}
        </OutsideClickHandler>
      ) : (
        ""
      )}
    </div>
  );
};

export default FoodSuggestionInput;
