import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import OrderCreateUpdateForm from "../../../components/admin/Forms/Order/OrderCreateUpdateForm";
import {
  adminOrderDetails,
  adminOrderEdit,
} from "../../../redux/actions/AdminActions/adminOrderActions";
import Layout from "../Layout/layout";

const EditOrder = () => {
  const { orderDetails } = useSelector((state) => state.adminOrder);
  console.log(orderDetails);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [detailsText, setDetailsText] = useState("");
  useEffect(() => {
    dispatch(adminOrderDetails(params.id));
  }, [dispatch, params]);

  const handleSubmitData = (data) => {
    console.log(data);
    const body = {
      vat: data.vat,
      discount_amount: data.discount_amount,
      shipping_price: data.shipping_price,
      service_fee: data.service_fee,
      total_price: data.total_price,
      subtotal_price: data.subtotal_price,
      order_status: data.order_status.value,
      payment_type: data.payment_type.value,
      delivered_at: data.delivered_at ? data.delivered_at : null,
      order_delivery_time: data.order_delivery_time,
      delivery_time: data.delivery_time,
      schedule_time: data.schedule_time,
      schedule_date: data.schedule_date,
      delivery_option: data.delivery_option.value,
      rider_instruction: detailsText,
      cutlery: data.is_Cutlery,
      is_ordered: data.is_Ordered,
      active: data.is_Active,
    };
    dispatch(adminOrderEdit(body, params.id, history));
    // if (data.image.length > 0) {
    //   const formData = new FormData();
    //   formData.append("image", data.image[0]);
    //   formData.append("name", data.name);
    //   formData.append("owner", parseInt(data.owner.value));
    //   formData.append("description", data.description);
    //   formData.append("status", data.status);
    //   // console.log(data.image[0].name);
    //   dispatch(adminRestaurantEdit(formData, params.id, history));
    // } else {
    //   dispatch(
    //     adminRestaurantEdit(
    //       {
    //         name: data.name,
    //         owner: parseInt(data.owner.value),
    //         description: data.description,
    //         status: data.status,
    //       },
    //       params.id,
    //       history
    //     )
    //   );
    // }
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <OrderCreateUpdateForm
            edit
            orderDetails={orderDetails}
            handleSubmitData={handleSubmitData}
            detailsText={detailsText}
            setDetailsText={setDetailsText}
          />
        </div>
      </div>
    </Layout>
  );
};

export default EditOrder;
