import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useHistory } from "react-router-dom";
import DeliverySteps from "../components/Home/DeliverySteps";
import Hero from "../components/Home/Hero";
import HomeMenu from "../components/Home/HomeMenu";
import HomePrimaryBanner from "../components/Home/HomePrimaryBanner";
import OurApps from "../components/Home/OurApps";
import SocialMedia from "../components/Home/SocialMedia";
import BannerThumbImg from "../images/banner/banner_thumb_new.png";
import Layout from "./Layout";

const searchOptions = {
  componentRestrictions: { country: ["uk"] },
};

const Home = () => {
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState(
    localStorage.getItem("location") || ""
  );

  // const searchInput = useRef(null);
  // function setInput1Focus() {
  //   searchInput.current.focus();
  // }

  // const { restaurants } = useSelector((state) => state.restaurant);

  const history = useHistory();

  // const dispatch = useDispatch();

  const handleSelect = async (value) => {
    if (value === "") localStorage.removeItem("location");
    else localStorage.setItem("location", value);
    setAddress(value);

    const results = await geocodeByAddress(value);

    localStorage.setItem("formatted_address", results[0].formatted_address);
    const latlng = await getLatLng(results[0]);
    if (latlng) localStorage.setItem("latlng", `${latlng.lat}, ${latlng.lng}`);
    fetchRestByLocation(latlng);
    // history.push('restaurants')
    // fetchRestaurantsByAddress(latlng.lat, latlng.lng);
    // console.log('enterrrr')
  };

  const fetchRestByLocation = (latlng) => {
    // dispatch(fetchRestaurantsByAddress(latlng.lat, latlng.lng, history));
    // history.push(encodeURI(`restaurants/?lat=${latlng.lat}&lng=${latlng.lng}`));
    if (category) {
      history.push(
        encodeURI(
          `/restaurants/?lat=${latlng.lat}&lng=${latlng.lng}&foodOption=${category}`
        )
      );
    } else {
      history.push(
        encodeURI(`restaurants/?lat=${latlng.lat}&lng=${latlng.lng}`)
      );
    }
  };

  const handleMenuClick = (name) => {
    setCategory(name);
  };

  const getBrowserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        getUserAddressBy(position.coords.latitude, position.coords.longitude);
      },
      function (error) {
        alert("The Locator was denied, Please add your address manually");
      }
    );
  };

  const getUserAddressBy = (lat, long) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_API_KEY}&latlng=${lat},${long}`;
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        // if (location.results[0].formatted_address === "")
        //   localStorage.removeItem("location");
        // else
        //   localStorage.setItem(
        //     "location",
        //     location.results[0].formatted_address
        //   );
        console.log(location);

        for (let i = 0; i < location.results.length; i++) {
          // console.log(location.results[i]);
          if (location.results[i].types[0] === "route") {
            console.log(location.results[i].formatted_address);
            if (location.results[i].formatted_address === "") {
              localStorage.removeItem("location");
            } else {
              localStorage.setItem(
                "location",
                location.results[i].formatted_address
              );
            }
            setAddress(location.results[i].formatted_address);
            break;
          } else if (location.results[i].types[0] === "neighborhood") {
            if (location.results[i].formatted_address === "") {
              localStorage.removeItem("location");
            } else {
              localStorage.setItem(
                "location",
                location.results[i].formatted_address
              );
            }
            setAddress(location.results[i].formatted_address);
            break;
          } else {
            if (location.results[0].formatted_address === "")
              localStorage.removeItem("location");
            else
              localStorage.setItem(
                "location",
                location.results[0].formatted_address
              );
            setAddress(location.results[0].formatted_address);
          }
        }
        // for (
        //   let i = 0;
        //   i < location.results[0].address_components.length;
        //   i++
        // ) {
        //   if (
        //     location.results[0].address_components[i].types[0] == "locality"
        //   ) {
        //     let city = location.results[0].address_components[i].short_name;
        //     console.log(city);
        //   }
        // }
        // setAddress(location.results[0].formatted_address);
      })
      .catch((err) => {});
  };

  return (
    <Layout home partnar>
      <Hero banner={`bg-banner-bg`}>
        <div
          className="
           flex justify-between items-center xl:gap-20 lg:gap-12 md:h-bg-height h-[400px] w-full rounded-md "
        >
          <div className="banner-tex lg:w-[60%] w-full xl:pt-16">
            <h2 className="lg:text-4xl md:text-3xl text-2xl text-[#002138] md:pb-3 pb-2 ">
              Tuck into a <span className="text-[#0DC1D9]">takeaway today</span>
            </h2>
            <p className="md:text-lg text-base text-black  font-oswald pb-5">
              Find restaurants delivering right now, near you
            </p>
            <div className="bg-white p-3 shadow-[0px_1px_15px_rgba(46,49,146,0.1)] rounded-lg md:flex w-full">
              <div className="relative w-full">
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      <input
                        {...getInputProps({
                          placeholder: "E.g. 21 Stonewall Drive",
                        })}
                        className="border rounded-md p-5 w-full placeholder-gray-500 text-sm  focus:outline-none focus-visible:outline-black"
                        aria-label="search google maps for delivery address"
                        id="search"
                      />
                      <i
                        className="absolute top-1/2 right-3 transform -translate-y-1/2"
                        onClick={getBrowserLocation}
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="10.75"
                            y1="3.75"
                            x2="10.75"
                            y2="0.25"
                            stroke="#02F0C3
"
                            strokeWidth="0.5"
                            strokeLinecap="round"
                          />
                          <line
                            x1="10.75"
                            y1="21.75"
                            x2="10.75"
                            y2="18.25"
                            stroke="#02F0C3
"
                            strokeWidth="0.5"
                            strokeLinecap="round"
                          />
                          <line
                            x1="18.25"
                            y1="10.75"
                            x2="21.75"
                            y2="10.75"
                            stroke="#02F0C3
"
                            strokeWidth="0.5"
                            strokeLinecap="round"
                          />
                          <line
                            x1="0.25"
                            y1="10.75"
                            x2="3.75"
                            y2="10.75"
                            stroke="#02F0C3
"
                            strokeWidth="0.5"
                            strokeLinecap="round"
                          />
                          <circle
                            cx="11"
                            cy="11"
                            r="7.75"
                            fill="white"
                            stroke="#02F0C3
"
                            strokeWidth="0.5"
                          />
                          <circle
                            cx="11"
                            cy="11"
                            r="5.75"
                            stroke="#02F0C3
"
                            strokeWidth="0.5"
                          />
                          <circle cx="11" cy="11" r="2" fill="#02F0C3
" />
                        </svg>
                      </i>
                      <div className="absolute top-full left-0 rounded-md w-full z-20">
                        {loading ? (
                          <div className="shadow-md bg-white">
                            Getting Results...
                          </div>
                        ) : null}

                        {suggestions.map((suggestion, index) => {
                          const style = suggestion.active
                            ? {
                                backgroundColor: "rgba(12, 190, 218, 0.11)",
                                cursor: "pointer",
                                color: "#000",
                                padding: "10px 10px",
                                borderRadius: "5px",
                              }
                            : {
                                backgroundColor: "#fff",
                                cursor: "pointer",
                                padding: "10px 10px",
                              };

                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>
              </div>
              <button
                onClick={() => handleSelect(address)}
                type="button"
                className="custom-btn md:ml-3 md:mt-0 mt-4 w-full rounded-md md:w-60 hover:bg-[#0DC1D9] hover:text-white transition-all ease-out font-semibold"
              >
                Find Restaurants
              </button>
            </div>
          </div>
          <div className="banner-thumb xl:w-[40%] lg:w-[40%] lg:block hidden lg:mt-8 ">
            <img className="mt-0 ml-auto" src={BannerThumbImg} alt="" />
          </div>
        </div>
      </Hero>
      <HomeMenu
        handleMenuClick={handleMenuClick}
        // setInput1Focus={setInput1Focus}
      />
      {/* <SponsorItem /> */}
      <HomePrimaryBanner />
      <DeliverySteps />
      <OurApps />
      <SocialMedia />
      {/* <HomeContent /> */}
    </Layout>
  );
};

export default Home;
