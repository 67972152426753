import React from "react";
import banner2 from "../../../images/driver/banner-bike.png";
import banner1 from "../../../images/driver/home-banner.png";
const DriverHomeBanner = () => {
  return (
    <div className="relative lg:mb-0 md:mb-24">
      <img src={banner1} alt="" className="w-full lg:h-80 md:h-full h-32" />

      <img
        src={banner2}
        alt=""
        className="absolute bottom-0 xl:left-44 md:left-20 left-7 z-10 xl:h-max lg:h-2/3 h-3/5"
      />
      <div className="absolute bg-white rounded-md shadow-themeShadow xl:left-[460px] lg:left-80 md:left-52 left-28 xl:top-36 lg:top-44 top-20  xl:w-[541px] w-2/5 xl:p-16 lg:p-12 md:p-6 p-3 text-center z-0">
        {/* lg:left-40 lg:top-20 */}
        <h1 className=" xl:text-4xl lg:text-3xl md:text-2xl text-sm">
          Get Started Today
        </h1>
        <p className="xl:text-2xl md:text-lg text-xs">
          Request for a rider in just a few clicks
        </p>
        <button className="md:text-sm text-xs xl:px-36 xl:py-3 lg:px-20 md:px-16 md:py-2  px-4 py-1 rounded-md text-white bg-[#0DC1D9] md:mt-8 mt-3">
          REQUEST A RIDER
        </button>
      </div>
    </div>
  );
};

export default DriverHomeBanner;
