import {
  ADMIN_ORDER_LIST,
  ADMIN_CUSTOMER_PAYABLE_INFO_LIST,
  ADMIN_ORDER_DETAILS,
  ADMIN_ORDER_LOADING,
  ADMIN_ORDER_ERROR,
  FETCH_ALL_DRIVER_LIST,
  // FETCH_ALL_ASSIGNED_LIST,
} from "../../types";
import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";
import { vendorOrderList } from "../vendorActions/vendorOrderActions";

export const adminOrderList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_ORDER_LOADING });
    let token = auth.getToken();
    axios
      .get(`${API_URL}/order/admin/order/`, {
        params: {
          search: data.search,
          page: data.page,
          status: data.status,
        },
        headers: { Authorization: token },
      })
      .then((res) => dispatch({ type: ADMIN_ORDER_LIST, payload: res.data }))
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
      });
  };

export const adminDriverList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_ORDER_LOADING });
    axios
      .get(`http://18.118.249.221/api/users/all-active-users`, {
        params: {
          // search: data.search,
          page: data.page,
          // status: data.status,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: FETCH_ALL_DRIVER_LIST, payload: res.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
      });
  };

export const adminOrderDetails = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_ORDER_LOADING });
  let token = auth.getToken();
  axios
    .get(`${API_URL}/order/admin/order/${id}/`, {
      headers: { Authorization: token },
    })
    .then((res) => dispatch({ type: ADMIN_ORDER_DETAILS, payload: res.data }))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
    });
};

export const adminOrderEdit =
  (data, id, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_ORDER_LOADING });
    let token = auth.getToken();
    axios
      .patch(`${API_URL}/order/admin/order/${id}/`, data, {
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res);
        if (vendor) {
          history.push(`/vendor/order-details/${id}`);
        } else history.push(`/admin/order-details/${id}`);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
      });
  };

export const adminOrderItemDelete =
  (order_id, order_items_id) => async (dispatch) => {
    dispatch({ type: ADMIN_ORDER_LOADING });
    let token = auth.getToken();
    axios
      .get(`${API_URL}/order/order-item-delete/admin/`, {
        params: {
          order_id,
          order_items_id,
        },
        headers: { Authorization: token },
      })
      .then((_) => dispatch(adminOrderDetails(order_id)))
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
      });
  };

export const adminOrderItemVariationDelete =
  (order_id, order_items_id, variation_id) => async (dispatch) => {
    dispatch({ type: ADMIN_ORDER_LOADING });
    let token = auth.getToken();
    axios
      .get(`${API_URL}/order/order-variation-delete/admin/`, {
        params: {
          order_id,
          order_items_id,
          variation_id,
        },
        headers: { Authorization: token },
      })
      .then((_) => dispatch(adminOrderDetails(order_id)))
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
      });
  };

export const adminOrderStatusChange =
  (data, id, res_br, vendor) => async (dispatch) => {
    // (data, id, history) => async (dispatch) => {
    dispatch({ type: ADMIN_ORDER_LOADING });
    let token = auth.getToken();
    axios
      .patch(`${API_URL}/order/admin/order/${id}/`, data, {
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res);
        if (vendor) {
          dispatch(vendorOrderList({ res_branch: res_br }));
        } else dispatch(adminOrderList());
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
      });
  };

export const adminOrderDelete =
  (id, history, res_br, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_ORDER_LOADING });
    let token = auth.getToken();
    axios
      .delete(`${API_URL}/order/admin/order/${id}/`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res);
        if (vendor && history) {
          history.push("/vendor/order-list");
        } else if (vendor) {
          dispatch(vendorOrderList({ res_branch: res_br }));
        } else if (history) {
          history.push("/admin/order-list");
        } else dispatch(adminOrderList());
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
      });
  };

export const adminCustomerPayableInfoList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_ORDER_LOADING });
    let token = auth.getToken();
    axios
      .get(`${API_URL}/order/admin/customer-payable-info/`, {
        params: {
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: ADMIN_CUSTOMER_PAYABLE_INFO_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
      });
  };

export const adminCustomerPayableInfoDelete = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_ORDER_LOADING });
  let token = auth.getToken();
  axios
    .delete(`${API_URL}/order/admin/customer-payable-info/${id}/`, {
      headers: { Authorization: token },
    })
    .then((_) => {
      dispatch(adminCustomerPayableInfoList());
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
    });
};

export const adminOrderInvoice = (order_id) => async (dispatch) => {
  dispatch({ type: ADMIN_ORDER_LOADING });
  let token = auth.getToken();
  axios
    .get(`${API_URL}/order/invoice/`, {
      params: {
        order_id,
      },
      headers: { Authorization: token },
    })
    .then((_) => dispatch(adminOrderDetails(order_id)))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
    });
};

export const adminAssignDriver = (data) => async (dispatch) => {
  dispatch({ type: ADMIN_ORDER_LOADING });
  axios
    .post(`http://18.118.249.221/api/orders/save-new-order`, data)
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
};

export const driverSearch = (data) => async (dispatch) => {
  dispatch({ type: ADMIN_ORDER_LOADING });
  axios
    .post(`${API_URL}/order/driver/`, data)
    .then((res) => {
      console.log(res);
      dispatch(adminOrderDetails(data.order));
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_ORDER_ERROR, payload: err.response });
    });
};
