import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import SocialMedia from "../../../components/Home/SocialMedia";
import ModalContainer from "../../../components/common/ModalContainer";
import DriverRegForm from "../../../components/driver/RegForm/DriverRegForm";
import finishModal from "../../../images/driver/finishModal.png";
import { driverRegAction } from "../../../redux/actions/driverAction/driverRegAction";
import Layout from "../../Layout";
const DriverRegistration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [count, setcount] = useState(1);
  const [selectedArea, setselectedArea] = useState("");
  const [otp, setotp] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const onSubmit = (data) => {
    // console.log(data);
    if (
      data.name &&
      data.phone_number &&
      data.email &&
      data.password &&
      data.create_password &&
      count === 1
    ) {
      setcount(2);
    } else if (data.area && data.license && count === 2) {
      setcount(3);
      dispatch(driverRegAction(data));
      // regSuccessModal();
    }

    console.log(data);
  };
  const regSuccessModal = () => {
    console.log("modal");
    setIsOpen(true);
  };

  // console.log(otp);

  return (
    <Layout>
      <div className="mb-56 mt-16 flex justify-center">
        <DriverRegForm
          count={count}
          onSubmit={onSubmit}
          otp={otp}
          setotp={setotp}
        />
      </div>
      <SocialMedia />
      <ModalContainer open={modalIsOpen}>
        <div className="max-w-sm md:max-w-lg py-12 mx-auto ">
          <div className="flex justify-start">
            <img src={finishModal} alt="" />
          </div>
          <div className="text-center px-6 py-10">
            <p>
              <strong>Your regestration has been successfull.</strong>
            </p>
            <p className="text-[#464956] font-light">
              We’ll contact you in the next few days about getting set up and
              ready to launch
            </p>
            <button
              onClick={() => {
                setIsOpen(false);
                history.push("/");
              }}
              className="custom-btn px-10 bg-[#0DC1D9] text-white mt-6"
            >
              Done
            </button>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
};

export default DriverRegistration;
