import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ pageCount, handlePageClick, forcePage }) => {
  console.log(forcePage);
  let forcePageObj = {};
  if (forcePage === 0) {
    console.log('enter...');
    forcePageObj["forcePage"] = 0;
  }
  return (
    <div className="table-pagination">
      <ReactPaginate
        {...forcePageObj}
        nextLabel="Next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< Previous"
        pageClassName="paginate_button page-item previous"
        pageLinkClassName="h-[40px] w-[40px] block ml-0 leading-[40px] text-center text-gray-500  border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        previousClassName="paginate_button page-item previous"
        previousLinkClassName="h-[40px] block leading-[40px] px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        nextClassName="paginate_button page-item next"
        nextLinkClassName="h-[40px] block leading-[40px] px-3 ml-0 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        containerClassName="inline-flex -space-x-px"
        activeClassName="text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Pagination;
