import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";
import {
  ADMIN_VOUCHER_LOADING,
  ADMIN_VOUCHER_LIST,
  ADMIN_VOUCHER_DETAIL,
  ADMIN_CUSTOMER_USED_VOUCHER,
  ADMIN_VOUCHER_ERROR,
} from "../../types";
import { vendorVoucherListAction } from "../vendorActions/vendorVoucherActions";
export const adminVoucherListAction =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_VOUCHER_LOADING });
    const token = auth.getToken();
    // console.log(data);
    await axios
      .get(`${API_URL}/voucher/admin/`, {
        params: {
          search: data.search,
          status: data.status,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) => {
        //   console.log(res.data);
        dispatch({ type: ADMIN_VOUCHER_LIST, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: ADMIN_VOUCHER_ERROR, payload: err.response.data });
        console.log(err);
      });
  };

export const adminVoucherDetailAction = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_VOUCHER_LOADING });
  const token = auth.getToken();
  await axios
    .get(`${API_URL}/voucher/admin/${id}`, {
      headers: { Authorization: token },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({ type: ADMIN_VOUCHER_DETAIL, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_VOUCHER_ERROR, payload: err.response.data });
      console.log(err);
    });
};

export const adminDeleteVoucherAction =
  (id, history, details, vendor, res) => async (dispatch) => {
    dispatch({ type: ADMIN_VOUCHER_LOADING });
    const token = auth.getToken();
    await axios
      .delete(`${API_URL}/voucher/admin/${id}`, {
        headers: { Authorization: token },
      })
      .then((_) => {
        if (vendor && details) {
          history.push("/vendor/voucher-list");
        } else if (vendor) {
          dispatch(vendorVoucherListAction({ res_branch: res }));
        } else if (details) {
          history.push("/admin/voucher-list");
        } else dispatch(adminVoucherListAction());
      })
      .catch((err) => {
        dispatch({ type: ADMIN_VOUCHER_ERROR, payload: err.response.data });
        console.log(err);
      });
  };
export const adminCustomerUsedvoucherAction = () => async (dispatch) => {
  dispatch({ type: ADMIN_VOUCHER_LOADING });
  const token = auth.getToken();
  await axios
    .get(`${API_URL}/voucher/customer-voucher-info/`, {
      headers: { Authorization: token },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({ type: ADMIN_CUSTOMER_USED_VOUCHER, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_VOUCHER_ERROR, payload: err.response.data });
    });
};

export const adminAddVoucherAction =
  (body, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_VOUCHER_LOADING });
    let token = auth.getToken();
    await axios
      .post(`${API_URL}/voucher/admin/`, body, {
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res);
        if (vendor) {
          history.push("/vendor/voucher-list");
        } else {
          history.push("/admin/voucher-list");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_VOUCHER_ERROR, payload: err.response.data });
      });
  };

export const adminEditVoucherAction =
  (body, history, id, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_VOUCHER_LOADING });
    let token = auth.getToken();
    await axios
      .patch(`${API_URL}/voucher/admin/${id}/`, body, {
        headers: { Authorization: token },
      })
      .then((_) => {
        if (vendor) {
          history.push("/vendor/voucher-list");
        } else {
          history.push("/admin/voucher-list");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_VOUCHER_ERROR, payload: err.response.data });
      });
  };
