import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import "./styles.css";

import { Link } from "react-router-dom";
import { googleLogin, loginUser } from "../redux/actions/authActions";
import Layout from "./Layout";
// import GoogleLogin from "react-google-login";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
// import { FcGoogle } from "react-icons/fc";
import { Toaster } from "react-hot-toast";
import { BsGoogle } from "react-icons/bs";
import auth from "../util/auth";
import "./styles.css";
// import googleLogin from "../services/googleLogin";
import FacebookLogin from "react-facebook-login";
import { FaFacebookSquare } from "react-icons/fa";
;
function Login() {
  const { authError } = useSelector((state) => state.auth);

  console.log(authError);
  // const redirect = location.search ? location.search.split("=")[1] : "/";

  const history = useHistory();
  let authState = auth.getToken();
  let location = useLocation();
  useEffect(() => {
    let { from } = location.state || { from: { pathname: "/" } };
    if (authState) {
      history.replace(from);
    }
    // eslint-disable-next-line
  }, [authState]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const value = {
      ...values,
      // formType: formType,
    };
    dispatch(loginUser(value, history));
  };

  // const responseGoogle = (response) => {
  //     console.log(response);
  // dispatch(googleLogin(response.accessToken, history));
  // let googleResponse = googleLogin(response.accessToken);
  // console.log(googleResponse);
  // setValue("firstName", response.profileObj.givenName);
  // setValue("lastName", response.profileObj.familyName);
  // setValue("email", response.profileObj.email);
  // };
 

  const [isViewed, setisViewed] = useState(false);
  const togglePassword = () => {
    setisViewed(!isViewed);
  };

  const [user, setUser] = useState([]);
  const googlelogin = useGoogleLogin({
    onSuccess: (codeResponse) => (
      setUser(codeResponse),
      dispatch(googleLogin(codeResponse.access_token, history))
    ),
    onError: (error) => console.log("Login Failed:", error),
  });
  console.log(user);
  const responseFacebook = (response) => {
   
    console.log(response);
    // dispatch(facebookLogin(response?.accessToken, history));
    // let fName = res?.name?.split(" ")[0];
    // let lName = res?.name?.split(" ")[1];
    // setValue("firstName", fName);
    // setValue("lastName", lName);
    // setValue("email", res.email);
  };
  const handleSuccess = (response) => {
    console.log(response.status);
  }

  const handleError = (error) => {
    console.log(error);
  }
  return (
    <Layout loginHeader>
      <div className="">
        
        {/* font-body bg-login-bg bg-cover object-contain bg-center */}
        <div className="container ">
          <Toaster/>
          <div className="my-20 flex justify-center ">
            <div className="w-[500px] shadow-[0px_0px_15px_rgba(46,49,146,0.08)] rounded-md py-8 ">
              <div>
                <h3 className="text-2xl text-center">Welcome back!</h3>
                <p className="text-center text-[#464956] text-xs">
                  Log in by typing your email/phone number and password.
                </p>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="md:px-9 md:px-6 pt-4 rounded"
                >
                  <div className="mb-4 w-full">
                    <input
                      className={
                        errors?.identifier
                          ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                          : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                      }
                      id="identifier"
                      type="text"
                      name="identifier"
                      placeholder="Enter Email / Phone number"
                      {...register("identifier", {
                        required: true,
                      })}
                    />
                   
                    {errors?.identifier?.type === "required" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        This field is required
                      </p>
                    )}
                  </div>

                  <div className="">
                    <div className="relative w-full">
                      <input
                        className={
                          errors?.password
                          ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                          : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                        }
                        id="password"
                        type={isViewed ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        {...register("password", {
                          required: true,
                        })}
                      />
                      <i
                        onClick={togglePassword}
                        className="absolute  inset-y-0 right-2 mt-3 mr-2"
                        // inset-y-0 right-2 mt-3 mr-2
                      >
                        {isViewed ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            // height={12}
                            // width={20}
                            viewBox="0 0 24 20"
                            className="w-6 h-4"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            className="w-6 h-4"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                          // <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          // <path d="M7.59649 5.61678C5.78519 5.4664 4.241 4.65073 2.75941 3.48874C2.66759 3.60721 2.57577 3.73936 2.47144 3.85328C2.17512 4.18593 1.87462 4.50947 1.56996 4.833C1.42806 4.98338 1.22356 5.00616 1.06914 4.90591C0.91472 4.80566 0.83125 4.60516 0.885505 4.41377C0.91472 4.31352 0.981496 4.21783 1.04827 4.14036C1.39885 3.74848 1.75777 3.36115 2.12921 2.96014C1.99148 2.82344 1.84958 2.70496 1.72438 2.56826C1.19434 1.98043 0.66431 1.38804 0.142622 0.791092C-0.0577057 0.558694 -0.0410117 0.285284 0.155143 0.107567C0.347124 -0.070149 0.58084 -0.0245807 0.772821 0.221488C1.83706 1.58398 3.05573 2.74597 4.49141 3.63C5.38036 4.17682 6.3194 4.55959 7.33356 4.69174C8.32686 4.82389 9.29093 4.65073 10.2258 4.27707C11.7199 3.67557 12.9845 2.68218 14.1238 1.49284C14.5203 1.08273 14.8792 0.627046 15.2549 0.18959C15.3717 0.0528854 15.5011 -0.0291375 15.6722 0.0118739C15.8433 0.0528854 15.9477 0.17592 15.9894 0.358193C16.027 0.531353 15.9602 0.668058 15.86 0.795649C15.2841 1.54297 14.6539 2.23105 13.9653 2.85989C13.936 2.88724 13.9068 2.91458 13.8609 2.96014C14.224 3.35203 14.5788 3.73936 14.9377 4.12669C15.0504 4.24973 15.1338 4.38643 15.1046 4.56871C15.0754 4.74642 14.9836 4.86946 14.8208 4.9287C14.6497 4.99249 14.5078 4.9287 14.3909 4.8011C14.0404 4.42289 13.694 4.04467 13.3476 3.6619C13.3058 3.61633 13.2683 3.55709 13.2307 3.50241C11.77 4.65073 10.2175 5.47552 8.40615 5.62133C8.40615 5.89019 8.40615 6.14993 8.40615 6.40511C8.40615 6.78333 8.41033 7.15699 8.40615 7.5352C8.40198 7.80861 8.23086 8 7.99715 8C7.75926 8 7.59232 7.81317 7.59232 7.5352C7.59649 6.9018 7.59649 6.27296 7.59649 5.61678Z" fill="#B3B3B3"/>
                          // </svg>
                        )}
                      </i>
                    </div>
                    
                    {errors?.password?.type === "required" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        You must specify a password
                      </p>
                    )}
                  </div>
                  <div className=" mt-1">
                    {!Array.isArray(authError) && (
                      <p className="text-xs italic text-red-500">{authError}</p>
                    )}
                    {/* {serverError && (
                        <p className="text-xs italic text-red-500">
                          You are Currently Offline
                        </p>
                      )} */}
                  </div>
                  <div className="text-end pt-2 pb-5">
                    <Link to={`/forgot-password`}className="text-xs text-[#028FF0]">
                      Forgot your password?
                    </Link>
                  </div>
                 
                  <div className="mb-4 text-center">
                    <button
                      className="w-full px-5 py-3 font-bold text-white bg-[#0DC1D9] rounded-lg hover:bg-[#0DC1D9] focus:outline-none focus:shadow-outline "
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                  <div className="flex mb-4 mx-16">
                    <div className="w-1/2 border-t border-[#8F9394] mt-3"></div>
                    <div className=" flex items-center justify-center px-3">
                      <span className="border-l border-[#8F9394]"></span>
                      <span className="">OR</span>
                      <span className="border-r border-gray-400"></span>
                    </div>
                    <div className="w-1/2 border-t border-[#8F9394] mt-3"></div>
                  </div>

                 
                 
                  {/* <div className="mb-6 text-center">
                    <button
                      className="w-full p-3 font-bold text-white bg-[#0DC1D9] rounded-lg hover:bg-indigo-700 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={() => login()}
                    >
                      Continue with google
                    </button>
                  </div> */}
                  {/* <div className="mb-6 text-center">
                  
                    <FacebookLogin
                      // appId="4922934877752441"
                      appId="850232235652365"
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile"
                      
                      icon={<CiFacebook />}
                     
                      cssClass="kep-login-facebook2 w-full"
                      // onClick={componentClicked}
                      callback={responseFacebook}
                    />
                   
                  </div> */}

                  {/* <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: "10px 0 10px 0",
                      justifyContent: "center",
                    }}
                  > */}
                  {/* <GoogleLogin
                        clientId="382129473346-0q7pg4q7bf3rgcr6ibpfgdq06krs7mrk.apps.googleusercontent.com"
                        buttonText="Login Using Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />  */}
                  {/* <FacebookLogin
                        // appId="4922934877752441"
                        appId="850232235652365"
                        autoLoad={false}
                        fields="name,email,picture"
                        scope="public_profile"
                        icon={<FaFacebook />}
                        // icon="fa-facebook"
                        cssClass="kep-login-facebook2"
                        // className="facebook-button"
                        // onClick={componentClicked}
                        callback={responseFacebook}
                      />  */}
                  {/* </div> */}
                 
                </form>
                <div className="md:flex mb-4 justify-center items-center gap-3 px-4">
                    <div className="md:w-1/2 relative md:my-0 my-2 ">
                      <BsGoogle className="absolute text-white inset-y-4 md:inset-x-5 inset-x-20"/>
                      <button
                        className="p-3 w-full font-bold text-white bg-[#DF4B38] rounded-lg focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => googlelogin()}
                      >
                        Sign in with google
                      </button>
                    </div>

                    <div className="md:w-1/2">
                    
                    <FacebookLogin
                        // appId="4922934877752441"
                        // appId="850232235652365"
                        appId="256838426965520"
                        autoLoad={false}
                        fields="name,email,picture"
                        scope="public_profile"
                        icon={<FaFacebookSquare />}
                        // icon="fa-facebook"
                        cssClass="kep-login-facebook2 w-full bg-[#3E5C9A] rounded-lg text-center p-3 rounded-lg"
                        // onClick={componentClicked}
                        callback={responseFacebook}
                      />
                      {/* <FacebookProvider appId="256838426965520">
      <LoginButton
        scope="email"
        onError={handleError}
        onSuccess={handleSuccess}
      >
        Login via Facebook
      </LoginButton>
    </FacebookProvider> */}
                    </div>
                  </div>
                  <div className="text-center text-sm">
                    <p className="text-[#888888]">
                      Not Registered Yet?
                      <Link
                        className="inline-block text-[#028FF0] align-baseline "
                        to={`register`}
                      >
                        Create an Account!
                      </Link>
                    </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Login;
