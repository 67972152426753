import React from "react";

const NetBanking = () => {
  return (
    <div className="card-content shadow-lg mt-4 px-4 py-5 bg-white sm:p-6 rounded-md block text-sm font-medium text-slate-700">
      <div className="grid lg:grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <input
            type="text"
            name="first-name"
            id="first-name"
            placeholder="Bank Transaction ID"
            className="mt-1 focus:outline-none border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm rounded-md h-10 p-2"
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <input
            type="text"
            name="last-name"
            id="last-name"
            placeholder="Order Number"
            className="mt-1 focus:outline-none border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm rounded-md h-10 p-2"
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <input
            type="text"
            name="account-number"
            id="account-number"
            placeholder="Account Number"
            className="mt-1 focus:outline-none focus:ring-indigo-500 w-full focus:border-indigo-500  shadow-sm sm:text-sm border border-gray-300 rounded-md h-10 p-2"
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <input
            type="number"
            id="number"
            name="number"
            placeholder="Payment Reference Number"
            className="mt-1  w-full appearance-none py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <input
            type="text"
            name="sort-code"
            id="Short Code"
            placeholder="Account Number"
            className="mt-1 focus:outline-none focus:ring-indigo-500 w-full focus:border-indigo-500  shadow-sm sm:text-sm border border-gray-300 rounded-md h-10 p-2"
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <input
            type="number"
            id="amount"
            name="amount"
            placeholder="Amount"
            className="mt-1  w-full appearance-none py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="col-span-6">
          <div className="flex items-start">
            <input type="checkbox" className="card-checkbox" id="" />
            <div className="pl-2">
              <h6 className="text-base font-medium text-black leading-3 pb-1">
                Save input details
              </h6>
              <p className="text-sm text-gray-500">
                I acknowledge that my card information is saved in my Daraz
                account for subsequent transactions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 pt-6 sm:px-6">
        <button
          type="button"
          className="inline-flex justify-center py-2 px-8 border-0 shadow-sm text-sm font-medium rounded-md text-white bg-gradient-to-r from-indigo-900 to-pink-700 hover:from-pink-700 hover:to-indigo-900 w-32"
        >
          Pay Now
        </button>
      </div>
    </div>
  );
};

export default NetBanking;
