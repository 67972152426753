import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AdminRoute from "./components/common/AdminRoute";
import PrivateRoute from "./components/common/PrivateRoute";

import Layout from "./pages/Layout";
import Checkout from "./pages/checkout";
import home from "./pages/home";
import login from "./pages/login";
import registration from "./pages/registration";
import RestaurantDetails from "./pages/restaurantDetails";
import SearchRestaurant from "./pages/searchRestaurant";

import VendorRoute from "./components/common/VendorRoute";
import ChatVendor from "./components/vendor/Layout/ChatVendor";
import FAQ from "./pages/FAQ";
import TermConditions from "./pages/TermConditions";
import About from "./pages/about";
import AccountActivation from "./pages/accountActivation";
import addMenuVariations from "./pages/admin/ manageMenuVariations/addMenuVariations";
import editMenuVariations from "./pages/admin/ manageMenuVariations/editMenuVariations";
import menuVariationsList from "./pages/admin/ manageMenuVariations/menuVariationsList";
import Home from "./pages/admin/Home/Home";
import contactUs from "./pages/admin/manageApplicationSettings/contactUs";
import contentSettings from "./pages/admin/manageApplicationSettings/contentSettings";
import generalSettings from "./pages/admin/manageApplicationSettings/generalSettings";
import DriverList from "./pages/admin/manageDriver/DriverList";
import addFoodIngredients from "./pages/admin/manageFoodIngredients/addFoodIngredients";
import editFoodIngredients from "./pages/admin/manageFoodIngredients/editFoodIngredients";
import foodIngredientsList from "./pages/admin/manageFoodIngredients/foodIngredientsList";
import addMenuCategories from "./pages/admin/manageMenuCategories/addMenuCategories";
import editMenuCategories from "./pages/admin/manageMenuCategories/editMenuCategories";
import menuCategoriesList from "./pages/admin/manageMenuCategories/menuCategoriesList";
import addMenuItems from "./pages/admin/manageMenuItems/addMenuItems";
import editMenuItems from "./pages/admin/manageMenuItems/editMenuItems";
import menuItemsDetails from "./pages/admin/manageMenuItems/menuItemsDetails";
import menuItemsList from "./pages/admin/manageMenuItems/menuItemsList";
import addOrder from "./pages/admin/manageOrder/addOrder";
import customerPaymentInfo from "./pages/admin/manageOrder/customerPaymentInfo";
import editOrder from "./pages/admin/manageOrder/editOrder";
import orderDetails from "./pages/admin/manageOrder/orderDetails";
import orderList from "./pages/admin/manageOrder/orderList";
import addRestaurant from "./pages/admin/manageRestaurant/addRestaurant";
import addRestaurantBranchs from "./pages/admin/manageRestaurant/addRestaurantBranchs";
import addRestaurantCategories from "./pages/admin/manageRestaurant/addRestaurantCategories";
import editRestaurant from "./pages/admin/manageRestaurant/editRestaurant";
import editRestaurantBranchs from "./pages/admin/manageRestaurant/editRestaurantBranchs";
import restaurantBranchDetails from "./pages/admin/manageRestaurant/restaurantBranchDetails";
import restaurantDetails from "./pages/admin/manageRestaurant/restaurantDetails";
import restaurantList from "./pages/admin/manageRestaurant/restaurantList";
import ReviewRatingList from "./pages/admin/manageReviewRating/reviewRatingList";
import addSponsor from "./pages/admin/manageSponsors/addSponsor";
import editSponsors from "./pages/admin/manageSponsors/editSponsors";
import sponsorsList from "./pages/admin/manageSponsors/sponsorsList";
import editUser from "./pages/admin/manageUser/editUser";
import newUsers from "./pages/admin/manageUser/newUsers";
import userDetails from "./pages/admin/manageUser/userDetails";
import userList from "./pages/admin/manageUser/userList";
import vendorDetails from "./pages/admin/manageVendorRequest/vendorDetails";
import VendorRequestList from "./pages/admin/manageVendorRequest/vendorRequestList";
import addVoucher from "./pages/admin/manageVoucher/addVoucher";
import customerUsedVoucher from "./pages/admin/manageVoucher/customerUsedVoucher";
import editVoucher from "./pages/admin/manageVoucher/editVoucher";
import voucherDetails from "./pages/admin/manageVoucher/voucherDetails";
import voucherList from "./pages/admin/manageVoucher/voucherList";
import ContactUs from "./pages/contactUs";
import driverHome from "./pages/driver/home/driverHome";
import driverRegistration from "./pages/driver/registration/driverRegistration";
import foodIngredients from "./pages/foodIngredients";
import forgotPassword from "./pages/forgotPassword";
import Order from "./pages/order";
import OrderTrack from "./pages/order-track";
import Payment from "./pages/payment";
import PaymentSuccess from "./pages/paymentSuccess";
import PrivacyPolicy from "./pages/privacyPolicy";
import Profile from "./pages/profile";
import restaurantPartner from "./pages/restaurantPartner";
import vendorAddMenuVariations from "./pages/vendor/ manageMenuVariations/addMenuVariations";
import vendorEditMenuVariations from "./pages/vendor/ manageMenuVariations/editMenuVariations";
import vendorMenuVariationsList from "./pages/vendor/ manageMenuVariations/menuVariationsList";
import VendorHome from "./pages/vendor/Home/Home";
import vendorAddFoodIngredients from "./pages/vendor/manageFoodIngredients/addFoodIngredients";
import vendorEditFoodIngredients from "./pages/vendor/manageFoodIngredients/editFoodIngredients";
import vendorFoodIngredientsList from "./pages/vendor/manageFoodIngredients/foodIngredientsList";
import vendorAddMenuCategories from "./pages/vendor/manageMenuCategories/addMenuCategories";
import vendorMenuCategoriesList from "./pages/vendor/manageMenuCategories/menuCategoriesList";
import vendorAddMenuItems from "./pages/vendor/manageMenuItems/addMenuItems";
import vendorEditMenuItems from "./pages/vendor/manageMenuItems/editMenuItems";
import vendorMenuItemsDetails from "./pages/vendor/manageMenuItems/menuItemsDetails";
import vendorMenuItemsList from "./pages/vendor/manageMenuItems/menuItemsList";
import vendoraddOrder from "./pages/vendor/manageOrder/addOrder";
import vendorEditOrder from "./pages/vendor/manageOrder/editOrder";
import vendorOrderDetails from "./pages/vendor/manageOrder/orderDetails";
import vendorOrderList from "./pages/vendor/manageOrder/orderList";
import vendorAddRestaurantBranchs from "./pages/vendor/manageRestaurant/addRestaurantBranchs";
import vendorRestaurantCategories from "./pages/vendor/manageRestaurant/addRestaurantCategories";
import vendorEditRestaurant from "./pages/vendor/manageRestaurant/editRestaurant";
import vendorEditRestaurantBranchs from "./pages/vendor/manageRestaurant/editRestaurantBranchs";
import VendorRestaurantBranchDetails from "./pages/vendor/manageRestaurant/restaurantBranchDetails";
import vendorRestaurantBranchList from "./pages/vendor/manageRestaurant/restaurantBranchList";
import vendorAddVoucher from "./pages/vendor/manageVoucher/addVoucher";
import vendorEditVoucher from "./pages/vendor/manageVoucher/editVoucher";
import vendorVoucherDetails from "./pages/vendor/manageVoucher/voucherDetails";
import vendorVoucherList from "./pages/vendor/manageVoucher/voucherList";
import vendorRegistration from "./pages/vendor/vendorRegistration/vendorRegistration";
import Wishlist from "./pages/wishlist";

function RouteApp() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={home} />
        <Route exact path="/layout" component={Layout} />
        <Route exact path="/register" component={registration} />
        <Route exact path="/login" component={login} />
        <Route exact path="/forgot-password" component={forgotPassword} />
        <Route exact path="/restaurants" component={SearchRestaurant} />
        <Route exact path="/restaurant/:id" component={RestaurantDetails} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/term-conditions" component={TermConditions} />
        {/*<Route exact path="/restaurants" component={restaurants}/>*/}
        <PrivateRoute exact path="/checkout" component={Checkout} />
        <PrivateRoute exact path="/payment/:type?" component={Payment} />
        <PrivateRoute exact path="/order" component={Order} />
        <PrivateRoute
          exact
          path="/order/track/:orderId"
          component={OrderTrack}
        />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/wishlist" component={Wishlist} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact-us" component={ContactUs} />
       
        <Route exact path="/payment-success" component={PaymentSuccess} />
        <Route exact path="/food/ingredients" component={foodIngredients} />
        <Route exact path="/account/activation" component={AccountActivation} />
        
        <Route exact path="/faq" component={FAQ} />
        
        
        <AdminRoute exact path="/admin/" component={Home} />
        <Route exact path="/admin/user-list" component={userList} />
        <Route exact path="/admin/user/edit/:id" component={editUser} />
        <Route exact path="/admin/user-details/:id" component={userDetails} />
        <Route exact path="/admin/new-users" component={newUsers} />
        <Route exact path="/admin/restaurant-list" component={restaurantList} />
        <Route
          exact
          path="/admin/restaurant/edit/:id"
          component={editRestaurant}
        />
        <Route
          extact
          path="/admin/restaurant-details/:id"
          component={restaurantDetails}
        />
        <Route
          extact
          path="/admin/restaurant-branch-details/:id"
          component={restaurantBranchDetails}
        />
        <Route exact path="/admin/add-restaurant" component={addRestaurant} />
        <Route
          exact
          path="/admin/add-restaurant-branches/:res_id?"
          component={addRestaurantBranchs}
        />
        <Route
          exact
          path="/admin/restaurant-branch/edit/:id"
          component={editRestaurantBranchs}
        />
        <Route
          exact
          path="/admin/add-restaurant-categories"
          component={addRestaurantCategories}
        />
        <Route
          exact
          path="/admin/menu-categories-list"
          component={menuCategoriesList}
        />
        <Route
          exact
          path="/admin/add-menu-categories"
          component={addMenuCategories}
        />
        <Route
          exact
          path="/admin/menu-categories/edit/:id"
          component={editMenuCategories}
        />
        <Route exact path="/admin/menu-items-list" component={menuItemsList} />
        <Route exact path="/admin/add-menu-items" component={addMenuItems} />
        <Route
          exact
          path="/admin/menu-items/edit/:id"
          component={editMenuItems}
        />
        <Route
          exact
          path="/admin/menu-items-details/:id"
          component={menuItemsDetails}
        />
        <Route
          exact
          path="/admin/menu-variations-list"
          component={menuVariationsList}
        />
        <Route
          exact
          path="/admin/add-menu-variations"
          component={addMenuVariations}
        />
        <Route
          exact
          path="/admin/menu-variations/edit/:id"
          component={editMenuVariations}
        />
        <Route
          exact
          path="/admin/food-ingredients-list"
          component={foodIngredientsList}
        />
        <Route
          exact
          path="/admin/add-food-ingredients"
          component={addFoodIngredients}
        />
        <Route
          exact
          path="/admin/food-ingredients/edit/:id"
          component={editFoodIngredients}
        />
        <Route exact path="/admin/order-list" component={orderList} />
        <Route exact path="/admin/order-details/:id" component={orderDetails} />
        <Route exact path="/admin/add-order" component={addOrder} />
        <Route exact path="/admin/order/edit/:id" component={editOrder} />
        <Route exact path="/admin/driver-list" component={DriverList} />
        <Route
          exact
          path="/admin/customer-payment-info"
          component={customerPaymentInfo}
        />
        <Route exact path="/admin/voucher-list" component={voucherList} />
        <Route
          exact
          path="/admin/voucher-details/:id"
          component={voucherDetails}
        />
        <Route
          exact
          path="/admin/customer-used-voucher"
          component={customerUsedVoucher}
        />
        <Route exact path="/admin/add-voucher" component={addVoucher} />
        <Route exact path="/admin/voucher/edit/:id" component={editVoucher} />
        <Route exact path="/admin/sponsors-list" component={sponsorsList} />
        <Route exact path="/admin/add-sponsor" component={addSponsor} />
        <Route exact path="/admin/sponsor/edit/:id" component={editSponsors} />
        <Route exact path="/admin/contact-us" component={contactUs} />
        <Route
          exact
          path="/admin/content-settings"
          component={contentSettings}
        />
        <Route
          exact
          path="/admin/general-settings"
          component={generalSettings}
        />
        <Route
          exact
          path="/admin/vendor/request"
          component={VendorRequestList}
        />
        <Route
          exact
          path="/admin/vendor/details/:id"
          component={vendorDetails}
        />
        <Route exact path="/admin/review-rating" component={ReviewRatingList} />
        <VendorRoute exact path="/vendor/" component={VendorHome} />
        <VendorRoute
          exact
          path="/vendor/restaurant/edit/:id"
          component={vendorEditRestaurant}
        />
        <VendorRoute
          exact
          path="/vendor/add-restaurant-branches/"
          component={vendorAddRestaurantBranchs}
        />
        <VendorRoute
          exact
          path="/vendor/restaurant-branches-list/"
          component={vendorRestaurantBranchList}
        />
        <VendorRoute
          exact
          path="/vendor/restaurant-branch-details/:id"
          component={VendorRestaurantBranchDetails}
        />
        <VendorRoute
          exact
          path="/vendor/restaurant-branch/edit/:id"
          component={vendorEditRestaurantBranchs}
        />
        <VendorRoute
          exact
          path="/vendor/add-restaurant-categories"
          component={vendorRestaurantCategories}
        />
        <VendorRoute
          exact
          path="/vendor/menu-categories-list"
          component={vendorMenuCategoriesList}
        />
        <VendorRoute
          exact
          path="/vendor/add-menu-categories"
          component={vendorAddMenuCategories}
        />
        <VendorRoute
          exact
          path="/vendor/menu-items-list"
          component={vendorMenuItemsList}
        />
        <VendorRoute
          exact
          path="/vendor/menu-items-details/:id"
          component={vendorMenuItemsDetails}
        />
        <VendorRoute
          exact
          path="/vendor/add-menu-items"
          component={vendorAddMenuItems}
        />
        <VendorRoute
          exact
          path="/vendor/menu-items/edit/:id"
          component={vendorEditMenuItems}
        />
        <VendorRoute
          exact
          path="/vendor/menu-variations-list"
          component={vendorMenuVariationsList}
        />
        <VendorRoute
          exact
          path="/vendor/add-menu-variations"
          component={vendorAddMenuVariations}
        />
        <VendorRoute
          exact
          path="/vendor/menu-variations/edit/:id"
          component={vendorEditMenuVariations}
        />
        <VendorRoute
          exact
          path="/vendor/food-ingredients-list"
          component={vendorFoodIngredientsList}
        />
        <VendorRoute
          exact
          path="/vendor/add-food-ingredients"
          component={vendorAddFoodIngredients}
        />
        <VendorRoute
          exact
          path="/vendor/food-ingredients/edit/:id"
          component={vendorEditFoodIngredients}
        />
        <VendorRoute
          exact
          path="/vendor/order-list"
          component={vendorOrderList}
        />
        <VendorRoute
          exact
          path="/vendor/order-details/:id"
          component={vendorOrderDetails}
        />
        <VendorRoute
          exact
          path="/vendor/order/edit/:id"
          component={vendorEditOrder}
        />
        <VendorRoute
          exact
          path="/vendor/add-order"
          component={vendoraddOrder}
        />
        <VendorRoute
          exact
          path="/vendor/voucher-list"
          component={vendorVoucherList}
        />
        <VendorRoute
          exact
          path="/vendor/voucher-details/:id"
          component={vendorVoucherDetails}
        />
        <VendorRoute
          exact
          path="/vendor/add-voucher"
          component={vendorAddVoucher}
        />
        <VendorRoute
          exact
          path="/vendor/voucher/edit/:id"
          component={vendorEditVoucher}
        />
        <Route
          exact
          path="/vendor/registration"
          component={vendorRegistration}
        />
        <Route exact path="/restaurant-partner" component={restaurantPartner} />
        <Route exact path="/driver/home" component={driverHome} />
        <Route
          exact
          path="/driver/registration"
          component={driverRegistration}
        />
        <Route exact path="/chat" component={ChatVendor} />
      </Switch>
    </Router>
  );
}

export default RouteApp;
