import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import OrderImg from "../../images/payment/step-1.webp";
import { cancelOrder } from "../../redux/actions/orderAction";
import { IMAGE_URL } from "../../settings/config";

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const ActiveOrder = ({ order_history }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <>
      {order_history?.filter(
        (order) =>
          order.order_status !== "CANCELLED" &&
          order.order_status !== "DELIVERED"
      ).length > 0 ? (
        order_history
          ?.filter(
            (order) =>
              order.order_status !== "CANCELLED" &&
              order.order_status !== "DELIVERED"
          )
          .map((item) => {
            return (
              <div key={item.id}>
                <div className="order-item bg-white shadow-md rounded-md px-6 py-8 mt-4">
                  <div className="order-id">
                    <div className="md:flex justify-between">
                      <div className="id-info">
                        <h5 className="text-black font-medium text-lg">
                          Order ID#
                          <span className="text-primaryColor">
                            {item.order_number}
                          </span>
                        </h5>
                        <h4 className="text-black font-medium text-lg">
                          <span className="text-btnBg">
                            {item?.restaurant_branch.name} -{" "}
                            {item?.restaurant_branch.postcode}
                          </span>
                        </h4>
                        <p className="text-gray-500 text-sm font-normal pt-1">
                          {moment(item?.created_at).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )}
                        </p>
                      </div>
                      <div className="track-btn">
                        <button
                          type="button"
                          className="bg-[#89e488] px-4 md:px-8 py-2 rounded-sm text-white mt-6 md:mt-0"
                          onClick={() =>
                            history.push(`/order/track/${item.order_number}`)
                          }
                        >
                          Track your order
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex justify-between">
                    <div>
                      {item?.items.map((foodItem) => (
                        <div
                          className="md:flex justify-between mt-4"
                          key={foodItem.id}
                        >
                          <div className="md:flex items-start w-10/12 md:w-full">
                            <div className="thumb h-20 w-14 flex items-center">
                              <img
                                src={`${IMAGE_URL}${foodItem.food_items.image}`}
                                alt={foodItem.food_items.name}
                                width="42"
                                height="56"
                              />
                            </div>
                            <div className="text md:pl-3">
                              {/* <h6 className="text-btnBg text-base">
                                    {item?.restaurant_branch.name} -{" "}
                                    {item?.restaurant_branch.postcode}
                                  </h6> */}
                              <h4 className="text-black text-base">
                                {foodItem.food_items.name}
                              </h4>
                              <h6 className="text-black text-sm">
                                QTY: {foodItem.quantity}
                              </h6>
                              <span className="text-black text-base">
                                Price:{" "}
                                <strong>
                                  £{foodItem.food_items.total_price}
                                </strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="order-status pt-5 md:pt-0 md:text-right">
                      <h4 className="text-black text-base font-semibold">
                        Order Status
                      </h4>
                      <p className="text-black text-base font-medium">
                        {/* 5:20 PM */}
                        {item?.delivery_time === "schedule" ? (
                          <>
                            {moment(item?.schedule_date).format(
                              "dddd, MMMM Do YYYY"
                            )}
                            ,{" "}
                            {/* {moment(item?.schedule_time).format(
                                          "LTS"
                                        )} */}
                            {tConvert(item?.schedule_time)}
                            {/* {new Date(item?.schedule_time)} */}
                            {/* {item?.schedule_time} */}
                          </>
                        ) : (
                          moment(item?.order_delivery_time).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )
                        )}
                      </p>
                      <p className="text-[#0DC1D9] text-base font-normal inline-block">
                        {item?.order_status}
                      </p>
                      <br />
                      {item.order_status === "CREATED" ? (
                        <button
                          type="button"
                          className="text-primaryColor text-sm"
                          onClick={() => dispatch(cancelOrder(item.id))}
                        >
                          Cancel Order
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="border-t border-dashed md:ml-20 mt-4 pt-4">
                    <p className="text-black text-xs">
                      Delivery Fee:{" "}
                      <strong className="font-semibold">
                        £{item?.restaurant_branch.delivery_charge}
                      </strong>
                    </p>
                    <p className="text-primaryColor text-xs">
                      Voucher Discount:
                      <strong className="font-semibold">
                        £{item?.discount_amount}
                      </strong>
                    </p>
                    <p className="text-xs">
                      <strong className="text-black font-semibold">
                        Delivery to
                      </strong>
                      : {item?.shipping_address?.others} Post Code:{" "}
                      {item?.shipping_address?.postcode}{" "}
                      {item?.shipping_address?.city}
                    </p>
                  </div>
                </div>
                <div className="order-total py-4 text-right">
                  <span className="text-lg font-medium text-black">
                    Total:{" "}
                    <strong className="font-semibold">
                      £{item.total_price}
                    </strong>
                  </span>
                </div>
              </div>
            );
          })
      ) : (
        <div className="">
          <div className="payment-thumb text-center">
            <img src={OrderImg} className="mx-auto" alt="empty order" />
            <h4 className="text-xl font-semibold text-black pt-10">
              You have no active order!
            </h4>
            <p className="text-sm text-gray-500 font-normal pt-1">
              Thank you for choosing us
            </p>
            <Link to="/">
              <button
                type="button"
                className="px-8 py-3 rounded-md text-white text-xs font-normal cursor-pointer bg-green-400 mt-6"
              >
                Back to Home
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ActiveOrder;
