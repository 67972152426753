import React from "react";
import SocialMedia from "../components/Home/SocialMedia";
import Layout from "./Layout";

import "../../src/pages/styles.css";
import Banner from "../components/vendor/Home/Banner";
import Workflow from "../components/vendor/Home/Workflow";
import HowCanHelp from "../components/vendor/Home/HowCanHelp";
import Instractions from "../components/vendor/Home/Instractions";
import PartnerwithUs from "../components/vendor/Home/PartnerwithUs";

const restaurantPartner = () => {
  return (
    <>
      <Layout>
        {/* banner section */}
        <Banner />
        {/* How it Works */}
        <Workflow />
        {/* How can British Marketplace help your business? */}
        <HowCanHelp />
        {/* instraction section */}
        <Instractions />

        {/*  */}
        <PartnerwithUs />
        <SocialMedia />
      </Layout>
    </>
  );
};

export default restaurantPartner;
