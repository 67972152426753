import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import UserCreateUpdateForm from "../../../components/admin/Forms/User/UserCreateUpdateForm";
import {
  AdminNewUserImagePhone,
  adminRegisterUser,
} from "../../../redux/actions/AdminActions/adminUserActions";
import Layout from "../Layout/layout";

function NewUsers() {
  const { singleUserReg } = useSelector((state) => state.adminUser);
  const [image, setImage] = useState(null);
  const [imagePreview, setimagePreview] = useState("");
  const [count, setCount] = useState(1);
  const history = useHistory();
  console.log({ singleUserReg });

  const dispatch = useDispatch();
  if (image) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = function () {
        setimagePreview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }
  const handleSubmitData = (data) => {
    console.log(data);

    if (
      (data.additional_phone_number || image) &&
      singleUserReg.user.id &&
      count === 2
    ) {
      const uploadData = new FormData();
      uploadData.append(
        "additional_phone_number",
        data.additional_phone_number
      );
      uploadData.append("profile_pic", image, image.name);
      dispatch(
        AdminNewUserImagePhone(uploadData, singleUserReg.user.id, history)
      );
    } else {
      setCount(2);
      dispatch(adminRegisterUser(data));
    }
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-1">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <UserCreateUpdateForm
            image={image}
            imagePreview={imagePreview}
            setImage={setImage}
            handleSubmitData={handleSubmitData}
            count={count}
            setCount={setCount}
          />
        </div>
      </div>
    </Layout>
  );
}

export default NewUsers;
