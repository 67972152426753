import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { logoutAction } from "../redux/actions/authActions";

const Layout = (props) => {
  const { user } = useSelector((state) => state.auth);
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let menuButton = document.querySelector("#menuButton");
    let closeButton = document.querySelector("#close-menu");
    let overlayButton = document.querySelector("#overlay");
    let sideMenu = document.querySelector("#side-menu");
    menuButton.addEventListener("click", () => {
      sideMenu.classList.add("open-side-nav");
      overlayButton.classList.add("overlay");
    });
    closeButton.addEventListener("click", () => {
      sideMenu.classList.remove("open-side-nav");
      overlayButton.classList.remove("overlay");
    });
    overlayButton.addEventListener("click", () => {
      overlayButton.classList.remove("overlay");
      sideMenu.classList.remove("open-side-nav");
    });
  }, []);

  const logoutHandler = () => {
    console.log("enter");
    dispatch(logoutAction(history));
  };

  // useEffect(() => {
  //   const order_data = getOrderDataFromLocalStorage();
  //   if (user) {

  //   }
  // }, [])
  return (
    <>
      <Header loginHeader={props?.loginHeader}
        user={user}
        cart={cart}
        logout={logoutHandler}
        home={props.home}
        suggestionBar={props.suggestionBar}
        foodSuggestionsBar={props.foodSuggestionsBar}
        partnar={props.partnar}
      />

      {props.children}
      <Footer />
    </>
  );
};

export default Layout;
