import React from "react";
import { Link } from "react-router-dom";

const SocialMedia = () => {
  return (
    <section className="py-5 bg-gray-100">
      <div className="container">
        <div className="flex justify-center items-center gap-4">
          <span className="text-lg font-medium text-black">Follow Us:</span>
          <Link onClick={()=>window.open(`https://www.facebook.com/britishMarketplaceuk/`)} target="_blank"
            className="
              h-9
              w-9
              flex
              items-center
              text-center
              rounded-full
              bg-[#0DC1D9]
            "
          >
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-5 h-5 m-auto currentColor text-white"
              viewBox="0 0 24 24"
            >
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </Link>
          <Link onClick={()=>window.open(`https://www.instagram.com/britishmarket_place/`)} target="_blank"
            className="
              h-9
              w-9
              flex
              items-center
              text-center
              rounded-full
              bg-[#0DC1D9]
            "
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-5 h-5 m-auto currentColor text-white"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
