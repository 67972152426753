import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { adminRestaurantStatusChange } from "../../../redux/actions/AdminActions/adminRestaurantActions";

const RestaurantInfo = ({
  resDetailsWithBranch,
  handleDeleteClick,
  vendor,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="mr-3 shadow p-3 h-max w-1/2 lg:w-1/3">
      {resDetailsWithBranch?.image ? (
        <img
          className="w-1/2 h-1/2 mb-6 mx-auto"
          src={resDetailsWithBranch?.image}
          alt=""
        />
      ) : (
        <img
          className="w-1/2 h-1/2 mb-6 mx-auto"
          src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
          alt=""
        />
      )}

      <div className="">
        <h2>
          {" "}
          Restaurant Name: <strong>{resDetailsWithBranch?.name}</strong>
        </h2>
        <h2>
          Owner name:{" "}
          <strong>
            {resDetailsWithBranch?.owner?.first_name}{" "}
            {resDetailsWithBranch?.owner?.last_name}
          </strong>
        </h2>
        <h2>
          Owner Phone number:{" "}
          <strong>{resDetailsWithBranch?.owner?.phone_number}</strong>
        </h2>
        <h2>
          Owner Email: <strong>{resDetailsWithBranch?.owner?.email}</strong>
        </h2>
        <h2>
          Description: <strong>{resDetailsWithBranch?.description}</strong>
        </h2>
        <h2>Status: {resDetailsWithBranch?.status ? "True" : "False"}</h2>
        <div className="flex justify-center my-3">
          <Link
            to={
              vendor
                ? `/vendor/restaurant/edit/${resDetailsWithBranch.id}`
                : `/admin/restaurant/edit/${resDetailsWithBranch.id}`
            }
          >
            <button className="rounded py-2 px-5 text-white mr-2 bg-yellow-400">
              Edit
            </button>
          </Link>

          {resDetailsWithBranch?.status ? (
            <button
              onClick={() =>
                dispatch(adminRestaurantStatusChange(resDetailsWithBranch?.id))
              }
              className="rounded py-2 px-5 mr-2 text-white  bg-green-600"
            >
              Inactive
            </button>
          ) : (
            <button
              onClick={() =>
                dispatch(adminRestaurantStatusChange(resDetailsWithBranch?.id))
              }
              className="rounded py-2 px-5 mr-2 text-white  bg-red-600"
            >
              Active
            </button>
          )}
          {vendor ? null : (
            <button
              onClick={() => {
                handleDeleteClick(resDetailsWithBranch?.id);
              }}
              className="rounded py-2 px-5 text-white  bg-red-600"
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantInfo;
