import React, { useEffect, useState } from "react";
import { BiCategory, BiFoodMenu } from "react-icons/bi";
import { FaStar, FaUsers } from "react-icons/fa";
import { IoFastFoodOutline, IoRestaurant } from "react-icons/io5";
import {
    MdArrowDropDown,
    MdArrowLeft,
    MdDashboard,
    MdMenuOpen,
    MdOutlineSettingsApplications,
} from "react-icons/md";
import { RiCloseFill, RiCoupon3Line } from "react-icons/ri";
import { SiGithubsponsors } from "react-icons/si";

import "./Sidebar.css";

import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({ closeBtnHandler }) => {
  const [isManageUserOpen, setIsManageUserOpen] = useState(false);
  const [isManageRestaurentListOpen, setIsManageRestaurentListOpen] =
    useState(false);
  const [isMenuCategoriesOpen, setIsMenuCategoriesOpen] = useState(false);
  const [isMenuItemsOpen, setIsMenuItemsOpen] = useState(false);
  // const [isRestaurentBranchOpen, setISRestaurentBranchOpen] = useState(false);
  const [isMenuVariationsOpen, setIsMenuVariationsOpen] = useState(false);
  const [isFoodIngredientsOpen, setIsFoodIngredientsOpen] = useState(false);
  const [isOrderOpen, setIsOrderOpen] = useState(false);
  const [isDriverOpen, setIsDriverOpen] = useState(false);
  const [isVoucherOpen, setIsVoucherOpen] = useState(false);
  const [isSponsorOpen, setIsSponsorOpen] = useState(false);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isAppSettingsOpen, setIsAppSettingsOpen] = useState(false);
  const location = useLocation();
  // console.log(location);
  // console.log(isCloseBtnOpen);
  // let activeClassName = "activeLink";

  useEffect(() => {
    let menuButton = document.querySelector("#dropdown-example");
    let menuButton2 = document.querySelector("#dropdown-example2");
    menuButton.addEventListener("click", () => {
      menuButton2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageRestaurantBtn = document.querySelector("#manageRestaurantBtn");
    let manageRestaurantBtn2 = document.querySelector("#manageRestaurantBtn2");
    manageRestaurantBtn.addEventListener("click", () => {
      manageRestaurantBtn2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageMenuCategories = document.querySelector("#manageMenuCategories");
    let manageMenuCategories2 = document.querySelector(
      "#manageMenuCategories2"
    );
    manageMenuCategories.addEventListener("click", () => {
      manageMenuCategories2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageMenuVariations = document.querySelector("#manageMenuVariations");
    let manageMenuVariations2 = document.querySelector(
      "#manageMenuVariations2"
    );
    manageMenuVariations.addEventListener("click", () => {
      manageMenuVariations2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageMenuItems = document.querySelector("#manageMenuItems");
    let manageMenuItems2 = document.querySelector("#manageMenuItems2");
    manageMenuItems.addEventListener("click", () => {
      manageMenuItems2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageFoodIngredients = document.querySelector(
      "#manageFoodIngredients"
    );
    let manageFoodIngredients2 = document.querySelector(
      "#manageFoodIngredients2"
    );
    manageFoodIngredients.addEventListener("click", () => {
      manageFoodIngredients2.classList.toggle("show");
    });
  }, []);

  useEffect(() => {
    let manageOrder = document.querySelector("#manageOrder");
    let manageOrder2 = document.querySelector("#manageOrder2");
    manageOrder.addEventListener("click", () => {
      manageOrder2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageDriver = document.querySelector("#manageDriver");
    let manageDriver2 = document.querySelector("#manageDriver2");
    manageDriver.addEventListener("click", () => {
      manageDriver2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageVoucher = document.querySelector("#manageVoucher");
    let manageVoucher2 = document.querySelector("#manageVoucher2");
    manageVoucher.addEventListener("click", () => {
      manageVoucher2.classList.toggle("show");
    });
  }, []);

  useEffect(() => {
    let manageSponsors = document.querySelector("#manageSponsors");
    let manageSponsors2 = document.querySelector("#manageSponsors2");
    manageSponsors.addEventListener("click", () => {
      manageSponsors2.classList.toggle("show");
    });
    let manageRevirew = document.querySelector("#manageRevirew");
    let manageRevirew2 = document.querySelector("#manageRevirew2");
    manageRevirew.addEventListener("click", () => {
      manageRevirew2.classList.toggle("show");
    });
  }, []);

  useEffect(() => {
    let manageApplicationSettings = document.querySelector(
      "#manageApplicationSettings"
    );
    let manageApplicationSettings2 = document.querySelector(
      "#manageApplicationSettings2"
    );
    manageApplicationSettings.addEventListener("click", () => {
      manageApplicationSettings2.classList.toggle("show");
    });
  }, []);

  return (
    <aside
      className="tracking-wide h-screen bg-gray-800 font-light overflow-auto"
      aria-label="Sidebar"
    >
      <div className=" py-3 text-gray-300 dark:bg-gray-200">
        <div className="flex justify-end text-xl lg:hidden ">
          <button>
            <RiCloseFill onClick={closeBtnHandler} className="mr-2  text-xl" />
          </button>
        </div>

        <ul className="space-y-2 ">
          <li className="mx-6">
            <NavLink
              to=""
              className="p-4  dark:text-white dark:hover:bg-gray-700"
            >
              <span className="text-xl tracking-wider font-light">
                British Marketplace
              </span>
            </NavLink>
          </li>
          <hr className="mx-0" />
          <li>
            <NavLink
              to="/admin"
              className="flex items-center p-2 text-base font-normal  hover:text-[#0DC1D9] dark:text-white dark:hover:bg-gray-700"
            >
              <MdDashboard className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">Dashboard</span>
            </NavLink>
          </li>
          <hr className="mx-0" />
          {/* manage users */}
          <li>
            <button
              id="dropdown-example"
              type="button"
              onClick={() => {
                setIsManageUserOpen(!isManageUserOpen);
              }}
              className="flex items-center py-1 px-3  hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <FaUsers className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Users
              </span>
              {isManageUserOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isManageUserOpen ? ( */}
            {/* // || location.pathname === "/admin/userList" */}

            <ul
              id="dropdown-example2"
              className={
                location.pathname === "/admin/user-list" ||
                location.pathname === "/admin/new-users" ||
                location.pathname === "/admin/vendor/request"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li className="">
                <NavLink
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                  to="/admin/user-list"
                >
                  {" "}
                  User List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/new-users"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  New Users
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/vendor/request"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  New Vendor Request
                </NavLink>
              </li>
            </ul>
            {/* ) : null}  */}
          </li>
          {/* manage Restaurant list*/}
          <li>
            <button
              id="manageRestaurantBtn"
              type="button"
              onClick={() =>
                setIsManageRestaurentListOpen(!isManageRestaurentListOpen)
              }
              className="flex items-center py-1 px-3 text-base hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <IoRestaurant className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Restaurant
              </span>
              {isManageRestaurentListOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>

            {/* {isManageRestaurentListOpen ? ( */}
            <ul
              id="manageRestaurantBtn2"
              className={
                location.pathname === "/admin/add-restaurant" ||
                location.pathname === "/admin/restaurant-list" ||
                location.pathname === "/admin/add-restaurant-categories" ||
                location.pathname === "/admin/add-restaurant-branches"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/restaurant-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Restaurant List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-restaurant"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Add Restaurant
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-restaurant-categories"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Add Restaurant Categories
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-restaurant-branches"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Add Restaurant Branches
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* menu categories*/}
          <li>
            <button
              id="manageMenuCategories"
              type="button"
              onClick={() => {
                setIsMenuCategoriesOpen(!isMenuCategoriesOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <BiCategory className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Menu Categories
              </span>
              {isMenuCategoriesOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isMenuCategoriesOpen ? ( */}
            <ul
              id="manageMenuCategories2"
              className={
                location.pathname === "/admin/menu-categories-list" ||
                location.pathname === "/admin/add-menu-categories"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/menu-categories-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Menu Categories List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-menu-categories"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Menu Categories
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* menu items*/}
          <li>
            <button
              id="manageMenuItems"
              type="button"
              onClick={() => {
                setIsMenuItemsOpen(!isMenuItemsOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <IoFastFoodOutline className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Menu Items
              </span>
              {isMenuItemsOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isMenuItemsOpen ? ( */}
            <ul
              id="manageMenuItems2"
              className={
                location.pathname === "/admin/menu-items-list" ||
                location.pathname === "/admin/add-menu-items"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/menu-items-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Menu Items List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-menu-items"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Menu Items
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* menu variations */}
          <li>
            <button
              id="manageMenuVariations"
              type="button"
              onClick={() => {
                setIsMenuVariationsOpen(!isMenuVariationsOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <BiFoodMenu className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Menu Variations
              </span>
              {isMenuVariationsOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isMenuVariationsOpen ? ( */}
            <ul
              id="manageMenuVariations2"
              className={
                location.pathname === "/admin/menu-variations-list" ||
                location.pathname === "/admin/add-menu-variations"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/menu-variations-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Menu Variations List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-menu-variations"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Menu Variations
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* food ingredients */}
          <li>
            <button
              id="manageFoodIngredients"
              type="button"
              onClick={() => {
                setIsFoodIngredientsOpen(!isFoodIngredientsOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <BiFoodMenu className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Food Ingredients
              </span>
              {isFoodIngredientsOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            <ul
              id="manageFoodIngredients2"
              className={
                location.pathname === "/admin/food-ingredients-list" ||
                location.pathname === "/admin/add-food-ingredients"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/food-ingredients-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Food Ingredients List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-food-ingredients"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Food Ingredients
                </NavLink>
              </li>
            </ul>
          </li>
          {/* order */}
          <li>
            <button
              id="manageOrder"
              type="button"
              onClick={() => {
                setIsOrderOpen(!isOrderOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <MdMenuOpen className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Order
              </span>
              {isOrderOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isOrderOpen ? ( */}
            <ul
              id="manageOrder2"
              className={
                location.pathname === "/admin/order-list" ||
                location.pathname === "/admin/customer-payment-info" ||
                location.pathname === "/admin/add-order"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/order-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Order List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/customer-payment-info"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Customer Payment Information
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-order"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Add Order
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>

          {/* driver */}
          <li>
            <button
              id="manageDriver"
              type="button"
              onClick={() => {
                setIsDriverOpen(!isDriverOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <MdMenuOpen className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Driver
              </span>
              {isDriverOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isOrderOpen ? ( */}
            <ul
              id="manageDriver2"
              className={
                location.pathname === "/admin/driver-list" ||
                location.pathname === "/admin/customer-payment-info" ||
                location.pathname === "/admin/add-order"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/driver-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Driver List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/customer-payment-info"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Customer Payment Information
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-order"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Add Order
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* voucher */}
          <li>
            <button
              id="manageVoucher"
              type="button"
              onClick={() => {
                setIsVoucherOpen(!isVoucherOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <RiCoupon3Line className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Voucher
              </span>
              {isVoucherOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isVoucherOpen ? ( */}
            <ul
              id="manageVoucher2"
              className={
                location.pathname === "/admin/voucher-list" ||
                location.pathname === "/admin/customer-used-voucher" ||
                location.pathname === "/admin/add-voucher"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/voucher-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Voucher List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/customer-used-voucher"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Customer Used Voucher
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-voucher"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Voucher
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* sponsor */}
          <li>
            <button
              id="manageSponsors"
              type="button"
              onClick={() => {
                setIsSponsorOpen(!isSponsorOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <SiGithubsponsors className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Sponsors
              </span>
              {isSponsorOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isSponsorOpen ? ( */}
            <ul
              id="manageSponsors2"
              className={
                location.pathname === "/admin/sponsors-list" ||
                location.pathname === "/admin/add-sponsor"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/sponsors-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Sponsors List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/add-sponsor"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Sponsor
                </NavLink>
              </li>
            </ul>
            {/* // ) : null} */}
          </li>
          {/* Review Rating */}
          <li>
            <button
              id="manageRevirew"
              type="button"
              onClick={() => {
                setIsReviewOpen(!isReviewOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <FaStar className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Review Rating
              </span>
              {isReviewOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isSponsorOpen ? ( */}
            <ul
              id="manageRevirew2"
              className={
                location.pathname === "/admin/review-rating"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/review-rating"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Review Rating List
                </NavLink>
              </li>
            </ul>
            {/* // ) : null} */}
          </li>
          {/* Application settings */}
          <li>
            <button
              id="manageApplicationSettings"
              type="button"
              onClick={() => {
                setIsAppSettingsOpen(!isAppSettingsOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <MdOutlineSettingsApplications className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Application Settings
              </span>
              {isAppSettingsOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isAppSettingsOpen ? ( */}
            <ul
              id="manageApplicationSettings2"
              className={
                location.pathname === "/admin/contact-us" ||
                location.pathname === "/admin/content-settings" ||
                location.pathname === "/admin/general-settings"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/admin/contact-us"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/content-settings"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Content Settings
                </NavLink>
              </li>
              <li>
                {/* <div className="flex items-center ">
                    <GoPrimitiveDot className=" " /> */}
                <NavLink
                  to="/admin/general-settings"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  General Settings
                </NavLink>
                {/* </div> */}
              </li>
            </ul>
            {/* ) : null} */}
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
