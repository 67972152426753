import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

function HomeNotificationList({ notificatinos }) {
  return (
    <div className="py-3">
      <span>Notification List</span>
      <table className="table-auto my-2 border-collapse custom-table-data">
        <thead>
          <tr className="bg-gray-700 text-white">
            <th className="custom-table-data">Sl</th>
            <th className="custom-table-data">Notification Type</th>
            <th className="custom-table-data">Description</th>
            <th className="custom-table-data">Customer Name</th>
            <th className="custom-table-data">Action</th>
          </tr>
        </thead>
        <tbody className="font-light">
          {notificatinos?.reverse()?.map((item, index) => (
            <tr>
              <td className="custom-table-data">{index + 1}</td>
              <td className="custom-table-data">{item.type}</td>
              <td className="custom-table-data">{item.message}</td>
              <td className="custom-table-data">
                {item.from_notification.first_name}{" "}
                {item.from_notification.last_name}
              </td>
              <td className="custom-table-data">
                <Link
                  to={`/vendor/order-details/${item?.order_number}`}
                  className="bg-green-600 rounded text-white p-1"
                >
                  Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default HomeNotificationList;
