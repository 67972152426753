import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const SponsorCreateUpdateForm = ({ edit, sponsor, onSubmit }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
    setValue,
  } = useForm({
    defaultValues: {},
  });
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: 32,
    }),
  };
  // console.log(watch("name"));
  const [imagePreview, setImagePreview] = useState(null);
  if (watch("image")?.length > 0) {
    try {
      let watchImage = watch("image");
      // console.log(watchImage[0]);
      const reader = new FileReader();
      // console.log(reader);
      reader.readAsDataURL(watchImage[0]);
      reader.onload = function () {
        setImagePreview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }
  //   console.log(watch("name"));
  useEffect(() => {
    if (sponsor) {
      console.log("enter...............");
      setValue("name", sponsor?.name);
      setValue("status", sponsor?.status);
    }
  }, [sponsor]);

  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">{edit ? "Edit" : "Add"} sponsor</h1>
      <div>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="md:grid md:grid-cols-1 md:gap-3 pt-2">
            <div className="md:w-1/3">
              <label htmlFor="name" className=" text-gray-700">
                Name
              </label>

              <input
                type="text"
                name="name"
                {...register("name", {
                  required: edit ? false : "This field is required",
                })}
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.name?.message}</p>
            </div>
            <div className="block sm:my-1">
              <label htmlFor="image" className=" text-gray-700">
                Image
              </label>
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              {!imagePreview && sponsor ? (
                <img
                  src={sponsor.image}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              <input
                {...register("image", {
                  required: edit ? "" : "Image is required",
                  message: "Image is required",
                })}
                type="file"
                name="image"
                id="image"
                className="mt-1  block w-full border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.image?.message}</p>
            </div>{" "}
            <div className="form-check flex items-center my-1">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="flexCheckChecked"
                {...register("status")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="flexCheckChecked"
              >
                Status
              </label>
            </div>
            <div className=" mt-2">
              <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SponsorCreateUpdateForm;
