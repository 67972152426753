import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { searchRestaurant } from "../../redux/actions/restaurantAction";

function getLatLngFromLocalStorage() {
  return localStorage.getItem("latlng") ? localStorage.getItem("latlng") : "";
}

function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: "bold" }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
}

const SuggestionInput = () => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState("");
  const urlParams = queryString.parse(window.location.search);

  const { resSuggestion } = useSelector((state) => state.restaurant);

  const history = useHistory();

  // console.log(filteredSuggestions);

  const onChange = (e) => {
    const userInput = e.currentTarget.value;
    setUserInput(userInput);
    setShowSuggestions(true);
  };

  const handleTopSearchClick = (value) => {
    setUserInput(value);
  };

  const handleCategoryClick = (value) => {
    history.push({
      pathname: history.location.pathname,
      search: `?lat=${urlParams.lat}&lng=${urlParams.lng}&categories=${value}`,
    });
    setShowSuggestions(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    let data = getLatLngFromLocalStorage();
    let formateArr = data.split(",");
    let res = Object.assign(
      {},
      {
        lat: formateArr[0],
        lng: formateArr[1],
      }
    );
    dispatch(searchRestaurant({ lat: res.lat, lng: res.lat, s: userInput }));
    // const timeOutId = setTimeout(async () => {

    // }, 500);
    // return () => clearTimeout(timeOutId);
  }, [userInput, dispatch]);

  const diffMoment = (min, max) => {
    var minTime = moment(min);
    var maxTime = moment(max);
    return maxTime.diff(minTime);
  };

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (resSuggestion?.food.length > 0 || resSuggestion?.res.length > 0) {
      suggestionsListComponent = (
        <div>
          <h4 className="text-black lg:text-base text-sm font-medium pt-4 px-3">
            Categories
          </h4>
          <ul className="mt-4">
            {resSuggestion.category.length > 0 ? (
              resSuggestion.category.map((item) => (
                <li
                  key={item.id}
                  className="flex flex-wrap items-center gap-2 py-1 px-3 group sm:text-base text-sm hover:bg-[#EEFFEE] cursor-pointer"
                  onClick={() => handleCategoryClick(item.name)}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-[#464956]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </span>
                  <span className="cursor-pointer">
                    {getHighlightedText(item.name, userInput)}
                  </span>
                </li>
              ))
            ) : (
              <em className="px-3">No suggestions, you're on your own!</em>
            )}
          </ul>
          <h4 className="text-black lg:text-base text-sm font-medium pt-4 px-3">
            Trending Restaurants
          </h4>
          <ul className="mt-4">
            {resSuggestion.res.length > 0 ? (
              // filteredSuggestions.res.map((res) => <p>{res.name}</p>)
              resSuggestion.res.map((res) => (
                <li className="py-1 group hover:bg-[#EEFFEE]" key={res.id}>
                  <Link to={`/restaurant/${res.id}`}>
                    <div className="flex flex-wrap gap-4 px-3">
                      <div className="pr-thumb w-14 h-14">
                        <img
                          src={res.image}
                          alt={res.name}
                          className="w-full h-full"
                        />
                      </div>
                      <div className="pr-text">
                        <h6 className="text-black font-normal text-sm pb-1">
                          {/* {res.name} */}
                          {getHighlightedText(res.name, userInput)}
                        </h6>
                        <div className="flex items-center gap-2 divide-x">
                          <div className="flex gap-2 items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-[#FF953A]"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <span className="text-xs text-[#464646]">4.5</span>
                          </div>
                          <span className="text-xs text-[#a0a0a0] pl-2">
                            Around{" "}
                            {diffMoment(
                              res.min_delivery_time,
                              res.max_delivery_time
                            )}{" "}
                            mins
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            ) : (
              <em className="px-3">No suggestions, you're on your own!</em>
            )}
          </ul>
          <h4 className="text-black lg:text-base text-sm font-medium px-3">
            Items
          </h4>
          <ul className="mt-2">
            {resSuggestion.food.length > 0 ? (
              resSuggestion.food.map((res, index) => {
                return (
                  <li
                    key={index}
                    className="py-1 group hover:bg-[#EEFFEE]"
                    // className="border-gray-300 py-3 px-2 group hover:bg-[#EEFFEE]"
                  >
                    <Link to={`/restaurant/${res.restaurant_id}`}>
                      <div className="flex flex-wrap gap-4 px-3">
                        <div className="pr-thumb w-14 h-14">
                          <img
                            src={res.image}
                            alt={res.name}
                            className="w-full h-full"
                          />
                        </div>
                        <div className="pr-text">
                          <h6 className="text-black font-normal text-sm pb-1">
                            {getHighlightedText(res.name, userInput)}
                          </h6>
                          <div className="flex items-center gap-2 divide-x">
                            <span className="text-black text-xs">£3.49</span>
                            <span className="text-xs text-[#a0a0a0] pl-2">
                              served by {res.restaurant}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })
            ) : (
              <em className="px-3">No suggestions, you're on your own!</em>
            )}
          </ul>
        </div>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em className="px-3 pt-2">No suggestions, you're on your own!</em>
        </div>
      );
    }
  }

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  return (
    <div className="relative">
      <div className="resturant-search xl:min-w-[600px] lg:min-w-[300px] md:min-w-[400px] relative md:block hidden">
        <input
          type="text"
          name="res-search"
          onChange={onChange}
          onFocus={handleFocus}
          autoComplete="new-password"
          // onKeyDown={onKeyDown}
          value={userInput}
          className="bg-transparent w-full px-12 py-2 rounded-md border border-[#0DC1D9] placeholder:text-[#464956] placeholder:text-sm focus-visible:outline-[#028FF0] focus-visible:outline-search_outline"
          placeholder="Write some things"
        />
        <span className="absolute top-1/2 left-5 -translate-y-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-[#464956]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
      </div>

      {showSuggestions ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowSuggestions(false);
          }}
        >
          <div className="res-search-result absolute bg-white rounded-md mt-3 py-3 top-full left-0 w-full shadow-themeShadow z-50 md:block hidden max-h-[80vh] overflow-y-auto">
            <div className="px-3">
              <h4 className="text-black lg:text-base text-sm font-medium pb-3">
                Top Search


              </h4>
              <ul className="flex flex-wrap items-center gap-2">
                <li>
                  <button
                    type="button"
                    className="border border-black px-2 py-1 rounded text-black text-xs"
                    onClick={() => handleTopSearchClick("Dessert")}
                  >
                    Dessert
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="border border-black px-2 py-1 rounded text-black text-xs"
                    onClick={() => handleTopSearchClick("Sushi")}
                  >
                    Sushi
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="border border-black px-2 py-1 rounded text-black text-xs"
                    onClick={() => handleTopSearchClick("Burger")}
                  >
                    Burger
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="border border-black px-2 py-1 rounded text-black text-xs"
                    onClick={() => handleTopSearchClick("Pizza")}
                  >
                    Pizza
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="border border-black px-2 py-1 rounded text-black text-xs"
                    onClick={() => handleTopSearchClick("KFC")}
                  >
                    KFC
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="border border-black px-2 py-1 rounded text-black text-xs"
                    onClick={() => handleTopSearchClick("BFC")}
                  >
                    BFC
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="border border-black px-2 py-1 rounded text-black text-xs"
                    onClick={() => handleTopSearchClick("Chicken burger")}
                  >
                    Chicken burger
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="border border-black px-2 py-1 rounded text-black text-xs"
                    onClick={() => handleTopSearchClick("Helty Food")}
                  >
                    Helty Food
                  </button>
                </li>
              </ul>
            </div>
            {suggestionsListComponent}
          </div>
        </OutsideClickHandler>
      ) : (
        ""
      )}
    </div>
  );
};

export default SuggestionInput;
