import React from "react";
import { Link as HashLink } from "react-scroll";
import loadingImg from "../../images/app/loading.gif";
const HomeMenuItem = (props) => {
  return (
    <HashLink
    activeClass="active"
    to={"search"}
    // isDynamic={true}
    spy={true}
    smooth={true}
    offset={-500}
    duration={500}
    onClick={() => {
      props.handleMenuClick(props?.name);
      // props.setInput1Focus();
    }}
    className="
                font-normal
                text-md text-primaryColor
                flex
                items-center
                cursor-pointer
              "
  >
    <div className="food-item text-center">
      <div className="item-thumb max-w-[270px] mx-2 lg:mx-4 sm:h-48 h-24">
    
        <img
          className=" w-full h-full px-2"
          // rounded-br-3xl rounded-tl-3xl  relative z-10
          src={props.loading ? loadingImg : props?.image}
          alt={props?.name}
        />
        {/* <div className="absolute h-full w-full -bottom-4 -left-4 border border-[#FF7D47] rounded-br-3xl rounded-tl-3xl"></div> */}
      </div>
    
        <div className="item-text pt-3 max-w-[250px] mx-auto">
          <h4 className="text-black text-base font-medium pb-1">
            {props?.name}
          </h4>
          <p className="text-gray-700 text-xs">
            Classic, comforting dishes that always hit the spot.
          </p>
        </div>
    </div>
      </HashLink>
  );
};

export default HomeMenuItem;
