import {
  PLACE_ORDER_SUCCESS,
  GET_ORDER_SUMMERY,
  GET_ORDER_SUMMERY_LOADING,
  SUBMIT_PAYMENT_CARD_SUCCESS,
  FETCH_ORDER_HISTORY,
  LOADING_ORDER,
  GET_VOUCHER_DISCOUNT,
  GET_VOUCHER_DISCOUNT_ERROR,
  GET_ORDER_TRACK,
} from "../types";

function getDiscountFromSessionStorage() {
  return sessionStorage.getItem("discount_amount")
    ? sessionStorage.getItem("discount_amount")
    : "";
}

const initialState = {
  loading: false,
  order_summery: [],
  order_history: [],
  order_track: {},
  order_success: false,
  discount_amount: getDiscountFromSessionStorage(),
  discount_amount_error: "",
  shaKey: "",
};

export const OrderReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        order_success: true,
        loading: false,
        discount_amount: 0,
      };

    case GET_ORDER_SUMMERY_LOADING:
      return { ...state, loading: true };

    case LOADING_ORDER:
      return { ...state, loading: true };

    case GET_ORDER_SUMMERY:
      return { ...state, order_summery: action.payload, loading: false };

    case GET_ORDER_TRACK:
      return { ...state, order_track: action.payload, loading: false };

    case FETCH_ORDER_HISTORY:
      return { ...state, order_history: action.payload, loading: false };

    case SUBMIT_PAYMENT_CARD_SUCCESS:
      return { ...state, shaKey: action.payload, loading: false };
    case GET_VOUCHER_DISCOUNT:
      return {
        ...state,
        discount_amount: action.payload,
        loading: false,
        discount_amount_error: "",
      };
    case GET_VOUCHER_DISCOUNT_ERROR:
      return {
        ...state,
        discount_amount_error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
