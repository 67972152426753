import React from "react";
// import bannerImage from "../../images/app/delivery.png";
import { Link } from "react-router-dom";
import img1 from "../../images/app/01.png";
import img3 from "../../images/app/03.png";
import img4 from "../../images/app/04.png";
import img5 from "../../images/app/05.png";
import img2 from "../../images/app/22.png";

const HomePrimaryBanner = () => {
  return (
    <section className="lg:py-0 mt-10">
      {/* delivery-man relative*/}
      <div className="container">
      <div className="grid grid-flow-row lg:grid-cols-12 grid-cols-8 gap-4 ">
     
      <div className="lg:row-span-2 lg:col-span-3 col-span-4 relative max-h-[416px] cursor-pointer">
   <Link to={`/vendor/registration`}>
      <img src={img1} alt="not available" className="w-full h-full rounded-lg  shadow-[0px_0px_7px_rgba(46,49,146,0.14)] "/>
      <div className="absolute  top-0 left-3  pt-4">
    <h2 className="text-black font-medium xl:text-2xl lg:text-xl md:text-2xl text-base ">Become a Home made food partner</h2>
    <p className="text-[#4A4A4A] xl:text-sm text-xs">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
   <button
                type="button"
                className="md:px-4 px-3 md:py-2 py-1 xl:text-sm text-xs shadow-[0px_1px_7px_rgba(46,49,146,0.13)] rounded-md uppercase mt-3 bg-gradient-to-r from-[#028FF0] to-[#0DC1D9] text-white"
              >
                Apply Now
              </button>
  </div>
   </Link>
      </div>
      <div className="lg:row-span-2 lg:col-span-3 col-span-4 relative  max-h-[416px] cursor-pointer">
      <Link to={`/vendor/registration`}>
      <img src={img2} alt="not available" className="w-full h-full rounded-lg shadow-[0px_0px_7px_rgba(46,49,146,0.14)]"/>
    
      <div className="absolute bottom-3 xl:left-5 left-3 text-white">
      <h2 className="font-medium xl:text-2xl lg:text-lg md:text-2xl text-base drop-shadow-[0px_4px_4px_rgba(0,0,0,0.14)]">Become a Restaurant partner</h2>
    <p className="text-white  xl:text-sm text-xs drop-shadow-2xl">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
   
   <div className="text-end pr-3">
   <button
                type="button"
                className="md:px-4 px-3 md:py-2 py-1 xl:text-sm text-xs shadow-[0px_1px_7px_rgba(46,49,146,0.13)] rounded-md uppercase mt-2 bg-gradient-to-r from-[#028FF0] to-[#0DC1D9] text-white"
              >
                Apply Now
              </button>
   </div>
  </div>
   </Link>

      </div>
     
      <div className=" lg:col-span-6 col-span-8 relative max-h-[200px] cursor-pointer">
        <Link to={`/driver/registration`}>
      <img src={img3} alt="not available" className="w-full h-full rounded-lg  "/>
      <div className="absolute xl:top-3 lg:top-1 md:top-3 top-1 left-3">
    <h2 className="text-white font-medium xl:text-2xl lg:text-lg md:text-2xl text-base drop-shadow-[0px_4px_4px_rgba(0,0,0,0.14)]">When you want to be the Delivery man
    <br /> Applying online is easy
</h2>
    <p className="text-[#B7E4FF] xl:text-sm text-xs drop-shadow-2xl">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    <button
                type="button"
                className="md:px-4 px-3 md:py-2 py-1 xl:text-sm text-xs shadow-themeShadow  rounded-md uppercase mt-1 bg-gradient-to-r from-[#028FF0] to-[#0DC1D9] text-white"
                >
                Apply Now
              </button>
  </div>
                </Link>
      </div>
     
      <div className="lg:col-span-3 col-span-4 relative max-h-[200px] cursor-pointer">
        <Link onClick={()=>window.open(`https://business.britishmarketplace.co.uk/login`)}>
      <img src={img4} alt="not available " className="w-full h-full rounded-lg "/>
      <div className="absolute xl:top-10 lg:top-3 md:top-8 top-0 xl:left-48 lg:left-24 md:left-48 left-24">
   <div className="text-center">
   <h2 className="text-white font-medium xl:text-xl lg:text-sm md:text-2xl text-sm drop-shadow-[0px_5px_4px_rgba(0,0,0,0.3)]">Business Opportunities with us.</h2>
    
    <button
                type="button"
                className=" md:px-3 px-2 lg:py-2 py-1 text-xs shadow-[0px_0px_10px_rgba(2,10,250,1)] rounded-md uppercase mt-1 bg-[#002138] text-white"
              >
                Apply Now
              </button>
   </div>
  </div>
   </Link>
      </div>
      <div className="lg:col-span-3 col-span-4 relative max-h-[200px] cursor-pointer">
        <Link onClick={()=>window.open(`https://business.britishmarketplace.co.uk/login`)}>

      <img src={img5} alt="not available " className="w-full h-full rounded-lg "/>
      <div className="absolute xl:top-10 lg:top-3 md:top-10 top-0 xl:left-44 lg:left-24 md:left-48 left-24">
      <div className="text-center">
   <h2 className="text-white font-medium xl:text-xl lg:text-sm md:text-2xl text-sm drop-shadow-[0px_5px_4px_rgba(0,0,0,0.3)]">Your Products Our Responsibilities</h2>
    
    <button
                type="button"
                className="md:px-3 px-2 lg:py-2 py-1 text-xs shadow-[0px_0px_10px_rgba(2,10,250,1)] rounded-md uppercase mt-1 bg-[#002138] text-white"
              >
                Apply Now
              </button>
   </div>
   
  </div>
        </Link>
      </div>
      
      
    </div>
   
      </div>
       
    </section>

  );
};

export default HomePrimaryBanner;
