import React from "react";
import { Tooltip } from "@material-tailwind/react";
// import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
// import { Link } from "react-router-dom";
import Pagination from "../../common/Pagination";

const CategoryList = ({
  resCategories,
  handlePageClick,
  search,
  handleSearch,
  handleDeleteClick,
  vendor,
}) => {
  return (
    <div className=" m-3 mt-5 mb-5">
      <p className="text-center">Restaurant Category List</p>
      <div className="flex items-center">
        {/* search bar */}
        <div className="relative md:w-1/3 my-2">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            value={search}
            onChange={handleSearch}
            type="text"
            id="simple-search"
            className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
            placeholder="Search here.."
          />
        </div>
        {/*  status select box*/}

        {/* react select */}
      </div>
      <div className="overflow-x-scroll">
        <table className="table-auto  w-full border-collapse custom-table-data my-2">
          <thead className="text-center">
            <tr className="bg-gray-800 text-gray-300 ">
              <th className="custom-table-data">Name</th>
              <th className="custom-table-data">Status</th>
              {vendor ? null : <th className="custom-table-data"> Action</th>}
            </tr>
          </thead>
          <tbody className="text-center">
            {resCategories?.data?.map((category) => (
              <tr key={category?.id}>
                <td className="custom-table-data">{category?.name}</td>
                <td className="custom-table-data">
                  {category?.status ? "True" : "False"}
                </td>
                <td className="custom-table-data text-xl">
                  {/* <Tooltip content="Edit">
                    <Link to={`/admin`}>
                      <button>
                        {" "}
                        <AiOutlineEdit className="mx-2 text-yellow-500" />
                      </button>
                    </Link>
                  </Tooltip> */}
                  {vendor ? null : (
                    <Tooltip content="Delete">
                      <button onClick={() => handleDeleteClick(category?.id)}>
                        <RiDeleteBin6Line className="mx-2 text-red-500" />
                      </button>
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        pageCount={
          resCategories?.meta?.last_page > 0
            ? resCategories?.meta?.last_page
            : 0
        }
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default CategoryList;
