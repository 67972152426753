import {
  VENDOR_ORDER_LIST,
  HOME_MADE_INGREDIENTS_LIST,
  VENDOR_ORDER_ERROR,
  VENDOR_ORDER_LOADING,
} from "../../types";
import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";

export const vendorOrderList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: VENDOR_ORDER_LOADING });
    let token = auth.getToken();
    axios
      .get(`${API_URL}/order/vendor/order/`, {
        params: {
          res_branch: data.res_branch,
          search: data.search,
          page: data.page,
          status: data.status,
        },
        headers: { Authorization: token },
      })
      .then((res) => dispatch({ type: VENDOR_ORDER_LIST, payload: res.data }))
      .catch((err) => {
        console.log(err);
        dispatch({ type: VENDOR_ORDER_ERROR, payload: err.response });
      });
  };

export const vendorHomeMadeIngredients = (id) => async (dispatch) => {
  dispatch({ type: VENDOR_ORDER_LOADING });
  let token = auth.getToken();
  axios
    .get(`${API_URL}/order/order-home-made-ingredients/vendor/`, {
      params: {
        order_items: id,
      },
      headers: { Authorization: token },
    })
    .then((res) =>
      dispatch({ type: HOME_MADE_INGREDIENTS_LIST, payload: res.data })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: VENDOR_ORDER_ERROR, payload: err.response });
    });
};
