import React from "react";
import { Link } from "react-router-dom";

const OrderSummery = ({
  cart,
  delivery_charge,
  serviceFee,
  newTotalWithPrice,
  discount_amount,
  ex_sum,
  newHomeMadeWithPrice3,
}) => {
  return (
    <div className="md:w-3/12 sticky self-start" style={{ top: "50px" }}>
      <div className="order-summery bg-white p-4 rounded-md">
        <div className="flex items-center pb-6">
          <i>
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 38.1"
              className="h-5 w-5 fill-[#facd1b]"
            >
              <path
                className="cls-1"
                d="M42.51,33.51H16.24a1.3,1.3,0,0,1,0-2.59H41.47l.83-3.8H14.84a1.29,1.29,0,0,1-1.26-1l-1.4-6.39a1.28,1.28,0,0,1,.26-1.08,1.26,1.26,0,0,1,1-.48H44.27l.83-3.8H12a1.3,1.3,0,0,1-.91-.38l-6-6A1.48,1.48,0,0,0,3,10.07,1.29,1.29,0,0,1,1.19,11.9,4.07,4.07,0,0,1,4.07,5,4,4,0,0,1,7,6.14l5.63,5.63H46.71A1.31,1.31,0,0,1,48,13.34l-1.4,6.38a1.29,1.29,0,0,1-1.26,1H15.05l.83,3.8h28a1.32,1.32,0,0,1,1,.48,1.3,1.3,0,0,1,.25,1.09l-1.4,6.38A1.29,1.29,0,0,1,42.51,33.51Z"
                transform="translate(0 -4.95)"
              />
              <path
                className="cls-1"
                d="M22.39,43.05A3.61,3.61,0,1,1,26,39.44,3.61,3.61,0,0,1,22.39,43.05Zm0-5a1.39,1.39,0,1,0,1.39,1.39A1.39,1.39,0,0,0,22.39,38.05Z"
                transform="translate(0 -4.95)"
              />
              <path
                className="cls-1"
                d="M37.63,43.05a3.61,3.61,0,1,1,3.61-3.61A3.61,3.61,0,0,1,37.63,43.05Zm0-5A1.39,1.39,0,1,0,39,39.44,1.39,1.39,0,0,0,37.63,38.05Z"
                transform="translate(0 -4.95)"
              />
            </svg>
          </i>
          <h4 className="text-lg text-black font-semibold ml-2">Basket</h4>
        </div>
        <ul className="list-none">
          {cart.length > 0
            ? cart.map((cartItem, index) => (
                <li key={index} className=" my-4  ">
                  <div className="flex justify-between items-center mb-2">
                    <p className="text-black font-normal text-sm">
                      {cartItem.quantity} x {cartItem.name}
                    </p>
                    <p className="text-black font-normal text-sm">
                      £{cartItem.total_price}
                    </p>
                  </div>
                  {cartItem?.home_made.map((item) => (
                    <span
                      className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                      key={item.id}
                    >
                      <div>{item.name}</div>
                      <div>{item.price}</div>
                      <Link
                        to={{
                          pathname: "/food/ingredients/",
                          search: `?search=${item.name}`,
                          state: {
                            cartItem: cartItem,
                            item_id: item.id,
                          },
                        }}
                      >
                        Change
                      </Link>
                    </span>
                  ))}
                  {cartItem?.home_made_change.map((item) => (
                    <span
                      className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                      key={item.product.id}
                    >
                      <div>{item.product.name}</div>
                      <div>{item.product.price}</div>
                    </span>
                  ))}
                </li>
              ))
            : ""}

          {/* <li className="flex justify-between items-center my-4 ">
            <p className="text-black font-normal text-sm">1x Hamburger</p>
            <p className="text-black font-normal text-sm">£8.75</p>
          </li>
          <li className="flex justify-between items-center my-4  ">
            <p className="text-black font-normal text-sm">1x Bacob Burger</p>
            <p className="text-black font-normal text-sm">£10.95</p>
          </li> */}
          {cart.length > 0 ? (
            <>
              <li className="flex justify-between items-center my-4 pt-4 border-t">
                <p className="text-gray-400 font-normal text-sm">Subtotal</p>
                <p className="text-gray-400 font-normal text-sm">
                  £
                  {(newTotalWithPrice + ex_sum + newHomeMadeWithPrice3).toFixed(
                    2
                  )}
                </p>
              </li>
              <li className="flex justify-between items-center my-4">
                <p className="text-gray-400 font-normal text-sm">
                  Delivery fee
                </p>
                <p className="text-gray-400 font-normal text-sm">
                  £{delivery_charge}
                </p>
              </li>
              <li className="flex justify-between items-center my-4 pb-4 border-b">
                <p className="text-gray-400 font-normal text-sm">Service fee</p>
                <p className="text-gray-400 font-normal text-sm">
                  £{serviceFee}
                </p>
              </li>
              {discount_amount && (
                <li className="flex justify-between items-center my-4 pb-4 border-b">
                  <p className="text-gray-400 font-normal text-sm">
                    Discount Amount
                  </p>
                  <p className="text-gray-400 font-normal text-sm">
                    £{parseInt(discount_amount) > 0 && discount_amount}
                  </p>
                </li>
              )}
              <li className="flex justify-between items-center">
                <div className=""></div>
                <p className="text-black-400 font-semibold text-sm">Total</p>
                <p className="text-black-400 font-semibold text-sm">
                  £
                  {(
                    newTotalWithPrice +
                    delivery_charge +
                    newHomeMadeWithPrice3 +
                    ex_sum +
                    serviceFee -
                    discount_amount
                  ).toFixed(2)}
                </p>
              </li>
            </>
          ) : (
            "Your Cart is empty"
          )}
        </ul>
      </div>
    </div>
  );
};

export default OrderSummery;
