import { useForm } from "react-hook-form";
import Rating from "react-rating";
import { useDispatch } from "react-redux";
import cryImg from "../../images/rating/cry.png";
import cry2Img from "../../images/rating/cry2.png";
import loveImg from "../../images/rating/love.png";
import love2Img from "../../images/rating/love2.png";
import sadImg from "../../images/rating/sad.png";
import sad2Img from "../../images/rating/sad2.png";
import smileImg from "../../images/rating/smile.png";
import smile2Img from "../../images/rating/smile2.png";
import thumbImg from "../../images/rating/thumb.png";
import thumb2Img from "../../images/rating/thumb2.png";
import { reviewRatingModal } from "../../redux/actions/commonActions";
import ModalContainer from "../common/ModalContainer";

const ReviewRating = ({
  modalIsOpen,
  closeModal,
  review,
  setreview,
  rating,
  setrating,
  ratingData,
}) => {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();

  const handleReviewText = (e) => {
    setreview(e.target.value);
  };
  const data = {
    review: review,
    rating: rating,
    restaurant_branch_id: ratingData.res_branch,
    order: ratingData.order_id,
  };
  const onSubmit = () => {
    dispatch(reviewRatingModal(data));
    closeModal();
  };

  return (
    <ModalContainer open={modalIsOpen}>
      <div className="bg-white lg:w-[600px] mx-auto  rounded-md">
        <div className="close_modal relative">
          <button
            onClick={closeModal}
            type="button"
            className="close_icon absolute top-2 right-2 bg-white bg-opacity-70 rounded-full h-[25px] w-[25px] leading-[25px]"
          >
            <svg
              width="10"
              height="10"
              className="mx-auto"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.22251 4.97678C6.38674 5.12602 6.50617 5.23794 6.62561 5.34986C7.43926 6.16318 8.25292 6.9765 9.06657 7.78981C9.35769 8.08082 9.4622 8.40913 9.32037 8.79713C9.12629 9.33437 8.45446 9.53583 7.97672 9.20752C7.89461 9.14783 7.81996 9.08068 7.75278 9.00606C6.87941 8.13305 6.0135 7.2675 5.14013 6.39449C5.09534 6.34972 5.05055 6.30495 4.9983 6.26018C4.94605 6.31241 4.90126 6.35718 4.85647 6.40195C3.96817 7.28989 3.07987 8.17782 2.19157 9.06575C1.90044 9.35676 1.56453 9.45376 1.18383 9.31199C0.646372 9.11052 0.45229 8.43898 0.788202 7.96889C0.840455 7.89428 0.907637 7.82712 0.97482 7.75997C1.84819 6.88696 2.7141 6.01395 3.58747 5.1484C3.63226 5.10363 3.69198 5.07378 3.75169 5.02155C3.67705 4.93947 3.63226 4.89471 3.58 4.84247C2.6917 3.95454 1.81087 3.07407 0.922567 2.1936C0.638908 1.90259 0.534402 1.56682 0.676231 1.17882C0.870314 0.64158 1.54214 0.440116 2.01988 0.768428C2.10199 0.828121 2.17664 0.895275 2.24382 0.969891C3.11719 1.8429 3.9831 2.70845 4.85647 3.58146C4.90126 3.62623 4.94605 3.671 4.9983 3.71577C5.05055 3.66354 5.09534 3.61877 5.14013 3.574C6.02843 2.68606 6.91673 1.79813 7.80503 0.910198C8.09616 0.619195 8.43207 0.522194 8.81277 0.663965C9.35023 0.865429 9.54431 1.53697 9.2084 2.00706C9.15615 2.08167 9.08896 2.14883 9.02178 2.21598C8.14841 3.08899 7.2825 3.962 6.40913 4.82755C6.36434 4.88724 6.30463 4.91709 6.22251 4.97678Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className="review_content px-6 py-8">
          <h4 className="text-black text-center text-base font-medium font-body mb-2">
            Review & Rating
          </h4>
          <h4 className="text-black text-center text-base font-medium font-body">
            {ratingData.res_branch_name}
          </h4>
          <form
            onSubmit={handleSubmit(onSubmit)}
            action=""
            className="text-center"
          >
            <div className=" flex flex-wrap justify-center gap-6 pt-6">
              <Rating
                stop={5}
                onClick={(rate) => {
                  setrating(rate);
                }}
                initialRating={rating}
                emptySymbol={[
                  <>
                    <img
                      src={cryImg}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />{" "}
                    very bad
                  </>,
                  <>
                    <img
                      src={sadImg}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    bad
                  </>,
                  <>
                    <img
                      src={smileImg}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    good
                  </>,
                  <>
                    <img
                      src={thumbImg}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    very good
                  </>,
                  <>
                    <img
                      src={loveImg}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    excellent
                  </>,
                ]}
                fullSymbol={[
                  <>
                    <img
                      src={cry2Img}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    very bad
                  </>,
                  <>
                    <img
                      src={sad2Img}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    bad
                  </>,
                  <>
                    <img
                      src={smile2Img}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    good
                  </>,
                  <>
                    <img
                      src={thumb2Img}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    very good
                  </>,
                  <>
                    <img
                      src={love2Img}
                      className="icon mx-4"
                      width={40}
                      height={40}
                    />
                    excellent
                  </>,
                ]}
              />
            </div>

            <textarea
              type="text"
              onChange={handleReviewText}
              rows="5"
              value={review}
              className="text-black text-sm shadow-themeShadow border-0 w-2/3 mt-6 rounded-md"
              placeholder="write message"
            ></textarea>
            <br />

            <button className="custom-btn px-10 bg-[#0DC1D9] text-white mt-6">
              Done
            </button>
          </form>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ReviewRating;
