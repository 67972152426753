import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import OrderSteps from "../components/order/OrderSteps";
import { fetchOrderTrack } from "../redux/actions/orderAction";
import Chat from "./Chat";
import Layout from "./Layout";

const OrderTrack = () => {
  const { order_track } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.auth);

  console.log(user);

  // const messagesEndRef = useRef(null);

  // const scrollToBottom = () => {
  //   return messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  // const [messages, setMessages] = useState([])
  // const [inputValue, setInputValue] = useState("")
  // const [isOpen, setIsOpen] = useState(false)

  // const handleOpen = () => {
  //   setIsOpen(true)
  // };

  // const handleClose = () => {
  //   setIsOpen(false)
  // };

  console.log(order_track);

  let params = useParams();

  const dispatch = useDispatch();

  // let client = new W3CWebSocket(
  //   "ws://127.0.0.1:8000/ws/chat/" + user?.user?.id + "/"
  // );

  // useEffect(() => {
  //   // scrollToBottom();

  // }, [client])

  // client.onopen = () => {
  //   console.log("WebSocket Client Connected");
  // };

  // client.onmessage = (message) => {
  //   const dataFromServer = JSON.parse(message.data);
  //   // const dataFromServer2 = JSON.parse(message);
  //   // console.log(dataFromServer2);
  //   console.log("got reply! ", dataFromServer.type);
  //   console.log("got reply! ", dataFromServer);
  //   if (dataFromServer) {
  //     setMessages([
  //       ...messages,
  //       {
  //         msg: dataFromServer.message,
  //         name: dataFromServer.username,
  //         id: dataFromServer.username,
  //       },
  //     ]);
  //     setIsOpen(true);
  //   }
  // };

  useEffect(() => {
    dispatch(fetchOrderTrack(params.orderId));
    const interval = setInterval(() => {
      dispatch(fetchOrderTrack(params.orderId));
      console.log("Logs every minute");
    }, 10000);

    return () => clearInterval(interval);
    // dispatch(fetchOrderTrack(params.orderId));
  }, [dispatch, params]);

  // const onButtonClicked = (e) => {
  //   e.preventDefault();
  //   console.log(inputValue);
  //   console.log(user?.user?.id);
  //   console.log(order_track?.restaurant_branch?.restaurant?.owner?.id);
  //   client.send(
  //     JSON.stringify({
  //       type: "message",
  //       // message: this.state.value,
  //       // username: this.state.name,
  //       message: inputValue,
  //       sent_by: user?.user?.id,
  //       send_to: order_track?.restaurant_branch?.restaurant?.owner?.id,
  //       thread_id: 1,
  //     })
  //   );
  //   setInputValue("")

  // };

  // console.log({messages});
  return (
    <Layout>
      <section className="traker-area relative">
        <div className="container">
          <div className="traking-content">
            <div className="text-center pb-12">
              <h3 className="text-black text-xl font-semibold">
                Track Your Order
              </h3>
              <h2 className="text-themeColor font-bold text-sm">ETA</h2>
              <h2 className="text-primaryColor font-bold text-xl">
                {order_track.delivery_time &&
                  moment(order_track.delivery_time).format("h:mm:ss a")}
              </h2>
              <p className="text-black font-normal text-sm">
                Preparing your Meal
              </p>
            </div>
            {(order_track?.order_status === "CREATED" ||
              order_track?.order_status === "APPROVED") && <OrderSteps />}
            {order_track?.order_status === "PROCESSING" && <OrderSteps step1 />}
            {order_track?.order_status === "DELIVERING" && (
              <OrderSteps step1 step2 />
            )}
            {order_track?.order_status === "DELIVERED" && (
              <OrderSteps step1 step2 step3 />
            )}
          </div>
          <Chat
            userId={user?.user?.id}
            ownerId={order_track?.restaurant_branch?.restaurant?.owner?.id}
          />
        </div>
        {/* <div id="center-text">
          <h2>ChatBox UI</h2>
          <p>Message send and scroll to bottom enabled </p>
        </div>
        <div
          onClick={handleOpen}
          id={isOpen ? "scale" : "chat-circle"}
          className="btn btn-raised"
        >
          <div id="chat-overlay"></div>
          <i className="material-icons">speaker_phone</i>
        </div> */}

        {/* <div className={isOpen ? "scale" : "chat-box"}>
          <div className="chat-box-header">
            ChatBot
            <span className="chat-box-toggle" onClick={handleClose}>
              <i className="material-icons">close</i>
            </span>
          </div>
          <div className="chat-box-body">
            <div className="chat-box-overlay"></div>
            <div className="chat-logs" id="chat-logs">
              {messages?.map((message) => {
                return (
                  <div
                    className={
                      message.id === user?.user?.id ? "chat-msg self" : "chat-msg other"
                    }
                  >
                    <span className="msg-avatar">
                      <img src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" />
                    </span>
                    <div className="cm-msg-text">{message.msg}</div>
                  </div>
                );
              })}
              <div ref={messagesEndRef} />
            </div>
          </div>
          <div className="chat-input">
            <form onSubmit={onButtonClicked}>
         
              <input
                type="text"
                id="chat-input"
                placeholder="Send a message..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button type="submit" className="chat-submit">
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div> */}
      </section>
    </Layout>
  );
};

export default OrderTrack;
