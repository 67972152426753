import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../pages/vendor/Layout/layout";
import { fetchChatusers } from "../../../redux/actions/commonActions";
import ChatComponent from "./chat/ChatComponent";

const ChatVendor = () => {
  const { allChatUsers, allMessages } = useSelector((state) => state.common);
  console.log(allMessages);
  const { user } = useSelector((state) => state.auth);
  console.log(allChatUsers);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchChatusers());
    const interval = setInterval(() => {
      dispatch(fetchChatusers());
      console.log("Logs every minute");
    }, 10000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Layout>
      <div className="chat_area">
        <div className="container mx-auto">
          <div className="flex antialiased md:p-0 p-4">
            <div className="flex flex-row h-full w-full overflow-x-hidden relative">
              <ChatComponent
                isChatOpen={isChatOpen}
                setIsChatOpen={setIsChatOpen}
                allChatUsers={allChatUsers}
                user={user}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChatVendor;
