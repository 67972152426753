import React from "react";

const FooterInfo = ({
  cutlerySwitch,
  setCutlerySwitch,
  termsSwitch,
  setTermsSwitch,
}) => {
  return (
    <>
      <div className="sm:flex flex-wrap items-center justify-between py-8">
        <div className="cs-left flex items-baseline w-full sm:w-3/4">
          <div className="cs-icon">
            <img src="../public/images/checkout/04.png" alt="" />
          </div>
          <div className="cs-text pl-4">
            <h4 className="text-base font-semibold text-black pb-1">Cutlery</h4>
            <p>
              The restaurant will add cutlery for you, if they have some
              available.
            </p>
          </div>
        </div>
        <label
          htmlFor="checked-toggle"
          className="relative inline-flex items-center mr-8 cursor-pointer"
        >
          <input
            type="checkbox"
            value=""
            id="checked-toggle"
            className="sr-only peer"
            onChange={() => setCutlerySwitch(!cutlerySwitch)}
            checked={cutlerySwitch ? true : false}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[#89e488]dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#89e488]"></div>
        </label>
      </div>
      <div className="cs-des sm:flex items-center justify-between bg-white shadow-xl rounded-lg p-4">
        <div className="cs-text w-full sm:w-10/12">
          <p className="text-sm font-normal">
            Our plant-based(PB) Meatless Meatballs, covered with our signature
            marinara sauce and topped with tasty Mozzarella-style
            plant-based(PB) slices.Our plant-based(PB) Meatless Meatballs,
            covered with our signature marinara sauce and topped with tasty
            Mozzarella-style plant-based(PB) slices. (PB)Our
            plant-based/vegetarian products are made using
            plant-based/vegetarian ingredients.
            {/* <a href="#0" className=" text-yellow-500">
              Here
            </a> */}
          </p>
        </div>
        <label
          htmlFor="checked-toggle2"
          className="relative inline-flex items-center mr-5 cursor-pointer"
        >
          <input
            type="checkbox"
            value=""
            id="checked-toggle2"
            className="sr-only peer"
            onChange={() => setTermsSwitch(!termsSwitch)}
            checked={termsSwitch ? true : false}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[#89e488]dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#89e488]"></div>
        </label>
        {/* <div className="cs-switch w-full sm:w-1/12">
          <div className="flex justify-start sm:justify-center p-10 pt-4 sm:p-0 sm:pt-0 sm:pl-8">
            <div className="form-check form-switch">
              <input
                className="form-check-input appearance-none w-12 -ml-10 rounded-full float-left h-6 align-top bg-no-repeat bg-contain bg-black focus:outline-none cursor-pointer shadow-sm"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={() => setTermsSwitch(!termsSwitch)}
                // checked
                checked={termsSwitch ? true : false}
              />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default FooterInfo;
