import React from "react";
import Modal from "react-modal";

// import { CloseIcon } from "../../components/overlay/styles";
// import { fadeIn, fadeInUp, slideInDown } from "react-animations";
// import * as S from "./style";

Modal.setAppElement("#root");

function ModalContainer(props) {
  return (
    <Modal
      isOpen={props.open}
      onRequestClose={props.closeModal}
      overlayClassName="modal-overlay"
      contentLabel="Example Modal"
      className={"modal"}
      shouldCloseOnOverlayClick={true}
    >
      {props.children}
    </Modal>
  );
}

export default ModalContainer;
