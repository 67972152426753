import {
  ADMIN_SPONSOR_LOADING,
  ADMIN_SPONSOR_LIST,
  ADMIN_SPONSOR_DETAILS,
  ADMIN_SPONSOR_ERROR,
} from "../../types";

const initialState = {
  loading: false,
  sponsorsList: [],
  sponsorDetails: {},
  error: null
};
const adminSponsorReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADMIN_SPONSOR_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADMIN_SPONSOR_LIST:
      return {
        ...state,
        loading: false,
        sponsorsList: action.payload,
        error: null,
      };
    case ADMIN_SPONSOR_DETAILS:
      return {
        ...state,
        loading: false,
        sponsorDetails: action.payload,
        error: null,
      };
    case ADMIN_SPONSOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default adminSponsorReducer;
