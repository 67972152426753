import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

const customStyles = {
  input: (provided, state) => ({
    ...provided,
    height: 32,
  }),
};

function RestaurantCreateUpdateForm({
  adminLimitUserList,
  handleSubmitData,
  resDetails,
  edit,
  vendor,
}) {
  const [userOption, setUserOption] = useState([]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (resDetails) {
      console.log(resDetails.status);
      setValue("name", resDetails.name);
      // setValue("first_name", resDetails.first_name);
      setValue("description", resDetails.description);
      setValue("status", resDetails.status);
      if (resDetails.owner && userOption.length > 0) {
        let a = userOption.find(
          (item) => parseInt(item.value) === parseInt(resDetails.owner.id)
        );
        console.log(resDetails.owner.id);
        console.log(userOption);
        console.log(a);
        setValue("owner", { value: a.value, label: a.label });
      }

      // setValue("roleSelected", a.value);
      // setRoleSelected({ value: a.value, label: a.label });
      // setRoleSelected({ value: userDetails.role });
    }
  }, [resDetails, setValue, userOption]);

  const [imagePreview, setimagePreview] = useState("");

  if (watch("image")?.length > 0) {
    try {
      let o = watch("image");
      console.log(o[0]);
      const reader = new FileReader();
      console.log(reader);
      reader.readAsDataURL(o[0]);
      reader.onload = function () {
        setimagePreview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    let option = [];
    if (adminLimitUserList?.length > 0) {
      adminLimitUserList
        .filter((i) => i.identifier !== "")
        .map((item) =>
          // setValue("owner", { value: item.id, label: item.identifier })
          option.push({ value: item.id, label: item.identifier })
        );
      setUserOption(option);
    }
  }, [adminLimitUserList, setValue]);

  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">
        {edit ? "Edit Restaurant" : "Add Restaurant"}
      </h1>
      <div>
        <form action="" onSubmit={handleSubmit(handleSubmitData)}>
          <div className="md:grid md:grid-cols-3 md:gap-3 pt-2">
            <div className="">
              <label htmlFor="name" className=" text-gray-700">
                Name
              </label>

              <input
                type="text"
                {...register("name", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                name="name"
                id="name"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.name?.message}</p>
            </div>
            <div className="">
              <label htmlFor="owner" className=" text-gray-700">
                Owner
              </label>

              <Controller
                name="owner"
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="mt-1"
                    options={userOption}
                    isDisabled={vendor ? true : false}
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.owner && errors.owner.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="addres" className=" text-gray-700">
                Description
              </label>
              <input
                {...register("description", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                type="text"
                className="mt-1 block w-full  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.description?.message}
              </p>
            </div>
            <div className="block sm:my-1">
              <label htmlFor="image" className=" text-gray-700">
                Image
              </label>
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              {!imagePreview && resDetails ? (
                <img
                  src={resDetails.image}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              <input
                {...register("image", {
                  required: edit ? "" : "Image is required",
                  message: "Image is required",
                })}
                type="file"
                name="image"
                id="image"
                className="mt-1  block w-full border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.image?.message}</p>
            </div>{" "}
            <br />
            {vendor ? null : (
              <div className="form-check flex items-center sm:my-1">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id="flexCheckChecked"
                  {...register("status")}
                />
                <label
                  className="form-check-label inline-block text-gray-800"
                  htmlFor="flexCheckChecked"
                >
                  Status
                </label>
              </div>
            )}
          </div>
          <div className=" mt-2 cursor-pointer">
            <button className="bg-[#1882b8] rounded text-white px-4 py-2">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RestaurantCreateUpdateForm;
