import React, { Component } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { fetchChatusers } from "../../../../redux/actions/commonActions";
import { API_URL, IMAGE_URL } from "../../../../settings/config";

class ChatComponent extends Component {
  state = {
    isLoggedIn: false,
    messages: [],
    value: "",
    name: "",
    room: "vacad",
    isOpen: false,
    to_user_id: "",
    thread_id: "",
  };
  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
    this.messagesRef = React.createRef();
  }
  handleOpen = () => {
    this.setState({
      ...this.state,
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      isOpen: false,
    });
  };
  scrollToBottom = () => {
    const chat = document.getElementById("chat-logs");
    console.log(chat);
    chat.scrollTop = chat.scrollHeight;
  };
  client = new W3CWebSocket(
    `${process.env.REACT_APP_CHAT_URL}/ws/chat/` +
      this.props.user?.user?.id +
      "/"
  );
  onButtonClicked = (e) => {
    console.log(e);
    this.client.send(
      JSON.stringify({
        type: "message",
        message: this.state.value,
        sent_by: this.props.user.user.id,
        send_to: this.state.to_user_id,
        thread_id: this.state.thread_id,
        name: this.state.name,
      })
    );
    this.setState({
      value: "",
    });
    // this.state.value = "";
    e.preventDefault();
  };
  componentDidUpdate() {
    this.scrollToBottom();
  }
  handleChatClick = ({ from_user, to_user, thread_id, name }) => {
    console.log({ from_user, to_user, thread_id, name });
    // this.props.chatM({ from_user, to_user });
    this.scrollToBottom();

    fetch(
      `${API_URL}/chat/messages/?` +
        new URLSearchParams({
          from_user: from_user,
          to_user: to_user,
        })
    )
      .then((res) => res.json())
      .then((data) => {
        let chatData = data.chat_message;
        let b = [];
        for (let i = 0; i < chatData.length; i++) {
          b.push({
            msg: chatData[i].message,
            name: chatData[i].user.identifier,
            id: chatData[i].user.id,
          });
        }
        this.setState((state) => ({
          // messages: [...state.messages, ...b],
          messages: b,
          to_user_id: to_user,
          thread_id: thread_id,
          name: name,
        }));
      });

    this.props.chatM();

    // const dispatch = useDispatch();
    // dispatch(fetchChatMessages({ from_user, to_user }));
  };
  componentDidMount() {
    // console.log();
    this.scrollToBottom();
    // fetch(
    //   "http://127.0.0.1:8000/chat/aaa/?" +
    //     new URLSearchParams({
    //       from_user: 1,
    //       to_user: 2,
    //     })
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     let chatData = data.chat_message;
    //     console.log(chatData);
    //     let b = [];
    //     for (let i = 0; i < chatData.length; i++) {
    //       b.push({
    //         msg: chatData[i].message,
    //         name: chatData[i].user.email,
    //         id: chatData[i].user.id,
    //       });
    //     }
    //     console.log(b);
    //     this.setState((state) => ({
    //       messages: [...state.messages, ...b],
    //     }));
    //   })
    //   .catch((err) => console.log(err));

    this.client.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    this.client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      console.log("got reply! ", dataFromServer.type);
      console.log("got reply! ", dataFromServer);
      if (dataFromServer) {
        this.setState((state) => ({
          isOpen: true,
          messages: [
            ...state.messages,
            {
              msg: dataFromServer.message,
              name: dataFromServer.name,
              id: dataFromServer.username,
            },
          ],
        }));
      }
    };
  }

  render() {
    const { isChatOpen, setIsChatOpen, allChatUsers, user } = this.props;
    console.log(allChatUsers);
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsChatOpen(false);
          }}
        >
          <div
            id="mult_chat_menu"
            className={`md:flex flex-col py-8 pl-6 pr-2 md:w-64 bg-white flex-shrink-0 md:static absolute top-0 w-[250px] h-full transition-all z-50 md:border-0 border rounded-tl-xl rounded-bl-xl ${
              isChatOpen ? "left-0" : "-left-[250px]"
            }`}
          >
            <div className="flex flex-row items-center justify-center h-12 w-full">
              <div className="flex items-center justify-center rounded-2xl text-themeColor bg-indigo-100 h-10 w-10">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                  ></path>
                </svg>
              </div>
              <div className="ml-2 font-bold text-2xl">QuickChat</div>
            </div>
            <div className="flex flex-col items-center bg-indigo-100 border border-gray-200 mt-4 w-full py-6 px-4 rounded-lg">
              <div className="h-20 w-20 rounded-full border overflow-hidden">
                <img
                  src={`${IMAGE_URL}${user.profile_pic}`}
                  alt="Avatar"
                  className="h-full w-full"
                />
              </div>
              <div className="text-sm font-semibold mt-2">
                {user?.user?.first_name}
              </div>
              <div className="text-xs text-gray-500">{user?.user?.role}</div>
            </div>
            <div className="flex flex-col mt-8">
              <div className="flex flex-row items-center justify-between text-xs">
                <span className="text-base text-black font-body font-normal">
                  Conversations
                </span>
                {/* <span className="flex items-center justify-center bg-gray-300 h-5 w-5 rounded-full text-xs font-body text-black">
                  4
                </span> */}
              </div>
              <div className="flex flex-col space-y-1 mt-4 -mx-2 h-48 overflow-y-auto">
                {allChatUsers?.map((chatuser) =>
                  chatuser.first_person.id === user?.user?.id ? (
                    <button
                      className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2 disabled:bg-slate-500  disabled:text-white"
                      onClick={() =>
                        this.handleChatClick({
                          to_user: chatuser.first_person.id,
                          from_user: chatuser.second_person.id,
                          thread_id: chatuser?.id,
                          name: chatuser.first_person.identifier,
                        })
                      }
                      key={chatuser?.id}
                      disabled={
                        this.state.to_user_id === chatuser.second_person.id
                      }
                    >
                      <div className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full">
                        {chatuser.second_person.identifier.charAt(0)}
                      </div>
                      <div className="ml-2 text-sm font-normal flex items-center gap-2 relative">
                        {chatuser.second_person.identifier}{" "}
                        {parseInt(chatuser.unread) > 0 && (
                          <p className="absolute -top-2 -right-5 bg-red-500 text-white text-xs h-5 w-5 font-light font-oswald flex items-center justify-center rounded-full animate-pulse">
                            {chatuser.unread}
                          </p>
                        )}
                      </div>
                    </button>
                  ) : (
                    <button
                      className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2 disabled:bg-slate-500 disabled:text-white"
                      key={chatuser?.id}
                      onClick={() =>
                        this.handleChatClick({
                          from_user: chatuser.second_person.id,
                          to_user: chatuser.first_person.id,
                          thread_id: chatuser?.id,
                          name: chatuser.first_person.identifier,
                        })
                      }
                      disabled={
                        this.state.to_user_id === chatuser.first_person.id
                      }
                    >
                      <div className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full">
                        {chatuser.first_person.identifier.charAt(0)}
                      </div>
                      <div className="ml-2 text-sm font-normal flex items-center gap-2 relative">
                        {chatuser.first_person.identifier}
                        {parseInt(chatuser.unread) > 0 && (
                          <p className="absolute -top-2 -right-5 bg-red-500 text-white text-xs h-5 w-5 font-light font-oswald flex items-center justify-center rounded-full animate-pulse">
                            {chatuser.unread}
                          </p>
                        )}
                      </div>
                    </button>
                  )
                )}
                {/* <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
              <div className="flex items-center justify-center h-8 w-8 bg-gray-200 rounded-full">
                M
              </div>
              <div className="ml-2 text-sm font-normal">Marta Curtis</div>
              <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
                2
              </div>
            </button>
            <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
              <div className="flex items-center justify-center h-8 w-8 bg-orange-200 rounded-full">
                P
              </div>
              <div className="ml-2 text-sm font-normal">Philip Tucker</div>
            </button>
            <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
              <div className="flex items-center justify-center h-8 w-8 bg-pink-200 rounded-full">
                C
              </div>
              <div className="ml-2 text-sm font-normal">Christine Reid</div>
            </button>
            <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
              <div className="flex items-center justify-center h-8 w-8 bg-purple-200 rounded-full">
                J
              </div>
              <div className="ml-2 text-sm font-normal">Jerry Guzman</div>
            </button> */}
              </div>
            </div>
          </div>
        </OutsideClickHandler>
        <div className="flex flex-col flex-auto h-full md:px-6 px-0">
          <div className="flex flex-col flex-auto flex-shrink-0 rounded-xl bg-gray-100 h-full">
            <div className="bg-white p-4 shadow-themeShadow rounded-tl-xl rounded-tr-xl md:rounded-bl-none rounded-bl-md md:rounded-br-none rounded-br-md md:mx-0 mx-4 md:mt-0 mt-4">
              <div className="flex justify-between items-center gap-3">
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    id="mult_chat_btn"
                    className="md:hidden"
                    onClick={() => setIsChatOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  </button>
                  <div className="flex items-center gap-3">
                    <div className="user_thumb">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-10 h-10"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>

                      {/* <img
                        className="h-10 w-10 rounded-full"
                        src="../public/images/chat/avatar-s-9.jpeg"
                        alt=""
                      /> */}
                    </div>
                    <div className="user_name">
                      <h6>{this.state.name}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col max-h-[600px] overflow-y-auto mb-4 px-4">
              <div className="flex flex-col h-full">
                <div
                  className="grid grid-cols-12 gap-y-2"
                  id="chat-logs"
                  className="chat-logs"
                >
                  {this?.state?.messages.length > 0
                    ? this?.state?.messages
                        .filter(
                          (item) =>
                            item.id === this.props.user.user.id ||
                            item.id === this?.state?.to_user_id
                        )
                        .map((message, index) => {
                          if (message.id === this.props.user.user.id) {
                            console.log(message);
                            return (
                              <div className="col-start-1 sm:col-end-8 col-span-12 p-3 rounded-lg">
                                <div className="flex flex-row items-center">
                                  <div className="flex items-center justify-center sm:h-10 h-6 sm:w-10 w-6 rounded-full bg-themeColor text-white text-sm font-body flex-shrink-0">
                                    {message?.name?.charAt(0)}
                                  </div>
                                  <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                                    <p className="text-black xl:text-lg md:text-base text-sm font-normal font-body">
                                      {message.msg}
                                    </p>
                                    <div className="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500">
                                      {message.name}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="sm:col-start-6 col-start-1 col-end-13 p-3 rounded-lg">
                                <div className="flex items-center justify-start flex-row-reverse">
                                  <div className="flex items-center justify-center sm:h-10 h-6 sm:w-10 w-6 rounded-full bg-themeColor text-white text-sm font-body flex-shrink-0">
                                    {message.name.charAt(0)}
                                  </div>
                                  <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                                    <p className="text-black xl:text-lg md:text-base text-sm font-normal font-body">
                                      {message.msg}
                                    </p>
                                    <div className="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500">
                                      {message.name}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                    : "Please Selete a Person"}
                </div>
              </div>
            </div>
            {this?.state?.messages.length > 0 && (
              <form onSubmit={this.onButtonClicked}>
                <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
                  <div className="flex-grow ml-4">
                    <div className="relative w-full">
                      <input
                        type="text"
                        value={this.state.value}
                        onChange={(e) => {
                          this.setState({ value: e.target.value });
                          this.value = this.state.value;
                        }}
                        className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                      />
                      <button className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="ml-4">
                    <button
                      type="submit"
                      className="flex items-center justify-center bg-themeColor hover:bg-indigo-600 rounded-xl text-white px-4 py-2 flex-shrink-0"
                    >
                      <span>Send</span>
                      <span className="ml-2">
                        <svg
                          className="w-4 h-4 transform rotate-45 -mt-px"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            )}
            <div className="p-4"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    chatM: () => {
      console.log("fsdfsdfsdfsdf");
      return dispatch(fetchChatusers());
    },
  };
};

export default connect(null, mapDispatchToProps)(ChatComponent);
