import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PlacesAutocomplete from "react-places-autocomplete";
import banner1 from "../../../../images/vendorReg/vendor-reg-1.png";
import banner2 from "../../../../images/vendorReg/vendor-reg-2.png";

const VendorRegistrationForm = ({
  count,
  onSubmit,
  setcount,
  handleChange,
  handleSelect,
  address,
  setaddress,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { name: "" } });
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: 38,
      border: "1px",
      outline: "none !important",
      boxShadow: "none !important",
    }),
  };

  useEffect(() => {
    if (address.lat) {
      setValue("city", address.city);
      setValue("country", address.country);
      setValue("postcode", address.postcode);
      setValue("lat", address.lat.toFixed(5));
      setValue("lng", address.lng.toFixed(5));
      setValue("full_address", address.full_address);
    }
  }, [address, setValue]);
  return (
    <>
      {count === 1 && (
        <div className=" md:w-[463px] shadow-themeShadow rounded-md py-8">
          <div className="text-center pb-8">
            <img src={banner1} alt="" className="mx-auto mb-4" />
            <p className="font-medium">Tell us about yourself </p>
            <p
              className="text-[#464956] text-sm font-light
"
            >
              continue your process{" "}
            </p>
          </div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="px-7">
              <div className=" grid grid-cols-2 gap-2">
                <div className="my-2">
                  <input
                    {...register("first_name", { required: true })}
                    type="text"
                    placeholder="First name"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  {errors.first_name && (
                    <p className="text-red-500 text-xs">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="my-2">
                  <input
                    {...register("last_name", { required: true })}
                    type="text"
                    placeholder="Last name"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  {errors.last_name && (
                    <p className="text-red-500 text-xs">
                      This field is required
                    </p>
                  )}
                </div>
              </div>
              <div className="my-4">
                <input
                  {...register("phone_number", { required: true })}
                  type="text"
                  placeholder="Phone Number"
                  className="shadow-themeShadow border-0 rounded-md py-3 w-full focus:border focus:border-[#89e488] text-sm"
                />
                {errors.phone_number && (
                  <p className="text-red-500 text-xs">This field is required</p>
                )}
              </div>
              <div className="my-4">
                <button
                  type="submit"
                  className="shadow-themeShadow rounded-md border-0 py-3 w-full bg-[#0DC1D9] text-white "
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      <br />
      {count === 2 && (
        <div className=" md:w-[463px] shadow-themeShadow rounded-md py-8">
          <div className="pb-8 text-center">
            <img src={banner2} alt="" className="mx-auto mb-4" />
            <p className=" font-medium">Start Selling on British MarketPlace</p>
            <p
              className="text-[#464956] text-sm font-light
"
            >
              continue your process{" "}
            </p>
          </div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="px-7">
              <div className="my-4">
                <input
                  {...register("res_name", { required: true })}
                  type="text"
                  placeholder="Restaurant Name"
                  className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                />
                {errors.res_name && (
                  <p className="text-red-500 text-xs">This field is required</p>
                )}
              </div>
              <PlacesAutocomplete
                value={address.full_address}
                onChange={(e) =>
                  setaddress({
                    ...address,
                    full_address: e,
                  })
                }
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      // value={address.full_address}
                      {...getInputProps({
                        placeholder: "Restaurant Address ...",
                        className:
                          "shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => {
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#89e488",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "10px",
                              borderRadius: "5px",
                              fontSize: "16px",
                            }
                          : {
                              backgroundColor: "#fff",
                              cursor: "pointer",
                              padding: "10px",
                              fontSize: "16px",
                            };
                        console.log(suggestion);
                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, {
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <p className="text-sm text-red-600">
                {errors.full_address?.message}
              </p>
              <div className="my-4">
                <input
                  value={address.country}
                  {...register("country", { required: true })}
                  type="text"
                  name="counrty"
                  id="country"
                  placeholder="Country"
                  className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                />
                {errors.country && (
                  <p className="text-sm text-red-500">This field is required</p>
                )}
              </div>

              <div className=" grid grid-cols-2 gap-2">
                <div>
                  <input
                    // value={address.city}
                    {...register("city", { required: true })}
                    type="text"
                    placeholder="City"
                    name="city"
                    id="city"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  <p className="text-sm text-red-500">{errors.city?.message}</p>
                </div>
                <div>
                  <input
                    {...register("postcode", { required: true })}
                    type="text"
                    placeholder="Post Code"
                    className="shadow-themeShadow rounded-md border-0 focus:border focus:border-[#89e488] py-3 w-full text-sm"
                  />
                  {errors?.postcode && (
                    <p className="text-sm text-red-500">
                      This field is required
                    </p>
                  )}
                </div>
              </div>

              <div className="my-4">
                <input
                  {...register("email", { required: true })}
                  type="text"
                  placeholder="Email"
                  className="shadow-themeShadow rounded-md border-0 py-3 w-full focus:border focus:border-[#89e488] text-sm"
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">This field is required</p>
                )}
              </div>
              <div className="my-4">
                <input
                  {...register("password", { required: true })}
                  type="password"
                  placeholder="Password"
                  className="shadow-themeShadow rounded-md border-0  focus:border-[#89e488] py-3 w-full focus:outline-none text-sm"
                />
                {errors.password && (
                  <p className="text-red-500 text-xs">This field is required</p>
                )}
              </div>
              <div className="my-4">
                <button
                  // type="submit"
                  onClick={() => setcount(1)}
                  className="shadow-themeShadow rounded-md border-0 py-3 w-full bg-[#0DC1D9] text-white text-xs mb-4"
                >
                  Previous
                </button>
                <button
                  // onClick={regSuccessModal}
                  type="submit"
                  className="shadow-themeShadow rounded-md border-0 py-3 w-full bg-[#0DC1D9] text-white"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default VendorRegistrationForm;
