import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import Slider2 from "../slider/Slider2";
// import { HashLink } from "react-router-hash-link";
import { Link as HashLink } from "react-scroll";

const categoryBreakpoints = {
  0: {
    slidesPerView: 3,
  },
  576: {
    slidesPerView: 5,
  },
  768: {
    slidesPerView: 7,
  },
  1024: {
    slidesPerView: 7,
  },
  1200: {
    slidesPerView: 8,
  },
  1400: {
    slidesPerView: 10,
  },
};

const FoodCategorySlider = ({ foodItems }) => {
  const catData = [];
  // foodItems?.map((item) => {
  //    {
  //     item.categories?.map((cat) => catData.push(cat.name));
  //   }
  // });
  foodItems?.map((item) =>
    item.categories?.map((cat) => catData.push(cat.name))
  );
  // console.log({catData});
  let unique = [...new Set(catData)];
  const [swiper, setSwiper] = useState(0);
  // console.log({unique});
  const handleActive = (to, index) => {
    console.log(to, index);
    console.log(unique.length);
    // if (String(to) == String(unique[4])) {
    if (unique.length > 5) setSwiper(index);
    // }
  };

  console.log({swiper});

  return (
    <section className="food-menu py-5 bg-white mt-10 sticky border-b-black border-dashed border-b-[0.5px]" id="foodNavbar">
     
      <div className="container relative px-0">
        <div className="foodMenu-slider overflow-hidden">
          <div className="uppercase text-sm text-black font-normal">
            <Slider2 swiper={swiper} breakpoints={categoryBreakpoints} gap={5}>
              {unique.map((item, index) => (
                <SwiperSlide
                  key={index}
                  datatype={`${item}`}
                  data-hash={`${item}`}
                >
                  <div
                    className="
                swiper-slide
              "
                  >
                    <HashLink
                      activeClass="active"
                      onSetActive={(e) => handleActive(e, index)
                      }
                      to={`${item}`}
                      isDynamic={true}
                      spy={true}
                      hashSpy={true}
                      smooth={true}
                      offset={0}
                      duration={100}
                      className="px-4 py-[6px] inline-block cursorPointer nav-item text-xs
                      text-center
                rounded-[25px]
                bg-[#e6e6e6] "
                    >
                      {item} 
                    </HashLink>
                  </div>
                </SwiperSlide>
              ))}
            </Slider2>
          </div>
        </div>
      </div>
     
    </section>
  );
};

export default FoodCategorySlider;
