import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import AddressInfo from "../../../components/admin/UserDetails/AddressInfo";
import PersonalInfo from "../../../components/admin/UserDetails/PersonalInfo";
import {
  adminChangeStatus,
  adminUserAddrDelete,
  adminUserDetailsWithAddr,
} from "../../../redux/actions/AdminActions/adminUserActions";
import Layout from "../Layout/layout";
import ModalContainer from "../../../components/common/ModalContainer";
import { ADMIN_USER_ADDRESS_DELETE_CLEAN } from "../../../redux/types";

function UserDetails() {
  const { userDetailsAddr, deleteAddr } = useSelector(
    (state) => state.adminUser
  );
  console.log(userDetailsAddr);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleleState, setDeleleState] = useState(null);

  console.log(deleleState);

  const handleDeleteClick = (delete_id) => {
    setIsOpen(true);
    setDeleleState(delete_id);
  };

  function closeModal() {
    setIsOpen(false);
  }
  const handleDelete = () => {
    dispatch(adminUserAddrDelete(deleleState));
    closeModal();
    setDeleleState(null);
  };

  useEffect(() => {
    dispatch(adminUserDetailsWithAddr(id));
    dispatch({ type: ADMIN_USER_ADDRESS_DELETE_CLEAN });
  }, [dispatch, id, deleteAddr]);

  const handleChangeStatus = (change_id, status) => {
    dispatch(adminChangeStatus(change_id, status));
  };

  return (
    <Layout>
      <div className=" mx-3 my-8 flex ">
        <PersonalInfo
          userDetailsAddr={userDetailsAddr}
          handleChangeStatus={handleChangeStatus}
        />

        <AddressInfo
          userDetailsAddr={userDetailsAddr?.address}
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          handleDelete={handleDelete}
          handleDeleteClick={handleDeleteClick}
        />
      </div>
      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Delete user?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default UserDetails;
