import React from "react";
import TrackImg1 from "../../images/track/01.webp";
import TrackImg2 from "../../images/track/02.webp";
import TrackImg3 from "../../images/track/03.webp";
import TrackImg4 from "../../images/track/04.webp";
import StepImg1 from "../../images/track/step1.png";
import StepImg2 from "../../images/track/step2.png";
import StepImg3 from "../../images/track/step3.png";
import ActiveStepImg1 from "../../images/track/active-step-1.png";
import ActiveStepImg2 from "../../images/track/active-step-2.png";
import ActiveStepImg3 from "../../images/track/active-step-3.png";

const OrderSteps = (props) => {
  console.log(props);
  return (
    <ul className="traking-list">
      {props.step1 ? (
        <li>
          <img src={TrackImg1} alt="track order 1" />
          <div className="progress1 active">
            <img src={StepImg1} className="defult-step" alt="step 1" />
            <img
              src={ActiveStepImg1}
              className="active-step"
              alt="active step 1"
            />
          </div>
        </li>
      ) : (
        <li>
          <img src={TrackImg1} alt="track order 1" />
          <div className="progress1 ">
            <img src={StepImg1} className="defult-step" alt="step 1" />
            <img
              src={ActiveStepImg1}
              className="active-step"
              alt="active step 1"
            />
          </div>
        </li>
      )}

      {props.step2 ? (
        <li>
          <img src={TrackImg2} alt="track order 2" />
          <div className="progress1 active">
            <img src={StepImg2} className="defult-step" alt="step 2" />
            <img
              src={ActiveStepImg2}
              className="active-step"
              alt="active step 2"
            />
          </div>
        </li>
      ) : (
        <li>
          <img src={TrackImg2} alt="track order 2" />
          <div className="progress1">
            <img src={StepImg2} className="defult-step" alt="step 2" />
            <img
              src={ActiveStepImg2}
              className="active-step"
              alt="active step 2"
            />
          </div>
        </li>
      )}

      {props.step3 ? (
        <li>
          <img src={TrackImg3} alt="track order 3" />
          <div className="progress1 active">
            <img src={StepImg3} className="defult-step" alt="step 3" />
            <img
              src={ActiveStepImg3}
              className="active-step"
              alt="active step 3"
            />
          </div>
        </li>
      ) : (
        <li>
          <img src={TrackImg3} alt="track order 3" />
          <div className="progress1">
            <img src={StepImg3} className="defult-step" alt="step 3" />
            <img
              src={ActiveStepImg3}
              className="active-step"
              alt="active step 3"
            />
          </div>
        </li>
      )}
      <li>
        <img src={TrackImg4} alt="track order 4" />
      </li>
    </ul>
  );
};

export default OrderSteps;
