import React, { useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import moment from "moment";

const customerSelectOption = [
  { value: "CREATED", label: "CREATED" },
  { value: "APPROVED", label: "APPROVED" },
];
const orderStatusSelectOption = [
  { value: "CREATED", label: "CREATED" },
  { value: "APPROVED", label: "APPROVED" },
  { value: "CANCELLED", label: "CANCELLED" },
  { value: "PROCESSING", label: "PROCESSING" },
  { value: "DELIVERED", label: "DELIVERED" },
  { value: "REFUNDED", label: "REFUNDED" },
];
const paymentTypeSelectOption = [
  { value: "COD", label: "Cash on Delivery" },
  { value: "CARD", label: "Card" },
  { value: "NET", label: "Net Banking" },
];
const deliveryOptionSelectOption = [
  { value: "DOOR", label: "Meet at door" },
  { value: "OUTSIDE", label: "Meet outside" },
  { value: "LEAVE", label: "Leave at door" },
];
const resBranchSelectOption = [{ value: "a", label: "a" }];
const shippingAddressSelectOption = [{ value: "a", label: "a" }];

const itemsSelectOption = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const customStyles = {
  input: (provided, state) => ({
    ...provided,
    height: 32,
  }),
};

const OrderCreateUpdateForm = ({
  edit,
  orderDetails,
  handleSubmitData,
  detailsText,
  setDetailsText,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  function handleDetailsChange(event) {
    setDetailsText(event);
    console.log(event);
  }
  console.log(detailsText);
  useEffect(() => {
    if (orderDetails) {
      setValue("order_number", orderDetails.order_number);
      setValue("vat", orderDetails.vat);
      setValue("discount_amount", orderDetails.discount_amount);
      setValue("shipping_price", orderDetails.shipping_price);
      setValue("service_fee", orderDetails.service_fee);
      setValue("total_price", orderDetails.total_price);
      setValue("subtotal_price", orderDetails.subtotal_price);
      if (orderDetails.delivered_at)
        setValue(
          "delivered_at",
          moment(orderDetails.delivered_at).format("YYYY-MM-DDTkk:mm")
        );
      setValue("delivery_time", orderDetails.delivery_time);

      setValue("schedule_time", orderDetails.schedule_time);

      if (orderDetails.order_delivery_time)
        setValue(
          "order_delivery_time",
          moment(orderDetails.order_delivery_time).format("YYYY-MM-DDTkk:mm")
        );
      setValue("schedule_date", orderDetails.schedule_date);
      setValue("is_Cutlery", orderDetails.cutlery);
      setValue("is_Ordered", orderDetails.is_ordered);
      setValue("is_Active", orderDetails.active);
      if (orderDetails?.rider_instruction)
        setDetailsText(orderDetails?.rider_instruction);
      if (orderDetails.customer)
        setValue("customer", {
          value: orderDetails.customer.id,
          label: orderDetails.customer.identifier,
        });
      if (orderDetails.restaurant_branch)
        setValue("restaurant_branch", {
          value: orderDetails.restaurant_branch.id,
          label: orderDetails.restaurant_branch.name,
        });
      // if (orderDetails.items)
      //   setValue(
      //     "items",
      //     orderDetails.items.map((item) => ({
      //       value: item.food_items.id,
      //       label: item.food_items.name,
      //     }))
      //   );
      if (orderDetails.items)
        setValue(
          "items",
          orderDetails.items.map((item) => ({
            value: item.id,
            label: item.food_items.name,
          }))
        );
      if (orderDetails.shipping_address)
        setValue("shipping_address", {
          value: orderDetails.shipping_address.id,
          label: orderDetails.shipping_address.city,
        });
      if (orderDetails.order_status && orderStatusSelectOption.length > 0) {
        let a = orderStatusSelectOption.find(
          (item) => String(item.value) === String(orderDetails.order_status)
        );
        setValue("order_status", { value: a.value, label: a.label });
      }
      if (orderDetails.payment_type && paymentTypeSelectOption.length > 0) {
        let a = paymentTypeSelectOption.find(
          (item) => String(item.value) === String(orderDetails.payment_type)
        );
        console.log(a);
        setValue("payment_type", { value: a.value, label: a.label });
      }
      if (
        orderDetails.delivery_option &&
        deliveryOptionSelectOption.length > 0
      ) {
        let a = deliveryOptionSelectOption.find(
          (item) => String(item.value) === String(orderDetails.delivery_option)
        );
        console.log(a);
        setValue("delivery_option", { value: a.value, label: a.label });
      }
    }
  }, [orderDetails, setValue, setDetailsText]);
  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">
        {edit === true ? "Edit" : "Add"} Order{" "}
      </h1>
      <div>
        <form action="" onSubmit={handleSubmit(handleSubmitData)}>
          <div className="md:grid md:grid-cols-3 gap-3 pt-2">
            <div className="">
              <label htmlFor="customer" className=" text-gray-700">
                Customer
              </label>
              <Controller
                name="customer"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={customerSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                    isDisabled
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.customer && errors.customer.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="restaurant_branch" className=" text-gray-700">
                Restaturant Branch
              </label>
              <Controller
                name="restaurant_branch"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={resBranchSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                    isDisabled
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.restaurant_branch &&
              errors.restaurant_branch.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            {/* <div className="">
              <label htmlFor="billing_address" className=" text-gray-700">
                Billing Address
              </label>
              <Controller
                name="billing_address"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={billingAddressSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.billing_address &&
              errors.billing_address.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div> */}
          </div>
          <div className="md:grid md:grid-cols-1 gap-3 pt-2">
            {" "}
            <div className="">
              <label htmlFor="items" className=" text-gray-700">
                Items
              </label>
              <Controller
                name="items"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={itemsSelectOption}
                    styles={customStyles}
                    className="mt-1 basic-multi-select"
                    isSearchable={true}
                    isClearable
                    isMulti
                    isDisabled
                    classNamePrefix="select"
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.items && errors.items.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 gap-3 pt-2">
            <div className="">
              <label htmlFor="shipping_address" className=" text-gray-700">
                Shipping Address
              </label>
              <Controller
                name="shipping_address"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={shippingAddressSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                    isDisabled
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.shipping_address &&
              errors.shipping_address.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Order number
              </label>
              <input
                {...register("order_number", {
                  required: edit ? false : "This field is required",
                })}
                disabled
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.order_number?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="vat" className=" text-gray-700">
                Vat
              </label>

              <input
                type="text"
                {...register("vat", {
                  required: edit ? false : "This field is required",
                })}
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.vat?.message}</p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Discount amount
              </label>
              <input
                {...register("discount_amount", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.discount_amount?.message}
              </p>
            </div>
            {/* <div className="">
              <label htmlFor="" className=" text-gray-700">
                Voucher code
              </label>
              <input
                {...register("voucher_code", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.voucher_code?.message}
              </p>
            </div> */}
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Shipping price
              </label>
              <input
                {...register("shipping_price", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.max_uses_user?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Service fee
              </label>
              <input
                {...register("service_fee", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                step="any"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.service_fee?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Total price
              </label>
              <input
                {...register("total_price", {
                  required: edit ? false : "This field is required",
                })}
                type="number"
                step="any"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.total_price?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Subtotal price
              </label>
              <input
                {...register("subtotal_price", {
                  required: edit ? false : "This field is required",
                })}
                type="number"
                step="any"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.subtotal_price?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="customer" className=" text-gray-700">
                Order Status
              </label>
              <Controller
                name="order_status"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={orderStatusSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.order_status &&
              errors.order_status.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            {/* <div className="">
              <label htmlFor="" className=" text-gray-700">
                Paid at
              </label>
              <input
                {...register("paid_at", {
                  required: edit ? false : "This field is required",
                })}
                type="datetime-local"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.paid_at?.message}</p>
            </div> */}
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Payment type
              </label>
              <Controller
                name="payment_type"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={paymentTypeSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.payment_type &&
              errors.payment_type.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Delivered at
              </label>
              <input
                {...register("delivered_at", {
                  required: edit ? false : "This field is required",
                })}
                type="datetime-local"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.delivered_at?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Delivery time
              </label>
              <input
                {...register("delivery_time", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.delivery_time?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Schedule time
              </label>
              <input
                {...register("schedule_time", {
                  required: edit ? false : "This field is required",
                })}
                type="time"
                step={1}
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.schedule_time?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Order delivery time
              </label>
              <input
                {...register("order_delivery_time", {
                  required: edit ? false : "This field is required",
                })}
                type="datetime-local"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.order_delivery_time?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Schedule date
              </label>
              <input
                {...register("schedule_date", {
                  required: edit ? false : "This field is required",
                })}
                type="date"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.schedule_date?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Delivery option
              </label>
              <Controller
                name="delivery_option"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={deliveryOptionSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.delivery_option &&
              errors.delivery_option.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 md:gap-2 pt-2 mb-4">
            <div className="md:mb-6 mb-20 mt-4">
              <label htmlFor="details" className=" text-gray-700">
                Rider instruction
              </label>
              <ReactQuill
                className="mt-1 h-32 "
                value={detailsText}
                onChange={handleDetailsChange}
                modules={OrderCreateUpdateForm.modules}
                formats={OrderCreateUpdateForm.formats}
                preserveWhitespace
              />
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-2 pt-2">
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="flexCheckChecked"
                {...register("is_Cutlery")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="flexCheckChecked"
              >
                Cutlery
              </label>
            </div>
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer "
                type="checkbox"
                id="flexCheckChecked"
                {...register("is_Ordered")}
              />
              <label
                className="form-check-label inline-block text-gray-800 "
                htmlFor="flexCheckChecked"
              >
                Is ordered
              </label>
            </div>
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="flexCheckChecked"
                {...register("is_Active")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="flexCheckChecked"
              >
                Active
              </label>
            </div>
            <div className=" mt-2">
              <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
OrderCreateUpdateForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
export default OrderCreateUpdateForm;
