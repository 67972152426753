import React from "react";
import thumbnail2 from "../../../images/driver/bike.png";
import thumbnail1 from "../../../images/driver/cycle.png";

const ApplyNow = () => {
  return (
    <div className="bg-gradient-to-r from-[#171D35] to-[#00DF8F] text-white rounded-md grid grid-cols-4 gap-4 md:mx-28 my-16 h-48">
      <img
        src={thumbnail1}
        alt=""
        className="col-span-1  mx-auto w-52 lg:mt-5 ml-4 md:mt-20 mt-[104px] xl:mt-0"
      />
      <div className="col-span-2 text-center mt-6">
        <h1 className="lg:text-2xl text-xl">Applying online is easy</h1>
        <p className="text-xs">
          Make sure you have your ID, driving licence (for scooter and car
          drivers) and proof of right to work.
        </p>
        <button className="md:text-sm text-xs xl:px-28 xl:py-3 md:px-20 md:py-2 px-8 py-1 rounded-md text-white bg-[#0DC1D9] md:mt-8 mt-3">
          APPLY NOW
        </button>
      </div>
      <img
        src={thumbnail2}
        alt=""
        className="col-span-1 mx-auto lg:mt-12 md:mt-[90px] mt-28 w-44"
      />
    </div>
  );
};

export default ApplyNow;
