import React from "react";

const DriverInfo = () => {
  return (
    <div className="py-10">
      <p className="flex justify-center py-5">Driver Info</p>
      <div className="table-item flex">
        <ul
          className={` w-2/5 overview_list  items-center px-4 py-3 bg-gradient-to-tl from-[#028FF0]/10 to-[#0DC1D9]/10 bg-opacity-80 rounded-tl-md rounded-tr-md`}
        >
          <li>
            <span className="text-black lg:text-base text-sm font-light text-opacity-70">
              Serial No.
            </span>
          </li>
          <li>
            <span className="text-black lg:text-base text-sm font-light text-opacity-70">
              Username
            </span>
          </li>
          <li>
            <span className="text-black lg:text-base text-sm font-light text-opacity-70">
              Country
            </span>
          </li>
          <li>
            <span className="text-black lg:text-base text-sm font-light text-opacity-70">
              shopping amount
            </span>
          </li>
        </ul>
        <ul className="overview_list w-3/5 items-center px-4 py-3 border-[#9D9D9D]">
          <li>
            <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
              1
            </span>
          </li>
          <li>
            <span className="lg:text-base text-sm text-black font-normal text-opacity-80 max-w-[180px] truncate inline-block">
              gfhfghf
            </span>
          </li>
          <li>
            <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
              dfgdfgfdf
            </span>
          </li>
          <li>
            <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
              454545
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DriverInfo;
