import React from "react";
import { Tooltip } from "@material-tailwind/react";
import { RiDeleteBin6Line } from "react-icons/ri";

const AddressInfo = ({ userDetailsAddr, handleDeleteClick }) => {
  return (
    <div className="overflow-x-scroll shadow w-1/2 lg:w-2/3">
      <table className="table-auto  w-full border-collapse custom-table-data my-2">
        <thead className="text-center">
          <tr className="bg-gray-800 text-gray-300 ">
            <th className="custom-table-data">Full Address</th>
            <th className="custom-table-data">City</th>
            <th className="custom-table-data">State</th>
            <th className="custom-table-data">Postcode</th>
            <th className="custom-table-data">Country</th>
            <th className="custom-table-data">Others</th>
            <th className="custom-table-data">Action</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {userDetailsAddr?.map((address) => (
            <tr key={address.id}>
              <td className="custom-table-data">{address.full_address}</td>
              <td className="custom-table-data">{address.city}</td>
              <td className="custom-table-data">{address.state}</td>
              <td className="custom-table-data">{address.postcode}</td>
              <td className="custom-table-data">{address.country}</td>
              <td className="custom-table-data">{address.others}</td>
              <td className="custom-table-data text-xl">
                <Tooltip content="Delete">
                  <button onClick={() => handleDeleteClick(address.id)}>
                    <RiDeleteBin6Line className="mx-2 text-red-500" />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddressInfo;
