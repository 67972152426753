import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CEOImage from "../images/about/ceo.png";
import GoalsImage from "../images/about/goals.png";
import AboutIcon1 from "../images/about/icon/01.png";
import { fetchAboutUs } from "../redux/actions/commonActions";
import Layout from "./Layout";

const About = () => {
  const { aboutUs } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAboutUs());
  }, [dispatch]);
  return (
    <Layout>
      <section className="about-us py-12">
        <div className="container">
          <div className="section-title text-center pb-8">
            <h3 className="font-semibold text-2xl">Story of BMP food</h3>
          </div>
          <div className="about-details pb-8 text-sm text-gray-700">
            <p className="pb-4">{aboutUs}</p>
            <p className="pb-4">
              BMP FOOD cares about our riders, and that’s why we were the first
              on-demand company to call for an end to the trade-off between
              flexibility and security that currently exists in employment law.
              We want to work with Governments in all countries to make this
              happen
            </p>
            <p className="pb-4">
              All your favorite food, Daily Groceries, and Medicine are now just
              a tap away on your phone to be delivered to your home with swift
              and effective service! BMP FOOD is expanding its reach and service
              capabilities everyday. If your answer is yes when we say,”BMP
              FOOD?”, you’ll find what you need to satisfy your Hunger every
              time: Today, Tomorrow, and Beyond; With BMP FOOD! BMP FOOD is on a
              mission to transform the way customers eat. A key ingredient of
              our success is having the best selection of popular restaurants to
              choose from. Whether people want a pad Thai in the evening, a
              salad at lunch, or freshly scrambled eggs for breakfast - we’ve
              got it covered. By constantly innovating and expanding, we offer
              the best choice and convenience. Our aim is to bring great food
              direct to customers as fast as possible, in less than 30 minutes.
              BMP FOOD is proud to offer well-paid, flexible work and our riders
              are at the heart of all that we do. Their hard work powers our
              growth, and without them we would be unable to bring our customers
              the best food on demand while helping great local restaurants to
              grow.
            </p>
            <p>
              BMP FOOD cares about our riders, and that’s why we were the first
              on-demand company to call for an end to the trade-off between
              flexibility and security that currently exists in employment law.
              We want to work with Governments in all countries to make this
              happen
            </p>
          </div>
          <div className="grid md:grid-cols-3 lg:grid-cols-5 sm:grid-cols-2 place-items-center">
            <div className="counter-item text-center w-[150px]">
              <div className="bg-[#0DC1D9] rounded-md p-4">
                <img src={AboutIcon1} className="mx-auto" alt="" />
              </div>
              <div className="item-text pt-4">
                <h4 className="font-medium text-base">Our Partner</h4>
                <strong className="text-black">10k</strong>
              </div>
            </div>
            <div className="counter-item text-center w-[150px]">
              <div className="bg-[#0DC1D9] rounded-md p-4">
                <img src={AboutIcon1} className="mx-auto" alt="" />
              </div>
              <div className="item-text pt-4">
                <h4 className="font-medium text-base">Our Partner</h4>
                <strong className="text-black">10k</strong>
              </div>
            </div>
            <div className="counter-item text-center w-[150px]">
              <div className="bg-[#0DC1D9] rounded-md p-4">
                <img src={AboutIcon1} className="mx-auto" alt="" />
              </div>
              <div className="item-text pt-4">
                <h4 className="font-medium text-base">Our Partner</h4>
                <strong className="text-black">10k</strong>
              </div>
            </div>
            <div className="counter-item text-center w-[150px]">
              <div className="bg-[#0DC1D9] rounded-md p-4">
                <img src={AboutIcon1} className="mx-auto" alt="" />
              </div>
              <div className="item-text pt-4">
                <h4 className="font-medium text-base">Our Partner</h4>
                <strong className="text-black">10k</strong>
              </div>
            </div>
            <div className="counter-item text-center w-[150px]">
              <div className="bg-[#0DC1D9] rounded-md p-4">
                <img src={AboutIcon1} className="mx-auto" alt="" />
              </div>
              <div className="item-text pt-4">
                <h4 className="font-medium text-base">Our Partner</h4>
                <strong className="text-black">10k</strong>
              </div>
            </div>
          </div>
          <div className="ceo-says py-8 md:flex justify-between items-center">
            <div className="says-text md:w-1/2 w-full">
              <h4 className="text-lg font-medium text-black">
                BMP Food CEO Says
              </h4>
              <p className="text-sm text-gray-600 pt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="says-thumb mt-8 md:mt-0 relative">
              <img src={CEOImage} className="rounded-lg" alt="" />
              <div>
                <button type="button" className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="#fff"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="our-gols py-8">
            <h3 className="text-2xl font-semibold text-center mb-4">
              Our Goal
            </h3>
            <div className="lg:flex justify-between items-start xl:items-center gap-12">
              <div className="gols-thumb lg:w-[40%]">
                <img src={GoalsImage} className="w-full rounded-lg" alt="" />
              </div>
              <div className="gols-text lg:w-[60%] mt-8 lg:mt-0">
                <p>
                  <strong className="text-black text-3xl">We are in UK</strong>{" "}
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.Lorem
                  Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
            </div>
          </div>

          <h1 className="text-center gradient-text">
            WE WANT TO OPEN OUR BRANCHES ALL OVER THE WORLD
          </h1>
        </div>
      </section>
    </Layout>
  );
};

export default About;
