import React from "react";
import Accordion from "./Accordion";
const FAQs = () => {
  // const [openAccordionId, setOpenAccordionId] = useState(null);

  // const handleAccordionClick = (accordionId) => {
  //   if (openAccordionId === accordionId) {
  //     setOpenAccordionId(null);
  //   } else {
  //     setOpenAccordionId(accordionId);
  //   }
  // };
  return (
    <div className="container my-10">
      <h1 className="text-3xl text-center leading-[3.25rem]">
        Frequently asked questions
      </h1>
      <div className="flex justify-center pb-10 ">
        <p className="lg:w-[610px] text-xs text-center">
          {" "}
          British Marketplace is on a mission to transform the way you order
          food. We partner with the best restaurants in the business – from
          local hotspots to national favourites – and bring you the food you
          love, right to your door.
        </p>
      </div>
    
      <div>
        <Accordion
           id="1"
           // openAccordionId={openAccordionId}
          // setOpenAccordionId={handleAccordionClick}
          title="Q. How does it work?"
          content="You can order either on the website or by using the British Marketplace app, available on iOS and Android. Simply add your postcode to find all the great restaurants delivering in your area, choose your food and place your order.
Once the restaurant receives your order, they’ll get to work preparing your food and then carefully package it. Once it's all ready to go, a British Marketplace rider will pick it up and bring it to you.If you want to be super-organized, you can also order up to 24 hours in advance with a scheduled delivery."
        />
        <Accordion
        id="2"
        // openAccordionId={openAccordionId}
       // setOpenAccordionId={handleAccordionClick}
          title="Q. What times can I order for?"
          content="You can order either on the website or by using the British Marketplace app, available on iOS and Android. Simply add your postcode to find all the great restaurants delivering in your area, choose your food and place your order.
Once the restaurant receives your order, they’ll get to work preparing your food and then carefully package it. Once it's all ready to go, a British Marketplace rider will pick it up and bring it to you.If you want to be super-organized, you can also order up to 24 hours in advance with a scheduled delivery."
        />
        <Accordion
        id="3"
        // openAccordionId={openAccordionId}
       // setOpenAccordionId={handleAccordionClick}
          title="Q. How is the food delivered to me?"
          content="You can order either on the website or by using the British Marketplace app, available on iOS and Android. Simply add your postcode to find all the great restaurants delivering in your area, choose your food and place your order.
Once the restaurant receives your order, they’ll get to work preparing your food and then carefully package it. Once it's all ready to go, a British Marketplace rider will pick it up and bring it to you.If you want to be super-organized, you can also order up to 24 hours in advance with a scheduled delivery."
        />
        <Accordion
        id="4"
        // openAccordionId={openAccordionId}
       // setOpenAccordionId={handleAccordionClick}
          title="Q. Why doesn't British Marketplace accept cash?"
          content="You can order either on the website or by using the British Marketplace app, available on iOS and Android. Simply add your postcode to find all the great restaurants delivering in your area, choose your food and place your order.
Once the restaurant receives your order, they’ll get to work preparing your food and then carefully package it. Once it's all ready to go, a British Marketplace rider will pick it up and bring it to you.If you want to be super-organized, you can also order up to 24 hours in advance with a scheduled delivery."
        />
        
      </div>
      
    </div>
  );
};

export default FAQs;
