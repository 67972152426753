import React from "react";
import AboutSafety from "../../../components/driver/Home/AboutSafety";
import ApplyNow from "../../../components/driver/Home/ApplyNow";
import DriverHomeBanner from "../../../components/driver/Home/DriverHomeBanner";
import FAQ from "../../../components/driver/Home/FAQ";
import HowitWorks from "../../../components/driver/Home/HowitWorks";
import RiderAppSetUp from "../../../components/driver/Home/RiderAppSetUp";
import WhyJoinBmp from "../../../components/driver/Home/WhyJoinBmp";
import SocialMedia from "../../../components/Home/SocialMedia";
import Layout from "../../Layout";

const driverHome = () => {
  return (
    <Layout>
      <DriverHomeBanner />
      <WhyJoinBmp />
      <RiderAppSetUp />
      <AboutSafety />
      <HowitWorks />
      <FAQ />
      <ApplyNow />
      <SocialMedia />
    </Layout>
  );
};

export default driverHome;
