import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

const initialOptions = {
  "client-id":
    "AeNMA59cZOeoeivbrTi6o9Vuc4LEcaEGkXD93bqf1-aYok9l1ioKUccqQ13sef7e_vQ6PnsN7sCe1No5",
  // "client-id":
  //   "Aaq3nLAR5sdFoF1JG6MUlyZ-heCuaTdNz9FPtrOeLou8istC8mJO6DWGsCv2pj7yFzk0Y29rS0V7Xj8g",
  currency: "GBP",
  intent: "capture",
};

const Paypal = () => {
  return (
    <div className="card-content shadow-lg mt-8 px-4 py-5 bg-white sm:p-6 rounded-md">
      <Toaster />
      <div className="grid">
        <h4 className="col-span-3 text-base lg:text-lg font-semibold text-black pb-2">
          Paypal Payment Ruls
        </h4>
        <div className="col-span-6">
          <div className="flex items-start">
            <span className="text-base text-black">01.</span>
            <div className="pl-2">
              <p className="text-sm text-black">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt br ut labore et
                <br />
                dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <span className="text-base text-black">02.</span>
            <div className="pl-2">
              <p className="text-sm text-black">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et
                <br />
                dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <span className="text-base text-black">03.</span>
            <div className="pl-2">
              <p className="text-sm text-black">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et
                <br />
                dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          // style={{ layout: "horizontal" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "10.00",
                  },
                  custom_id: "e-book-1234", // the name or slug of the thing you're selling
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(function (details) {
              console.log(details);
              alert("Complete........");
            });
          }}
          onCancel={() =>
            toast.error("You cancelled the payment", { duration: 5000 })
          }
          onError={(err) => {
            alert(
              "You cancelled the payment. Try again by clicking the PayPal button"
            );
          }}
        />
      </PayPalScriptProvider>
      {/* <div className="px-4 py-3 pt-6 sm:px-6">
        <button
          type="button"
          className="inline-flex justify-center py-2 px-8 border-0 shadow-sm text-sm font-medium rounded-md text-white bg-gradient-to-r from-indigo-900 to-pink-700 hover:from-pink-700 hover:to-indigo-900 w-32"
        >
          Pay Now
        </button>
      </div> */}
    </div>
  );
};

export default Paypal;
