import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";
import {
  ADMIN_SPONSOR_LOADING,
  ADMIN_SPONSOR_LIST,
  ADMIN_SPONSOR_DETAILS,
  ADMIN_SPONSOR_ERROR,
} from "../../types";
export const adminSponsorListAction =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_SPONSOR_LOADING });
    let token = auth.getToken();
    // console.log(data);
    await axios
      .get(`${API_URL}/sponsor/admin/sponsor/`, {
        params: {
          search: data.search,
          page: data.page,
          status: data.status,
        },
        headers: { Authorization: token },
      })
      .then((res) => {
        // console.log(res.data);
        dispatch({ type: ADMIN_SPONSOR_LIST, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: ADMIN_SPONSOR_ERROR, payload: err.response.data });
        console.log(err);
      });
  };

export const adminSponsorDetailsAction = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_SPONSOR_LOADING });
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/sponsor/admin/sponsor/${id}/`, {
      headers: { Authorization: token },
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({ type: ADMIN_SPONSOR_DETAILS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_SPONSOR_ERROR, payload: err.response.data });
    });
};

export const adminAddSponsor = (data, history) => async (dispatch) => {
  dispatch({ type: ADMIN_SPONSOR_LOADING });
  let token = auth.getToken();
  await axios
    .post(`${API_URL}/sponsor/sponsor/`, data, {
      header: { Authorization: token },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_SPONSOR_ERROR, payload: err.response.data });
    });
  history.push("/admin/sponsors-list");
};

export const adminEditSponsor = (data, id, history) => async (dispatch) => {
  dispatch({ type: ADMIN_SPONSOR_LOADING });
  let token = auth.getToken();
  await axios
    .patch(`${API_URL}/sponsor/sponsor/${id}/`, data, {
      header: { Authorization: token },
    })
    .then((_) => history.push("/admin/sponsors-list"))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_SPONSOR_ERROR, payload: err.response.data });
    });
};
export const adminDeleteSponsor = (id) => async (dispatch, history) => {
  let token = auth.getToken();

  await axios
    .delete(`${API_URL}/sponsor/sponsor/${id}/`, {
      header: { Authorization: token },
    })
    .then((_) => dispatch(adminSponsorListAction()));
  history.push("/admin/sponsors-list");
};
