import {
  REQUEST_CART_FAILED,
  REQUEST_CART_PENDING,
  REQUEST_CART_SUCCESS,
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  INCREASE_CART_ITEM,
  DECREASE_CART_ITEM,
  SET_SERVICE_FEE,
  ADD_EXTRA_PRODUCTS_TO_CART,
  REMOVE_EXTRA_PRODUCTS_CART_ITEM,
  REMOVE_All_EXTRA_PRODUCTS_CART_ITEM,
  SET_FOOD_INGREDIENTS_HOME_MADE,
} from "../types";

function getCartFromLocalStorage() {
  return localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
}
function getHomeMadeCartFromLocalStorage() {
  return localStorage.getItem("cart-home")
    ? JSON.parse(localStorage.getItem("cart-home"))
    : [];
}

function getHomeMadeIngredientsFromLocalStorage() {
  return localStorage.getItem("home-made-ingredients")
    ? JSON.parse(localStorage.getItem("home-made-ingredients"))
    : [];
}

function arrayEquals(a, b) {
  console.log(a, b);
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

const initialStateCart = {
  isPending: false,
  cart: getCartFromLocalStorage(),
  homeMadeCart: getHomeMadeCartFromLocalStorage(),
  homeMadeIngredients: getHomeMadeIngredientsFromLocalStorage(),
  removeSuccess: false,
  serviceFee: 0,
  error: "",
};

export const requestCart = (state = initialStateCart, action = {}) => {
  switch (action.type) {
    case REQUEST_CART_PENDING:
      return Object.assign({}, state, { isPending: true });

    case REQUEST_CART_SUCCESS:
      return Object.assign({}, state, {
        cart: action.payload,
        isPending: false,
      });

    case REQUEST_CART_FAILED:
      return Object.assign({}, state, {
        error: action.payload,
        isPending: true,
      });

    case SET_FOOD_INGREDIENTS_HOME_MADE:
      localStorage.setItem(
        "home-made-ingredients",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        homeMadeIngredients: action.payload,
      };

    case ADD_EXTRA_PRODUCTS_TO_CART:
      let homeMadeCart = state.homeMadeCart;

      let incsCart = [...state.cart].map((item2) => {
        let a = [...item2.home_made_change, action.payload.product];

        return item2._id === action.payload.cartItem._id
          ? {
              ...item2,
              home_made_change: a,
              // home_made_change: [
              //   ...item2.home_made_change,
              //   action.payload.product,
              // ],
              home_made: [...item2.home_made].filter(
                (item) => item.id !== action.payload.product.item_id
              ),
              home_made_total:
                [...item2.home_made]
                  .filter((item) => item.id !== action.payload.product.item_id)
                  .reduce((total, cartItem) => {
                    return (total += parseFloat(cartItem.price));
                    // }, 0),
                  }, 0) * item2.quantity,
              home_made_change_total:
                a.reduce((total, cartItem) => {
                  return (total += parseFloat(cartItem.product.price));
                  // }, 0),
                }, 0) * item2.quantity,
            }
          : item2;
      });
      localStorage.setItem("cart", JSON.stringify(incsCart));

      homeMadeCart.push(action.payload.product);
      localStorage.setItem("cart-home", JSON.stringify(homeMadeCart));
      return {
        ...state,
        cart: incsCart,
        homeMadeCart: homeMadeCart,
      };

    case ADD_TO_CART:
      let cart = state.cart;
      const {
        id,
        name,
        image,
        total_price,
        quantity,
        extra,
        res_id,
        home_made = [],
        // homeVariationPriceResult = 0,
        // standerdHomeMade,
        // changedHomeMade,
      } = action.payload;
      const item = [...state.cart].filter((item) => item.id === id);
      if (item.length === 0) {
        let newHomeMadeWithPrice2 = 0;
        if (home_made) {
          newHomeMadeWithPrice2 = home_made.reduce((total, cartItem) => {
            return (total += parseFloat(cartItem.price));
          }, 0);
        }
        const newItem = {
          id,
          _id: Math.floor(Math.random() * (999 - 100 + 1) + 100),
          name,
          image,
          total_price,
          quantity: quantity,
          // homeVariationPrice: parseInt(homeVariationPriceResult),
          extra,
          res_id,
          home_made,
          home_made_all: home_made,
          home_made_total: newHomeMadeWithPrice2,
          home_made_change: [],
          // standerdHomeMade,
          // changedHomeMade,
        };
        cart.push(newItem);
        localStorage.setItem("cart", JSON.stringify(cart));
        return {
          ...state,
          cart: cart,
        };
      } else {
        console.log("0000000000000");
        for (let i = 0; i < item.length; i++) {
          let extraItem = item[i]?.extra.map((ex) => ex.id);

          if (extraItem.length > 0 && item[i].id === id) {
            console.log("11111111111111");
            console.log("111aaaaaaa", item[i].id === id);
            var inputIds = extra?.map(function (a) {
              return parseInt(a.id);
            });
            console.log(
              extraItem.sort(),
              inputIds.sort(),
              arrayEquals(extraItem.sort(), inputIds.sort())
            );

            if (arrayEquals(extraItem.sort(), inputIds.sort())) {
              console.log("yesssssssss");
              console.log(item[i]);
              let newItem = {
                ...item[i],
                quantity: item[i].quantity + quantity,
              };
              let incsCart = [...state.cart].map((item2) => {
                return item2._id === newItem._id
                  ? { ...item2, quantity: item2.quantity + quantity }
                  : item2;
              });
              localStorage.setItem("cart", JSON.stringify(incsCart));
              return { ...state, cart: incsCart };
            }
            // else {
            //   console.log("exxxxpppp");
            //   // .reduce((total, cartItem) => {
            //   //     return (total += parseFloat(cartItem.price));
            //   //   }, 0),
            //   let newHomeMadeWithPrice2 = 0;
            //   if (home_made) {
            //     newHomeMadeWithPrice2 = home_made.reduce((total, cartItem) => {
            //       return (total += parseFloat(cartItem.price));
            //     }, 0);
            //   }
            //   const newItem = {
            //     id,
            //     _id: Math.floor(Math.random() * (999 - 100 + 1) + 100),
            //     name,
            //     image,
            //     total_price,
            //     quantity: quantity,
            //     extra,
            //     res_id,
            //     home_made,
            //     home_made_all: home_made,
            //     home_made_total: newHomeMadeWithPrice2,
            //     home_made_change: [],
            //     // homeVariationPrice: parseInt(homeVariationPriceResult),
            //     // standerdHomeMade,
            //     // changedHomeMade,
            //   };
            //   cart.push(newItem);
            //   localStorage.setItem("cart", JSON.stringify(cart));
            //   return {
            //     ...state,
            //     cart: cart,
            //   };
            // }
          } else if (extraItem.length < 1 && item[i].id === id) {
            console.log("2222222222222");
            if (extra.length > 0) {
              let newHomeMadeWithPrice2 = 0;
              if (home_made) {
                newHomeMadeWithPrice2 = home_made.reduce((total, cartItem) => {
                  return (total += parseFloat(cartItem.price));
                }, 0);
              }
              const newItem = {
                id,
                _id: Math.floor(Math.random() * (999 - 100 + 1) + 100),
                name,
                image,
                total_price,
                quantity: quantity,
                // homeVariationPrice: parseInt(homeVariationPriceResult),
                extra,
                res_id,
                home_made,
                home_made_all: home_made,
                home_made_total: newHomeMadeWithPrice2,
                home_made_change: [],
                // standerdHomeMade,
                // changedHomeMade,
              };
              cart.push(newItem);
              localStorage.setItem("cart", JSON.stringify(cart));
              return {
                ...state,
                cart: cart,
              };
            } else {
              let incsCart = [...state.cart].map((item3) => {
                return item3._id === item[i]._id
                  ? { ...item3, quantity: item3.quantity + quantity }
                  : item3;
              });
              localStorage.setItem("cart", JSON.stringify(incsCart));
              return { ...state, cart: incsCart };
            }
          } else if (item[i].id === 100) {
            console.log("33333333333333");
            let newHomeMadeWithPrice2 = 0;
            if (home_made) {
              newHomeMadeWithPrice2 = home_made.reduce((total, cartItem) => {
                return (total += parseFloat(cartItem.price));
              }, 0);
            }
            const newItem = {
              id,
              _id: Math.floor(Math.random() * (999 - 100 + 1) + 100),
              name,
              image,
              total_price,
              quantity: quantity,
              extra,
              res_id,
              home_made,
              home_made_all: home_made,
              home_made_total: newHomeMadeWithPrice2,
              home_made_change: [],

              // homeVariationPrice: parseInt(homeVariationPriceResult),
              // standerdHomeMade,
              // changedHomeMade,
            };
            cart.push(newItem);
            console.log(newItem);
            localStorage.setItem("cart", JSON.stringify(cart));
            return {
              ...state,
              cart: cart,
            };
          }
        }
        let newHomeMadeWithPrice2 = 0;
        if (home_made) {
          newHomeMadeWithPrice2 = home_made.reduce((total, cartItem) => {
            return (total += parseFloat(cartItem.price));
          }, 0);
        }
        const newItem = {
          id,
          _id: Math.floor(Math.random() * (999 - 100 + 1) + 100),
          name,
          image,
          total_price,
          quantity: quantity,
          extra,
          res_id,
          home_made,
          home_made_all: home_made,
          home_made_total: newHomeMadeWithPrice2,
          home_made_change: [],

          // homeVariationPrice: parseInt(homeVariationPriceResult),
          // standerdHomeMade,
          // changedHomeMade,
        };
        cart.push(newItem);
        console.log(newItem);
        localStorage.setItem("cart", JSON.stringify(cart));
        return {
          ...state,
          cart: cart,
        };
      }
    // let extraItem = item?.map((i) => i.extra.map((ex) => ex.id));
    // console.log(extraItem);
    // if (extraItem.length > 0) {
    //   for (let j = 0; j < extraItem.length; j++) {
    //     console.log(extraItem[j]);
    //     var twoIds = extra?.map(function (a) {
    //       return parseInt(a.id);
    //     });
    //     // console.log(twoIds);
    //     console.log(arrayEquals(extraItem[j], twoIds));
    //     let incsCart;
    //     if (arrayEquals(extraItem[j], twoIds)) {
    //       console.log();
    //       incsCart = [...state.cart].map((item2) => {
    //         // return { ...item, quantity: item.quantity + quantity };
    //         return arrayEquals(extraItem[j], twoIds)
    //           ? { ...item2, quantity: item2.quantity + quantity }
    //           : { ...item2, quantity: quantity };
    //       });
    //       console.log(incsCart);
    //       localStorage.setItem("cart", JSON.stringify(incsCart));
    //       return { ...state, cart: incsCart };
    //     } else {
    //       const newItem = {
    //         id,
    //         _id: Math.floor(Math.random() * (999 - 100 + 1) + 100),
    //         name,
    //         image,
    //         total_price,
    //         quantity: quantity,
    //         extra,
    //       };
    //       cart.push(newItem);
    //       localStorage.setItem("cart", JSON.stringify(cart));
    //       return {
    //         ...state,
    //         cart: cart,
    //       };
    //     }
    //   }
    // } else {
    //   const newItem = {
    //     id,
    //     _id: Math.floor(Math.random() * (999 - 100 + 1) + 100),
    //     name,
    //     image,
    //     total_price,
    //     quantity: quantity,
    //     extra,
    //   };
    //   cart.push(newItem);
    //   localStorage.setItem("cart", JSON.stringify(cart));
    //   return {
    //     ...state,
    //     cart: cart,
    //   };
    // }

    // let extraItem = item?.extra.map((ex) => ex.id);
    // var twoIds = extra?.map(function (a) {
    //   return parseInt(a.id);
    // });
    // console.log(extraItem, twoIds);
    // console.log(arrayEquals(extraItem, twoIds));
    // if (arrayEquals(extraItem, twoIds)) {
    //   const incsCart = [...state.cart].map((item) => {
    //     return item.id === id
    //       ? { ...item, quantity: item.quantity + quantity }
    //       : { ...item, quantity: quantity };
    //   });
    //   localStorage.setItem("cart", JSON.stringify(incsCart));
    //   return { ...state, cart: incsCart };
    // } else {
    //   const newItem = {
    //     id,
    //     name,
    //     image,
    //     total_price,
    //     quantity: quantity,
    //     extra,
    //   };
    //   cart.push(newItem);
    //   localStorage.setItem("cart", JSON.stringify(cart));
    //   return {
    //     ...state,
    //     cart: cart,
    //   };
    // }

    // return state;

    // case ADD_TO_CART:
    //   let cart = state.cart;
    //   console.log(typeof cart);
    //   const { id, name, image, total_price, quantity, extra } = action.payload;
    //   const item = [...state.cart].find((item) => item.id === id);
    //   console.log(item);
    //   let extraItem = item?.extra.map((ex) => ex.id);
    //   var twoIds = extra?.map(function (a) {
    //     return parseInt(a.id);
    //   });
    //   console.log(extraItem, twoIds);
    //   console.log(arrayEquals(extraItem, twoIds));
    //   if (arrayEquals(extraItem, twoIds)) {
    //     const incsCart = [...state.cart].map((item) => {
    //       return item.id === id
    //         ? { ...item, quantity: item.quantity + quantity }
    //         : { ...item, quantity: quantity };
    //     });
    //     localStorage.setItem("cart", JSON.stringify(incsCart));
    //     return { ...state, cart: incsCart };
    //   } else {
    //     const newItem = {
    //       id,
    //       name,
    //       image,
    //       total_price,
    //       quantity: quantity,
    //       extra,
    //     };
    //     cart.push(newItem);
    //     localStorage.setItem("cart", JSON.stringify(cart));
    //     return {
    //       ...state,
    //       cart: cart,
    //     };
    //   }

    // let variationItem2 = [];
    // var twoIds = extra?.map(function (a) {
    //   return parseInt(a.id);
    // });
    // const variation = [...state.cart].filter((variationItem) => {
    //   console.log(variationItem?.extra);
    //   let i = variationItem?.extra?.map((vItem) => parseInt(vItem.id));

    //   console.log(i);
    //   console.log(twoIds);
    //   return arrayEquals(i, twoIds);
    // });
    // console.log(variation);
    // const variation = [...state.cart].map((variationItem) => {
    //   console.log(variationItem);

    //   var oneIDs = variationItem?.extra.map(function (a) {
    //     variationItem2.push(a.id);
    //     return parseInt(a.id);
    //   });

    //   return oneIDs;
    // });
    // console.log(variation);
    // var twoIds = extra?.map(function (a) {
    //   return parseInt(a.id);
    // });
    // console.log(variationItem2, twoIds);
    // if (!arrayEquals(variationItem2, twoIds)) {
    //   console.log("enter");
    // }
    // arrayEquals(oneIDs, twoIds);
    // if (item) {
    //   const incsCart = [...state.cart].map((item) => {
    //     return item.id === id
    //       ? { ...item, quantity: item.quantity + quantity }
    //       : { ...item, quantity: quantity };
    //   });
    //   localStorage.setItem("cart", JSON.stringify(incsCart));
    //   return { ...state, cart: incsCart };
    // } else {
    //   const newItem = {
    //     id,
    //     name,
    //     image,
    //     total_price,
    //     quantity: quantity,
    //     extra,
    //   };
    //   cart.push(newItem);
    //   localStorage.setItem("cart", JSON.stringify(cart));
    //   return {
    //     ...state,
    //     cart: cart,
    //   };
    // }
    // console.log(title);

    // const item = [...state.cart].find((item) => item._id === _id);
    // if (item) {
    //   const incsCart = [...state.cart].map((item) => {
    //     return item._id === _id
    //       ? { ...item, amount: item.amount + amount }
    //       : { ...item };
    //   });
    //   localStorage.setItem("cart", JSON.stringify(incsCart));
    //   return { ...state, cart: incsCart };
    // } else {
    //   const newItem2 = {
    //     _id,
    //     title,
    //     imageUrl,
    //     price: parseInt(price),
    //     amount: amount,
    //     description,
    //   };
    //   cart.push(newItem2);
    //   localStorage.setItem("cart", JSON.stringify(cart));
    //   console.log(cart);
    //   return {
    //     ...state,
    //     cart: cart,
    //   };
    // }

    // return state;

    case REMOVE_EXTRA_PRODUCTS_CART_ITEM:
      console.log(action.payload);
      let decCart2 = [...state.cart].map((item2) => {
        let a = [...item2.home_made_change].filter(
          (item) => item.product.id !== action.payload.product
        );
        let b = a.filter((item) => item.item_id === action.payload.item_id);
        console.log(b.length);
        if (b.length === 0) {
          // tomorrrowwwww
          let c = [...item2.home_made_all].find(
            (item) => item.id === action.payload.item_id
          );

          // let total = parseFloat(c.price);

          // let total = c.reduce((total, cartItem) => {
          //   return (total += parseFloat(cartItem.price));
          // }, 0);

          // console.log(c);
          return item2._id === action.payload.cartItem._id
            ? {
                ...item2,
                home_made_change: a,
                home_made: [...item2.home_made, c],
                home_made_total: [...item2.home_made, c].reduce(
                  (total, cartItem) => {
                    return (total += parseFloat(cartItem.price));
                  },
                  0
                ),
                home_made_change_total: a.reduce((total, cartItem) => {
                  return (total += parseFloat(cartItem.product.price));
                }, 0),
              }
            : item2;
        } else {
          return item2._id === action.payload.cartItem._id
            ? {
                ...item2,
                home_made_change: a,
                // home_made_change: [
                //   ...item2.home_made_change,
                //   action.payload.product,
                // ],
                home_made_change_total: a.reduce((total, cartItem) => {
                  return (total += parseFloat(cartItem.product.price));
                }, 0),
              }
            : item2;
        }
      });
      let newCart2 = [...state.homeMadeCart].filter(
        (item) => item.product.id !== action.payload.product
      );
      localStorage.setItem("cart", JSON.stringify(decCart2));
      localStorage.setItem("cart-home", JSON.stringify(newCart2));
      return { ...state, homeMadeCart: newCart2, cart: decCart2 };

    case REMOVE_All_EXTRA_PRODUCTS_CART_ITEM:
      localStorage.setItem("cart-home", JSON.stringify([]));
      return { ...state, homeMadeCart: [] };

    case REMOVE_CART_ITEM:
      console.log(action.payload);
      let newCart = [...state.cart].filter(
        (item) => item._id !== action.payload
      );

      if (newCart.length <= 0) {
        localStorage.setItem("cart", JSON.stringify(newCart));
        localStorage.setItem("cart-home", JSON.stringify([]));
        return { ...state, cart: newCart, homeMadeCart: [] };
      } else {
        localStorage.setItem("cart", JSON.stringify(newCart));
        return { ...state, cart: newCart };
      }

    case INCREASE_CART_ITEM:
      console.log(action.payload);
      const incCart = [...state.cart].map((item) => {
        let a = [...item.home_made].reduce((total, cartItem) => {
          return (total += parseFloat(cartItem.price));
        }, 0);
        let home_made_total = item.home_made_total + a;

        if (item.home_made_change_total !== 0) {
          let b = [...item.home_made_change].reduce((total, cartItem) => {
            return (total += parseFloat(cartItem.product.price));
          }, 0);
          // console.log(item.home_made_change_total);
          let temp = item.home_made_change_total + b;
          console.log(temp);
          return item._id === action.payload
            ? {
                ...item,
                quantity: item.quantity + 1,
                home_made_total,
                home_made_change_total: temp,
              }
            : { ...item };
        }

        return item._id === action.payload
          ? { ...item, quantity: item.quantity + 1, home_made_total }
          : { ...item };
      });
      localStorage.setItem("cart", JSON.stringify(incCart));
      return { ...state, cart: incCart };

    case DECREASE_CART_ITEM:
      const decCart = [...state.cart].map((item) => {
        console.log(item.home_made_total);
        console.log(item.quantity - 1);
        console.log(item.home_made_total / (item.quantity - 1));
        let a = [...item.home_made].reduce((total, cartItem) => {
          return (total += parseFloat(cartItem.price));
        }, 0);
        let home_made_total = item.home_made_total - a;

        if (item.home_made_change_total !== 0) {
          let b = [...item.home_made_change].reduce((total, cartItem) => {
            return (total += parseFloat(cartItem.product.price));
          }, 0);
          let temp = item.home_made_change_total - b;
          return item._id === action.payload
            ? {
                ...item,
                quantity: item.quantity - 1,
                home_made_total,
                home_made_change_total: temp,
              }
            : { ...item };
        }

        return item._id === action.payload
          ? { ...item, quantity: item.quantity - 1, home_made_total }
          : { ...item };
      });
      localStorage.setItem("cart", JSON.stringify(decCart));
      console.log(decCart);
      return { ...state, cart: decCart };

    case SET_SERVICE_FEE:
      return { ...state, serviceFee: action.payload.service_fee };

    default:
      return state;
  }
};
