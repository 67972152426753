import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import VoucherCreateUpdateForm from "../../../components/admin/Forms/Voucher/VoucherCreateUpdateForm";
import { adminRestaurantBranchNameOnlyList } from "../../../redux/actions/AdminActions/adminRestaurantActions";
import {
  adminEditVoucherAction,
  adminVoucherDetailAction,
} from "../../../redux/actions/AdminActions/adminVoucherAction";
import Layout from "../Layout/layout";

const EditVoucher = () => {
  const { resBranchNameOnlyList } = useSelector(
    (state) => state.adminRestaurant
  );
  const { voucherDetail } = useSelector((state) => state.adminVoucher);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  // console.log(id);
  // const [voucher, setVoucher] = useState({});
  useEffect(() => {
    dispatch(adminRestaurantBranchNameOnlyList());
  }, [dispatch]);
  useEffect(() => {
    dispatch(adminVoucherDetailAction(id));
  }, [id, dispatch]);
  const onSubmit = (data) => {
    const body = {
      restaurant_branch: data.restaurantBranch.value,
      code: data.code,
      description: data.description,
      uses: data.uses,
      max_uses: data.max_uses,
      max_uses_user: data.max_uses_user,
      discount_amount: data.discount_amount,
      is_fixed: data.is_fixed,
      starts_at: data.starts_at,
      expires_at: data.expires_at,
      min_order_price: data.min_order_price,
      status: data.status,
      is_admin_code: data.is_admin_code,
      type: data.type,
    };
    dispatch(adminEditVoucherAction(body, history, id));
  };

  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <VoucherCreateUpdateForm
            edit
            voucher={voucherDetail}
            restaurantBranchData={resBranchNameOnlyList}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </Layout>
  );
};

export default EditVoucher;
