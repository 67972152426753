import {
  ADMIN_RESTAURANT_LIST,
  ADMIN_RESTAURANT_LOADING,
  ADMIN_RESTAURANT_ERROR,
  ADMIN_RESTAURANT_DETAILS,
  ADMIN_RESTAURANT_DETAILS_WITH_BRANCH,
  ADMIN_RESTAURANT_NAME_ONLY_LIST,
  ADMIN_RESTAURANT_CATEGORY,
  ADMIN_RESTAURANT_BRANCH_DETAILS_EDIT,
  ADMIN_RESTAURANT_BRANCH_NAME_ONLY_LIST,
  ADMIN_REVIEW_RATING_LIST,
} from "../../types";
import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";

export const adminRestaurantList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    await axios
      .get(`${API_URL}/restaurant/restaurant/`, {
        params: {
          search: data.search,
          page: data.page,
        },
      })
      .then((res) =>
        dispatch({ type: ADMIN_RESTAURANT_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };

export const adminRestaurantNameOnlyList = () => async (dispatch) => {
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .get(`${API_URL}/restaurant/restaurant-name-only/`, config)
    .then((res) =>
      dispatch({ type: ADMIN_RESTAURANT_NAME_ONLY_LIST, payload: res.data })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
    });
};

export const adminAddRestaurant = (data, history) => async (dispatch) => {
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  // const body = {
  //   name: data.name,
  //   owner: data.owner.value,
  //   description: data.description,
  //   image: data.image[0],
  //   status: data.status,
  // };
  await axios
    .post(`${API_URL}/restaurant/restaurant/`, data, config)
    .then((res) => {
      console.log(res);
      history.push("/admin/restaurant-list");
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
    });
};

export const adminAddRestaurantBranch =
  (data, res_id, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    await axios
      .post(`${API_URL}/restaurant/res/branch/`, data, config)
      .then((res) => {
        console.log(res);
        if (vendor) {
          history.push(`/vendor/restaurant-branches-list`);
        } else {
          history.push(`/admin/restaurant-details/${res_id}`);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };

export const adminEditRestaurantBranch =
  (data, id, res_id, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    await axios
      .patch(`${API_URL}/restaurant/res/branch/${id}/`, data, config)
      .then((res) => {
        console.log(res);
        if (vendor) {
          history.push(`/vendor/restaurant-branches-list`);
        } else {
          history.push(`/admin/restaurant-branch-details/${res_id}`);
        }

        // history.goBack();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };

export const adminRestaurantDetails = (id) => async (dispatch) => {
  console.log(id);
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .get(`${API_URL}/restaurant/restaurant/${id}`, config)
    .then((res) =>
      dispatch({ type: ADMIN_RESTAURANT_DETAILS, payload: res.data })
    )
    .catch((err) =>
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response })
    );
};

export const adminRestaurantBranchDetailsEdit = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .get(`${API_URL}/restaurant/res/branch/${id}`, config)
    .then((res) =>
      dispatch({
        type: ADMIN_RESTAURANT_BRANCH_DETAILS_EDIT,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response })
    );
};

export const adminRestaurantDetailsWithBranch = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .get(`${API_URL}/restaurant/restaurant-with-branch/${id}`, config)
    .then((res) =>
      dispatch({
        type: ADMIN_RESTAURANT_DETAILS_WITH_BRANCH,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response })
    );
};

export const adminRestaurantEdit =
  (data, id, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    console.log(data);
    await axios
      .patch(`${API_URL}/restaurant/restaurant/${id}/`, data, config)
      .then((res) => {
        console.log(res);
        if (vendor) {
          history.push("/vendor/restaurant-branches-list");
        } else history.push("/admin/restaurant-list");
      })
      .catch((err) =>
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response })
      );
  };

export const adminRestaurantCategoryList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/restaurant/admin/category/`, {
        params: {
          page: data.page,
          search: data.search,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: ADMIN_RESTAURANT_CATEGORY, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };

export const adminRestaurantStatusChange = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .patch(`${API_URL}/restaurant/change/status/`, { res_id: id }, config)
    .then((_) => dispatch(adminRestaurantDetailsWithBranch(id)))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
    });
};

export const adminRestaurantDelete =
  (id, history, list) => async (dispatch) => {
    console.log(history);
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    axios
      .delete(`${API_URL}/restaurant/restaurant-name-only/${id}/`, config)
      .then((_) => {
        if (list) {
          dispatch(adminRestaurantList());
        }
        history.push("/admin/restaurant-list");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };

export const adminRestaurantBranchDelete =
  (id, history, res_id, list) => async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    axios
      .delete(`${API_URL}/restaurant/res/branch/${id}/`, config)
      .then((_) => {
        if (list) {
          dispatch(adminRestaurantDetailsWithBranch(res_id));
        } else {
          history.push(`/admin/restaurant-details/${res_id}`);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };

export const adminRestaurantCategoryAdd =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    await axios
      .post(`${API_URL}/restaurant/category/`, data, config)
      .then((_) => dispatch(adminRestaurantCategoryList()))
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };

export const adminRestaurantCategoryDelete = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .delete(`${API_URL}/restaurant/category/${id}/`, config)
    .then((_) => dispatch(adminRestaurantCategoryList()))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
    });
};

export const adminRestaurantBranchNameOnlyList = () => async (dispatch) => {
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .get(`${API_URL}/restaurant/restaurant-branch-name-only/`, config)
    .then((res) =>
      dispatch({
        type: ADMIN_RESTAURANT_BRANCH_NAME_ONLY_LIST,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
    });
};

export const adminRestaurantReviewRatingList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/review/admin/review-rating`, {
        params: {
          search: data.search,
          page: data.page,
          status: data.status,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({
          type: ADMIN_REVIEW_RATING_LIST,
          payload: res.data,
        })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };

export const adminRestaurantReviewRatingDelete = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_RESTAURANT_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .delete(`${API_URL}/review/admin/review-rating/${id}/`, config)
    .then((_) => dispatch(adminRestaurantReviewRatingList()))
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
    });
};

export const adminRestaurantReviewRatingChangeStatus =
  (id) => async (dispatch) => {
    dispatch({ type: ADMIN_RESTAURANT_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    await axios
      .get(`${API_URL}/review/change-status/${id}/`, config)
      .then((_) => dispatch(adminRestaurantReviewRatingList()))
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_RESTAURANT_ERROR, payload: err.response });
      });
  };
