import {
  ADMIN_USER_LIST,
  ADMIN_USER_LOADING,
  ADMIN_USER_ERROR,
  ADMIN_USER_DETAILS,
  ADMIN_REGISTER_SINGLE_USER_DETAILS,
  ADMIN_USER_DETAILS_WITH_ADDRESS,
  ADMIN_USER_ADDRESS_DELETE,
  ADMIN_LIMIT_USER_LIST,
  ADMIN_VENDOR_REGISTRATION_LIST,
  ADMIN_VENDOR_DETAILS,
} from "../../types";
import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";
import { logoutAction } from "../authActions";
import VendorDetails from "../../../pages/admin/manageVendorRequest/vendorDetails";

export const adminUserListAction =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_USER_LOADING });
    let token = auth.getToken();

    await axios
      .get(`${API_URL}/users/admin/users/`, {
        params: {
          page: data.page,
          status: data.status,
          search: data.search,
        },
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: ADMIN_USER_LIST, payload: res.data });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return (window.location.href = "http://localhost:3000/login");
        }
        console.log(err);
      });
  };

export const adminLimitUserListAction =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_USER_LOADING });
    let token = auth.getToken();

    await axios
      .get(`${API_URL}/users/admin/limit/users/`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        dispatch({ type: ADMIN_LIMIT_USER_LIST, payload: res.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data });
      });
  };

export const adminRegisterUser = (newUserData) => async (dispatch) => {
  dispatch({ type: ADMIN_USER_LOADING });
  const body = {
    // identifier: newUserData.phone_number,
    username: newUserData.username,
    first_name: newUserData.first_name,
    last_name: newUserData.last_name,
    email: newUserData.email_address,
    phone_number: newUserData.phone_number,
    is_confirmed: newUserData.status,
    is_active: newUserData.is_active,
    is_staff: newUserData.is_staff,
    password: newUserData.password,
    role: newUserData.roleSelected.value,
  };
  console.log(newUserData);

  await axios
    .post(`${API_URL}/users/registration/`, body)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: ADMIN_REGISTER_SINGLE_USER_DETAILS,
        payload: res.data.data,
      });
      // history.push("/admin/user-list");
    })
    .catch((err) => {
      console.log(err.response);
      const data = err.response?.data?.error;
      const commonErr = [];
      if (data) {
        for (const [i, j] of Object.entries(data)) {
          console.log(`${i}: ${j}`);
          commonErr.push(`${j}`);
        }
      }

      dispatch({ type: ADMIN_USER_ERROR, payload: commonErr });

      console.log(commonErr);
      // if (data) {
      //   dispatch(authError(commonErr));
      //   // dispatch({
      //   //     type: SET_ERRORS,
      //   //     payload: commonErr,
      //   // });
      // } else {
      //   dispatch({
      //     type: SERVER_ERROR,
      //   });
      // }
    });
};

export const adminUserDetails = (id) => async (dispatch) => {
  console.log(id);
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  dispatch({ type: ADMIN_USER_LOADING });
  await axios
    .get(`${API_URL}/users/details/${id}`, config)
    .then((res) => dispatch({ type: ADMIN_USER_DETAILS, payload: res.data }))
    .catch((err) => {
      console.log(err.response.status);
      dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data });
    });
};

export const adminUserDetailsWithAddr = (id) => async (dispatch) => {
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  dispatch({ type: ADMIN_USER_LOADING });
  await axios
    .get(`${API_URL}/users/profiles/${id}`, config)
    .then((res) =>
      dispatch({ type: ADMIN_USER_DETAILS_WITH_ADDRESS, payload: res.data })
    )
    .catch((err) => {
      console.log(err.response.status);
      // if (err.response.status === 401) {
      //   localStorage.removeItem("jwt");
      //   delete axios.defaults.headers.common["Authorization"];
      //   auth.clearAppStorage();
      // dispatch({ type: SET_UNAUTHENTICATED });
      // if (history) history.push("/login");
      // dispatch(logoutAction());
      // window.location.href = "http://localhost:3000/login";
      // }
      dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data });
    });
};

export const adminEditUserDetails =
  (id, newUserData, history) => async (dispatch) => {
    dispatch({ type: ADMIN_USER_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    console.log({ newUserData });
    const body = {
      // identifier: newUserData.phone_number,
      username: newUserData.username,
      first_name: newUserData.first_name,
      last_name: newUserData.last_name,
      email: newUserData.email_address,
      phone_number: newUserData.phone_number,
      is_confirmed: newUserData.status,
      is_active: newUserData.is_active,
      is_staff: newUserData.is_staff,
      password: newUserData.password,
      role: newUserData.roleSelected.value,
    };
    axios
      .patch(`${API_URL}/users/admin/users/${id}/`, body, config)
      // .then((_) => history.push("/admin/user-list"))
      .catch((err) => console.log(err));
  };

export const AdminNewUserImagePhone =
  (data, id, history) => async (dispatch) => {
    dispatch({ type: ADMIN_USER_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    console.log(data);
    axios
      .patch(`${API_URL}/users/details/${id}/`, data, config)
      .then((_) => {
        // console.log(res);
        history.push("/admin/user-list");
      })
      .catch((err) => console.log(err));
  };

export const adminUserDelete = (id) => async (dispatch) => {
  console.log(id);
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  dispatch({ type: ADMIN_USER_LOADING });
  console.log(id);
  await axios
    .delete(`${API_URL}/users/admin/limit/users/${id}`, config)
    .then((_) => dispatch(adminUserListAction()))
    .catch((err) =>
      dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data })
    );
};

export const adminUserAddrDelete = (id) => async (dispatch) => {
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  dispatch({ type: ADMIN_USER_LOADING });
  console.log(id);
  await axios
    .delete(`${API_URL}/users/admin/address/${id}`, config)
    .then((_) => dispatch({ type: ADMIN_USER_ADDRESS_DELETE }))
    .catch((err) =>
      dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data })
    );
};

export const adminChangeStatus = (id, status) => async (dispatch) => {
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  const body = {
    // identifier: newUserData.phone_number,
    is_active: status,
  };

  console.log(status);

  dispatch({ type: ADMIN_USER_LOADING });
  axios
    .patch(`${API_URL}/users/admin/users/${id}/`, body, config)
    .then((res) => {
      console.log(res);
      dispatch(adminUserDetailsWithAddr(id));
    })
    .catch((err) =>
      dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data })
    );
};

export const adminVendorRegistrationListAction =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_USER_LOADING });
    let token = auth.getToken();

    await axios
      .get(`${API_URL}/users/admin/vendor/registration`, {
        params: {
          page: data.page,
          search: data.search,
        },
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: ADMIN_VENDOR_REGISTRATION_LIST, payload: res.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data });
      });
  };

export const adminVendorDetails = (id) => async (dispatch) => {
  // console.log(id);
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  dispatch({ type: ADMIN_USER_LOADING });
  await axios
    .get(`${API_URL}/users/admin/vendor/registration/${id}/`, config)
    .then((res) => {
      // console.log(res);
      dispatch({ type: ADMIN_VENDOR_DETAILS, payload: res.data });
    })
    .catch((err) =>
      dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data })
    );
};
export const vendorStatusChange = (id, status) => async (dispatch) => {
  let token = auth.getToken();
  const body = {
    is_approved: status,
  };
  console.log(status);
  dispatch({ type: ADMIN_USER_LOADING });
  axios
    .patch(`${API_URL}/users/admin/vendor/registration/${id}/`, body, {
      headers: { Authorization: token },
    })
    .then((res) => {
      // console.log(res);
      dispatch(adminVendorDetails(id));
    })
    .catch((err) =>
      dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data })
    );
};

export const adminVendorRegistrationDelete =
  (id, history) => async (dispatch) => {
    dispatch({ type: ADMIN_USER_LOADING });
    let token = auth.getToken();

    await axios
      .delete(`${API_URL}/users/admin/vendor/registration/${id}/`, {
        headers: { Authorization: token },
      })
      .then((_) => {
        if (history) {
          history.push("/admin/vendor/request");
        } else {
          dispatch(adminVendorRegistrationListAction());
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_USER_ERROR, payload: err.response.data });
      });
  };
