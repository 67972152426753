import React from "react";
import { Link } from "react-router-dom";
import BackgroundImg from "../../images/app/app_bg_new.png";
import AppleImg from "../../images/app/apple.png";
import GoogleImg from "../../images/app/google.png";
import OurAppImg from "../../images/app/our_app_new.png";

const OurApps = () => {
  return (
    <section
      className="bmp-app bg-right-bottom bg-cover bg-no-repeat object-contain xl:max-h-[450px] lg:max-h-[280px] h-full my-20"
      style={{ backgroundImage: `url(${BackgroundImg})` }}
    >
      <div className="container mx-auto ">
        <div className="grid grid-cols-6 items-center ">
          <div className="col-span-2 lg:block hidden xl:-mt-8">
            <img className="-translate-y-10 xl:min-h-[580px] " src={OurAppImg} alt="" />
          </div>
          <div className="lg:col-span-4 col-span-6 text-center xl:max-w-[600px] lg:mx-0 mx-auto py-20">
            <div className="app-content">
              <h3 className="md:text-3xl text-2xl font-bold font-serif text-black">
                British marketplace now your hand
              </h3>
              <p className="md:text-lg text-base font-serif md:pt-2 pt-1 text-[rgb(230,74,25)]">
                Download! to get this app Faster way to Order Food
              </p>
              <div className="app-stor flex justify-center gap-4 pt-5">
                <Link onClick={()=>window.open(`https://play.google.com/store/apps/details?id=uk.co.britishmarketplace.british_market_place`)} target="_blank">
                  <img src={GoogleImg} alt="" />
                </Link>
                <Link onClick={()=>window.open(`https://apps.apple.com/nl/app/british-marketplace/id1611486168?l=en`)} target="_blank">
                  <img src={AppleImg} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurApps;
