import {
  ADMIN_USER_LIST,
  ADMIN_USER_LOADING,
  ADMIN_USER_ERROR,
  ADMIN_USER_DETAILS,
  ADMIN_REGISTER_SINGLE_USER_DETAILS,
  ADMIN_USER_DETAILS_WITH_ADDRESS,
  ADMIN_USER_ADDRESS_DELETE,
  ADMIN_USER_ADDRESS_DELETE_CLEAN,
  ADMIN_LIMIT_USER_LIST,
  ADMIN_VENDOR_REGISTRATION_LIST,
  ADMIN_VENDOR_DETAILS,
} from "../../types";

const initialState = {
  loading: false,
  adminUserList: [],
  adminLimitUserList: [],
  vendorReqList: [],
  adminError: null,
  userDetails: {},
  userDetailsAddr: {},
  singleUserReg: {},
  deleteAddr: false,
  vendorDetails: {},
};

export default function adminUserReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADMIN_USER_LOADING:
      return {
        ...state,
        loading: true,
        adminError: null,
      };
    case ADMIN_USER_LIST:
      return {
        ...state,
        adminUserList: action.payload,
        loading: false,
        adminError: null,
      };
    case ADMIN_LIMIT_USER_LIST:
      return {
        ...state,
        adminLimitUserList: action.payload,
        loading: false,
        adminError: null,
      };

    case ADMIN_VENDOR_REGISTRATION_LIST:
      return {
        ...state,
        vendorReqList: action.payload,
        loading: false,
        adminError: null,
      };
    case ADMIN_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
        adminError: null,
      };
    case ADMIN_USER_DETAILS_WITH_ADDRESS:
      return {
        ...state,
        userDetailsAddr: action.payload,
        loading: false,
        adminError: null,
      };
    case ADMIN_REGISTER_SINGLE_USER_DETAILS:
      return {
        ...state,
        singleUserReg: action.payload,
        loading: false,
        adminError: null,
      };
    case ADMIN_USER_ADDRESS_DELETE:
      return {
        ...state,
        deleteAddr: true,
        loading: false,
        adminError: null,
      };
    case ADMIN_USER_ADDRESS_DELETE_CLEAN:
      return {
        ...state,
        deleteAddr: false,
        loading: false,
        adminError: null,
      };
    case ADMIN_USER_ERROR:
      return {
        ...state,
        adminError: action.payload,
        loading: false,
      };
    case ADMIN_VENDOR_DETAILS:
      return {
        ...state,
        vendorDetails: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
