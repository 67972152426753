import React, { useEffect, useState } from "react";
import Layout from "../Layout/layout";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Tooltip } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/common/Pagination";
import ModalContainer from "../../../components/common/ModalContainer";
import {
  adminVendorRegistrationDelete,
  adminVendorRegistrationListAction,
} from "../../../redux/actions/AdminActions/adminUserActions";

const VendorRequestList = () => {
  const { vendorReqList } = useSelector((state) => state.adminUser);
  console.log(vendorReqList);
  const [search, setSearch] = useState("");
  const [deleleState, setDeleleState] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  function closeModal() {
    setIsOpen(false);
  }
  const handleDeleteClick = (id) => {
    console.log(id);
    setIsOpen(true);
    setDeleleState(id);
  };
  const handleDelete = async () => {
    dispatch(adminVendorRegistrationDelete(deleleState));
    closeModal();
    setDeleleState(null);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    dispatch(
      adminVendorRegistrationListAction({ page: 1, search: e.target.value })
    );
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    dispatch(adminVendorRegistrationListAction({ page: currentPage }));
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  useEffect(() => {
    dispatch(adminVendorRegistrationListAction({ page: 1 }));
  }, [dispatch]);
  return (
    <Layout>
      <aside className="">
        <div className="m-3">
          <p className="text-center">Vendor Request List</p>
          <div className="flex items-center">
            {/* search bar */}
            <div className="relative md:w-1/3 my-2">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                value={search}
                onChange={handleSearch}
                id="simple-search"
                className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
                placeholder="Search here.."
              />
            </div>
          </div>
          <div className="overflow-x-scroll">
            <table className="table-auto  w-full border-collapse custom-table-data my-2">
              <thead className="text-center">
                <tr className="bg-gray-800 text-gray-300 ">
                  <th className="custom-table-data">Fast Name</th>
                  <th className="custom-table-data">Last Name</th>
                  <th className="custom-table-data">Email address</th>
                  <th className="custom-table-data">Phone Number</th>
                  <th className="custom-table-data">Restaurant Name</th>
                  <th className="custom-table-data"> Post Code</th>
                  <th className="custom-table-data"> Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {vendorReqList?.data?.map((user) => (
                  <tr key={user.id}>
                    <td className="custom-table-data">
                      {user?.vendor_first_name}
                    </td>
                    <td className="custom-table-data ">
                      {user?.vendor_last_name}
                    </td>
                    <td className="custom-table-data">{user.vendor_email}</td>
                    <td className="custom-table-data">
                      {user.vendor_phone_number}
                    </td>
                    <td className="custom-table-data">
                      {user.restaurant_name}
                    </td>
                    <td className="custom-table-data">{user?.postcode}</td>
                    <td className="custom-table-data text-xl">
                      <Tooltip content="View">
                        <Link to={`/admin/vendor/details/${user.id}`}>
                          <button>
                            {" "}
                            <AiOutlineEye className="mx-2 text-green-500" />
                          </button>
                        </Link>
                      </Tooltip>

                      <Tooltip content="Delete">
                        <button onClick={() => handleDeleteClick(user?.id)}>
                          <RiDeleteBin6Line className="mx-2 text-red-500" />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            pageCount={
              vendorReqList?.meta?.last_page > 0
                ? vendorReqList?.meta?.last_page
                : 0
            }
            handlePageClick={handlePageClick}
          />
        </div>
      </aside>
      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Delete user?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
};

export default VendorRequestList;
