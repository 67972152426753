import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout";

import {
  changeProfilePic,
  changeUserDefaultAddr,
  deleteUserAddress,
  editUserData,
  fetchUserAddress,
  passChangeAction,
  passChangeMessageClear,
} from "../redux/actions/authActions";

const Profile = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState(null);
  console.log(image);
  const [Checked, setChecked] = useState();
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [imagePreview, setimagePreview] = useState("");
  const {
    user,
    userAddress,
    passChangeMsg,
    passChangeLoading,
    authError,
    profileUpdateSuccess,
  } = useSelector((state) => state.auth);
  console.log(passChangeMsg);
  const dispatch = useDispatch();
  console.log(user);
  useEffect(() => {
    dispatch(fetchUserAddress());
  }, [dispatch]);
  useEffect(() => {
    if (user?.user.first_name) {
      setFirstName(user.user.first_name);
    }
    if (user?.user.last_name) {
      setLastName(user.user.last_name);
    }
    if (user?.user.email) {
      setEmail(user.user.email);
    }
    if (user?.user.phone_number) {
      setPhone(user.user.phone_number);
    }
  }, [user]);

  useEffect(() => {
    userAddress
      ?.filter((address) => address.default_address === true)
      .map((item) => setChecked(item.id));
  }, [userAddress]);

  useEffect(() => {
    if (passChangeMsg) toast.success(passChangeMsg, { duration: 5000 });
    if (authError) toast.error(authError, { duration: 5000 });
    if (profileUpdateSuccess)
      toast.success("Your Profile Updated Successfully", { duration: 5000 });
    dispatch(passChangeMessageClear());
  }, [passChangeMsg, authError, profileUpdateSuccess, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editUserData({
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phone,
      })
    );
    if (image) {
      const uploadData = new FormData();
      uploadData.append("profile_pic", image, image.name);
      dispatch(changeProfilePic(uploadData, user.id));
    }
  };

  const handleAddressSelect = (id) => {
    console.log(id);
    setChecked(id);
    dispatch(changeUserDefaultAddr(id));
  };

  const handlePassChange = (e) => {
    e.preventDefault();
    dispatch(
      passChangeAction({
        old_password: currentPass,
        new_password: newPass,
      })
    );
    setCurrentPass("");
    setNewPass("");
  };

  if (image) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = function () {
        setimagePreview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }
  const responseMessage = (response) => {
    console.log("success", response);
};
const errorMessage = (error) => {
    console.log("fail",error);
};
const clientId = "382129473346-0q7pg4q7bf3rgcr6ibpfgdq06krs7mrk.apps.googleusercontent.com"
  return (
    <Layout>
      <section className="profile-area py-8 bg-white">
        <div className="container">
          <div className="section-wrapper pt-6">
            <Toaster />
            <div className="profile-content max-w-max-profile mx-auto">
              <div className="section-header text-center mb-8">
                <h3 className="text-black font-semibold text-2xl">
                  My Account
                </h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="flex">
                  {image ? (
                    <img
                      src={imagePreview}
                      alt="preview"
                      className=" h-14 w-14 mr-3 rounded-full"
                    />
                  ) : (
                    <div
                      className="profile-thumb h-12 w-12 text-center bg-green-400 rounded-full mr-4"
                      style={{ lineHeight: "60px" }}
                    >
                      <label htmlFor="profileThumb" className="inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </label>
                    </div>
                  )}

                  <label
                    htmlFor="profileThumb"
                    className="w-48 flex items-center px-4 py-2 bg-white text-black rounded-lg shadow-lg tracking-wide capitalize border border-blue cursor-pointer hover:bg-[#89e488] hover:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="ml-2" style={{ transform: "scale(1)" }}>
                      Upload Image
                    </span>
                  </label>
                  <input
                    type="file"
                    name="profile-image"
                    id="profileThumb"
                    className="hidden"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
                <div className="rounded py-6">
                  <div className="pb-6">
                    <div className="flex justify-between gap-3">
                      <input
                        className="bg-white shadow-themeShadow rounded-md px-4 py-3 w-full"
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <input
                        className="bg-white shadow-themeShadow rounded-md px-4 py-3 w-full"
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="pb-4">
                    <input
                      className="bg-white shadow-themeShadow rounded-md px-4 py-3 w-full"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="pb-4">
                    <input
                      className="bg-white shadow-themeShadow rounded-md px-4 py-3 w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      //placeholder:text-black
                      type="number"
                      placeholder="+44-12345678910"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="submit-btn text-right">
                  <button
                    type="submit"
                    className="text-black text-base font-medium rounded-md bg-gray-200 hover:bg-[#89e488] hover:text-white px-6 py-3"
                  >
                    Save Change
                  </button>
                </div>
              </form>

              <div className="section-header text-center mb-8 pt-8">
                <h3 className="text-black font-semibold text-2xl">Address</h3>
              </div>

              {userAddress?.map((address) => (
                <div
                  key={address.id}
                  className="delete-text flex justify-between items-start text-black mb-4"
                >
                  <div className="text-col text-xs leading-5">
                    <p>{address.others}</p>
                    <p>
                      {address.city} {address.state} {address.country}{" "}
                      {address.postcode}
                    </p>
                    <p>{address.phone_number}</p>
                  </div>
                  <div className="flex">
                    {/* <button type="button" className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </button> */}
                    <div>
                      <label className="flex items-center cursor-pointer text-xl">
                        <span
                          className={
                            Checked === address.id
                              ? "w-6 h-6 inline-block mr-2 rounded-sm border border-grey flex-no-shrink radioButton"
                              : "w-6 h-6 inline-block mr-2 rounded-sm border border-grey flex-no-shrink"
                          }
                          onClick={() => handleAddressSelect(address.id)}
                        ></span>
                      </label>
                    </div>
                    <button
                      type="button"
                      onClick={() => dispatch(deleteUserAddress(address.id))}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#C8102E"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}

              <div className="section-header text-center mb-8 mt-8">
                <h3 className="text-black font-semibold text-2xl">Password</h3>
              </div>
              <form onSubmit={handlePassChange}>
                <div className="rounded py-6">
                  <div className="pb-6">
                    <div className="flex">
                      <input
                        id="currentPassword"
                        className="bg-white shadow-themeShadow rounded-md px-4 py-3 w-full placeholder:text-black"
                        type="password"
                        placeholder="Current Password"
                        required
                        value={currentPass}
                        onChange={(e) => setCurrentPass(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="pb-4">
                    <input
                      id="newPassword"
                      className="bg-white shadow-themeShadow rounded-md px-4 py-3 w-full placeholder:text-black"
                      type="password"
                      placeholder="New Password"
                      required
                      value={newPass}
                      onChange={(e) => setNewPass(e.target.value)}
                    />
                  </div>
                </div>
                <div className="submit-btn text-right">
                  {passChangeLoading ? (
                    <button
                      type="submit"
                      className="text-black text-base font-medium rounded-md bg-gray-200 px-6 py-3"
                      disabled
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="text-black text-base font-medium rounded-md bg-gray-200 px-6 py-3"
                    >
                      Save Change
                    </button>
                  )}
                </div>
              </form>

              <div className="section-header text-center mb-8 mt-8">
                <h3 className="text-black font-semibold text-2xl">
                  Social Account
                </h3>
              </div>
              <div className="sm:flex flex-wrap justify-between">
              {/* <GoogleLogin
              clientId={clientId}
           onSuccess={responseMessage} onError={errorMessage} 
          
          > */}
                <button
                  type="button"
                  className="flex bg-googleColor px-6 py-3 rounded-md text-white text-base w-full sm:w-auto"
                >
                  <img src="../public/images/profile/google.png" alt="" />
                  <span className="text-base">Sign in with google</span>
                </button>
              {/* </GoogleLogin> */}
                <button
                  type="button"
                  className="flex bg-facebookColor px-6 py-3 rounded-md text-white text-base w-full sm:w-auto mt-4 sm:mt-0"
                >
                  <img src="../public/images/profile/facebook.png" alt="" />
                  <span className="text-base ml-2">Sign in with Facebook</span>
                </button>
              </div>
            
              <p className="text-base text-gray-600 mt-4">
                You currently have no account connected
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Profile;
