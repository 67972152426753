import "./App.css";

import RouteApp from "./route";

function App() {
  return (
    <div>
      <RouteApp />
    </div>
   
  );
}

export default App;
