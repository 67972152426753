import axiosNewInstance from "axios";
import { API_URL } from "../../settings/config";
import {
  PLACE_ORDER_SUCCESS,
  // PLACE_ORDER_LOADING,
  REQUEST_CART_SUCCESS,
  GET_ORDER_SUMMERY,
  GET_ORDER_SUMMERY_LOADING,
  SUBMIT_PAYMENT_CARD_SUCCESS,
  // SUBMIT_PAYMENT_CARD_LOADING,
  SAVE_PAYMENT_INFO_LOADING,
  FETCH_ORDER_HISTORY,
  LOADING_ORDER,
  GET_VOUCHER_DISCOUNT,
  GET_ORDER_TRACK,
  GET_VOUCHER_DISCOUNT_ERROR,
} from "../types";
import auth from "../../util/auth";
import axios from "axios";
import { logoutAction } from "./authActions";

export const placeOrder =
  (
    cartData,
    newTotalWithPrice,
    serviceFee,
    voucherCode,
    discount_amount,
    total,
    res_id,
    dateValue,
    timeValue,
    subAddress,
    cutlerySwitch,
    deliveryOption,
    delivertTime,
    history
  ) =>
  async (dispatch) => {
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    await axiosNewInstance
      .post(
        `${API_URL}/order/`,
        {
          cartData,
          subtotal_price: newTotalWithPrice,
          service_fee: serviceFee,
          voucher_code: voucherCode,
          discount_amount: discount_amount,
          total,
          res_id,
          schedule_time: timeValue,
          schedule_date: dateValue,
          shipping_address: subAddress,
          cutlery: cutlerySwitch,
          delivery_option: deliveryOption,
          delivery_time: delivertTime,
        },
        config
      )
      .then((res) => {
        console.log(res);

        // dispatch({ type: REQUEST_CART_SUCCESS, payload: [] });
        // localStorage.setItem("cart", JSON.stringify([]));
        dispatch({ type: PLACE_ORDER_SUCCESS });
        sessionStorage.removeItem("discount_amount");
        history.push("/payment");
      })
      .catch((err) => console.log(err));
  };

export const getOrderSummery = () => async (dispatch) => {
  dispatch({ type: GET_ORDER_SUMMERY_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .get(`${API_URL}/order/order-summary/`, config)
    .then((res) => dispatch({ type: GET_ORDER_SUMMERY, payload: res.data }))
    .catch((err) => console.log(err));
};

export const submitPaymentCard = (brand) => async (dispatch) => {
  let token = auth.getToken();

  console.log(token);

  await axios
    .get(`${API_URL}/order/payment-card/`, {
      params: {
        brand: brand,
      },
      headers: { Authorization: token },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: SUBMIT_PAYMENT_CARD_SUCCESS,
        payload: res.data.message,
      });
    })
    .catch((err) => console.log(err));
};

export const savePaymentInfo = (data) => async (dispatch) => {
  dispatch({ type: SAVE_PAYMENT_INFO_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .post(
      `${API_URL}/order/customer-payment-info/`,
      {
        orderID: data.orderID,
        amount: data.amount,
        currency: data.currency,
        card_no: data.card_no,
        brand: data.brand,
        ip: data.ip,
        payable_date: data.payable_date,
      },
      config
    )
    .then((res) => {
      console.log(res);
      dispatch({ type: REQUEST_CART_SUCCESS, payload: [] });
      localStorage.setItem("cart", JSON.stringify([]));
    })
    .catch((err) => console.log(err));
};

export const payCashOnDelivery = (history) => async (dispatch) => {
  dispatch({ type: SAVE_PAYMENT_INFO_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axiosNewInstance
    .put(`${API_URL}/order/cash-on-delivery/`, {}, config)
    .then((res) => {
      dispatch({ type: REQUEST_CART_SUCCESS, payload: [] });
      localStorage.setItem("cart", JSON.stringify([]));
      localStorage.setItem("order_data", JSON.stringify(res.data.data));
      history.push("/order");
    })
    .catch((err) => {
      if (err.response.status === 401) {
        console.log(err.response.status);
        // router.push("/login");
        // window.location.pathname = "/login";
        dispatch(logoutAction());
        // console.log(window.location.pathname);
      }
      console.log(err);
    });
};

export const fetchOrderHistory = () => async (dispatch) => {
  console.log("enter");
  dispatch({ type: LOADING_ORDER });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .get(`${API_URL}/order/order-history/`, config)
    .then((res) => {
      dispatch({ type: FETCH_ORDER_HISTORY, payload: res.data });
    })
    .catch((err) => console.log(err));
};

export const fetchOrderTrack = (orderId) => async (dispatch) => {
  dispatch({ type: LOADING_ORDER });
  let token = auth.getToken();
  console.log(orderId);
  axios
    .get(`${API_URL}/order/track`, {
      params: {
        orderId,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({ type: GET_ORDER_TRACK, payload: res.data });
    })
    .catch((err) => console.log(err));
};

export const cancelOrder = (orderId) => async (dispatch) => {
  dispatch({ type: GET_ORDER_SUMMERY_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .put(
      `${API_URL}/order/order-cancel/`,
      {
        orderId,
      },
      config
    )
    .then((res) => {
      console.log(res);
      dispatch(fetchOrderHistory());
    })
    .catch((err) => console.log(err));
};

export const deleteOrder = (orderId) => async (dispatch) => {
  dispatch({ type: GET_ORDER_SUMMERY_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .delete(
      `${API_URL}/order/order-delete/${orderId}/`,

      config
    )
    .then((res) => {
      console.log(res);
      dispatch(fetchOrderHistory());
    })
    .catch((err) => console.log(err));
};

export const deleteAllPastOrder = () => async (dispatch) => {
  dispatch({ type: GET_ORDER_SUMMERY_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .delete(
      `${API_URL}/order/past-order-delete/`,

      config
    )
    .then((res) => {
      console.log(res);
      dispatch(fetchOrderHistory());
    })
    .catch((err) => console.log(err));
};

export const getVoucherDiscount = (data) => async (dispatch) => {
  dispatch({ type: LOADING_ORDER });
  let token = auth.getToken();
  axios
    .get(`${API_URL}/voucher`, {
      params: {
        code: data.code,
        order_price: data.order_price,
        restaurant_branch: data.restaurant_branch,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res);
      if (res.data.discount_amount) {
        dispatch({
          type: GET_VOUCHER_DISCOUNT,
          payload: res.data.discount_amount,
        });
        sessionStorage.setItem("discount_amount", res.data.discount_amount);
      } else {
        dispatch({
          type: GET_VOUCHER_DISCOUNT_ERROR,
          payload: res.data.message,
        });
        sessionStorage.removeItem("discount_amount");
      }
    })
    .catch((err) => console.log(err));
};

export const cancelReviewRating = (orderId) => async (dispatch) => {
  dispatch({ type: LOADING_ORDER });
  let token = auth.getToken();
  axios
    .get(`${API_URL}/review/cancel/`, {
      params: {
        order_id: orderId,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => console.log(err));
};
