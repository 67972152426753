import React, { useEffect, useState } from "react";
import MenuItemsCreateUpdateForm from "../../../components/admin/Forms/MenuItems/MenuItemsCreateUpdateForm";
import Layout from "../Layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { adminRestaurantBranchNameOnlyList } from "../../../redux/actions/AdminActions/adminRestaurantActions";
import {
  adminMenuCategoriesName,
  adminMenuItemAdd,
} from "../../../redux/actions/AdminActions/adminMenuAction";
import { useHistory } from "react-router";

function AddMenuItems() {
  const { resBranchNameOnlyList } = useSelector(
    (state) => state.adminRestaurant
  );

  const { menuCategoriesName } = useSelector((state) => state.adminMenu);
  console.log(menuCategoriesName);

  const [detailsText, setDetailsText] = useState("");
  const [cookingMethodText, setCookingMethodText] = useState("");
  const [allergenInfoText, setAllergenInfoText] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(adminRestaurantBranchNameOnlyList());
    dispatch(adminMenuCategoriesName());
  }, [dispatch]);

  const handleSubmitData = (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("discount", data.discount);
    formData.append("review", data.review);
    formData.append("countInStock", data.stockcount);
    formData.append("status", data.status);
    formData.append("details", detailsText);
    formData.append("allergen_info", allergenInfoText);
    formData.append("cooking_method", cookingMethodText);
    if (data.image.length > 0) {
      formData.append("image", data.image[0]);
    }

    formData.append("restaurant_branch", parseInt(data.restaurantBranch.value));
    data.categories.forEach((category) => {
      formData.append("categories", category.value);
    });

    dispatch(adminMenuItemAdd(formData, history));
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <MenuItemsCreateUpdateForm
            resBranchNameOnlyList={resBranchNameOnlyList}
            menuCategoriesName={menuCategoriesName}
            handleSubmitData={handleSubmitData}
            detailsText={detailsText}
            setDetailsText={setDetailsText}
            cookingMethodText={cookingMethodText}
            setCookingMethodText={setCookingMethodText}
            allergenInfoText={allergenInfoText}
            setAllergenInfoText={setAllergenInfoText}
          />
        </div>
      </div>
    </Layout>
  );
}

export default AddMenuItems;
