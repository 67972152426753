import React, { useState } from "react";
import { Animate } from "react-simple-animate";

const HygieneCheck = ({ hygieneOption, handleToggle }) => {
  const [open, setOpen] = useState(false);
  const handleExpandToggle = () => {
    setOpen(!open);
  };
  console.log({hygieneOption});
  return (
    <>
      <button
        type="button"
        className="flex justify-between items-center py-4 cursor-pointer"
        onClick={handleExpandToggle}
      >
        <span className="text-lg text-black font-medium">Hygiene rating</span>
       
          <svg className={`arrow ${open ? '' : 'open'}`} width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.50834 6.4927C7.58784 6.39158 7.63401 6.31826 7.69299 6.25759C9.71132 4.2655 11.7297 2.27593 13.7531 0.288896C13.8788 0.167551 14.048 0.0639011 14.2173 0.0183964C14.4789 -0.0549166 14.7661 0.0992936 14.9046 0.331873C15.0457 0.56698 15.0303 0.865289 14.8585 1.0827C14.8097 1.14337 14.7533 1.19646 14.6994 1.25208C12.4939 3.42619 10.2909 5.6003 8.08537 7.77188C7.67247 8.1789 7.32369 8.1789 6.91336 7.77441C4.69242 5.58513 2.47149 3.39838 0.253117 1.2091C-0.172605 0.789448 -0.0418107 0.172607 0.499318 0.0285086C0.76347 -0.0422764 0.989154 0.0360927 1.17893 0.223167C1.56619 0.604901 1.95344 0.984106 2.34069 1.36584C3.99742 2.99895 5.65671 4.63459 7.31343 6.2677C7.36729 6.32332 7.41345 6.38399 7.50834 6.4927Z" fill="#0DC1D9"/>
</svg>

      
       
      </button>
      {open ? (
        <Animate
          play
          start={{ opacity: 0, transform: "translateY(-10)" }}
          end={{ opacity: 1, transform: "translateY(0)" }}
          duration={0.5}
        >
          <div className="bg-white shadow-themeShadow lg:p-5 p-2 rounded-md ">
            <ul className="">
              <li className="mt-4">
                <input
                  type="checkbox"
                  // name=""
                  // id="res1"
                  checked={hygieneOption.indexOf("5") === -1 ? false : true}
                  onChange={() => handleToggle("5", "hygiene")}
                  className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
                  //   onChange={() => handleToggle(category.name)}
                />
                <label
                  htmlFor="res1"
                  className="
                            text-gray-700
                            lg:text-md
                            text-sm
                            cursor-pointer
                          "
                >
                  Hygiene rating: 5
                </label>
              </li>
              <li className="mt-4">
                <input
                  type="checkbox"
                  // name=""
                  // id="res1"
                  //   checked={Checked.indexOf(category.name) === -1 ? false : true}
                  checked={hygieneOption.indexOf("4") === -1 ? false : true}
                  onChange={() => handleToggle("4", "hygiene")}
                  className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
                  //   onChange={() => handleToggle(category.name)}
                />
                <label
                  htmlFor="res1"
                  className="
                            text-gray-700
                            lg:text-md
                            text-sm
                            cursor-pointer
                          "
                >
                  Hygiene rating: 4
                </label>
              </li>
              <li className="mt-4">
                <input
                  type="checkbox"
                  // name=""
                  // id="res1"
                  //   checked={Checked.indexOf(category.name) === -1 ? false : true}
                  checked={hygieneOption.indexOf("3") === -1 ? false : true}
                  onChange={() => handleToggle("3", "hygiene")}
                  className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
                  //   onChange={() => handleToggle(category.name)}
                />
                <label
                  htmlFor="res1"
                  className="
                            text-gray-700
                            lg:text-md
                            text-sm
                            cursor-pointer
                          "
                >
                  Hygiene rating: 3
                </label>
              </li>
              <li className="mt-4">
                <input
                  type="checkbox"
                  // name=""
                  // id="res1"
                  //   checked={Checked.indexOf(category.name) === -1 ? false : true}
                  checked={hygieneOption.indexOf("2") === -1 ? false : true}
                  onChange={() => handleToggle("2", "hygiene")}
                  className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
                  //   onChange={() => handleToggle(category.name)}
                />
                <label
                  htmlFor="res1"
                  className="
                            text-gray-700
                            lg:text-md
                            text-sm
                            cursor-pointer
                          "
                >
                  Hygiene rating: 2
                </label>
              </li>
              <li className="mt-4">
                <input
                  type="checkbox"
                  // name=""
                  // id="res1"
                  //   checked={Checked.indexOf(category.name) === -1 ? false : true}
                  checked={hygieneOption.indexOf("1") === -1 ? false : true}
                  className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
                  onChange={() => handleToggle("1", "hygiene")}
                />
                <label
                  htmlFor="res1"
                  className="
                            text-gray-700
                            lg:text-md
                            text-sm
                            cursor-pointer
                          "
                >
                  Hygiene rating: 1
                </label>
              </li>
            </ul>
          </div>
        </Animate>
      ) : (
        ""
      )}
    </>
  );
};

export default HygieneCheck;
