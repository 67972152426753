import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import "moment-timezone";

const VoucherCreateUpdateForm = ({
  edit,
  onSubmit,
  restaurantBranchData,
  voucher,
  vendor,
}) => {
  const [resBranchSelectOption, setResBranchSelectOption] = useState([]);
  useEffect(() => {
    const restaurantBranchOptions = [];
    restaurantBranchData.map((option) =>
      restaurantBranchOptions.push({ value: option.id, label: option.name })
    );

    setResBranchSelectOption(restaurantBranchOptions);
  }, [restaurantBranchData]);

  // console.log(restaurantBranchData);
  // console.log(resBranchSelectOption);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: 32,
    }),
  };
  // console.log(voucher);
  useEffect(() => {
    if (voucher) {
      // var dt = new Date(voucher?.starts_at);
      // console.log(dt);
      setValue("code", voucher?.code);
      setValue("description", voucher?.description);
      setValue("uses", voucher?.uses);
      setValue("max_uses", voucher?.max_uses);
      setValue("max_uses_user", voucher?.max_uses_user);
      setValue("discount_amount", voucher?.discount_amount);
      setValue("min_order_price", voucher?.min_order_price);
      setValue("type", voucher?.type);
      setValue("is_fixed", voucher?.is_fixed);
      setValue("is_admin_code", voucher?.is_admin_code);
      setValue("status", voucher?.status);
      setValue(
        "starts_at",
        moment(voucher?.starts_at).format("YYYY-MM-DDTkk:mm")
      );
      setValue(
        "expires_at",
        moment(voucher?.expires_at).format("YYYY-MM-DDTkk:mm")
      );

      // console.log(voucher.starts_at);
      if (resBranchSelectOption.length > 0) {
        const selectedOption = resBranchSelectOption.find(
          (item) =>
            parseInt(item.value) === parseInt(voucher.restaurant_branch.id)
        );
        console.log(selectedOption);

        setValue("restaurantBranch", {
          value: selectedOption.value,
          label: selectedOption.label,
        });
      }
    }
  }, [voucher, resBranchSelectOption, setValue]);
  // console.log(edit);
  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">
        {edit === true ? "Edit" : "Add"} Voucher{" "}
      </h1>
      <div>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="md:grid md:grid-cols-3 gap-3 pt-2">
            <div className="">
              <label htmlFor="restaurantBranch" className=" text-gray-700">
                Restaturant Branch
              </label>
              <Controller
                name="restaurantBranch"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={resBranchSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.restaurantBranch &&
              errors.restaurantBranch.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Code
              </label>
              <input
                {...register("code", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.code?.message}</p>
            </div>
            <div className="">
              <label htmlFor="name" className=" text-gray-700">
                Description
              </label>

              <input
                type="text"
                {...register("description", {
                  required: edit ? false : "This field is required",
                })}
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.description?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Max uses
              </label>
              <input
                {...register("max_uses", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.max_uses?.message}</p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Max uses user
              </label>
              <input
                {...register("max_uses_user", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.max_uses_user?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Type
              </label>
              <input
                {...register("type", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.type?.message}</p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Discount amount
              </label>
              <input
                {...register("discount_amount", {
                  required: edit ? false : "This field is required",
                })}
                type="number"
                step="any"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.discount_amount?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Starts at
              </label>
              <input
                {...register("starts_at", {
                  required: edit ? false : "This field is required",
                })}
                type="datetime-local"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.starts_at?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Expires at
              </label>
              <input
                {...register("expires_at", {
                  required: edit ? false : "This field is required",
                })}
                type="datetime-local"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.expires_at?.message}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Min order price
              </label>
              <input
                {...register("min_order_price", {
                  required: edit ? false : "This field is required",
                })}
                type="number"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.min_order_price?.message}
              </p>
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-2 pt-2">
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="flexCheckChecked"
                {...register("is_fix")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                for="flexCheckChecked"
              >
                Fixed
              </label>
            </div>
            {vendor ? null : (
              <div className="form-check flex items-center">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer "
                  type="checkbox"
                  id="flexCheckChecked1"
                  {...register("is_admin_code")}
                />
                <label
                  className="form-check-label inline-block text-gray-800 "
                  for="flexCheckChecked1"
                >
                  Admin code
                </label>
              </div>
            )}

            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="flexCheckChecked"
                {...register("status")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                for="flexCheckChecked"
              >
                Status
              </label>
            </div>
          </div>
          <div className=" mt-2">
            <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VoucherCreateUpdateForm;
