import React from "react";

const AllIngredients = ({
  products,
  handleAddCart,
  homeMadeCart,
  handleRemoveCart,
}) => {
  return (
    <div className="all_ingredients">
      <div className="item_head py-6">
        <h4 className="text-black lg:text-xl md:text-lg text-base font-medium font-body">
          All Ingredients
        </h4>
      </div>
      <div className="grid lg:grid-cols-5 sm:grid-cols-2 grid-cols-1 gap-4">
        {products?.map((product) => (
          <div key={product?.id} className="ing_item">
            <div className="item_thumb">
              <img
                className="w-full rounded-md lg:h-[150px] md:h-[180px]"
                src={product.image}
                alt={product.name}
              />
            </div>
            <div className="item_text pt-4">
              <h4 className="md:text-sm text-xs text-black font-normal font-body line-clamp-2 h-10">
                {product.name}
              </h4>
              <div className="xl:flex block flex-wrap items-center justify-between gap-3 pt-5">
                <span className="xl:flex block xl:mb-0 mb-2 md:text-base text-sm font-medium text-black font-body">
                  {/* +£{product.price} */}
                </span>
                {homeMadeCart.length > 0 ? (
                  homeMadeCart.find((item) => {
                    console.log(item.id, product.id);
                    return parseInt(item.product.id) === parseInt(product.id);
                  }) ? (
                    <button
                      onClick={() => handleRemoveCart(product.id)}
                      type="button"
                      className="custom-btn px-5 py-[6px] w-[100px] bg-[#f80303] text-white"
                    >
                      Remove?
                    </button>
                  ) : (
                    <button
                      onClick={() => handleAddCart(product)}
                      type="button"
                      className="custom-btn px-5 py-[6px] w-[100px] bg-[#0DC1D9] text-white"
                    >
                      Add
                    </button>
                  )
                ) : (
                  <button
                    onClick={() => handleAddCart(product)}
                    type="button"
                    className="custom-btn px-5 py-[6px] bg-[#0DC1D9] w-[100px] text-white"
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllIngredients;
