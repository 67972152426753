import {
  FETCH_WISHLIST,
  GET_RESTAURANT_RATING_DATA,
  INCREASE_FOOD_VARIATIONS,
  INDIVIDUAL_ITEM_DECREASE,
  INDIVIDUAL_ITEM_INCREASE,
  LOADING_DATA,
  SEARCH_RESTAURANT,
  SEARCH_RESTAURANT_FOOD,
  // SET_ERRORS,
  // SERVER_ERROR,
  SET_CATEGORIES,
  SET_FOOD_CATEGORIES,
  SET_FOOD_MODAL_CLOSE,
  SET_FOOD_MODAL_OPEN,
  SET_FOOD_VARIATION,
  SET_FOOD_VARIATION_EMPTY,
  SET_RESTAURANTS,
  SET_RESTAURANT_DETAILS,
  SET_RESTAURANT_ERROR,
  TOGGLE_WISHLIST_NOTIFICATION,
  TOGGLE_WISHLIST_SUCCESS,
} from "../types";
// import axiosInstance from "axios";
import qs from "qs";
import { API_URL } from "../../settings/config";
import auth from "../../util/auth";
import axios from "../../util/axios";

export const foodModalOpenAction = () => (dispatch) => {
  dispatch({ type: SET_FOOD_MODAL_OPEN });
};

export const foodModalCloseAction = () => (dispatch) => {
  dispatch({ type: SET_FOOD_MODAL_CLOSE });
};

export const fetchRestaurantsByAddress = (queryData) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`${API_URL}/restaurant/search/`, {
      params: {
        lat: queryData.lat,
        lng: queryData.lng,
        categories: queryData.categories,
        doption: queryData.doption,
        foodOption: queryData.foodOption,
        hygieneOption: queryData.hygieneOption,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((res) => {
      dispatch({
        type: SET_RESTAURANTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err?.response?.status);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const fetchRestaurantCategories = () => async (dispatch) => {
  await axios
    .get(`${API_URL}/restaurant/category/`)
    .then((res) => {
      dispatch({
        type: SET_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err?.response?.data);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const fetchFoodCategories = () => async (dispatch) => {
  dispatch({ type: LOADING_DATA });
  await axios
    .get(`${API_URL}/menu/categories/`)
    .then((res) => {
      dispatch({
        type: SET_FOOD_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err?.request);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const fetchRestaurantDetails = (id) => async (dispatch) => {
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .get(`${API_URL}/restaurant/branch/${id}/`, config)
    .then((res) => {
      dispatch({
        type: SET_RESTAURANT_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const fetchFoodVariation = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DATA });
  await axios
    .get(`${API_URL}/menu/item/${id}/`)
    .then((res) => {
      dispatch({
        type: SET_FOOD_VARIATION,
        payload: { ...res.data, quantity: 1 },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const searchRestaurant = (data) => async (dispatch) => {
  dispatch({ type: LOADING_DATA });
  console.log(data);
  await axios
    .get(`${API_URL}/restaurant/suggestion/`, {
      params: {
        lat: data.lat,
        lng: data.lat,
        s: data.s,
      },
    })
    .then((res) => {
      dispatch({ type: SEARCH_RESTAURANT, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const searchRestaurantFood = (data) => async (dispatch) => {
  dispatch({ type: LOADING_DATA });
  await axios
    .get(`${API_URL}/restaurant/food/suggestion/`, {
      params: {
        s: data?.search,
        res_branch: data?.res_branch,
      },
    })
    .then((res) => {
      dispatch({ type: SEARCH_RESTAURANT_FOOD, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const addWishlistRes = (res_id) => async (dispatch) => {
  dispatch({ type: LOADING_DATA, payload: res_id });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .post(
      `${API_URL}/wishlist/list/`,
      {
        res_id,
      },
      config
    )
    .then((res) => {
      console.log(res);
      dispatch(fetchRestaurantDetails(res_id));
      dispatch({ type: TOGGLE_WISHLIST_NOTIFICATION, payload: res.data.message });

    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const fetchWishListRes = (data) => async (dispatch) => {
  console.log({data}, "fetch");
  dispatch({ type: LOADING_DATA });
  let token = auth.getToken();
  console.log(data);

  await axios
    .get(`${API_URL}/wishlist/list/`, {
      params: {
        lat: data?.lat,
        lng: data?.lng,
      },
      headers: { Authorization: token },
    })
    .then((res) => {
      dispatch({ type: FETCH_WISHLIST, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const toggleWishlist = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DATA });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  axios
    .post(
      `${API_URL}/wishlist/list/`,
      {
        res_id: id,
      },
      config
    )
    .then((res) => {
      console.log(res);
      dispatch({ type: TOGGLE_WISHLIST_SUCCESS, payload: res.data.message });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_RESTAURANT_ERROR,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    });
};

export const setEmptyVariation = () => (dispatch) => {
  dispatch({ type: SET_FOOD_VARIATION_EMPTY });
};

export const foodVariationIncrease = (foodItem) => (dispatch) => {
  dispatch({ type: INCREASE_FOOD_VARIATIONS, payload: foodItem });
};

export const individualItemIncrease = (id) => (dispatch) => {
  console.log(id);
  dispatch({ type: INDIVIDUAL_ITEM_INCREASE, payload: id });
};

export const individualItemDecrease = (id, amount) => (dispatch) => {
  dispatch({ type: INDIVIDUAL_ITEM_DECREASE, payload: { id, amount } });
};

export const toggleIngredientPrice = (price) => {};
export const restaurantRatingAction = (id) => async (dispatch) => {
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/review/review-rating`, {
      params: {
        res_id: id,
      },
      headers: { Authorization: token },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({ type: GET_RESTAURANT_RATING_DATA, payload: res.data });
    });
};
