import React from 'react';
import paymentThumb from "../../images/banner/faq_payment_thumb.png";

const FaqPayment = () => {
  return (
    <div className="container relative">
      <div className='absolute   xl:-right-12 lg:-right-3 -top-20 -z-10'>
      <svg width="113" height="261" viewBox="0 0 113 261" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.5 261C202.573 261 261 202.573 261 130.5C261 58.4268 202.573 0 130.5 0C58.4268 0 0 58.4268 0 130.5C0 202.573 58.4268 261 130.5 261ZM130.5 223.504C181.865 223.504 223.504 181.865 223.504 130.5C223.504 79.1352 181.865 37.4958 130.5 37.4958C79.1352 37.4958 37.4958 79.1352 37.4958 130.5C37.4958 181.865 79.1352 223.504 130.5 223.504Z" fill="#028FF0" fill-opacity="0.25"/>
</svg>

</div>
        <div
  className="
   flex justify-between items-center xl:gap-20 lg:gap-12 md:h-bg-height h-[400px] rounded-md "
>
 
  <div className="banner-tex lg:w-[60%] w-full lg:pt-16">
    <h2 className="xl:text-3xl md:text-2xl text-xl text-[#002138] md:pb-3 pb-2 ">
    Payments
    </h2>
    <p className="md:text-lg text-base text-black font-normal font-oswald">
    What do I do if I have a charge I do not recognise?
    </p>
    <p className="text-base text-black font-oswald pb-2 text-[#4F4F4F] ">
    British Marketplace finds that unrecognized charges can often be attributed to a friend, coworker, or family member using your payment method. We ask that you check with your family and friends to see if they may have used your card to place an order viaBritish Marketplace .
    </p>
    <ul className='list-disc lg:px-8 px-3'>
      <li>Last four digits of the credit or debit card used</li>
      <li>The card issuing company (such as Visa or MasterCard)</li>
      <li>The expiry date of your card</li>
      <li>The dates and amounts of the transactions</li>
      <li>The country and currency registered to your card</li>
      <li>The debiting party (eg. BMPUK, BritishMarketPlace)</li>
      <li>The email address associated to your British Marketplace account (if user)</li>
      
    </ul>
  
   
  </div>
  <div className="banner-thumb xl:w-[40%] lg:w-[40%] lg:block hidden lg:mt-20 relative">
    <img className="mt-0 ml-auto" src={paymentThumb} alt="" />
  </div>
</div>
    </div>
  )
}

export default FaqPayment