import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { submitContact } from "../redux/actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_CONTACT_US } from "../redux/types";
import toast, { Toaster } from "react-hot-toast";

const ContactUs = () => {
  const { contactUsSuccess } = useSelector((state) => state.common);
  const [toggleState, setToggleState] = useState(1);
  function handleToggleTab(index) {
    setToggleState(index);
  }
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [body, setBody] = useState("");
  const [experience, setExperience] = useState("");
  const [chooseOption, setChooseOption] = useState([]);

  useEffect(() => {
    if (contactUsSuccess) {
      toast.success("Thanks for your submission", { duration: 5000 });
      dispatch({ type: CLEAR_CONTACT_US });
    }
  }, [contactUsSuccess, dispatch]);

  const handleToggle = (value) => {
    let newOption = [...chooseOption];
    const currentIndex = chooseOption.indexOf(value);
    if (currentIndex === -1) {
      newOption.push(value);
    } else {
      newOption.splice(currentIndex, 1);
    }
    setChooseOption(newOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      submitContact({
        name,
        email,
        phone,
        body,
        causes: chooseOption,
        experience,
      })
    );
    setName("");
    setEmail("");
    setPhone("");
    setExperience("");
    setChooseOption("");
    setBody("");
    setToggleState(1);
  };

  return (
    <Layout>
      <section className="contact-us py-12">
        <div className="container">
          <Toaster />
          <div className="section-wrapper text-center pb-8">
            <h3 className="text-black font-semibold text-3xl font-mono">
              Contact Us
            </h3>
          </div>
          <div className="grid md:grid-cols-2">
            <div className="contact-details pb-8 md:pb-0">
              <div className="de-item flex pb-4">
                <div className="item-icon w-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </div>
                <div className="item-text flex">
                  <div className="text-title">
                    <h4 className="text-black font-medium text-xl mr-3 w-24">
                      Address :
                    </h4>
                  </div>
                  <div className="text-des">
                    <span className="text-gray-500 text-sm">
                      218-220 Whitechapel Road, Post Code: <br />
                      E1 1BJ, London, United Kingdom
                    </span>
                  </div>
                </div>
              </div>
              <div className="de-item flex pb-4">
                <div className="item-icon w-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                    />
                  </svg>
                </div>
                <div className="item-text flex">
                  <div className="text-title">
                    <h4 className="text-black font-medium text-xl mr-3 w-24">
                      Email :
                    </h4>
                  </div>
                  <div className="text-des">
                    <span className="text-gray-500 text-sm">
                      admin@britishmarketplace.co.uk
                    </span>
                  </div>
                </div>
              </div>
              <div className="de-item flex">
                <div className="item-icon w-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                </div>
                <div className="item-text flex">
                  <div className="text-title">
                    <h4 className="text-black font-medium text-xl mr-3 w-24">
                      Phone :
                    </h4>
                  </div>
                  <div className="text-des">
                    <span className="text-gray-500 text-sm">
                      (+44)08000654502
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-map">
              <div className="office-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.985395740555!2d90.39512511506055!3d23.783534384573127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7d6e0001087%3A0xc4b050ef97e92c20!2sBritish%20Marketplace%20Ltd!5e0!3m2!1sen!2sbd!4v1649138752411!5m2!1sen!2sbd"
                  width="100%"
                  height="250"
                  title="myFrame"
                  style={{ border: "0px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="contact-query text-gray-600 body-font relative">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Enquiry Type
            </h1>
          </div>
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <ul className="form-menu flex flex-wrap -m-2" id="tabs">
              <li
                className={
                  toggleState === 1 ? "active p-2 w-1/2" : " p-2 w-1/2"
                }
                onClick={() => handleToggleTab(1)}
              >
                <div
                  type="button"
                  className="bg-white text-center text-black text-base w-full rounded-md py-2 shadow-themeShadow px-2 cursor-pointer"
                >
                  Questions
                </div>
              </li>
              <li
                className={
                  toggleState === 2 ? "active p-2 w-1/2" : " p-2 w-1/2"
                }
                onClick={() => handleToggleTab(2)}
              >
                <div
                  type="button"
                  className="bg-white text-center text-black text-base w-full rounded-md py-2 shadow-themeShadow px-2 cursor-pointer"
                >
                  Experience
                </div>
              </li>
            </ul>
            <form method="post" onSubmit={handleSubmit}>
              <div id="tab-contents" className="mt-6">
                <div
                  id="tab1"
                  className={
                    toggleState === 1 ? "tab-contents active" : "tab-contents"
                  }
                >
                  <div className="p-2 w-full">
                    <div className="relative">
                      <textarea
                        id="message"
                        name="message"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        className="w-full bg-white rounded border-0 focus:border-indigo-500 shadow-themeShadow focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-black resize-none leading-6 transition-colors duration-200 ease-in-out p-4 placeholder:text-gray-700"
                        placeholder="what would you like to know?"
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="select-item">
                      <label className="custom-label flex p-2">
                        <div className="bg-white border border-yellow-500 w-6 h-6 p-1 flex justify-center items-center mr-2">
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleToggle("I want to be a business partner")
                            }
                            checked={
                              chooseOption.indexOf(
                                "I want to be a business partner"
                              ) === -1
                                ? false
                                : true
                            }
                            className="hidden"
                          />
                          <svg
                            className="hidden w-4 h-4 text-themeColor pointer-events-none"
                            viewBox="0 0 172 172"
                          >
                            <g
                              fill="none"
                              strokeWidth="none"
                              strokeMiterlimit="10"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <path d="M0 172V0h172v172z" />
                              <path
                                d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                                fill="currentColor"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                        </div>
                        <span className="text-black text-base font-normal hover:scale-[1]">
                          I want to be a business partner.
                        </span>
                      </label>
                      <label className="custom-label flex p-2">
                        <div className="bg-white border border-yellow-500 w-6 h-6 p-1 flex justify-center items-center mr-2">
                          <input
                            type="checkbox"
                            onChange={() => handleToggle("Need jobs")}
                            checked={
                              chooseOption.indexOf("Need jobs") === -1
                                ? false
                                : true
                            }
                            className="hidden"
                          />
                          <svg
                            className="hidden w-4 h-4 text-themeColor pointer-events-none"
                            viewBox="0 0 172 172"
                          >
                            <g
                              fill="none"
                              strokeWidth="none"
                              strokeMiterlimit="10"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <path d="M0 172V0h172v172z" />
                              <path
                                d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                                fill="currentColor"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                        </div>
                        <span className="text-black text-base font-normal hover:scale-[1]">
                          Need jobs.
                        </span>
                      </label>
                    </div>
                    <div className="select-item">
                      <label className="custom-label flex p-2">
                        <div className="bg-white border border-yellow-500 w-6 h-6 p-1 flex justify-center items-center mr-2">
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleToggle(
                                "I am interested in being a supplier"
                              )
                            }
                            checked={
                              chooseOption.indexOf(
                                "I am interested in being a supplier"
                              ) === -1
                                ? false
                                : true
                            }
                            className="hidden"
                          />
                          <svg
                            className="hidden w-4 h-4 text-themeColor pointer-events-none"
                            viewBox="0 0 172 172"
                          >
                            <g
                              fill="none"
                              strokeWidth="none"
                              strokeMiterlimit="10"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <path d="M0 172V0h172v172z" />
                              <path
                                d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                                fill="currentColor"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                        </div>
                        <span className="text-black text-base font-normal hover:scale-[1]">
                          I am interested in being a supplier.
                        </span>
                      </label>
                      <label className="custom-label flex p-2">
                        <div className="bg-white border border-yellow-500 w-6 h-6 p-1 flex justify-center items-center mr-2">
                          <input
                            type="checkbox"
                            onChange={() => handleToggle("Others")}
                            checked={
                              chooseOption.indexOf("Others") === -1
                                ? false
                                : true
                            }
                            className="hidden"
                          />
                          <svg
                            className="hidden w-4 h-4 text-themeColor pointer-events-none"
                            viewBox="0 0 172 172"
                          >
                            <g
                              fill="none"
                              strokeWidth="none"
                              strokeMiterlimit="10"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <path d="M0 172V0h172v172z" />
                              <path
                                d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                                fill="currentColor"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                        </div>
                        <span className="text-black text-base font-normal hover:scale-[1]">
                          Others.
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="input-group mt-8 mb-5">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full bg-white shadow-themeShadow rounded border-0 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-800 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out placeholder:text-gray-800"
                      placeholder="Name"
                    />
                  </div>
                  <div className="input-group mb-5">
                    <input
                      type="email"
                      id="emailId"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full bg-white shadow-themeShadow rounded border-0 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-800 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out placeholder:text-gray-800"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      id="number"
                      name="number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="w-full bg-white shadow-themeShadow rounded border-0 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-800 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out placeholder:text-gray-800"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
                <div
                  id="tab2"
                  className={
                    toggleState === 2 ? "tab-contents active" : "tab-contents"
                  }
                >
                  <div className="p-2 w-full">
                    <div className="relative">
                      <textarea
                        id="message"
                        name="message"
                        value={experience}
                        onChange={(e) => setExperience(e.target.value)}
                        className="w-full bg-white rounded border-0 focus:border-indigo-500 shadow-themeShadow focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-black resize-none leading-6 transition-colors duration-200 ease-in-out p-4 placeholder:text-gray-700"
                        placeholder="Write your experience...."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-2 w-full mt-8">
                <button
                  type="submit"
                  className="flex mx-auto text-black bg-[#E6E6E6] border-0 py-2 px-8 focus:outline-none hover:bg-themeColor hover:text-white rounded text-base shadow-themeShadow"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
