import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import SponsorCreateUpdateForm from "../../../components/admin/Forms/Sponsor/SponsorCreateUpdateForm";
import { adminAddSponsor } from "../../../redux/actions/AdminActions/adminSponsorAction";
import Layout from "../Layout/layout";

function AddSponsor() {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = (data) => {
    console.log(data);
    const formData = new FormData();
    if (data.image.length > 0) {
      formData.append("image", data.image[0]);
    }
    formData.append("name", data.name);
    formData.append("status", data.status);
    dispatch(adminAddSponsor(formData, history));
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <SponsorCreateUpdateForm onSubmit={onSubmit} />
        </div>
      </div>
    </Layout>
  );
}

export default AddSponsor;
