import {
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCT_LOADING,
  FETCH_PRODUCT_ERROR,
} from "../types";

import axios from "../../util/axios";
import { API_URL } from "../../settings/config";

export const fetchProducts =
  (queryData = {}) =>
  (dispatch) => {
    dispatch({ type: FETCH_PRODUCT_LOADING });
    axios
      .get(`${API_URL}/delete/products/`)
      .then((res) => {
        dispatch({
          type: FETCH_PRODUCT_LIST,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err?.response?.status);
        dispatch({
          type: FETCH_PRODUCT_ERROR,
          payload:
            err.response && err.response.data.detail
              ? err.response.data.detail
              : err.message,
        });
      });
  };
