import {
  VENDOR_MENU_ITEMS_LIST,
  VENDOR_MENU_VARIATION_LIST,
  VENDOR_MENU_INGREDIENTS_LIST,
  VENDOR_MENU_LOADING,
  VENDOR_MENU_ERROR,
} from "../../types";
import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";

export const vendorMenuItemsList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: VENDOR_MENU_LOADING });
    let token = auth.getToken();
    console.log(data);
    await axios
      .get(`${API_URL}/menu/vendor/item/`, {
        params: {
          res_branch: data.res_branch,
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: VENDOR_MENU_ITEMS_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: VENDOR_MENU_ERROR, payload: err.response });
      });
  };

export const vendorMenuVariationsList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: VENDOR_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/menu/vendor/food-variation/`, {
        params: {
          res_branch: data.res_branch,
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: VENDOR_MENU_VARIATION_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: VENDOR_MENU_ERROR, payload: err.response });
      });
  };

export const vendorMenuIngredients =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: VENDOR_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/menu/vendor/ingredient/`, {
        params: {
          res_branch: data.res_branch,
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: VENDOR_MENU_INGREDIENTS_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: VENDOR_MENU_ERROR, payload: err.response });
      });
  };
