import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FooterEndLogo from "../../images/logo/footer_logo.png";
import mainLogo from "../../images/logo/logo_new.png";
const Footer = () => {
  const { order_track } = useSelector((state) => state.order);
  // console.log(order_track?.order_number);
  const { cart } = useSelector((state) => state.cart);
  // console.log(cart);
  let cartItemsQuantity = cart?.reduce((total, cartItem) => {
    return (total += parseFloat(cartItem.quantity));
  }, 0);
 
  // console.log({cartItemsQuantity});
  return (
    <footer className="bg-white text-gray-600 body-font">
      <div className="container py-16">
        <div className="grid lg:grid-cols-4 md:grid-cols-5 grid-cols-2 gap-5">
          <div className="foot-item lg:col-span-1 md:col-span-2 col-span-2">
            <img className="pb-4" src={mainLogo} alt="Footer Logo" />
            <nav className="list-none">
              <li className="mb-2">
                <p className="text-gray-600 hover:text-indigo-800">
                  Got Question? Call us 24/7
                </p>
              </li>
              <li className="mb-2">
                <p className="font-medium text-sm text-black">012-345678900</p>
              </li>
              <li className="mb-2">
                <a
                  href="/"
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  admin@britishmarketplace.co.uk
                </a>
              </li>
              <li className="mb-2">
                <p className="text-gray-600 text-sm max-w-step-text">
                  218-220 Whitechapel Road, Post Code: E1 1BJ, London, United
                  Kingdom
                </p>
              </li>
            </nav>
          </div>
          <div className="foot-item">
            <h2
              className="
                title-font
                font-bold
                text-gray-900
                tracking-widest
                md:text-lg
                mb-3
              "
            >
              Discover
            </h2>
            <nav className="list-none">
              <li className="mb-2">
                <Link to={`/about`}
                 
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  About us
                </Link>
              </li>
              <li className="mb-2">
                <Link to={`/vendor/registration`}
                 
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Restaurant signup
                </Link>
              </li>
              <li className="mb-2">
                <Link
                 to={'/driver/registration'}
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Become a rider
                </Link>
              </li>
              <li className="mb-2">
                <Link to={`/term-conditions`}
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Term and Conditions
                </Link>
              </li>
              {/* <li className="mb-2">
                <a
                  href="#0"
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Newsletter
                </a>
              </li> */}
            </nav>
          </div>
          <div className="foot-item">
            <h2
              className="
                title-font
                font-bold
                text-gray-900
                tracking-widest
                md:text-lg
                mb-3
              "
            >
              My Account
            </h2>
            <nav className="list-none">
              <li className="mb-2">
                <Link 
                to={order_track?.order_number? `/order/track/${order_track?.order_number}` : `/`}
                
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Track My Order
                </Link>
              </li>
              <li className="mb-2">
             
                  <Link
                    to={cartItemsQuantity > 0 ? `/restaurant/${cart[0]?.res_id}` : `/`}
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  View Cart
                </Link>
              </li>
              <li className="mb-2">
                <Link
                 to={`/login`}
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Sign In
                </Link>
              </li>
              <li className="mb-2">
                <Link to={`/privacy-policy`}
                  href="#0"
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to={`/wishlist`}
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  My Wishlist
                </Link>
              </li>
            </nav>
          </div>
          <div className="foot-item">
            <h2
              className="
                title-font
                font-bold
                text-gray-900
                tracking-widest
                md:text-lg
                mb-3
              "
            >
              Help
            </h2>
            <nav className="list-none">
              <li className="mb-2">
                <Link
                 to={`/order`}
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Order History
                </Link>
              </li>
              <li className="mb-2">
                <Link to={`/contact-us`}
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Contact
                </Link>
              </li>
              <li className="mb-2">
              <Link to={`/faq`}
                  
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  FAQs
                </Link>
              </li>
              {/* <li className="mb-2">
                <a
                  href="#0"
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Payment Methods
                </a>
              </li> */}
              {/* <li className="mb-2">
                <a
                  href="#0"
                  className="text-gray-600 hover:text-indigo-800 cursor-pointer"
                >
                  Site Map
                </a>
              </li> */}
            </nav>
          </div>
        </div>
        <div className="hidden">
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0"
              id="tab-single-one"
              type="radio"
              name="tabs2"
            />
            <label
              className="block p-5 leading-normal cursor-pointer"
              htmlFor="tab-single-one"
            >
              Customer Service
            </label>
            <div
              className="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              "
            >
              <p className="p-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Tenetur, architecto, explicabo perferendis nostrum, maxime
                impedit atque odit sunt pariatur illo obcaecati soluta molestias
                iure facere dolorum adipisci eum? Saepe, itaque.
              </p>
            </div>
          </div>
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0"
              id="tab-single-two"
              type="radio"
              name="tabs2"
            />
            <label
              className="block p-5 leading-normal cursor-pointer"
              htmlFor="tab-single-two"
            >
              Cuisines
            </label>
            <div
              className="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              "
            >
              <p className="p-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Tenetur, architecto, explicabo perferendis nostrum, maxime
                impedit atque odit sunt pariatur illo obcaecati soluta molestias
                iure facere dolorum adipisci eum? Saepe, itaque.
              </p>
            </div>
          </div>
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0"
              id="tab-single-three"
              type="radio"
              name="tabs2"
            />
            <label
              className="block p-5 leading-normal cursor-pointer"
              htmlFor="tab-single-three"
            >
              Locations
            </label>
            <div
              className="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              "
            >
              <p className="p-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Tenetur, architecto, explicabo perferendis nostrum, maxime
                impedit atque odit sunt pariatur illo obcaecati soluta molestias
                iure facere dolorum adipisci eum? Saepe, itaque.
              </p>
            </div>
          </div>
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0"
              id="tab-single-four"
              type="radio"
              name="tabs2"
            />
            <label
              className="block p-5 leading-normal cursor-pointer"
              htmlFor="tab-single-four"
            >
              Brands
            </label>
            <div
              className="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              "
            >
              <p className="p-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Tenetur, architecto, explicabo perferendis nostrum, maxime
                impedit atque odit sunt pariatur illo obcaecati soluta molestias
                iure facere dolorum adipisci eum? Saepe, itaque.
              </p>
            </div>
          </div>
          <div className="tab w-full overflow-hidden border-t">
            <input
              className="absolute opacity-0"
              id="tab-single-five"
              type="radio"
              name="tabs2"
            />
            <label
              className="block p-5 leading-normal cursor-pointer"
              htmlFor="tab-single-five"
            >
              A bit more about us
            </label>
            <div
              className="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              "
            >
              <p className="p-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Tenetur, architecto, explicabo perferendis nostrum, maxime
                impedit atque odit sunt pariatur illo obcaecati soluta molestias
                iure facere dolorum adipisci eum? Saepe, itaque.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white relative">
        <div
          className="
            absolute
            -top-1/4
            left-1/2
            transform
            -translate-x-1/2
            bg-white
            px-8
          "
        >
          <img src={FooterEndLogo} alt="Footer Logo" />
        </div>
        <div className="container py-8 px-5 text-center border-t">
          <p className="text-gray-500 text-sm text-center">
            Copyright © 2021 @britishmarketplace —
            <a
              href="https://twitter.com/knyttneve"
              rel="noopener noreferrer"
              className="text-gray-600 ml-1"
              target="_blank"
            >
              All rights reserved.
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
