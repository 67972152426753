import React, { useEffect, useState } from "react";
import Layout from "../Layout/layout";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminRestaurantBranchDelete,
  adminRestaurantDelete,
  adminRestaurantDetailsWithBranch,
} from "../../../redux/actions/AdminActions/adminRestaurantActions";
import RestaurantInfo from "../../../components/admin/RestautantDetails/RestaurantInfo";
import RestaurantBranchList from "../../../components/admin/RestautantDetails/RestaurantBranchList";
import ModalContainer from "../../../components/common/ModalContainer";

function VendorRestaurantBranchList() {
  const { resDetailsWithBranch } = useSelector(
    (state) => state.adminRestaurant
  );

  const { user } = useSelector((state) => state.auth);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modal2IsOpen, setmodal2IsOpen] = useState(false);
  const [deleleState, setDeleleState] = useState(null);
  const [deleleBranchState, setDeleleBranchState] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(adminRestaurantDetailsWithBranch(user?.restaurant?.id));
  }, [dispatch, user?.restaurant?.id]);

  const handleDeleteClick = (delete_id) => {
    setIsOpen(true);
    setDeleleState(delete_id);
  };
  const handleBranchDeleteClick = (delete_id) => {
    setmodal2IsOpen(true);
    setDeleleBranchState(delete_id);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function closeBranchModal() {
    setmodal2IsOpen(false);
  }

  const handleDelete = () => {
    dispatch(adminRestaurantDelete(deleleState, history));
    closeModal();
    setDeleleState(null);
  };

  const handleBranchDelete = () => {
    dispatch(
      adminRestaurantBranchDelete(
        deleleBranchState,
        history,
        user?.restaurant?.id,
        "list"
      )
    );
    closeBranchModal();
    setDeleleBranchState(null);
  };

  return (
    <Layout>
      <div className=" mx-3 my-8  ">
        <div className="text-end mb-2">
          <Link className="" to={`/vendor/add-restaurant-branches/`}>
            <button className="rounded py-2 px-5 text-white  bg-[#62DB60]">
              Add Restaurant Branch
            </button>
          </Link>
        </div>
        <div className="flex">
          <RestaurantInfo
            resDetailsWithBranch={resDetailsWithBranch}
            handleDeleteClick={handleDeleteClick}
            vendor
          />

          <RestaurantBranchList
            branches={resDetailsWithBranch?.branches}
            handleBranchDeleteClick={handleBranchDeleteClick}
            vendor
          />
        </div>
      </div>
      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Delete Restaurant?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
      <ModalContainer open={modal2IsOpen} onRequestClose={closeBranchModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Delete Restaurant Branch?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleBranchDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeBranchModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default VendorRestaurantBranchList;
