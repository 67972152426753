import React, { useEffect, useState } from "react";
import Layout from "./Layout";
// import RestaurantHeader from "../components/restaurant/RestaurantHeader";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import FoodCategorySlider from "../components/food/FoodCategorySlider";
import FoodItems from "../components/food/FoodItems";
import OrderSummerySidebar from "../components/restaurant/OrderSummerySidebar";
import RestaurantDetailsInfo from "../components/restaurant/RestaurantDetailsInfo";
import WhiteCart from "../images/white-cart.png";
import { decreaseCart, increaseCart } from "../redux/actions/cartActions";
import { fetchRestaurantDetails } from "../redux/actions/restaurantAction";

function RestaurantDetails(props) {
  const params = useParams();
  const { restaurantDetails, foodVariation } = useSelector(
    (state) => state.restaurant
  );
  const { user } = useSelector((state) => state.auth);

  
  // console.log({restaurantDetails});

  const { cart, serviceFee, homeMadeCart } = useSelector((state) => state.cart);

  // console.log(homeMadeCart);
  const [count, setCount] = useState(0);
  const [homeMadeVariation, setHomeMadeVariation] = useState([]);
  // console.log({ homeMadeVariation, homeMadeCart });

  // let d = homeMadeVariation.map((i) => i.id);

  // console.log(d);

  // let c = homeMadeVariation.map((i) => {
  //   console.log(i);
  //   return homeMadeCart.filter((h) => {
  //     console.log(h);
  //     return h.item_id === i.id;
  //   });
  // });

  // console.log(ingreDiff(homeMadeVariation, homeMadeCart));

  const results = homeMadeVariation.filter(
    ({ id: id1 }) => !homeMadeCart.some(({ item_id: id2 }) => id2 === id1)
  );
  // const results2 = homeMadeVariation.filter(({ id: id1 }) =>
  //   homeMadeCart.some(({ item_id: id2 }) => id2 === id1)
  // );
  const results2 = homeMadeCart.filter(({ item_id: id1 }) =>
    homeMadeVariation.some(({ id: id2 }) => id2 === id1)
  );

  let newHomeMadeWithPrice = results?.reduce((total, cartItem) => {
    return (total += parseFloat(cartItem.price));
  }, 0);

  let newHomeMadeWithPrice2 = results2?.reduce((total, cartItem) => {
    return (total += parseFloat(cartItem.product.price));
  }, 0);
  // console.log(results);
  // console.log(newHomeMadeWithPrice);
  // console.log(results2);
  // console.log(newHomeMadeWithPrice2);

  let homeVariationPriceResult = newHomeMadeWithPrice + newHomeMadeWithPrice2;

  // let newHomeMadeWithPrice = 0;
  // if (results.length === results2.length) {
  //   newHomeMadeWithPrice = homeMadeVariation?.reduce((total, cartItem) => {
  //     return (total += parseFloat(cartItem.price));
  //   }, 0);
  // } else {
  //   let newHomeMadeWithPrice2 = homeMadeVariation?.reduce((total, cartItem) => {
  //     return (total += parseFloat(cartItem.price));
  //   }, 0);
  //   let newHomeMadeWithPrice3 = homeMadeCart?.reduce((total, cartItem) => {
  //     return (total += parseFloat(cartItem.price));
  //   }, 0);
  //   newHomeMadeWithPrice = newHomeMadeWithPrice2 + newHomeMadeWithPrice3;
  // }

  // console.log({ newHomeMadeWithPrice });

  // console.log("cccccccccc", c);

  // let  a = a.map(function (item) {
  //   return item == 3452 ? 1010 : item;
  // });

  useEffect(() => {
    if (foodVariation) {
      let a = foodVariation?.variations?.filter((i) => i.is_home_made === true);
      // console.log(a);
      let b = [];
      // let c = a?.map((j) => console.log(j));
      a?.map((j) => j?.variation_ingredients?.map((k) => b.push(k)));
      setHomeMadeVariation(b);

      // a?.variation_ingredients?.map((j) => {
      //   console.log(j);
      //   return b.push(j);
      // });
      // console.log(b);
      // setHomeMadeVariation(b);
    }
  }, [foodVariation]);
  // console.log(restaurantDetails);

  // const locationLatLng = localStorage.getItem("latlng");
  // const formattedLatLng = locationLatLng?.split(",");

  const formattedAddr = localStorage.getItem("location");

  const [Checked, setChecked] = useState([]);
  const [cartModalOpen, setCartModalOpen] = useState(false);

  const handleToggle = (value) => {
    console.log({value});
    console.log({Checked});
    
    let newChecked = [...Checked];
    console.log({newChecked});
    const currentIndex = Checked.filter((item) => item.id === value.id);
   
    console.log({currentIndex});
    // foodVariation.total_required_field.sum

    const foodVariationIndex = Checked.filter(
      (item) => item.food_variation_id === value.food_variation_id
      // (item) => item.value.food_variation_id === value.food_variation_id
    );
    console.log({ foodVariationIndex });
    // console.log(foodVariationIndex.length);
    // console.log(currentIndex.length);
    // console.log(currentIndex[0]?.id);

    // if (currentIndex.length === 0 && foodVariationIndex.length === 0) {
    if (currentIndex.length === 0) {
      // console.log(foodVariationIndex[0]?.id);
      // let count = 1;
      // if (foodVariationIndex.length > 0) {
      //   count = foodVariationIndex.length + 1;
      // }
      // console.log("enter");
      // newChecked.push({ ...value, count });
      if (value.required) {
        setCount(count + 1);
      }
      newChecked.push(value);
    }
    // else if (currentIndex.length === 0) {

    // }
    else {
      // console.log(currentIndex[0]?.value.id);
      // console.log(newChecked);
      let a = newChecked.filter((item) => item.id !== currentIndex[0]?.id);
      // console.log(a);
      newChecked = a;
      if (value.required) {
        setCount(count - 1);
      }
      // newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  // console.log({count});

  // console.log(restaurantDetails);

  // let newTotal = Checked.reduce((total, item) => {
  //   return (total += parseFloat(item.price));
  // }, 0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRestaurantDetails(params.id));
  }, [dispatch, params.id, cart]);

  // console.log({ cart });
  let ex_sum = 0;
  for (let item of cart) {
    // console.log(item);
    let extraTotalWithPrice = item.extra.reduce((total, cartItem) => {
      return (total += parseFloat(cartItem.price));
    }, 0);
    // console.log({ extraTotalWithPrice });
    ex_sum += extraTotalWithPrice * item.quantity;
    // console.log({ ex_sum });
  }
  let home_made_sum = 0;
  for (let item of cart) {
    // console.log(item);
    let homeMadeTotalWithPrice = item.home_made.reduce((total, cartItem) => {
      return (total += parseFloat(cartItem.price));
    }, 0);
    // console.log({ extraTotalWithPrice });
    home_made_sum += homeMadeTotalWithPrice * item.quantity;
    // console.log({ ex_sum });
  }

  // let extraTotalWithPrice = cart.reduce((total, cartItem) => {
  //   return (total +=
  //     parseFloat(cartItem.quantity) * parseFloat(cartItem.total_price));
  // }, 0);

  let newTotalWithPrice = cart.reduce((total, cartItem) => {
    return (total +=
      parseFloat(cartItem.quantity) * parseFloat(cartItem.total_price));
  }, 0);

  // if (homeMadeCart.length >= 0) {
  //   let newHomeMadeWithPrice = homeMadeVariation?.reduce((total, cartItem) => {
  //     return (total += parseFloat(cartItem.price));
  //   }, 0);
  // }

  // let newHomeMadeWithPrice = homeMadeVariation?.reduce((total, cartItem) => {
  //   return (total += parseFloat(cartItem.price));
  // }, 0);

  // console.log(newHomeMadeWithPrice);

  let newTotalItem = cart.reduce((total, cartItem) => {
    return (total += parseFloat(cartItem.quantity));
  }, 0);

  const history = useHistory();

  const handleCheckout = () => {
    // const total = newTotalWithPrice + serviceFee + delevery_charge;
    // dispatch(placeOrder(cart, total, res_id));
    history.push("/checkout");
  };

  let newHomeMadeWithPrice3 = cart?.reduce((total, cartItem) => {
    // console.log(cartItem.home_made_total);
    // console.log(cartItem.home_made_change_total);
    if (cartItem.home_made_change_total)
      return (total +=
        parseFloat(cartItem.home_made_total) +
        parseFloat(cartItem.home_made_change_total));
    else return (total += parseFloat(cartItem.home_made_total));
  }, 0);

  return (
    <Layout foodSuggestionsBar>
      {/* <RestaurantHeader
        step1="search restaurant list"
        step1Link={`restaurants/?lat=${formattedLatLng[0]}&lng=${formattedLatLng[1]}`}
        step2={restaurantDetails?.restaurant?.name}
      /> */}
      <section className="single-details pb-28 pt-10">
        <div className="container">
          <div className="">
            {/* lg:flex gap-5 */}
            <div className="">
            {/* lg:w-[70%] */}
              <RestaurantDetailsInfo
                name={restaurantDetails?.restaurant?.name}
                wishlist={restaurantDetails?.wishlist}
                categories={restaurantDetails?.categories}
                image={restaurantDetails?.image}
                delivery_charge={restaurantDetails?.delivery_charge}
                branch={restaurantDetails?.name}
                road={restaurantDetails?.road}
                city={restaurantDetails?.city}
                country={restaurantDetails?.country}
                open_time={restaurantDetails?.open_time}
                close_time={restaurantDetails?.close_time}
                max_delivery_time={restaurantDetails?.max_delivery_time}
                min_delivery_time={restaurantDetails?.min_delivery_time}
                user={user}
                rating={restaurantDetails?.rating}
              />
             
              <FoodCategorySlider  foodItems={restaurantDetails.food_items} />

              
              <div className="lg:flex gap-5 ">
                <div className="lg:w-[70%] ">

              <FoodItems
              
                foodItems={restaurantDetails.food_items}
                delevery_charge={restaurantDetails?.delivery_charge}
                res_id={restaurantDetails?.id}
                cart={cart}
                serviceFee={serviceFee}
                Checked={Checked}
                setChecked={setChecked}
                handleToggle={handleToggle}
                homeVariationPriceResult={homeVariationPriceResult.toFixed(2)}
                standerdHomeMade={results}
                changedHomeMade={results2}
                is_home_made={restaurantDetails?.is_home_made}
                homeMadeVariation={homeMadeVariation}
                count={count}
                setCount={setCount}
              />
                </div>
               <div className="lg:w-[30%] ">
              <OrderSummerySidebar
                cart={cart}
                Checked={Checked}
                delevery_charge={restaurantDetails?.delivery_charge}
                is_home_made={restaurantDetails?.is_home_made}
                serviceFee={serviceFee}
                formattedAddr={formattedAddr}
                newTotalWithPrice={newTotalWithPrice}
                ex_sum={ex_sum}
                home_made_sum={home_made_sum}
                homeMadeVariation={homeMadeVariation}
                homeVariationPriceResult={parseFloat(
                  homeVariationPriceResult
                ).toFixed(2)}
                newHomeMadeWithPrice3={newHomeMadeWithPrice3}
              />
              <div
                className=" lg:hidden fixed w-[100px] h-[100px] rounded-tl-lg rounded-bl-lg top-1/2 left-auto right-0 -translate-y-1/2 flex justify-center items-center flex-col bg-green-400 z-[101]"
                onClick={() => setCartModalOpen(true)}
              >
                <div>
                  <img className="mx-auto" src={WhiteCart} alt="" />
                  <span className=" text-white md:text-2xl text-xl font-normal">
                    <CountUp end={newTotalItem} /> items
                  </span>
                </div>
                <button
                  type="button"
                  className=" lg:text-xl text-lg rounded-xl border-0 "
                >
                  {/* &#2547; £ */}£{" "}
                  <CountUp
                    decimals={2}
                    end={
                      cart.length > 0
                        ? (
                            newTotalWithPrice +
                            restaurantDetails?.delivery_charge +
                            serviceFee +
                            newHomeMadeWithPrice3 +
                            ex_sum
                          ).toFixed(2)
                        : 0
                    }
                  />
                </button>
              </div>

              <div
                className={` order-history ${
                  cartModalOpen ? "active" : ""
                }  bg-white shadow-lg p-5 sm:p-10 lg:hidden `}
              >
                <div className="history-title pb-5 relative">
                  <span
                    className="
                      absolute
                      right-0
                      top-0
                      lg:hidden
                      h-8
                      w-8
                      leading-8
                      rounded-full
                      text-center
                      border border-gray-800
                    "
                    // id="closeOrder"
                    onClick={() => setCartModalOpen(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 mx-auto mt-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                  <h3 className="text-black uppercase font-normal leading-6 text-lg">
                    Order Summery
                  </h3>
                </div>
                {cart.length > 0
                  ? cart.map((cartItem, index) => (
                      <div key={index} className="order-item flex pb-5">
                        <div className="item-text">
                          <h4 className="text-black text-md leading-6 font-medium pt-0 lg:pt-2 xl:pt-0  line-clamp-2">
                            {cartItem.name}
                          </h4>
                          <div className="flex justify-start mt-2 mb-2 xl:mt-4">
                            <h6 className="font-semibold text-lg leading-5 text-[#E64A19] mr-5">
                              €{cartItem.total_price}
                            </h6>
                            <div className="flex justify-between gap-3  h-5 w-20 text-center">
                              <button
                                type="button"
                                onClick={() =>
                                  dispatch(increaseCart(cartItem._id))
                                }
                                className="
                                  bg-white shadow-themeShadow rounded-md px-2 h-6
                                "
                                // className="
                                //   h-5
                                //   w-5
                                //   rounded-sm
                                //   bg-white
                                //   leading-4
                                //   text-sm text-center text-black
                                // "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                  />
                                </svg>
                              </button>
                              <p className="w-6 h-6 px-2 border-none bg-white">
                                {cartItem.quantity}
                              </p>
                              {/* <input
                                type="text"
                                name="qt"
                                id=""
                                className="w-6 h-6 rounded-md active:shadow-themeShadow px-2 placeholder:text-black border-none"
                                placeholder={cartItem.quantity}
                              /> */}

                              <button
                                type="button"
                                onClick={() =>
                                  dispatch(
                                    decreaseCart(
                                      cartItem._id,
                                      cartItem.quantity
                                    )
                                  )
                                }
                                className="bg-white shadow-themeShadow rounded-md px-2 h-6"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M18 12H6"
                                  />
                                </svg>
                              </button>
                              {/* <p className="ml-4">
                                €{newTotalWithPrice?.toFixed(2)}
                              </p> */}
                            </div>
                          </div>
                          {cartItem?.home_made.map((item) => (
                            <span
                              className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                              key={item.id}
                            >
                              <div>{item.name}</div>
                              <div>{item.price}</div>
                              <Link
                                to={{
                                  pathname: "/food/ingredients/",
                                  search: `?search=${item.name}`,
                                  state: {
                                    cartItem: cartItem,
                                    item_id: item.id,
                                  },
                                }}
                              >
                                Change
                              </Link>
                            </span>
                          ))}
                          {cartItem?.home_made_change.map((item) => (
                            <span
                              className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                              key={item.product.id}
                            >
                              <div>{item.product.name}</div>
                              <div>{item.product.price}</div>
                            </span>
                          ))}
                        </div>
                      </div>
                    ))
                  : ""}
                {cart?.length > 0 ? (
                  <div className="total-price">
                    <ul className="p-0 m-0 list-none">
                      <li
                        className="
                        flex flex-wrap
                        w-full
                        justify-between
                        items-center
                        mb-3
                      "
                      >
                        <h6 className="text-black text-base font-normal leading-4">
                          Subtotal
                        </h6>
                        <p className="text-black text-base font-normal leading-4">
                          £
                          {(
                            newTotalWithPrice +
                            ex_sum +
                            newHomeMadeWithPrice3
                          ).toFixed(2)}
                        </p>
                      </li>
                      <li
                        className="
                        flex flex-wrap
                        w-full
                        justify-between
                        items-center
                        mb-3
                      "
                      >
                        <h6 className="text-black text-base font-normal leading-4">
                          Delivery Fee
                        </h6>
                        <p className="text-black text-base font-normal leading-4">
                          £{restaurantDetails?.delivery_charge}
                        </p>
                      </li>
                      <li
                        className="
                        flex flex-wrap
                        w-full
                        justify-between
                        items-center
                        mb-3
                      "
                      >
                        <h6 className="text-black text-base font-normal leading-4">
                          Service Fee
                        </h6>
                        <p className="text-black-600 text-base font-normal leading-4">
                          £{serviceFee?.toFixed(2)}
                        </p>
                      </li>
                    </ul>
                    <div className="flex justify-between border-t-2 border-dashed pt-5">
                      <h6 className="text-black text-base font-medium leading-4">
                        Total
                      </h6>
                      <p className="text-black text-base font-normal leading-4">
                        £
                        {(
                          newTotalWithPrice +
                          restaurantDetails?.delivery_charge +
                          serviceFee +
                          newHomeMadeWithPrice3 +
                          ex_sum
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`check-btn pt-3 ${
                    cart?.length === 0 ? "disabled" : ""
                  }`}
                >
                  <button
                    onClick={handleCheckout}
                    type="submit"
                    className="
                      bg-[#0DC1D9]
                      w-full
                      py-2
                      rounded-lg
                      text-white text-base
                      font-medium
                    "
                    disabled={cart?.length === 0}
                  >
                    Go To Checkout
                  </button>
                </div>
              </div>
            </div>
              </div>
             
            </div>
           
          </div>
        </div>
      </section>
     
      {/* <FoodCategorySlider foodItems={restaurantDetails.food_items} />
      <FoodItems
        foodItems={restaurantDetails.food_items}
        delevery_charge={restaurantDetails?.delivery_charge}
        res_id={restaurantDetails?.id}
        cart={cart}
        serviceFee={serviceFee}
      /> */}
    </Layout>
  );
}

export default RestaurantDetails;
