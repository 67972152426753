import React from "react";
import { FiShoppingBag } from "react-icons/fi";
import { IoIosArrowForward, IoMdGift } from "react-icons/io";
import { MdGroups, MdGroupAdd, MdOutlineMenu } from "react-icons/md";
import { GiCardboardBox } from "react-icons/gi";
import { BsBoxSeam } from "react-icons/bs";

import "./Home.css";

function HomeVendorProduct() {
  return (
    <div className="grid lg:grid-cols-4 gap-2 grid-cols-2">
      <div className=" bg-[#E64A19] rounded group  ease-in-out duration-300 delay-150">
        <div className="flex items-center p-2 h-[75%]">
          <div className="mx-auto text-white">
            <span className="text-5xl ">6</span>
            <p className="md:text-2xl tracking-wide">New Vendor</p>
          </div>
          <div className="mx-auto ">
            <MdGroupAdd className=" group-hover:scale-110 ease-in-out duration-300 delay-150 text-4xl md:text-7xl text-white md:h-14 md:w-14" />
          </div>
        </div>
        <hr className="mx-2 text-white" />
        <footer className="py-1 flex items-center justify-center text-white tracking-wide ">
          <span className=" tracking-wide md:text-base text-sm ">
            More Info
          </span>
          <IoIosArrowForward className="ml-2" />
        </footer>
      </div>
      {/* card 2 */}
      <div className=" bg-[#fcca01] rounded group  ease-in-out duration-300 delay-150">
        <div className="flex items-center p-2 h-[75%]">
          <div className="mx-auto text-white">
            <span className="text-5xl ">6</span>
            <p className="md:text-2xl tracking-wide">Active Vendor</p>
          </div>
          <div className="mx-auto ">
            <MdGroups className="group-hover:scale-110 ease-in-out duration-300 delay-150 text-4xl md:text-7xl text-white md:h-14 md:w-14" />
          </div>
        </div>
        <hr className="mx-2 text-white" />
        <footer className="py-1 flex items-center justify-center text-white tracking-wide ">
          <span className=" tracking-wide md:text-base text-sm ">
            More Info
          </span>
          <IoIosArrowForward className="ml-2" />
        </footer>
      </div>
      {/* card 3 */}
      <div className=" bg-[#62DB60] rounded group  ease-in-out duration-300 delay-150">
        <div className="flex items-center p-2 h-[75%]">
          <div className="mx-auto text-white">
            <span className="text-5xl ">6</span>
            <p className="md:text-2xl tracking-wide">New Products</p>
          </div>
          <div className="mx-auto ">
            <IoMdGift className="group-hover:scale-110 ease-in-out duration-300 delay-150 text-4xl md:text-7xl text-white md:h-14 md:w-14" />
          </div>
        </div>
        <hr className="mx-2 text-white" />
        <footer className="py-1 flex items-center justify-center text-white tracking-wide ">
          <span className=" tracking-wide md:text-base text-sm">More Info</span>
          <IoIosArrowForward className="ml-2" />
        </footer>
      </div>
      {/* card 4 */}
      <div className=" bg-[#77C9F2] rounded group  ease-in-out duration-300 delay-150">
        <div className="flex items-center p-2 h-[75%]">
          <div className="mx-auto text-white">
            <span className="text-5xl ">6</span>
            <p className="md:text-2xl tracking-wide">Published Products</p>
          </div>
          <div className="mx-auto ">
            {/* <FiShoppingBag className="group-hover:scale-110 ease-in-out duration-300 delay-150 text-4xl md:text-7xl  text-white" /> */}
            {/* <GiCardboardBox className="group-hover:scale-110 ease-in-out duration-300 delay-150 text-4xl md:text-7xl  text-white" /> */}
            <BsBoxSeam className="group-hover:scale-110 ease-in-out duration-300 delay-150 text-4xl md:text-7xl  text-white md:h-14 md:w-14" />
          </div>
        </div>
        <hr className="mx-2 text-white" />
        <footer className="py-1 flex items-center justify-center text-white tracking-wide ">
          <span className=" tracking-wide md:text-base text-sm">More Info</span>
          <IoIosArrowForward className="ml-2" />
        </footer>
      </div>
    </div>
  );
}

export default HomeVendorProduct;
