import {
  ADMIN_MENU_CATEGORY_LIST,
  ADMIN_MENU_LOADING,
  ADMIN_MENU_ERROR,
  ADMIN_MENU_CATEGORY_DETAILS,
  ADMIN_MENU_ITEMS_LIST,
  ADMIN_MENU_CATEGORY_NAME_LIST,
  ADMIN_MENU_ITEM_DETAILS,
  ADMIN_MENU_VARIATION_LIST,
  ADMIN_MENU_ITEMS_NAME_ONLY_LIST,
  ADMIN_MENU_VARIATION_DETAILS,
  ADMIN_MENU_INGREDIENTS_DETAILS,
  ADMIN_MENU_INGREDIENTS_LIST,
  ADMIN_MENU_VARIATION_NAME_ONLY_LIST,
} from "../../types";
import axios from "axios";
import { API_URL } from "../../../settings/config";
import auth from "../../../util/auth";
import {
  vendorMenuIngredients,
  vendorMenuItemsList,
  vendorMenuVariationsList,
} from "../vendorActions/vendorMenuAction";

export const adminMenuCategories =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/menu/admin/categories`, {
        params: {
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: ADMIN_MENU_CATEGORY_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminAddMenuCategories =
  (data, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    await axios
      .post(`${API_URL}/menu/admin/categories/`, data, config)
      .then((res) => {
        console.log(res);
        if (vendor) {
          history.push("/vendor/menu-categories-list");
        } else history.push("/admin/menu-categories-list");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuCategoryDetails = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_MENU_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .get(`${API_URL}/menu/admin/categories/${id}`, config)
    .then((res) => {
      dispatch({ type: ADMIN_MENU_CATEGORY_DETAILS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
    });
};

export const adminEditMenuCategory =
  (data, id, history) => async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    const config = {
      headers: { Authorization: token },
    };
    await axios
      .patch(`${API_URL}/menu/admin/categories/${id}/`, data, config)
      .then((_) => {
        history.push("/admin/menu-categories-list");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminDeleteMenuCategory = (id, page) => async (dispatch) => {
  dispatch({ type: ADMIN_MENU_LOADING });
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  await axios
    .delete(`${API_URL}/menu/admin/categories/${id}/`, config)
    .then((_) => {
      dispatch(adminMenuCategories({ page: page }));
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
    });
};

export const adminMenuItemsList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/menu/admin/item/`, {
        params: {
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: ADMIN_MENU_ITEMS_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuItemsNameOnlyList = (data) => async (dispatch) => {
  console.log(data);
  dispatch({ type: ADMIN_MENU_LOADING });
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/menu/admin/item-name-only/`, {
      params: {
        res_branch: data.value,
      },
      headers: { Authorization: token },
    })
    .then((res) =>
      dispatch({ type: ADMIN_MENU_ITEMS_NAME_ONLY_LIST, payload: res.data })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
    });
};

export const adminMenuCategoriesName =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/menu/admin/categories-name-only`, {
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: ADMIN_MENU_CATEGORY_NAME_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuItemAdd =
  (data = {}, history, vendor) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .post(`${API_URL}/menu/admin/item/`, data, {
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res.data);
        if (vendor) {
          history.push("/vendor/menu-items-list");
        } else history.push("/admin/menu-items-list");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuItemDetails = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_MENU_LOADING });
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/menu/admin/item/${id}`, {
      headers: { Authorization: token },
    })
    .then((res) => {
      dispatch({ type: ADMIN_MENU_ITEM_DETAILS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
    });
};

export const adminMenuItemEdit =
  (id, data, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .patch(`${API_URL}/menu/admin/item/${id}/`, data, {
        headers: { Authorization: token },
      })
      .then((_) => {
        if (vendor) {
          history.push("/vendor/menu-items-list");
        } else history.push("/admin/menu-items-list");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuItemDelete =
  (id, history, details, vendor, res) => async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .delete(`${API_URL}/menu/admin/item/${id}/`, {
        headers: { Authorization: token },
      })
      .then((_) => {
        if (details && vendor) {
          history.push("/vendor/menu-items-list");
        } else if (details) {
          history.push("/admin/menu-items-list");
        } else if (vendor) {
          dispatch(vendorMenuItemsList({ res_branch: res }));
        } else {
          dispatch(adminMenuItemsList());
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuVariationsList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/menu/admin/food-variation/`, {
        params: {
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: ADMIN_MENU_VARIATION_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuVariationsAdd =
  (data = {}, history, vendor) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .post(`${API_URL}/menu/admin/food-variation/`, data, {
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res);
        if (vendor) {
          history.push("/vendor/menu-variations-list");
        } else {
          history.push("/admin/menu-variations-list");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuVariationsDetails = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_MENU_LOADING });
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/menu/admin/food-variation/${id}`, {
      headers: { Authorization: token },
    })
    .then((res) => {
      dispatch({ type: ADMIN_MENU_VARIATION_DETAILS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
    });
};

export const adminMenuVariationsEdit =
  (data, id, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .patch(`${API_URL}/menu/admin/food-variation/${id}/`, data, {
        headers: { Authorization: token },
      })
      .then((_) => {
        if (vendor) {
          history.push("/vendor/menu-variations-list");
        } else {
          history.push("/admin/menu-variations-list");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuVariationsDelete = (id, vendor) => async (dispatch) => {
  dispatch({ type: ADMIN_MENU_LOADING });
  let token = auth.getToken();
  await axios
    .delete(`${API_URL}/menu/admin/food-variation/${id}/`, {
      headers: { Authorization: token },
    })
    .then((_) => {
      if (vendor) {
        dispatch(vendorMenuVariationsList({ res_branch: vendor }));
      } else dispatch(adminMenuVariationsList());
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
    });
};

export const adminMenuIngredients =
  (data = {}) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/menu/admin/ingredient/`, {
        params: {
          search: data.search,
          page: data.page,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({ type: ADMIN_MENU_INGREDIENTS_LIST, payload: res.data })
      )
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuVariationNameOnlyList = (data) => async (dispatch) => {
  console.log(data);
  dispatch({ type: ADMIN_MENU_LOADING });
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/menu/admin/food-variation-name-only/`, {
      params: {
        res_branch: data.value,
      },
      headers: { Authorization: token },
    })
    .then((res) =>
      dispatch({ type: ADMIN_MENU_VARIATION_NAME_ONLY_LIST, payload: res.data })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
    });
};

export const adminMenuIngredientsAdd =
  (data = {}, history, vendor) =>
  async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .post(`${API_URL}/menu/admin/ingredient/`, data, {
        headers: { Authorization: token },
      })
      .then((res) => {
        console.log(res);
        if (vendor) {
          history.push("/vendor/food-ingredients-list");
        } else {
          history.push("/admin/food-ingredients-list");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuIngredientsDelete =
  (id, res, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .delete(`${API_URL}/menu/admin/ingredient/${id}/`, {
        headers: { Authorization: token },
      })
      .then((_) => {
        if (vendor) {
          dispatch(vendorMenuIngredients({ res_branch: res, page: 1 }));
        } else {
          dispatch(adminMenuIngredients({ page: 1 }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };

export const adminMenuIngredientsDetails = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_MENU_LOADING });
  let token = auth.getToken();
  await axios
    .get(`${API_URL}/menu/admin/ingredient/${id}`, {
      headers: { Authorization: token },
    })
    .then((res) => {
      dispatch({ type: ADMIN_MENU_INGREDIENTS_DETAILS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
    });
};

export const adminMenuIngredientsEdit =
  (data, id, history, vendor) => async (dispatch) => {
    dispatch({ type: ADMIN_MENU_LOADING });
    let token = auth.getToken();
    await axios
      .patch(`${API_URL}/menu/admin/ingredient/${id}/`, data, {
        headers: { Authorization: token },
      })
      .then((_) => {
        if (vendor) {
          history.push("/vendor/food-ingredients-list");
        } else {
          history.push("/admin/food-ingredients-list");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADMIN_MENU_ERROR, payload: err.response });
      });
  };
