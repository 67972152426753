import React from "react";
import bannerThumb from "../../images/termConditions/bannerThumb.png";
import Hero from "../Home/Hero";
const TermConditionsBanner = () => {
  return (
    <Hero banner={`bg-term-conditions-banner-bg`} animation>
      <div
        className="block
   md:flex justify-between items-center xl:gap-10 lg:gap-12 md:h-bg-height h-[400px] w-full rounded-md "
      >
        <div className="banner-thumb xl:w-[50%] lg:w-[40%] lg:block hidden lg:mt-20 ">
          <img className="mt-0 ml-auto" src={bannerThumb} alt="" />
        </div>
        <div className="banner-tex lg:w-[40%] xl:pt-20">
          <h2 className="lg:text-4xl md:text-3xl text-2xl text-[#002138] md:pb-3 pb-2 ">
            <span className="font-bold">Terms & Conditions</span>
          </h2>
          <p className="md:text-lg text-base text-black font-oswald pb-5">
            Read before you proceed. Good things come with terms. We hold
            ourselves accountable. We know what we’re talking about. The first
            step in business is reading our terms. Knowledge is power, get to
            know our terms. Our terms are your guide
          </p>
        </div>
      </div>
     
    </Hero>
  );
};

export default TermConditionsBanner;
