import React from "react";
import thumbnail from "../../../images/driver/safety-thumbnail.png";

const AboutSafety = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-6 mx-10 my-16 place-items-center ">
      <div>
        {" "}
        <img src={thumbnail} alt="" />
      </div>
      <div className="py-10">
        <h1 className="text-xl pb-1">We're always here for you</h1>
        <p className="text-sm text-[#828282]">
          When you’re on the road, we’re with you – for help, advice and
          support, message or call us in the app. Your safety is our priority,
          so we’ll insure you in case of accidents, too. It’s totally free and
          applies from the moment you go online.
          <br />
          <br />
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
          <br />
          <br />
          It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged. It was
          popularised in the 1960s with the release of Letraset sheets
          containing Lorem Ipsum passages, and more recently with desktop
          publishing software like Aldus PageMaker including versions of Lorem
          Ipsum.
        </p>
      </div>
    </div>
  );
};

export default AboutSafety;
