import React from "react";
import OrderCreateUpdateForm from "../../../components/admin/Forms/Order/OrderCreateUpdateForm";
import Layout from "../Layout/layout";

const VendoraddOrder = () => {
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <OrderCreateUpdateForm />
        </div>
      </div>
    </Layout>
  );
};

export default VendoraddOrder;
