import { Tooltip } from "@material-tailwind/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ModalContainer from "../../../components/common/ModalContainer";
import Pagination from "../../../components/common/Pagination";
import {
    adminDeleteVoucherAction,
    adminVoucherListAction,
} from "../../../redux/actions/AdminActions/adminVoucherAction";
import Layout from "../Layout/layout";

function VoucherList() {
  const history = useHistory();
  const { voucherList } = useSelector((state) => state.adminVoucher);
  console.log(voucherList);
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setsearch] = useState("");
  const [status, setstatus] = useState("");

  const handleSearch = (e) => {
    // console.log(e.target.value);
    setsearch(e.target.value);
    dispatch(
      adminVoucherListAction({
        search: e.target.value,
        status: status,
      })
    );
  };
  const handleStatusChange = (e) => {
    setstatus(e.target.value);
    dispatch(
      adminVoucherListAction({
        page: 1,
        status: e.target.value,
        search: search,
      })
    );
  };
  const handlePageClick = (data) => {
    console.log(data);
    let currentPage = data.selected + 1;
    dispatch(adminVoucherListAction({ page: currentPage }));
  };
  useEffect(() => {
    dispatch(adminVoucherListAction());
  }, [dispatch]);
  function closeModal() {
    setIsOpen(false);
  }
  const handleDeleteClick = (id) => {
    setIsOpen(true);
    setSelectedId(id);
  };
  const handleDelete = async () => {
    dispatch(adminDeleteVoucherAction(selectedId, history));
    closeModal();
  };

  return (
    <Layout>
      <div className="m-3">
        <p className="text-center">Voucher List</p>
        <div className="flex items-center">
          {/* search bar */}
          <div className="relative md:w-1/3 my-2">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              value={search}
              onChange={handleSearch}
              id="simple-search"
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
              placeholder="Search here.."
            />
          </div>
          {/*  status select box*/}
          <div className="mx-2">
            <div className="flex justify-center">
              <div className="">
                <select
                  value={status}
                  onChange={handleStatusChange}
                  className="form-select 
      text-gray-700
      bg-white 
      border border-solid border-gray-300
      rounded
      focus:ring-gray-900 focus:border-gray-900 "
                  aria-label="Default select example"
                >
                  <option value="">All</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-scroll">
          <table className="table-auto  w-full border-collapse custom-table-data my-2">
            <thead className="text-center">
              <tr className="bg-gray-800 text-gray-300 ">
                <th className="custom-table-data"> Code</th>
                <th className="custom-table-data"> Restaurant branch</th>
                <th className="custom-table-data">Max uses user</th>
                <th className="custom-table-data"> Starts at</th>
                <th className="custom-table-data"> Expires at</th>
                <th className="custom-table-data"> Order status</th>
                <th className="custom-table-data"> Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {voucherList?.data?.map((voucher) => (
                <tr key={voucher?.id}>
                  <td className="custom-table-data">{voucher.code}</td>
                  <td className="custom-table-data">
                    {voucher.restaurant_branch?.name}
                  </td>
                  <td className="custom-table-data">{voucher.max_uses_user}</td>
                  <td className="custom-table-data">
                    {voucher.starts_at
                      ? moment(voucher.starts_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                      : null}
                  </td>
                  <td className="custom-table-data">
                    {voucher.expires_at
                      ? moment(voucher.expires_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                      : null}
                  </td>
                  <td className="custom-table-data">
                    {voucher?.status.toString().toUpperCase()}
                  </td>
                  <td className="custom-table-data text-xl">
                    <div className="flex justify-center ">
                      <Tooltip content="View">
                        <Link to={`/admin/voucher-details/${voucher.id}`}>
                          <button>
                            {" "}
                            <AiOutlineEye className="mx-2 text-green-500" />
                          </button>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Edit">
                        <Link to={`/admin/voucher/edit/${voucher.id}`}>
                          <button>
                            {" "}
                            <AiOutlineEdit className="mx-2 text-yellow-500" />
                          </button>
                        </Link>
                      </Tooltip>

                      <Tooltip content="Delete">
                        <button onClick={() => handleDeleteClick(voucher.id)}>
                          <RiDeleteBin6Line className="mx-2 text-red-500" />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                  <ModalContainer
                    open={modalIsOpen}
                    onRequestClose={closeModal}
                  >
                    <div id="modal-background">
                      <div id="modal" className="w-80 md:w-full">
                        <h2 className="my-3">
                          Are you sure you want to delete this item?
                        </h2>
                        <div className=" text-sm flex gap-8">
                          <button
                            className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                            onClick={handleDelete}
                          >
                            Delete
                          </button>
                          <button
                            className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalContainer>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        pageCount={
          voucherList?.meta?.last_page > 0 ? voucherList?.meta?.last_page : 0
        }
        handlePageClick={handlePageClick}
      ></Pagination>
    </Layout>
  );
}

export default VoucherList;
