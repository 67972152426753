import React from "react";
import Hero from "../../components/Home/Hero";
import faqThumb from "../../images/banner/faq_thumbnail.png";
const FAQBanner = () => {
  return (


<Hero banner={`bg-faq-banner-bg`} animation>
  
<div
  className="
   flex justify-between items-center xl:gap-20 lg:gap-12 md:h-bg-height h-[400px] w-full rounded-md "
>
  <div className="banner-tex lg:w-[60%] w-full xl:pt-16">
    <h2 className="lg:text-4xl md:text-3xl text-2xl text-[#002138] md:pb-3 pb-2 ">
    Hi we can help  <br />
  <span className="font-bold">you find any information</span>
  
    </h2>
    <p className="md:text-lg text-base text-black  font-oswald pb-5">
    An FAQ page is a time-saving customer service tactic that provides the most commonly asked questions and answers for current or potential customers.
    </p>
   
  </div>
  <div className="banner-thumb xl:w-[40%] lg:w-[40%] lg:block hidden lg:mt-20 ">
    <img className="mt-0 ml-auto" src={faqThumb} alt="" />
  </div>
</div>
</Hero>

  );
};

export default FAQBanner;

