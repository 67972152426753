import React from "react";

const Hero = (props) => {
  console.log(props?.banner);
  return (
    <section className= {`banner-area ${props?.banner} md:h-bg-height h-[450px] bg-cover bg-center object-contain relative` }
     >
    { props?.animation && <div className=" ">
      <svg className="absolute xl:top-24 xl:right-[450px] lg:top-28 lg:right-20 top-20 right-12 animate-spin-slow" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.9316 19.6739C28.4001 23.645 25.0192 27.7166 20.8459 27.0029L5.26346 24.3379C1.09015 23.6242 -0.745526 18.6603 1.95925 15.403L12.0584 3.24075C14.7632 -0.0165699 19.9798 0.875604 21.4483 4.84666L26.9316 19.6739Z" stroke="#20B03F" stroke-opacity="0.44"/>
</svg>
<svg className="absolute xl:bottom-28 xl:left-[870px] lg:bottom-28 lg:left-20 bottom-28 left-24 animate-bounce" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10.5" cy="10.5" r="10" stroke="#028FF0"/>
</svg>

      <svg className="absolute xl:top-24 xl:left-[550px] lg:top-28 lg:left-10 top-24 left-10 animate-bounce" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10.5" cy="10.5" r="10.5" fill="#FFE000"/>
</svg>

      <svg className="absolute xl:bottom-28 xl:right-10 lg:bottom-28 lg:right-5 bottom-28 right-10 animate-spin-slow" width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8184 26.9371C10.3482 29.9852 4.87963 28.1337 3.97498 23.6044L0.879093 8.10402C-0.0255571 3.57468 4.31219 -0.235474 8.68705 1.24575L23.6587 6.31479C28.0335 7.79601 29.1643 13.4577 25.6941 16.5058L13.8184 26.9371Z" fill="#84C348" fill-opacity="0.62"/>
</svg>

      </div>}
      <div className="container mx-auto">{props.children}</div>
    </section>
  );
};

export default Hero;
