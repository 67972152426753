import React from "react";
import { Redirect, Route } from "react-router-dom";

import auth from "../../util/auth";

const AdminRoute = ({ component: Component, ...rest }) => {
  // const redirect = location.search ? location.search.split("=")[1] : "/";
  // console.log(auth.getUserInfo().user.role);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.getToken() !== null && auth.getUserInfo().user.role === "ADMIN" ? (
          <Component {...props} />
        ) : (
          <Redirect
            // to={"/login"}
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
