import axios from "axios";
import { API_URL } from "../../settings/config";
import {
  REQUEST_CART_FAILED,
  REQUEST_CART_PENDING,
  REQUEST_CART_SUCCESS,
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  INCREASE_CART_ITEM,
  DECREASE_CART_ITEM,
  SET_SERVICE_FEE,
  ADD_EXTRA_PRODUCTS_TO_CART,
  REMOVE_EXTRA_PRODUCTS_CART_ITEM,
  SET_FOOD_INGREDIENTS_HOME_MADE,
  REMOVE_All_EXTRA_PRODUCTS_CART_ITEM,
} from "../types";

function getCartFromLocalStorage() {
  return localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
}

export const homeMadeFoodIngredients = (data) => (dispatch) => {
  dispatch({ type: SET_FOOD_INGREDIENTS_HOME_MADE, payload: data });
};

export const requestCart = () => (dispatch) => {
  dispatch({ type: REQUEST_CART_PENDING });
  // let cart = getCartFromLocalStorage();
  // console.log(cart);
  // if (cart.length < 0 || cart === undefined) {
  //   dispatch(removeAllHomeMadeProductCart());
  // }
  dispatch({ type: REQUEST_CART_SUCCESS, payload: getCartFromLocalStorage() });
  dispatch({ type: REQUEST_CART_FAILED, payload: "error" });
};

export const addCart = (product) => (dispatch) => {
  // dispatch(requestCart());
  console.log(product);
  dispatch({ type: ADD_TO_CART, payload: product });
};

export const addHomeMadeProductCart = (data) => (dispatch) => {
  // dispatch(requestCart());
  console.log(data);
  dispatch({ type: ADD_EXTRA_PRODUCTS_TO_CART, payload: data });
};

export const removeCart = (_id) => (dispatch) => {
  dispatch({ type: REMOVE_CART_ITEM, payload: _id });
};

export const removeHomeMadeProductCart = (_id) => (dispatch) => {
  dispatch({ type: REMOVE_EXTRA_PRODUCTS_CART_ITEM, payload: _id });
};

export const removeAllHomeMadeProductCart = () => (dispatch) => {
  dispatch({ type: REMOVE_All_EXTRA_PRODUCTS_CART_ITEM });
};

export const increaseCart = (_id) => (dispatch) => {
  dispatch({ type: INCREASE_CART_ITEM, payload: _id });
};

export const decreaseCart = (_id, amount) => (dispatch) => {
  if (amount === 1) {
    dispatch(removeCart(_id));
  } else {
    dispatch({ type: DECREASE_CART_ITEM, payload: _id });
  }
};

export const addServiceFee = (amount) => async (dispatch) => {
  dispatch({ type: REQUEST_CART_PENDING });
  await axios
    .get(`${API_URL}/order/add/extra`, {
      params: {
        order_amount: amount,
      },
    })
    .then((res) => dispatch({ type: SET_SERVICE_FEE, payload: res.data }))
    .catch((err) =>
      dispatch({
        type: REQUEST_CART_FAILED,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      })
    );
};
