import React, { useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import SocialMedia from "../../../components/Home/SocialMedia";
import ModalContainer from "../../../components/common/ModalContainer";
import VendorRegistrationForm from "../../../components/vendor/Form/Registration/VendorRegistrationForm";
import successfulReg from "../../../images/vendorReg/successful-reg-banner.png";
import { signupVendor } from "../../../redux/actions/authActions";
import Layout from "../../Layout";

const VendorRegistration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [count, setcount] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const onSubmit = (data) => {
    if (data.first_name && data.last_name && data.phone_number && count === 1) {
      setcount(2);
      console.log(data);
    } else console.log("submitted", data);
    const body = {
      vendor_first_name: data.first_name,
      vendor_last_name: data.last_name,
      vendor_phone_number: data.phone_number,
      restaurant_name: data.res_name,
      full_address: data.full_address,
      country: data.country,
      city: data.city,
      postcode: data.postcode,
      lat: data.lat,
      lng: data.lng,
      vendor_password: data.password,
    };
    // console.log(body);
    dispatch(signupVendor(body));
    if (count === 2) {
      regSuccessModal();
    }
  };
  const regSuccessModal = () => {
    console.log("modal");
    setIsOpen(true);
  };

  const [address, setaddress] = useState({
    full_address: "",
    country: "",
    city: "",
    postcode: "",
    lat: "",
    lng: "",
  });
  // const handleChange = (address) => {
  //   console.log(address);
  //   setaddress({ address });
  // };

  const handleSelect = async (value) => {
    console.log({ value });
    let city = "";
    let country = "";
    let postcode = "";
    let lat = "";
    let lng = "";
    const result = await geocodeByAddress(value);
    const latlng = await getLatLng(result[0]);
    if (latlng) {
      lat = latlng.lat;
      lng = latlng.lng;
    }
    for (let i = 0; i < result[0].address_components.length; i++) {
      if ((result[0].address_components[i].types[0] = "country"))
        country = result[0].address_components[i].long_name;
      if (
        (result[0].address_components[0].types[0] = "country") &&
        (result[0].address_components[0].types[1] = "political")
      )
        city = result[0].address_components[0].long_name;
      if (result[0].address_components[i].types[0] === "postal_code") {
        postcode = result[0].address_components[i].long_name;
      }

      console.log(
        "city:",
        city,
        "country:",
        country,
        "postcode:",
        postcode,
        "lat:",
        lat,
        "lng:",
        lng
      );
    }

    // console.log(result[0].address_components);
    console.log(result);
    setaddress({
      ...address,
      full_address: value,
      country: country,
      city: city,
      postcode: postcode,
      lat: lat,
      lng: lng,
    });
  };
  return (
    <Layout>
      <div className="mb-56 mt-16 flex justify-center">
        <VendorRegistrationForm
          count={count}
          onSubmit={onSubmit}
          setcount={setcount}
          // handleChange={handleChange}
          handleSelect={handleSelect}
          address={address}
          setaddress={setaddress}
          regSuccessModal={regSuccessModal}
        />
      </div>
      <SocialMedia />
      <ModalContainer open={modalIsOpen}>
        <div className="max-w-sm mx-auto md:max-w-lg">
          <div className="flex justify-center">
            <img src={successfulReg} alt="" />
          </div>
          <div className="text-center px-6 py-10">
            <p>
              <strong>We have all information we need</strong>
            </p>
            <p className="text-[#464956] font-light">
              We’ll contact you in the next few days about getting set up and
              ready to launch{" "}
            </p>
            <button
              onClick={() => {
                setIsOpen(false);
                history.push("/");
              }}
              className="custom-btn px-10 bg-[#0DC1D9] text-white mt-6"
            >
              Done
            </button>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
};

export default VendorRegistration;
