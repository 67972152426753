import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-tailwind/react";
import Layout from "../Layout/layout";
import { RiDeleteBin6Line } from "react-icons/ri";
import ModalContainer from "../../../components/common/ModalContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  adminContactDelete,
  adminContactList,
} from "../../../redux/actions/AdminActions/adminMetaActions";
import Pagination from "../../../components/common/Pagination";

function ContactUs() {
  const { contactList } = useSelector((state) => state.adminMeta);
  console.log(contactList);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [deleleState, setDeleleState] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminContactList());
  }, [dispatch]);
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleDeleteClick = (delete_id) => {
    console.log(delete_id);
    setIsOpen(true);
    setDeleleState(delete_id);
  };
  const handleDelete = async () => {
    dispatch(adminContactDelete(deleleState));
    closeModal();
    setDeleleState(null);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    dispatch(adminContactList({ page: 1, search: e.target.value }));
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    dispatch(adminContactList({ page: currentPage }));
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  return (
    <Layout>
      <div className="m-3">
        <p className="text-center">Contact Us</p>
        <div className="flex items-center">
          {/* search bar */}
          <div className="relative md:w-1/3 my-2">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              value={search}
              onChange={handleSearch}
              id="simple-search"
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
              placeholder="Search here.."
            />
          </div>
        </div>
        <div className="overflow-x-scroll">
          <table className="table-auto w-full border-collapse custom-table-data my-2">
            <thead className="text-center">
              <tr className="bg-gray-800 text-gray-300 ">
                <th className="custom-table-data"> Name</th>
                <th className="custom-table-data"> Email</th>
                <th className="custom-table-data">Phone</th>
                <th className="custom-table-data">Content</th>
                <th className="custom-table-data">Causes</th>
                <th className="custom-table-data">Experience</th>
                <th className="custom-table-data"> Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {contactList?.data?.map((contact) => (
                <tr key={contact?.id}>
                  <td className="custom-table-data">{contact.name}</td>
                  <td className="custom-table-data">{contact.email}</td>
                  <td className="custom-table-data">{contact.phone}</td>
                  <td className="custom-table-data">{contact.content}</td>
                  <td className="custom-table-data">{contact.causes}</td>
                  <td className="custom-table-data">{contact.experience}</td>
                  <td className="custom-table-data text-xl ">
                    <div className="flex justify-center items-center">
                      <Tooltip content="Delete">
                        <button onClick={() => handleDeleteClick(contact?.id)}>
                          <RiDeleteBin6Line className="mx-2 text-red-500" />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          pageCount={
            contactList?.meta?.last_page > 0 ? contactList?.meta?.last_page : 0
          }
          handlePageClick={handlePageClick}
        />
      </div>
      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Are you sure you want to delete this item?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default ContactUs;
