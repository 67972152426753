import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { adminMenuVariationNameOnlyList } from "../../../../redux/actions/AdminActions/adminMenuAction";
const FoodIngredientsCreateUpdateForm = ({
  edit,
  onSubmit,
  resBranchData,
  variationData,
  foodIngredients,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {},
  });
  const [resBranchSelectOption, setresBranchSelectOption] = useState([]);
  const [variationSelectOption, setvariationSelectOption] = useState([]);
  useEffect(() => {
    const resBranchOptions = [];
    resBranchData?.map((option) =>
      resBranchOptions.push({ value: option.id, label: option.name })
    );
    // console.log(resBranchOptions);
    setresBranchSelectOption(resBranchOptions);
    // eslint-disable-next-line
  }, [resBranchData]);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("data");
    if (watch("restaurantBranch")) {
      dispatch(adminMenuVariationNameOnlyList(watch("restaurantBranch")));
    }
    // eslint-disable-next-line
  }, [watch("restaurantBranch")]);
  useEffect(() => {
    const variationOptions = [];
    if (variationData?.length > 0) {
      variationData.map((option) =>
        variationOptions.push({ value: option.id, label: option.name })
      );
      setvariationSelectOption(variationOptions);
    } else {
      setvariationSelectOption(null);
    }
    setValue("variation", null);
    // eslint-disable-next-line
  }, [variationData]);
  useEffect(() => {
    if (foodIngredients) {
      setValue("name", foodIngredients?.name);
      setValue("price", foodIngredients?.price);
      setValue("quantity", foodIngredients?.quantity);
    }
    // eslint-disable-next-line
  }, [foodIngredients, setValue]);

  useEffect(() => {
    if (
      foodIngredients?.restaurant_branch &&
      resBranchSelectOption.length > 0
    ) {
      const selectedOption = resBranchSelectOption.find(
        (item) =>
          parseInt(item.value) ===
          parseInt(foodIngredients?.restaurant_branch?.id)
      );
      // console.log(selectedOption);
      setValue("restaurantBranch", {
        value: selectedOption.value,
        label: selectedOption.label,
      });
      // console.log(watch("restaurantBranch"));
    }
    // eslint-disable-next-line
  }, [foodIngredients, resBranchSelectOption]);

  useEffect(() => {
    if (
      foodIngredients?.restaurant_branch &&
      variationSelectOption?.length > 0
    ) {
      const selectedOption = variationSelectOption.find(
        (item) =>
          parseInt(item.value) === parseInt(foodIngredients?.variation?.id)
      );
      // console.log(selectedOption);
      if (selectedOption) {
        setValue("variation", {
          value: selectedOption.value,
          label: selectedOption.label,
        });
      } else {
        setValue("variation", null);
      }
      // setValue("variation", null);
      // console.log(watch("variation"));
    }
    // eslint-disable-next-line
  }, [variationSelectOption]);
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: 32,
    }),
  };

  return (
    <div className="m-3">
      <h1 className="py-2 text-center">
        {edit ? "Edit" : "Add"} Food Ingredients{" "}
      </h1>
      <div>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="md:grid md:grid-cols-3 md:gap-3 pt-2">
            <div className="">
              <label htmlFor="restaurantBranch" className=" text-gray-700">
                Item variation restaurant branch
              </label>
              <Controller
                name="restaurantBranch"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={resBranchSelectOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.restaurantBranch &&
              errors.restaurantBranch.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="variation" className=" text-gray-700">
                Variation
              </label>
              <Controller
                name="variation"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={variationSelectOption}
                    isDisabled={variationData.length > 0 ? false : true}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.variation && errors.variation.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Name
              </label>
              <input
                {...register("name", {
                  required: edit ? false : "This field is required",
                })}
                type="text"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.name?.message}</p>
            </div>
            <div className="">
              <label htmlFor="price" className=" text-gray-700">
                Price
              </label>
              <input
                type="number"
                step="any"
                {...register("price", {
                  required: edit ? false : "This field is required",
                })}
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.price?.message}</p>
            </div>
            <div className="">
              <label htmlFor="quantity" className=" text-gray-700">
                Quantity
              </label>

              <input
                type="number"
                {...register("quantity", {
                  required: edit ? false : "This field is required",
                })}
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.quantity?.message}</p>
            </div>
            <br />
            <div className=" mt-2">
              <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FoodIngredientsCreateUpdateForm;
