import {
  VENDOR_VOUCHER_LOADING,
  VENDOR_VOUCHER_LIST,
  VENDOR_VOUCHER_DETAIL,
  VENDOR_VOUCHER_ERROR,
} from "../../types";

const initialState = {
  loading: false,
  voucherList: [],
  voucherDetail: {},
  error: null,
};

export const vendorVoucherReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case VENDOR_VOUCHER_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VENDOR_VOUCHER_LIST:
      return {
        ...state,
        loading: false,
        voucherList: action.payload,
        error: null,
      };
    case VENDOR_VOUCHER_DETAIL:
      return {
        ...state,
        loading: false,
        voucherDetail: action.payload,
        error: null,
      };
    case VENDOR_VOUCHER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
