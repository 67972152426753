import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  addServiceFee,
  decreaseCart,
  increaseCart,
} from "../../redux/actions/cartActions";
// import { addCart } from "../../redux/actions/cartActions";

const OrderSummerySidebar = ({
  formattedAddr,
  cart,
  delevery_charge,
  serviceFee,
  newTotalWithPrice,
  ex_sum,
  newHomeMadeWithPrice3,
  is_home_made,
  home_made_sum,
  homeMadeVariation,
  homeVariationPriceResult = 0,
}) => {
  const { homeMadeCart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const history = useHistory();

  // const results = homeMadeVariation.filter(
  //   ({ id: id1 }) => !homeMadeCart.some(({ item_id: id2 }) => id2 === id1)
  // );

  // let newHomeMadeWithPrice2 = cart?.map((i) =>
  //   i.home_made_change.filter(
  //     ({ item_id: id1 }) => !i.home_made.some(({ id: id2 }) => id2 === id1)
  //   )
  // );
  // let newHomeMadeWithPrice2 = cart?.map(
  //   (i) =>
  //     i.home_made.map((j) => j.id) !== i.home_made_change.map((k) => k.item_id)
  // );
  let newHomeMadeWithPrice2 = cart?.map((i) =>
    i.home_made.filter(({ id: id1 }) =>
      i.home_made_change.some(({ item_id: id2 }) => id1 === id2)
    )
  );
  console.log(newHomeMadeWithPrice2);
  // let made = [];
  // let newHomeMadeWithPrice2 = cart?.map((i) =>
  //   i.home_made.map((j) => made.push(j))
  // );
  // console.log(made);
  // console.log(homeMadeCart);
  // console.log(newHomeMadeWithPrice2);
  // let newHomeMadeWithPrice3 = newHomeMadeWithPrice2?.map((i) => i.id);
  // console.log(newHomeMadeWithPrice3);

  // let r = [];

  console.log({ newHomeMadeWithPrice3 });

  // console.log(newHomeMadeWithPrice2);
  // const reducer = (accumulator, curr) => accumulator + curr;
  // console.log(newHomeMadeWithPrice2.reduce(reducer));
  // let newHomeMadeWithPrice2 = cart?.map((i) =>
  //   i?.changedHomeMade.reduce((total, cartItem) => {
  //     return (total += parseFloat(cartItem.price));
  //   }, 0)
  // );

  // console.log(newHomeMadeWithPrice2);

  console.log({cart});
  let newHomeMadeWithPrice = cart?.standerdHomeMade?.reduce(
    (total, cartItem) => {
      return (total += parseFloat(cartItem.product.price));
    },
    0
  );

  // console.log(homeVariationPriceResult);

  useEffect(() => {
    console.log(newTotalWithPrice);
    if (newTotalWithPrice > 0) {
      dispatch(
        addServiceFee(
          (newTotalWithPrice + ex_sum + newHomeMadeWithPrice3).toFixed(2)
        )
      );
    }
  }, [newTotalWithPrice, ex_sum, dispatch, newHomeMadeWithPrice3]);

  const handleCheckout = () => {
    // const total = newTotalWithPrice + serviceFee + delevery_charge;
    // dispatch(placeOrder(cart, total, res_id));
    history.push("/checkout");
  };
  return (
    <div className="sticky hidden lg:block">
      <div className="order-summery bg-white rounded-md p-4 shadow-themeShadow absolute xl:top-20 lg:top-24 w-full xl:max-h-[350px] lg:max-h-[200px] overflow-y-auto">
      <h4 className="text-black font-semibold text-xl pb-4">My Order</h4>
      <span className="text-black font-normal text-base truncate block">
        Your order from {formattedAddr}
      </span>
      <div className=" max-h-[400px] overflow-y-auto">
        {cart.length > 0
          ? cart.map((cartItem, index) => (
              <div
                key={index}
                className="flex flex-wrap justify-between items-start pt-4"
              >
                <div className="item-text">
                  <h6 className="text-black font-medium text-base">
                    {cartItem.name}
                  </h6>
                  {cartItem?.extra.map((item) => (
                    <span
                      className="text-gray-500 text-sm flex items-center gap-2 pb-2"
                      key={item.id}
                    >
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4v16m8-8H4"
                      />
                    </svg> */}
                      {item.name}
                    </span>
                  ))}
                  {cartItem?.home_made.map((item) => (
                    <span
                      className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                      key={item.id}
                    >
                      <div>{item.name}</div>
                      <div>{item.price}</div>
                      <Link
                        to={{
                          pathname: "/food/ingredients/",
                          search: `?search=${item.name}`,
                          state: {
                            cartItem: cartItem,
                            item_id: item.id,
                          },
                        }}
                      >
                        Change
                      </Link>
                    </span>
                  ))}
                  {cartItem?.home_made_change.map((item) => (
                    <span
                      className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                      key={item.product.id}
                    >
                      <div>{item.product.name}</div>
                      <div>{item.product.price}</div>
                    </span>
                  ))}

                  <strong className="text-[#E64A19] font-normal">
                    £{cartItem.total_price}
                  </strong>
                </div>
                <div className="qt-item flex gap-2 pt-0 bg-[#F1F1F1] shadow-themeShadow rounded-[25px] px-2 h-6">
                  <button
                    type="button"
                    onClick={() => dispatch(increaseCart(cartItem._id))}
                    className=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                  </button>
                  <input
                    type="text"
                    name="qt"
                    id=""
                    className="w-6  rounded-md  px-2 placeholder:text-black border-none bg-[#F1F1F1]"
                    // active:shadow-themeShadow
                    placeholder={cartItem.quantity}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      dispatch(decreaseCart(cartItem._id, cartItem.quantity))
                    }
                    // className="bg-white shadow-themeShadow rounded-md px-2 h-6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18 12H6"
                      />
                    </svg>
                  </button>
                </div>
                {/* {cartItem?.home_made.map((item) => (
                  <span
                    className="text-gray-500 text-sm flex items-center gap-2 pb-2 justify-between"
                    key={item.id}
                  >
                    <div>{item.price}</div>
                  </span>
                ))} */}
              </div>
            ))
          : ""}
      </div>

      {/* {is_home_made ? (
        <>
          {" "}
          <div className="mt-3">Changed Ingredients</div>
          <div className="max-h-[500px] overflow-y-auto">
            {homeMadeCart.length > 0
              ? homeMadeCart.map((cartItem, index) => (
                  <div key={index} className="order-item flex">
                    <div className="order-item flex ">
                      <div className="order-item flex pb-5">
                        <div className="item-text">
                          <h4 className=" text-black text-md leading-6 font-medium pt-0 lg:pt-2 xl:pt-0">
                            <div>
                              <span className=" text-red-500">
                                {cartItem?.item_name}(Starnderd){" "}
                              </span>
                              <span className=" text-red-500">
                                £{cartItem?.item_price}
                              </span>
                            </div>
                            <span className="font-normal"> To </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </>
      ) : null} */}

      {/* <div className="text-end my-[15px]">
        <Link to={`/food/ingredients`}>
          <button className="text-[#0DC1D9] border text-sm rounded-md px-4 py-1 border-[#0DC1D9] ">
            Buy my Ingredients
          </button>
        </Link>
      </div> */}
      <div className="border-b border-dashed border-gray-600 py-2"></div>
      {cart?.length > 0 ? (
        <div className="border-b border-dashed border-gray-600">
          <div className="flex flex-wrap justify-between items-center pb-2 pt-3">
            <span className="text-black text-sm">Subtotal</span>
            <span className="text-black text-sm">
              £{(newTotalWithPrice + ex_sum + newHomeMadeWithPrice3).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-wrap justify-between items-center pb-2">
            <span className="text-black text-sm">Delivery fee</span>
            <span className="text-black text-sm">£{delevery_charge}</span>
          </div>
          <div className="flex flex-wrap justify-between items-center pb-2">
            <span className="text-black text-sm">Service fee</span>
            <span className="text-black text-sm">£{serviceFee.toFixed(2)}</span>
          </div>
          <div className="flex flex-wrap justify-between items-center pb-2">
            <span className="text-black text-sm">Rider tip</span>
            <span className="text-black text-sm">
              £ 0.00
            </span>
          </div>
          <div className="flex flex-wrap justify-between items-center pb-2">
            <span className="text-black text-sm">Total</span>
            <span className="text-black text-sm">
              £
              {(
                newTotalWithPrice +
                delevery_charge +
                serviceFee +
                ex_sum +
                newHomeMadeWithPrice3
              ).toFixed(2)}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <button
        disabled={cart?.length === 0}
        onClick={handleCheckout}
        type="button"
        className={!cart?.length === 0 ? "w-full mt-6 rounded-md bg-[#0DC1D9] text-white font-medium text-base uppercase px-5 py-2 cursor-pointer" :
      "w-full mt-6 rounded-md bg-[#0DC1D9] text-white font-medium text-base uppercase px-5 py-2 "
    }
      >
        Go To Checkout
      </button>
      </div>
      
    </div>
  );
};

export default OrderSummerySidebar;
