import React from "react";
import thumbnail3 from "../../../images/restaurantPartner/09.png";

import img7 from "../../../images/restaurantPartner/11.png";
import img8 from "../../../images/restaurantPartner/12.png";
import img6 from "../../../images/restaurantPartner/13.png";
const Instractions = () => {
  return (
    <section className=" grid lg:grid-cols-2 gap-3 md:mx-28 mx-5 my-10 place-items-center">
      <div className="">
        <img
          src={thumbnail3}
          alt=""
          className="shadow-md rounded-md text-center "
        />
      </div>
      <ul className="">
        <li className="grid grid-cols-10 gap-4 min-h-[140px] relative after:absolute after:sm:block after:hidden after:sm:left-5 after:top-1 after:h-full after:w-4 after:bg-transparent after:border-transparent after:border-r after:border-dashed after:border-r-red-500">
          <div className="list_icon z-10 sm:block hidden col-span-2">
            <img src={img7} alt="" />
          </div>

          <div className="list_text md:col-span-8 col-span-10">
            <h1 className="text-xl">Sign up</h1>
            <p className="text-[#676971] text-lg pt-2">
              Partner with British Marketplace and tell us about your business -
              you'll need your FSA rating to get started
            </p>
          </div>
        </li>
        <li className="grid grid-cols-10 gap-4 min-h-[120px] relative after:absolute after:sm:block after:hidden after:sm:left-5 after:top-1 after:h-full after:w-4 after:bg-transparent after:border-transparent after:border-r after:border-dashed after:border-r-red-500">
          <div className="list_icon  z-10 sm:block hidden col-span-2">
            <img src={img8} alt="" />
          </div>

          <div className="list_text md:col-span-8 col-span-10">
            <h1 className="text-xl">Set up</h1>
            <p className="text-[#676971] text-lg pt-2">
              Upload a menu and share your store hours
            </p>
          </div>
        </li>
        <li className="grid grid-cols-10 gap-4 min-h-[120px]">
          <div className="list_icon sm:block hidden  col-span-2">
            <img src={img6} alt="" />
          </div>
          <div className="list_text md:col-span-8 col-span-10">
            <h1 className="text-xl">Sell</h1>
            <p className="text-[#676971] text-lg pt-2">
              Watch the orders come in and track them via Hub
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Instractions;
