import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "../Layout/layout";
import ModalContainer from "../../../components/common/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  adminMenuItemDelete,
  adminMenuItemDetails,
} from "../../../redux/actions/AdminActions/adminMenuAction";

function MenuItemsDetails() {
  const { menuDetails } = useSelector((state) => state.adminMenu);
  console.log(menuDetails);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleleState, setDeleleState] = useState(null);

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(adminMenuItemDetails(params.id));
  }, [dispatch, params]);

  function closeModal() {
    setIsOpen(false);
  }
  const handleDelete = async () => {
    dispatch(adminMenuItemDelete(deleleState, history, "details"));
    closeModal();
    setDeleleState(null);
  };

  const handleDeleteClick = (delete_id) => {
    console.log(delete_id);
    setIsOpen(true);
    setDeleleState(delete_id);
  };

  return (
    <Layout>
      <div className=" mx-3 my-8 ">
        <div className="my-3 shadow p-3 h-max mx-2 lg:mx-24">
          <p className="text-center mb-8">Menu Items Details</p>
          <img
            className=" md:w-[300px] md:h-[300px] w-[200px] h-[200px] mb-8 rounded border border-yellow-600 mx-auto"
            src={
              menuDetails?.image
                ? menuDetails?.image
                : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
            }
            alt=""
          />
          <div className="md:mx-24">
            <div className="grid grid-cols-1 gap-2 ">
              <p>
                Restaurant branch:{" "}
                <strong>{menuDetails?.restaurant_branch?.name}</strong>
              </p>
              <p>
                Categories:{" "}
                <strong>
                  {menuDetails?.categories?.map((item, index) => (
                    <strong key={item.id} className="mr-2">
                      {item.name}
                      {index === menuDetails?.categories.length - 1 ? "" : ","}
                    </strong>
                  ))}
                </strong>
              </p>
              <p>
                Name: <strong>{menuDetails?.name}</strong>
              </p>
              <p>
                Price: <strong>{menuDetails?.name}</strong>
              </p>
              <p className="flex">
                Details:{" "}
                <strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: menuDetails?.details?.replace(
                        /\r\n|\r|\n/g,
                        "<br />"
                      ),
                    }}
                  />
                </strong>
              </p>
              <p>
                Review: <strong>{menuDetails?.review}</strong>
              </p>
              <p>
                Discount: <strong>{menuDetails?.discount}</strong>
              </p>
              <p>
                Total price: <strong>{menuDetails?.total_price}</strong>
              </p>
              <p>
                Stock Count: <strong>{menuDetails?.countInStock}</strong>
              </p>
              <p className="flex">
                Cooking method:{" "}
                <strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: menuDetails?.cooking_method?.replace(
                        /\r\n|\r|\n/g,
                        "<br />"
                      ),
                    }}
                  />
                </strong>
              </p>
              <p className="flex">
                Allergen info:{" "}
                <strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: menuDetails?.allergen_info?.replace(
                        /\r\n|\r|\n/g,
                        "<br />"
                      ),
                    }}
                  />
                </strong>
              </p>
              <p>
                Status:{" "}
                <strong>{menuDetails?.status ? "True" : "False"}</strong>
              </p>
              <div className="flex justify-center my-8">
                <Link to={`/admin/menu-items/edit/${menuDetails?.id}`}>
                  <button className="rounded py-2 px-5 text-white mr-2 bg-yellow-400">
                    Edit
                  </button>
                </Link>
                <button
                  onClick={() => handleDeleteClick(menuDetails?.id)}
                  className="rounded py-2 px-5 text-white  bg-red-600"
                >
                  Delete
                </button>
                <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
                  <div id="modal-background">
                    <div id="modal" className="w-80 md:w-full">
                      <h2 className="my-3">
                        Are you sure you want to delete this item?
                      </h2>
                      <div className=" text-sm flex gap-8">
                        <button
                          className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                          onClick={handleDelete}
                        >
                          Delete
                        </button>
                        <button
                          className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </ModalContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MenuItemsDetails;
