import React, { useEffect, useState } from "react";
import { BiCategory, BiFoodMenu } from "react-icons/bi";
import { IoFastFoodOutline, IoRestaurant } from "react-icons/io5";
import {
    MdArrowDropDown,
    MdArrowLeft,
    MdDashboard,
    MdMenuOpen,
} from "react-icons/md";
import { RiCloseFill, RiCoupon3Line } from "react-icons/ri";

import "./Sidebar.css";

import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({ closeBtnHandler, user }) => {
  const [isManageRestaurentListOpen, setIsManageRestaurentListOpen] =
    useState(false);
  const [isMenuCategoriesOpen, setIsMenuCategoriesOpen] = useState(false);
  const [isMenuItemsOpen, setIsMenuItemsOpen] = useState(false);
  // const [isRestaurentBranchOpen, setISRestaurentBranchOpen] = useState(false);
  const [isMenuVariationsOpen, setIsMenuVariationsOpen] = useState(false);
  const [isFoodIngredientsOpen, setIsFoodIngredientsOpen] = useState(false);
  const [isOrderOpen, setIsOrderOpen] = useState(false);
  const [isVoucherOpen, setIsVoucherOpen] = useState(false);
  const location = useLocation();
  // console.log(location);
  // console.log(isCloseBtnOpen);
  // let activeClassName = "activeLink";

  useEffect(() => {
    let manageRestaurantBtn = document.querySelector("#manageRestaurantBtn");
    let manageRestaurantBtn2 = document.querySelector("#manageRestaurantBtn2");
    manageRestaurantBtn.addEventListener("click", () => {
      manageRestaurantBtn2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageMenuCategories = document.querySelector("#manageMenuCategories");
    let manageMenuCategories2 = document.querySelector(
      "#manageMenuCategories2"
    );
    manageMenuCategories.addEventListener("click", () => {
      manageMenuCategories2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageMenuVariations = document.querySelector("#manageMenuVariations");
    let manageMenuVariations2 = document.querySelector(
      "#manageMenuVariations2"
    );
    manageMenuVariations.addEventListener("click", () => {
      manageMenuVariations2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageMenuItems = document.querySelector("#manageMenuItems");
    let manageMenuItems2 = document.querySelector("#manageMenuItems2");
    manageMenuItems.addEventListener("click", () => {
      manageMenuItems2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageFoodIngredients = document.querySelector(
      "#manageFoodIngredients"
    );
    let manageFoodIngredients2 = document.querySelector(
      "#manageFoodIngredients2"
    );
    manageFoodIngredients.addEventListener("click", () => {
      manageFoodIngredients2.classList.toggle("show");
    });
  }, []);

  useEffect(() => {
    let manageOrder = document.querySelector("#manageOrder");
    let manageOrder2 = document.querySelector("#manageOrder2");
    manageOrder.addEventListener("click", () => {
      manageOrder2.classList.toggle("show");
    });
  }, []);
  useEffect(() => {
    let manageVoucher = document.querySelector("#manageVoucher");
    let manageVoucher2 = document.querySelector("#manageVoucher2");
    manageVoucher.addEventListener("click", () => {
      manageVoucher2.classList.toggle("show");
    });
  }, []);

  return (
    <aside
      className="tracking-wide h-screen bg-gray-800 font-light overflow-auto"
      aria-label="Sidebar"
    >
      <div className=" py-3 text-gray-300 dark:bg-gray-200">
        <div className="flex justify-end text-xl lg:hidden ">
          <button>
            <RiCloseFill onClick={closeBtnHandler} className="mr-2  text-xl" />
          </button>
        </div>

        <ul className="space-y-2 ">
          <li className="mx-6">
            <NavLink
              to=""
              className="p-4  dark:text-white dark:hover:bg-gray-700"
            >
              <span className="text-xl tracking-wider font-light">
                {user?.user?.role === "VENDOR" ? (
                  <div>{user?.restaurant?.name} Restaurant</div>
                ) : (
                  "BRITISH MARKETPLACE"
                )}
              </span>
            </NavLink>
          </li>
          <hr className="mx-0" />
          <li>
            <NavLink
              to="/admin"
              className="flex items-center p-2 text-base font-normal  hover:text-[#0DC1D9] dark:text-white dark:hover:bg-gray-700"
            >
              <MdDashboard className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">Dashboard</span>
            </NavLink>
          </li>
          <hr className="mx-0" />

          {/* manage Restaurant list*/}
          <li>
            <button
              id="manageRestaurantBtn"
              type="button"
              onClick={() =>
                setIsManageRestaurentListOpen(!isManageRestaurentListOpen)
              }
              className="flex items-center py-1 px-3 text-base hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <IoRestaurant className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Restaurant
              </span>
              {isManageRestaurentListOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>

            {/* {isManageRestaurentListOpen ? ( */}
            <ul
              id="manageRestaurantBtn2"
              className={
                location.pathname === "/admin/add-restaurant-categories" ||
                location.pathname === "/vendor/add-restaurant-branches" ||
                location.pathname === "/vendor/restaurant-branches-list"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/vendor/add-restaurant-categories"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Add Restaurant Categories
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendor/add-restaurant-branches"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Add Restaurant Branches
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendor/restaurant-branches-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Restaurant Branches List
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* menu categories*/}
          <li>
            <button
              id="manageMenuCategories"
              type="button"
              onClick={() => {
                setIsMenuCategoriesOpen(!isMenuCategoriesOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <BiCategory className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Menu Categories
              </span>
              {isMenuCategoriesOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isMenuCategoriesOpen ? ( */}
            <ul
              id="manageMenuCategories2"
              className={
                location.pathname === "/vendor/menu-categories-list" ||
                location.pathname === "/vendor/add-menu-categories"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/vendor/menu-categories-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Menu Categories List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendor/add-menu-categories"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Menu Categories
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* menu items*/}
          <li>
            <button
              id="manageMenuItems"
              type="button"
              onClick={() => {
                setIsMenuItemsOpen(!isMenuItemsOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <IoFastFoodOutline className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Menu Items
              </span>
              {isMenuItemsOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isMenuItemsOpen ? ( */}
            <ul
              id="manageMenuItems2"
              className={
                location.pathname === "/vendor/menu-items-list" ||
                location.pathname === "/vendor/add-menu-items"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/vendor/menu-items-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Menu Items List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendor/add-menu-items"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Menu Items
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* menu variations */}
          <li>
            <button
              id="manageMenuVariations"
              type="button"
              onClick={() => {
                setIsMenuVariationsOpen(!isMenuVariationsOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <BiFoodMenu className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Menu Variations
              </span>
              {isMenuVariationsOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isMenuVariationsOpen ? ( */}
            <ul
              id="manageMenuVariations2"
              className={
                location.pathname === "/vendor/menu-variations-list" ||
                location.pathname === "/vendor/add-menu-variations"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/vendor/menu-variations-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Menu Variations List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendor/add-menu-variations"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Menu Variations
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* food ingredients */}
          <li>
            <button
              id="manageFoodIngredients"
              type="button"
              onClick={() => {
                setIsFoodIngredientsOpen(!isFoodIngredientsOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <BiFoodMenu className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Food Ingredients
              </span>
              {isFoodIngredientsOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            <ul
              id="manageFoodIngredients2"
              className={
                location.pathname === "/vendor/food-ingredients-list" ||
                location.pathname === "/vendor/add-food-ingredients"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/vendor/food-ingredients-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Food Ingredients List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendor/add-food-ingredients"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Food Ingredients
                </NavLink>
              </li>
            </ul>
          </li>
          {/* order */}
          <li>
            <button
              id="manageOrder"
              type="button"
              onClick={() => {
                setIsOrderOpen(!isOrderOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <MdMenuOpen className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Order
              </span>
              {isOrderOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isOrderOpen ? ( */}
            <ul
              id="manageOrder2"
              className={
                location.pathname === "/vendor/order-list" ||
                location.pathname === "/vendor/add-order"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/vendor/order-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Order List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendor/add-order"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Add Order
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
          {/* voucher */}
          <li>
            <button
              id="manageVoucher"
              type="button"
              onClick={() => {
                setIsVoucherOpen(!isVoucherOpen);
              }}
              className="flex items-center py-1 px-3   hover:text-gray-100 hover:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <RiCoupon3Line className="text-xl" />
              <span className="flex-1 ml-2 whitespace-nowrap ">
                Manage Voucher
              </span>
              {isVoucherOpen ? (
                <MdArrowLeft className="text-2xl mx-3" />
              ) : (
                <MdArrowDropDown className="text-2xl mx-3" />
              )}
            </button>
            {/* {isVoucherOpen ? ( */}
            <ul
              id="manageVoucher2"
              className={
                location.pathname === "/vendor/voucher-list" ||
                location.pathname === "/vendor/add-voucher"
                  ? "dropdown space-y-2 show ml-10"
                  : "dropdown ml-10 space-y-2 "
              }
            >
              <li>
                <NavLink
                  to="/vendor/voucher-list"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  {" "}
                  Voucher List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendor/add-voucher"
                  className="ml-2 "
                  style={(isActive) => ({
                    color: isActive ? "#77C9F2" : " rgb(209 ,213 ,219)",
                  })}
                >
                  Add Voucher
                </NavLink>
              </li>
            </ul>
            {/* ) : null} */}
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
