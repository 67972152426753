import React from "react";
import img1 from "../../../images/restaurantPartner/04.png";
import img2 from "../../../images/restaurantPartner/05.png";
import img3 from "../../../images/restaurantPartner/06.png";
import img4 from "../../../images/restaurantPartner/07.png";

const Workflow = () => {
  return (
    <section className=" md:mx-28 mx-5">
      <h1 className="text-center text-2xl mb-8">How it Works</h1>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 mb-10 text-[#4A4A4A] text-center">
        <div className="">
          <img src={img1} alt="" className="mx-auto mb-3 " />
          <p>
            Customer enters a delivery address via the app or website, and
            selects a nearby restaurant.
          </p>
        </div>
        <div>
          <img src={img2} alt="" className="mx-auto mb-3" />
          <p>
            Restaurant accepts the order via the tablet provided by BMP, and
            starts preparing the order straight away.
          </p>
        </div>
        <div>
          <img src={img3} alt="" className="mx-auto mb-3" />
          <p>
            Our rider arrives at the right time to collect and delivers the
            order within 30 minutes after the order was placed.
          </p>
        </div>
        <div>
          <img src={img4} alt="" className="mx-auto mb-3" />
          <p>
            BMP sends you the proceeds from your orders every month and provides
            deep insights on your performance.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Workflow;
