import React, { useEffect, useState } from "react";
import Layout from "../Layout/layout";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { Tooltip } from "@material-tailwind/react";
import { useSelector, useDispatch } from "react-redux";
import {
  adminRestaurantDelete,
  adminRestaurantList,
} from "../../../redux/actions/AdminActions/adminRestaurantActions";
import Pagination from "../../../components/common/Pagination";
import ModalContainer from "../../../components/common/ModalContainer";

function RestaurantList() {
  const { resList } = useSelector((state) => state.adminRestaurant);
  const [search, setSearch] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleleState, setDeleleState] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteClick = (delete_id) => {
    setIsOpen(true);
    setDeleleState(delete_id);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = () => {
    dispatch(adminRestaurantDelete(deleleState, history, "list"));
    closeModal();
    setDeleleState(null);
  };

  useEffect(() => {
    dispatch(adminRestaurantList());
  }, [dispatch]);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    dispatch(adminRestaurantList({ page: currentPage }));
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    dispatch(adminRestaurantList({ page: 1, search: e.target.value }));
  };

  return (
    <Layout>
      <div className="m-3">
        <p className="text-center">Restaurant List</p>
        <div className="flex items-center">
          {/* search bar */}
          <div className="relative md:w-1/3 my-2">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              value={search}
              onChange={handleSearch}
              type="text"
              id="simple-search"
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
              placeholder="Search here.."
            />
          </div>
          {/*  status select box*/}

          {/* react select */}
        </div>
        <div className="overflow-x-scroll">
          <table className="table-auto  w-full border-collapse custom-table-data my-2">
            <thead className="text-center">
              <tr className="bg-gray-800 text-gray-300 ">
                <th className="custom-table-data">Name</th>
                <th className="custom-table-data">Owner</th>
                <th className="custom-table-data">Status</th>
                <th className="custom-table-data"> Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {resList?.data?.map((res) => (
                <tr key={res.id}>
                  <td className="custom-table-data">{res.name}</td>
                  <td className="custom-table-data">{res.owner.identifier}</td>
                  <td className="custom-table-data">
                    {res.status ? "true" : "false"}
                  </td>
                  <td className="custom-table-data text-xl">
                    <Tooltip content="View">
                      {/* <Link to={`user-details/${user.id}`}> */}
                      <Link to={`/admin/restaurant-details/${res.id}`}>
                        <button>
                          {" "}
                          <AiOutlineEye className="mx-2 text-green-500" />
                        </button>
                      </Link>
                    </Tooltip>

                    <Tooltip content="Edit">
                      <Link to={`/admin/restaurant/edit/${res.id}`}>
                        <button>
                          {" "}
                          <AiOutlineEdit className="mx-2 text-yellow-500" />
                        </button>
                      </Link>
                    </Tooltip>

                    <Tooltip content="Delete">
                      <button onClick={() => handleDeleteClick(res.id)}>
                        <RiDeleteBin6Line className="mx-2 text-red-500" />
                      </button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          pageCount={
            resList?.meta?.last_page > 0 ? resList?.meta?.last_page : 0
          }
          handlePageClick={handlePageClick}
        />
      </div>

      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Delete user?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default RestaurantList;
