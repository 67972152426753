import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { adminMenuItemsNameOnlyList } from "../../../../redux/actions/AdminActions/adminMenuAction";
import { useDispatch } from "react-redux";

const customStyles = {
  input: (provided, state) => ({
    ...provided,
    height: 32,
  }),
};

function MenuVariationsCreateUpdateForm({
  edit,
  resBranchNameOnlyList,
  menuItemNameList,
  handleSubmitData,
  menuVariationsDetails,
}) {
  const [resBranchOption, setResBranchOption] = useState([]);
  const [foodItemsOption, setFoodItemsOption] = useState([]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  console.log(watch("restaurantBranch"));

  const dispatch = useDispatch();

  useEffect(() => {
    if (menuVariationsDetails) {
      setValue("name", menuVariationsDetails.name);
      setValue("itemsNumber", menuVariationsDetails.choose_item_number);
      setValue("is_free", menuVariationsDetails.is_free);
      setValue("is_mandatory", menuVariationsDetails.is_mandatory);
      if (
        menuVariationsDetails.restaurant_branch &&
        resBranchOption.length > 0
      ) {
        let a = resBranchOption.find(
          (item) =>
            parseInt(item.value) ===
            parseInt(menuVariationsDetails.restaurant_branch.id)
        );
        setValue("restaurantBranch", { value: a.value, label: a.label });
      }
      // if (resBranchOption?.length > 0) {
      // let a = foodItemsOption?.filter(
      //   (item) =>
      //     parseInt(item.value) === parseInt(menuVariationsDetails.food_items.id)
      // );
      // console.log(a);
      // if (a !== undefined && a.length > 0)
      //   setValue("foodItems", { value: a[0].value, label: a[0].label });
      // }
    }
    // eslint-disable-next-line
  }, [menuVariationsDetails, setValue, resBranchOption]);

  useEffect(() => {
    if (menuVariationsDetails?.food_items) {
      let a = foodItemsOption?.filter(
        (item) =>
          parseInt(item.value) === parseInt(menuVariationsDetails.food_items.id)
      );
      console.log(a);
      if (a !== undefined && a.length > 0)
        setValue("foodItems", { value: a[0].value, label: a[0].label });
    }
    // eslint-disable-next-line
  }, [foodItemsOption]);

  useEffect(() => {
    if (watch("restaurantBranch")) {
      dispatch(adminMenuItemsNameOnlyList(watch("restaurantBranch")));
    }
    console.log("cccccccccccccccc");
    // eslint-disable-next-line
  }, [dispatch, watch("restaurantBranch")]);

  useEffect(() => {
    let option = [];
    if (resBranchNameOnlyList?.length > 0) {
      resBranchNameOnlyList.map((item) =>
        // setValue("owner", { value: item.id, label: item.identifier })
        option.push({ value: item.id, label: item.name })
      );
      setResBranchOption(option);
    }
    // eslint-disable-next-line
  }, [resBranchNameOnlyList]);

  useEffect(() => {
    let option = [];
    console.log(menuItemNameList);
    if (menuItemNameList?.length > 0) {
      menuItemNameList.map((item) =>
        // setValue("owner", { value: item.id, label: item.identifier })
        option.push({ value: item.id, label: item.name })
      );
      setFoodItemsOption(option);
    } else {
      setFoodItemsOption(null);
    }
    // }
    setValue("foodItems", null);
    // eslint-disable-next-line
  }, [menuItemNameList]);
  return (
    <div className="m-3">
      {" "}
      <h1 className="py-2 text-center">
        {edit ? "Edit" : "Add"} Menu Variation{" "}
      </h1>
      <div>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <div className="md:grid md:grid-cols-3 md:gap-24 pt-2">
            <div className="">
              <label htmlFor="restaurantBranch" className=" text-gray-700">
                Restaturant Branch
              </label>
              <Controller
                name="restaurantBranch"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={resBranchOption}
                    styles={customStyles}
                    className="mt-1"
                    isSearchable={true}
                    isClearable
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.restaurantBranch &&
              errors.restaurantBranch.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
            <div>
              <label htmlFor="" className=" text-gray-700">
                Food items
              </label>
              <Controller
                name="foodItems"
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="mt-1"
                    options={
                      foodItemsOption?.length > 0
                        ? foodItemsOption
                        : [{ value: "", label: "select one.." }]
                    }
                    isDisabled={foodItemsOption?.length > 0 ? false : true}
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
              {errors.foodItems && errors.foodItems.type === "required" ? (
                <div>
                  <span className="text-sm text-red-600">
                    This field is required
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-24 pt-2">
            <div className="">
              <label htmlFor="name" className=" text-gray-700">
                Name
              </label>

              <input
                type="text"
                {...register("name", {
                  required: "This field is required",
                  message: "This field is required",
                })}
                name="name"
                id="name"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{errors.name?.message}</p>
            </div>{" "}
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Choose item number
              </label>
              <input
                {...register("itemsNumber")}
                type="number"
                className="mt-1 w-full block shadow-sm  border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">
                {errors.itemsNumber?.message}
              </p>
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-2 pt-2">
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="flexCheckChecked"
                {...register("is_free")}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="flexCheckChecked"
              >
                Free
              </label>
            </div>
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer md:ml-7"
                type="checkbox"
                id="flexCheckChecked"
                {...register("is_mandatory")}
              />
              <label
                className="form-check-label inline-block text-gray-800 "
                htmlFor="flexCheckChecked"
              >
                Mandatory
              </label>
            </div>
            <div className="form-check flex items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="flexCheckChecked"
                {...register("status")}
                defaultChecked
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="flexCheckChecked"
              >
                Status
              </label>
            </div>
            <div className=" mt-2">
              <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MenuVariationsCreateUpdateForm;
