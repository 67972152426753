import React from "react";
import { Link } from "react-router-dom";
import thumbnail1 from "../../../images/restaurantPartner/02.png";
import thumbnail2 from "../../../images/restaurantPartner/03.png";
import banner1 from "../../../images/restaurantPartner/banner1.png";

const Banner = () => {
  return (
    <div className="mb-10">
      <div className="relative">
        <img
          src={banner1}
          alt=""
          className="w-full brightness-[0.7] md:h-max h-44"
        />
        <div className="absolute top-12 xl:left-96 lg:left-48 md:left-24 left-4">
          <h1 className="text-white xl:text-4xl lg:text-3xl text-2xl text-center">
            Become a Cuisine partner
          </h1>
          <p className="text-[#3FEA48] lg:text-base md:text-sm text-xs text-center">
            We’re hungry for the best things in life: bringing the best food and
            redefining the shopping experience to our customers.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-10 md:mx-28 mx-10">
          <div className="relative">
            <img
              style={{ boxShadow: "19px -16px ", color: "#0DC1D9" }}
              src={thumbnail1}
              alt=""
              // xl:-bottom-40 xl:left-36
              className=" rounded-md brightness-90 mx-auto lg:-mt-16 "
            />
            <div className="text-white absolute md:top-12 top-16 left-3 lg:top-6 lg:left-5 xl:top-20 xl:left-7">
              <h1 className="md:text-xl lg:text-3xl xl:text-4xl ">
                Become a Restaurant partner
              </h1>
              <Link to="/vendor/registration">
                {" "}
                <button className="md:text-sm text-xs  bg-[#0DC1D9] rounded-md xl:px-10 xl:py-3 lg:px-8 px-5 py-2">
                  {/* text-white absolute lg:top-16 lg:left-5 xl:top-32 xl:left-7 */}
                  CREATE ACCOUNT
                </button>
              </Link>
            </div>
          </div>
          <div className="relative">
            <img
              style={{ boxShadow: "19px -16px ", color: "#0DC1D9" }}
              src={thumbnail2}
              alt=""
              className=" rounded-md brightness-90 mx-auto lg:-mt-16"
            />
            <div className="text-white absolute md:top-12 lg:top-6 xl:top-20 top-16 left-3 md:left-3 lg:left-5  xl:left-7">
              <h1 className="md:text-xl lg:text-3xl xl:text-4xl text-base">
                Become a Home made partner
              </h1>
              <button className="md:text-sm text-xs bg-[#0DC1D9] rounded-md xl:px-10 xl:py-3 lg:px-8 px-5 py-2">
                CREATE ACCOUNT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
