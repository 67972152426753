import React from 'react'
import About from '../components/FAQ/About'
import FAQBanner from '../components/FAQ/FAQBanner'
import FAQs from '../components/FAQ/FAQs'
import FaqFees from '../components/FAQ/FaqFees'
import FaqPayment from '../components/FAQ/FaqPayment'
import Layout from './Layout'

const FAQ = () => {
  return (
    <Layout home>
      
<FAQBanner/>
<FAQs/>
<About/>
<FaqPayment/>
<FaqFees/>
    </Layout>
  )
}

export default FAQ