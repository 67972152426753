import React, { useEffect, useState } from "react";
import RestaurantCategoriesCreateUpdateForm from "../../../components/admin/Forms/Restaurant/RestaurantCategoriesCreateUpdateForm";
import CategoryList from "../../../components/admin/RestaurantCategory/CategoryList";
import Layout from "../Layout/layout";
import { useDispatch, useSelector } from "react-redux";
import {
  adminRestaurantCategoryDelete,
  adminRestaurantCategoryList,
} from "../../../redux/actions/AdminActions/adminRestaurantActions";
import ModalContainer from "../../../components/common/ModalContainer";

function VendorRestaurantCategories() {
  const { resCategoryList } = useSelector((state) => state.adminRestaurant);
  console.log(resCategoryList);
  const [search, setSearch] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleleState, setDeleleState] = useState(null);

  const dispatch = useDispatch();

  const handleDeleteClick = (delete_id) => {
    setIsOpen(true);
    setDeleleState(delete_id);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = () => {
    // dispatch(adminRestaurantDelete(deleleState, history, "list"));
    dispatch(adminRestaurantCategoryDelete(deleleState));
    closeModal();
    setDeleleState(null);
  };

  useEffect(() => {
    dispatch(adminRestaurantCategoryList());
  }, [dispatch]);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    dispatch(adminRestaurantCategoryList({ page: currentPage }));
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    dispatch(adminRestaurantCategoryList({ page: 1, search: e.target.value }));
  };
  return (
    <Layout>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <RestaurantCategoriesCreateUpdateForm />
        </div>
        <CategoryList
          resCategories={resCategoryList}
          handlePageClick={handlePageClick}
          search={search}
          handleSearch={handleSearch}
          handleDeleteClick={handleDeleteClick}
          vendor
        />
      </div>
      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Delete restaurant category?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default VendorRestaurantCategories;
