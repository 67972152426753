import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PaymentForm = ({ brand }) => {
  const [amount, setAmount] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [ssKey, setSsKEY] = useState("");

  const { user } = useSelector((state) => state.auth);
  const { order_summery, shaKey } = useSelector((state) => state.order);

  useEffect(() => {
    if (order_summery?.total_price > 0) {
      // setAmount(order_summery?.total_price * 100);
      setAmount(Math.round(order_summery?.total_price * 100));
      setOrderNumber(order_summery?.order_number);
    }
    if (user) {
      setUserName(user.user.first_name);
      setUserEmail(user.user.email);
    }
    if (shaKey) {
      setSsKEY(shaKey);
    }
  }, [order_summery, user, shaKey]);

  return (
    <div className=" py-3 pt-6 sm:px-6">
      <form
        method="post"
        action="https://payments.epdq.co.uk/ncol/prod/orderstandard.asp"
        // action="https://mdepayments.epdq.co.uk/ncol/test/orderstandard.asp"
        id="form1"
        name="form1"
      >
        <input
          type="hidden"
          NAME="ACCEPTURL"
          VALUE={`${process.env.REACT_APP_FRONT_END_URL}/payment-success`}
        ></input>
        <input
          type="hidden"
          name="AMOUNT"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <input
          type="hidden"
          NAME="BACKURL"
          VALUE={`${process.env.REACT_APP_FRONT_END_URL}/payment`}
        ></input>
        <input type="hidden" name="BRAND" value={brand} />
        <input
          type="hidden"
          name="CN"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <input type="hidden" name="CURRENCY" value="GBP" />
        <input
          type="hidden"
          name="EMAIL"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
        <input type="hidden" name="LANGUAGE" value="en-US" />
        <input
          type="hidden"
          name="ORDERID"
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
        />
        <input type="hidden" name="PM" id="pm" value="CreditCard" />
        <input type="hidden" name="PSPID" value="epdq1603016" />
        <input
          type="hidden"
          name="SHASIGN"
          id="SHASIGN"
          value={ssKey}
          onChange={(e) => setSsKEY(e.target.value)}
        />

        <input
          value="Submit"
          id="submit2"
          name="submit2"
          type="submit"
          className="inline-flex justify-center py-2 px-8 shadow-sm text-sm font-medium rounded-md  bg-gradient-to-r custom-btn2"
        />
      </form>
    </div>
  );
};

export default PaymentForm;
