import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import loadingImg from "../../images/app/loading.gif";
import {
    addCart,
    addServiceFee,
    homeMadeFoodIngredients
} from "../../redux/actions/cartActions";
import {
    fetchFoodVariation,
    foodModalCloseAction,
    foodModalOpenAction,
    individualItemDecrease,
    individualItemIncrease
} from "../../redux/actions/restaurantAction";

Modal.setAppElement("#root");

const FoodItems = ({
  foodItems,
  res_id,
  cart,
  serviceFee,
  Checked,
  setChecked,
  handleToggle,
  homeVariationPriceResult,
  standerdHomeMade,
  changedHomeMade,
  is_home_made,
  homeMadeVariation,
  count,
  setCount,
}) => {
  const { foodVariation, foodModal } = useSelector((state) => state.restaurant);
  const { homeMadeIngredients, homeMadeCart } = useSelector(
    (state) => state.cart
  );

  let bbb = [];
  console.log({foodVariation});
  let aaaaaaa = foodVariation?.variations
    ?.filter((item) => item.is_home_made === true)
    .map((ingre) => {
      // console.log(ingre);
      return ingre.variation_ingredients.map((c) => {
        return bbb.push(c);
      });
    });
  // let aaaaaaa = foodVariation?.variations
  //   ?.filter((item) => item.is_home_made === true)
  //   .map((ingre) =>
  //     ingre.variation_ingredients.map((c) => {
  //       return homeMadeCart.filter((item) => item.item_id === c.id);
  //     })
  //   );
  // console.log({ bbb });
  // console.log({ aaaaaaa });

  // console.log(homeMadeIngredients);
  // console.log(homeMadeCart);
  // const { foodVariation, loading, veri } = useSelector(
  //   (state) => state.restaurant
  // );
  const dispatch = useDispatch();

  const [ingredientsTest, setIngredientsTest] = useState([]);
  // console.log(ingredientsTest);

  useEffect(() => {
    let a = [];
    foodVariation?.variations
      ?.filter((item) => item.is_home_made === true)
      .map((ingre) =>
        ingre.variation_ingredients.map((c) => {
          a.push(c);
          return c;
        })
      );
    setIngredientsTest(a);
    if (a.length > 0 && res_id)
      dispatch(homeMadeFoodIngredients({ a, res_id }));

    // if (cart.length > 0 && cart[0].res_id !== res_id) {

    // }
    // if (a.length > 0) dispatch(addHomeMadeProductCart(a));
    // console.log({ a });
    // let b = a.map((i) => i);
    // console.log({ b });
  }, [foodVariation]);

  // const [variation, setVariation] = useState([]);
  // const [Checked, setChecked] = useState([]);

  // const [open, setOpen] = useState(false);

  const catData = [];

  // const handleToggle = (value) => {
  //   let newChecked = [...Checked];
  //   const currentIndex = Checked.filter((item) => item.id === value.id);
  //   // const foodVariationIndex = Checked.filter(
  //   //   (item) => item.food_variation_id === value.food_variation_id
  //   //   // (item) => item.value.food_variation_id === value.food_variation_id
  //   // );
  //   // console.log({ foodVariationIndex });
  //   // console.log(foodVariationIndex.length);
  //   // console.log(currentIndex.length);
  //   // console.log(currentIndex[0]?.id);

  //   // if (currentIndex.length === 0 && foodVariationIndex.length === 0) {
  //   if (currentIndex.length === 0) {
  //     // console.log(foodVariationIndex[0]?.id);
  //     // let count = 1;
  //     // if (foodVariationIndex.length > 0) {
  //     //   count = foodVariationIndex.length + 1;
  //     // }
  //     // console.log("enter");
  //     // newChecked.push({ ...value, count });
  //     newChecked.push(value);
  //   }
  //   // else if (currentIndex.length === 0) {

  //   // }
  //   else {
  //     // console.log(currentIndex[0]?.value.id);
  //     // console.log(newChecked);
  //     let a = newChecked.filter((item) => item.id !== currentIndex[0]?.id);
  //     // console.log(a);
  //     newChecked = a;
  //     // newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };

  // console.log(Checked);

  //   foodItems?.map((item) => catData.push(item));
  //   console.log(catData);
  foodItems?.map((item) => {
    item.categories?.map((cat) => catData.push(cat.name));

    return item;
  });
  let unique = [...new Set(catData)];
  function closeModal() {
    // setOpen(false);
    // dispatch(setEmptyVariation());
    setChecked([]);
    dispatch(foodModalCloseAction());
    setCount(0);
  }

  // const foodIngredientsAdd = (data) => {
  //   let { id, name, quantity, price } = data;
  //   const variationItem = [...variation].find((item) => item.id === id);
  //   console.log(variationItem);
  //   if (variationItem) {
  //     const incCart = [...variation].map((item) => {
  //       return item.id === id
  //         ? { ...item, quantity: item.quantity + 1 }
  //         : { ...item };
  //     });
  //     setVariation(incCart);
  //   } else {
  //     let variationArr = variation;
  //     const newItem2 = {
  //       id,
  //       name,
  //       price: parseFloat(price),
  //       quantity,
  //     };
  //     variationArr.push(newItem2);
  //     setVariation(variationArr);
  //     console.log("new variation", variation);
  //   }
  // };

  // const amount = foodVariation?.food_variation?.map((item) => {
  //   return item?.variation_ingredients?.map((foodItem) => {
  //     let a = variation?.filter((f) => f.id === foodItem.id);
  //     if (a.length > 0) {
  //       a.map((b) => console.log(b.quantity));
  //     }
  //   });
  // });

  // console.log({ amount });

  let newTotal = Checked.reduce((total, item) => {
    return (total += parseFloat(item.price));
  }, 0);

  let newTotalWithPrice = cart.reduce((total, cartItem) => {
    return (total +=
      parseFloat(cartItem.quantity) * parseFloat(cartItem.total_price));
  }, 0);

  useEffect(() => {
    if (newTotalWithPrice > 0) {
      dispatch(addServiceFee(newTotalWithPrice.toFixed(2)));
    }
  }, [newTotalWithPrice, dispatch]);

  // const total_all_price = (
  //   (parseFloat(foodVariation?.total_price) + newTotal) *
  //   foodVariation?.quantity
  // ).toFixed(2);
  // let total_all_price = 0;
  // if (foodVariation.total_price) {
  //   total_all_price = (
  //     (parseFloat(foodVariation?.total_price) + newTotal) *
  //     foodVariation?.quantity
  //   ).toFixed(2);
  // }
  // console.log(homeVariationPriceResult);

  const handleAddCart = (foodVariation) => {
    // console.log({foodVariation});
   
     if (cart.length !== 0 && cart[0].res_id !== res_id) {
      alert("You Don't order multiple restaurant at a time ")
      return
    }
    setCount(0);
    // console.log(homeVariationPriceResult);
    if (is_home_made) {
      dispatch(
        addCart({
          ...foodVariation,
          extra: Checked,
          res_id,
          home_made: homeMadeVariation,
        })
      );
    } else {
      dispatch(
        addCart({
          ...foodVariation,
          extra: Checked,
          res_id,
          // homeVariationPriceResult,
          // standerdHomeMade,
          // changedHomeMade,
        })
      );
    }

    let ingra = [];
    for (let i = 0; i < Checked.length; i++) {
      ingra.push(parseInt(Checked[i].id));
    }
    // axios
    //   .post("http://127.0.0.1:8000/api/order/add-to-cart/", {
    //     itemId: parseInt(foodVariation.id),
    //     variations: ingra,
    //   })
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err));

    // setOpen(false);
    dispatch(foodModalCloseAction());
    setChecked([]);
  };
  // console.log(total_all_price);
  console.log(foodVariation);
 
  
  


   
 
 
  return (
    <>
      {unique.map((item) => (
        <div className="res-item py-8" key={item} id={item}>
          <div className="item-header pb-8">
            <h4 className="text-black font-medium text-lg pb-1">{item}</h4>
            <span className="text-gray-600 font-normal text-sm block">
              Most ordered right now.
            </span>
          </div>
          <div className="grid md:grid-cols-2 gap-4">
            {foodItems.map((f) => {
              return f.categories
                .filter((c) => c.name === item)
                .map((a, index) => {
                  return (
                    <div
                      key={index}
                      className="res_item_inner px-4 py-8 bg-white rounded-md shadow-themeShadow flex justify-between items-start cursor-pointer"
                      onClick={() => {
                        dispatch(fetchFoodVariation(f.id));
                        // setOpen(true);
                        dispatch(foodModalOpenAction());
                        // console.log(f.id);
                      }}
                    >
                      <div className="item-text">
                        <h4 className="text-black font-medium text-lg">
                          {f?.name}
                        </h4>
                        <span className="text-gray-600 font-normal text-sm block">
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit.
                        </span>
                        <div className="flex gap-3">
                          <strong className="text-[#E64A19] pt-2">
                            From £{f.total_price}
                          </strong>
                          <del className="text-[#E64A19] pt-2">£ {f.price}</del>
                        </div>
                      </div>
                      <div className="item-thumb relative">
                        <img
                          className="rounded-md"
                          src={f.image}
                          alt={f?.name}
                          height="78"
                          width="105"
                        />
                        <span className="absolute bottom-0 right-0 bg-[#0DC1D9] h-5 w-5 rounded-md flex justify-center items-center">
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.0144 5.61126C10.9628 5.76787 10.8975 5.91966 10.8194 6.06487C10.7223 6.20169 10.5915 6.31116 10.4397 6.38268C10.288 6.45419 10.1203 6.48534 9.95301 6.47311H6.77776C6.54188 6.47311 6.48744 6.52755 6.48744 6.76343V9.93869C6.49419 10.0725 6.47439 10.2063 6.42924 10.3324C6.38409 10.4585 6.31444 10.5744 6.22431 10.6735C6.09815 10.8112 5.93516 10.9097 5.75464 10.9574C5.57413 11.0051 5.38372 11 5.20602 10.9427C5.02832 10.8854 4.87079 10.7782 4.75219 10.634C4.63358 10.4898 4.55885 10.3146 4.5369 10.1292C4.53025 10.0189 4.53025 9.90833 4.5369 9.79806V6.75435C4.5369 6.53662 4.47793 6.47765 4.25567 6.47765H1.08041C0.946626 6.4844 0.812827 6.46462 0.686713 6.41947C0.5606 6.37432 0.444664 6.30468 0.345566 6.21455C0.201164 6.08582 0.0991915 5.91635 0.0530347 5.72848C0.00687799 5.54062 0.0187281 5.34319 0.0870332 5.16219C0.148173 4.99148 0.255678 4.84119 0.397505 4.72821C0.539331 4.61523 0.709872 4.54403 0.889929 4.5226C0.998795 4.5226 1.11219 4.5226 1.22105 4.5226H4.26021C4.48248 4.5226 4.54144 4.46363 4.54144 4.24589V1.07064C4.52915 0.821418 4.60818 0.576277 4.76371 0.381158C4.8374 0.283672 4.93007 0.202085 5.03608 0.141286C5.14209 0.0804861 5.25925 0.0417281 5.38061 0.0273438H5.41693H5.63465H5.67092C5.79228 0.0417281 5.90949 0.0804861 6.0155 0.141286C6.12151 0.202085 6.21413 0.283672 6.28782 0.381158C6.44335 0.576277 6.52238 0.821418 6.51009 1.07064V4.24589C6.51009 4.48177 6.56453 4.53621 6.8004 4.53621H9.97566C10.143 4.52399 10.3107 4.55513 10.4624 4.62665C10.6142 4.69816 10.745 4.80763 10.8421 4.94446C10.9202 5.08967 10.9855 5.24145 11.0371 5.39806L11.0144 5.61126Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  );
                });
            })}
          </div>
        </div>
      ))}

      <Modal
        isOpen={foodModal}
        // isOpen={open}
        onRequestClose={closeModal}
        // onRequestClose={dispatch(foodModalCloseAction())}
        // style={customStyles}
        overlayClassName="modal-overlay"
        contentLabel="Example Modal"
        className={"modal"}
      >
        <div className="w-full">
          <div className="modal-header">
            {foodVariation?.name ? (
              <h2>{foodVariation?.name}</h2>
            ) : (
              <Skeleton count={1} />
            )}

            <span onClick={closeModal}>
              <div
                id="card_close"
                className="modal-close cursor-pointer z-50 h-8 w-8 leading-8 border text-center border-gray-700 rounded-full"
              >
                <svg
                  className="fill-current text-gray-500 mx-auto mt-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
              </div>
            </span>
          </div>
          <div className="modal-body">
            <div className="res-content py-4 text-left px-6">
              <div className="md:mx-5">
                <div className="d-thumb h-16 lg:h-36">
                  <img
                    className="object-scale-down w-full h-full"
                    src={
                      foodVariation?.image ? foodVariation.image : loadingImg
                    }
                    alt=""
                  />
                </div>
                <div className="d-text mt-2 lg:mt-4">
                  <h3 className="text-primaryColor text-lg lg:text-2xl font-semibold text-center">
                    £{foodVariation.total_price}
                  </h3>
                </div>
                <div className="item-count flex items-center justify-center py-2 lg:py-4">
                  <button
                    type="button"
                    className="h-6 w-8 text-center leading-5 rounded-sm shadow-md"
                    onClick={() =>
                      dispatch(
                        individualItemDecrease(
                          foodVariation.id,
                          foodVariation.quantity
                        )
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 mx-auto text-primaryColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20 12H4"
                      />
                    </svg>
                  </button>
                  <span className="inline-block h-5 w-5 leading-5 text-center">
                    {foodVariation.quantity}
                  </span>
                  <button
                    type="button"
                    className="h-6 w-8 text-center leading-5 rounded-sm shadow-md"
                    onClick={() =>
                      dispatch(individualItemIncrease(foodVariation.id))
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 mx-auto text-primaryColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </button>
                </div>
                <h4 className="text-gray-800 font-semibold md:text-lg lg:text-2xl text-center pb-2">
                  {foodVariation?.name}
                </h4>
                <p className="text-xs text-gray-500 text-center">
                  {foodVariation?.cooking_method ? (
                    <p className="text-sm py-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: foodVariation?.cooking_method?.replace(
                            /\r\n|\r|\n/g,
                            "<br />"
                          ),
                        }}
                      />
                      {/* {foodVariation.cooking_method} */}
                    </p>
                  ) : (
                    <>
                      <span className="block" />
                      <Skeleton count={2} />
                      <span className="block" />
                    </>
                  )}
                </p>
              </div>

              {foodVariation?.variations
                ?.filter((item) => item.is_home_made === true)
                .map((foodVariationItem) => (
                  <div
                    key={foodVariationItem.id}
                    className="ex-details pt-2 md:pt-5"
                  >
                    <h6 className="text-sm md:text-base font-semibold text-black pb-2">
                      {foodVariationItem.name}
                      {foodVariationItem?.is_mandatory && "(required)"}
                    </h6>
                    <ul>
                      {foodVariationItem?.variation_ingredients?.map(
                        (ingredientItem) => {
                          return (
                            <li
                              key={ingredientItem.id}
                              className="flex items-center justify-between mb-1 md:mb-3"
                            >
                              <div className="flex items-center justify-start">
                                <label
                                  htmlFor="item1"
                                  className="inline-block ml-2 text-xs sm:text-sm md:text-base"
                                >
                                  {ingredientItem.name}
                                </label>
                              </div>
                              <div className="price">
                                <span className="text-primaryColor text-xs sm:text-sm font-medium">
                                  {ingredientItem?.weight}
                                </span>
                              </div>
                              <div className="price">
                                <span className="text-primaryColor text-xs sm:text-sm font-medium">
                                  {ingredientItem?.price > 0 && (
                                    <>+£{ingredientItem.price}</>
                                  )}
                                </span>
                              </div>
                              {homeMadeCart.filter((item) => {
                                // console.log(item.item_id, ingredientItem.id);
                                return item.item_id === ingredientItem.id;
                              }).length > 0 ? (
                                <Link
                                  to={{
                                    pathname: "/food/ingredients/",
                                    search: `?search=${ingredientItem.name}`,
                                    state: {
                                      item_id: ingredientItem.id,
                                      item_name: ingredientItem.name,
                                      item_price: ingredientItem.price,
                                      food_item: foodVariation,
                                      res_id: res_id,
                                      extra: Checked,
                                    },
                                  }}
                                  // to={`/food/ingredients/?${ingredientItem.name}`}
                                  // component={ingredientItem?.id}
                                >
                                  Already Changed
                                </Link>
                              ) : (
                                <Link
                                  to={{
                                    pathname: "/food/ingredients/",
                                    search: `?search=${ingredientItem.name}`,
                                    state: {
                                      item_id: ingredientItem.id,
                                      item_name: ingredientItem.name,
                                      item_price: ingredientItem.price,
                                      food_item: foodVariation,
                                      res_id: res_id,
                                    },
                                  }}
                                  // to={`/food/ingredients/?${ingredientItem.name}`}
                                  // component={ingredientItem?.id}
                                >
                                  Change
                                </Link>
                              )}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                ))}

              {foodVariation?.variations
                ?.filter((item) => item.is_home_made === false)
                .map((foodVariationItem) => {
                  return (
                    <div
                      key={foodVariationItem.id}
                      className="ex-details pt-2 md:pt-5"
                    >
                      <h6 className="text-sm md:text-base font-semibold text-black pb-2">
                        {foodVariationItem.name}
                        {foodVariationItem?.is_mandatory && "(required)"}
                      </h6>
                      <ul>
                        {foodVariationItem?.variation_ingredients?.map(
                          (ingredientItem) => {
                            return (
                              console.log({ingredientItem}),
                              <li
                                key={ingredientItem.id}
                                className="flex items-center justify-between mb-1 md:mb-3"
                              >
                                <div className="flex items-center justify-start">
                                  <input
                                    type="checkbox"
                                    className="w-4 h-4"
                                    id={ingredientItem.id}
                                    disabled={
                                      Checked.map(function (e) {
                                        return e.id;
                                      }).indexOf(ingredientItem.id) === -1 &&
                                      Checked.filter((item) => {
                                        return (
                                          item.food_variation_id ===
                                          foodVariationItem.id
                                        );
                                      }).length ===
                                        foodVariationItem.choose_item_number
                                    }
                                    onChange={() =>
                                      handleToggle({
                                        id: ingredientItem.id,
                                        price: ingredientItem.price,
                                        food_variation_id: foodVariationItem.id,
                                        name: ingredientItem.name,
                                        required:
                                          foodVariationItem?.is_mandatory,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="item1"
                                    className="inline-block ml-2 text-xs sm:text-sm md:text-base"
                                  >
                                    {ingredientItem.name}
                                  </label>
                                </div>
                                <div className="price">
                                  <span className="text-primaryColor text-xs sm:text-sm font-medium">
                                    {ingredientItem?.price > 0 && (
                                      <>+£{ingredientItem.price}</>
                                    )}
                                  </span>
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  );
                })}
            </div>
          </div>
          <Toaster />
          {foodVariation.id ? (
            <div className="flex justify-center px-5 pt-2 space-x-14">
              <button
                onClick={() =>
                  foodVariation?.total_required_field.sum ?
                 ( foodVariation?.total_required_field.sum !== count ?
                
                  toast.error("Please select any variation", { duration: 5000 })
                 :
                  handleAddCart(foodVariation)
                  
                  )
:
                  handleAddCart(foodVariation)}
                // disabled={
                //   foodVariation?.total_required_field.sum
                //     ? foodVariation?.total_required_field.sum !== count
                //     : null
                // }
                type="submit"
                className="text-white text-base bg-[#0DC1D9] w-full px-4 py-3 rounded-md uppercase"
              >
                Add To Cart aaaaaa
                {(
                  (parseFloat(foodVariation.total_price) + newTotal) *
                  foodVariation.quantity
                ).toFixed(2)}
              </button>
            </div>
          ) : (
            <Skeleton count={1} height={40} />
          )}
          <div className="modal-footer"></div>
        </div>
      </Modal>
    </>
  );
};

export default FoodItems;
