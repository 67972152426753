import React from "react";
import { useState, Fragment } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
const FAQ = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  return (
    <div className=" ">
      <h1 className="text-xl pb-10 text-center">Frequently asked questions</h1>
      <div className="lg:mx-60 md:mx-36 mx-8 ">
        <Fragment className=" ">
          <Accordion
            className="shadow-themeShadow rounded-md my-5 px-4"
            open={open === 1}
            animate={customAnimation}
          >
            <AccordionHeader
              className="text-base font-normal"
              onClick={() => handleOpen(1)}
            >
              What are the requirements for being a rider?
            </AccordionHeader>
            <AccordionBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Doloribus voluptatum repudiandae est delectus a, id animi nesciunt
              voluptatem alias iste!
            </AccordionBody>
          </Accordion>
          <Accordion
            className="shadow-themeShadow rounded-md my-5 px-4"
            open={open === 2}
            animate={customAnimation}
          >
            <AccordionHeader
              className="text-base font-normal"
              onClick={() => handleOpen(2)}
            >
              What documents do I need?
            </AccordionHeader>
            <AccordionBody>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Exercitationem minima libero nisi dolor veniam provident adipisci
              dicta laboriosam veritatis commodi harum eaque dolores velit nemo
              ut architecto, minus enim numquam?
            </AccordionBody>
          </Accordion>
          <Accordion
            className="shadow-themeShadow rounded-md my-5 px-4"
            open={open === 3}
            animate={customAnimation}
          >
            <AccordionHeader
              className="text-base font-normal"
              onClick={() => handleOpen(3)}
            >
              What insurance do I need?
            </AccordionHeader>
            <AccordionBody>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui
              magni hic quas facilis vel quae, odit sed nam quia esse itaque
              dolores nisi odio nemo incidunt? Molestiae, inventore in placeat,
              iure excepturi aperiam quos nulla, autem doloribus laudantium
              saepe voluptatum.
            </AccordionBody>
          </Accordion>
          <Accordion
            className="shadow-themeShadow rounded-md my-5 px-4"
            open={open === 4}
            animate={customAnimation}
          >
            <AccordionHeader
              className="text-base font-normal"
              onClick={() => handleOpen(4)}
            >
              What does Deliveroo’s free rider insurance cover?
            </AccordionHeader>
            <AccordionBody>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Perferendis velit tempora ullam culpa corporis! Similique quasi
              rem repellendus corporis libero. Pariatur, qui adipisci provident,
              vitae eos aliquam eveniet reiciendis rem incidunt, impedit ipsam
              nobis enim ut voluptatem dolorum obcaecati quis. Nesciunt possimus
              saepe ipsam, illo odit totam temporibus vitae minus.
            </AccordionBody>
          </Accordion>
        </Fragment>
      </div>
    </div>
  );
};

export default FAQ;
