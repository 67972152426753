import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrivacyPolicy } from "../redux/actions/commonActions";
import Layout from "./Layout";

const PrivacyPolicy = () => {
  const { privacyPolicy } = useSelector((state) => state.common);
  console.log(privacyPolicy);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPrivacyPolicy());
  }, [dispatch]);
  return (
    <Layout>
      <section className="page-header py-36 bg-privacy-camp bg-center bg-cover rounded-l-[50%] rounded-t-none rounded-b-[50%]">
        <div className="container">
          <h3 className="text-center text-[#FAC819] text-6xl font-bold stroke-2 stroke-white">
            Privacy Policy
          </h3>
        </div>
      </section>

      <section className="privacy-policy py-12">
        <div className="container">
          <div className="plicy-wrapper">
            <p className="text-sm font-normal text-gray-700 pb-4">
              {privacyPolicy}
            </p>
            <div className="plicy-list">
              {/* <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                What information we collect about you?
              </h6> */}
              <p className="text-sm font-normal text-gray-700">
                This Privacy Policy is prepared by BMP and whose registered
                address is 218-220 Whitechapel Road, Post Code: E1 1BJ, London,
                United Kingdom (“We”) are committed to protecting and preserving
                the privacy of our visitors when visiting our site or
                communicating electronically with us. This policy sets out how
                we process any personal data we collect from you or that you
                provide to us through our website and social media sites. We
                confirm that we will keep your information secure and comply
                fully with all applicable UK Data Protection legislation and
                regulations. Please read the following carefully to understand
                what happens to personal data that you choose to provide to us,
                or that we collect from you when you visit our sites. By
                submitting information you are accepting and consenting to the
                practices described in this policy.
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                Types of information we may collect from you
              </h6>
              <p className="text-sm font-normal text-gray-700">
                We may collect, store and use the following kinds of personal
                information about individuals who visit and use our website and
                social media sites: Information you supply to us. You may supply
                us with information about you by filling in forms on our website
                or social media. This includes information you provide when you
                submit a contact/inquiry form. The information you give us may
                include but is not limited to, your name, address, e-mail
                address, and phone number.
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                How we may use the information we collect
              </h6>
              <p className="text-sm font-normal text-gray-700">
                We use the information in the following ways:
                <ul className="list-disc">
                  <li>
                    Information you supply to us. We will use this information:
                  </li>
                  <li>
                    to provide you with information and/or services that you
                    request from us;
                  </li>
                  <li>To contact you to provide the information requested.</li>
                </ul>
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                Disclosure of your information
              </h6>
              <p className="text-sm font-normal text-gray-700">
                Any information you provide to us will either be emailed
                directly to us or may be stored on a secure server. We do not
                rent, sell or share personal information about you with other
                people or non-affiliated companies. We will use all reasonable
                efforts to ensure that your personal data is not disclosed to
                regional/national institutions and authorities unless required
                by law or other regulations. Unfortunately, the transmission of
                information via the internet is not completely secure. Although
                we will do our best to protect your personal data, we cannot
                guarantee the security of your data transmitted to our site; any
                transmission is at your own risk. Once we have received your
                information, we will use strict procedures and security features
                to try to prevent unauthorized access.
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                Your rights – access to your personal data
              </h6>
              <p className="text-sm font-normal text-gray-700">
                You have the right to ensure that your personal data is being
                processed lawfully admin@britishmarketplace.co.uk . Your subject
                access right can be exercised in accordance with data protection
                laws and regulations. Any subject access request must be made in
                writing to [insert contact details]. We will provide your
                personal data to you within the statutory time frames. To enable
                us to trace any of your personal data that we may be holding, we
                may need to request further information from you. If you
                complain about how we have used your information, you have the
                right to complain to the Information Commissioner’s Office
                (ICO).
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                Changes to our privacy policy
              </h6>
              <p className="text-sm font-normal text-gray-700">
                Any changes we may make to our privacy policy in the future will
                be posted on this page and, where appropriate, notified to you
                by e-mail. Please check back frequently to see any updates or
                changes to our privacy policy.
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                Contact
              </h6>
              <p className="text-sm font-normal text-gray-700">
                Questions, comments, and requests regarding this privacy policy
                are welcomed and should be addressed to admin@britishmarketplace.co.uk.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="privacy-policy py-12">
        <div className="container">
          <div className="plicy-wrapper">
            <p className="text-sm font-normal text-gray-700 pb-4">
              {privacyPolicy}
            </p>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                What information we collect about you?
              </h6>
              <p className="text-sm font-normal text-gray-700">
                We collect personal information from you when you order goods or
                services from us or use our website. We also collect information
                when you complete any customer survey. Website usage information
                may also be collected using cookies
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                How will we use the information we collect from you?
              </h6>
              <p className="text-sm font-normal text-gray-700">
                Your personal information is sent to the delivery team for
                delivery. We also use your email id to send you newsletters or
                other information you have subscribed to. Time and again we have
                lucky draws among our customers for which we use their email
                IDs. Restaurant or BMP will never use your personal information
                for any other purpose other than the ones mentioned above. We
                take security seriously and we take precautions to keep your
                personal information secure. We have put in place appropriate
                physical, electronic and managerial procedures to safeguard the
                information we collect. However, due to the open communication
                nature of the Internet, BMP cannot always guarantee that
                communications between you and BMP or information stored on BMP
                servers will be 100% safe. However, they will be free from
                unauthorized access by third parties.
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                Cookies
              </h6>
              <p className="text-sm font-normal text-gray-700">
                You can set your browser to refuse all or some browser cookies,
                or to alert you when websites set or access cookies. If you
                disable or refuse cookies, please note that some parts of the
                Site may become inaccessible or not function properly. For more
                information about the cookies we use, and how to set or amend
                your cookie preferences, please see our Cookie Policy.
              </p>
            </div>
            <div className="plicy-list">
              <h6 className="text-base text-[#FAC819] font-semibold pb-2">
                How will we use the information we collect from you?
              </h6>
              <p className="text-sm font-normal text-gray-700">
                Information that we collect from you is used to process your
                order and to manage your account. We may also use your
                information to email you about other products or services that
                we think may be of interest to you. In processing your order we
                may send your information to credit reference and fraud
                prevention agencies. From time to time we may send your
                information to third parties which we consider may have goods or
                services which are of interest to you.
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  );
};

export default PrivacyPolicy;
