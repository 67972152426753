import React from "react";
import thumbnail from "../../../images/driver/driver.png";
const HowitWorks = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-6 mx-10 my-8 place-items-center">
      <div className="">
        <h1 className="text-xl pb-1">How it works</h1>
        <p className="text-sm text-[#828282]">
          Once you’ve joined British MarketPlace, here’s what to expect.
        </p>
        <ul className="timeline">
          <li>
            <h2 className="text-base">Accept and go</h2>
            <p className="text-xs text-[#525252]">
              While you’re online, we’ll offer you orders to deliver. Tap to
              accept the order.
            </p>
          </li>
          <li>
            <h2 className="text-base">Collect the order</h2>
            <p className="text-xs text-[#525252]">
              It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions
            </p>
          </li>
          <li>
            <h2 className="text-base">
              Make a customer’s day – deliver their food!
            </h2>
            <p className="text-xs text-[#525252]">
              Next, we'll show you a suggested route to the customer. After
              you've delivered their food, you're ready to accept a new order.
            </p>
          </li>
        </ul>
      </div>

      <img src={thumbnail} alt="" className=" " />
    </div>
  );
};

export default HowitWorks;
