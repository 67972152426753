import moment from "moment";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SuccessImg from "../images/payment/step-1.webp";
import { savePaymentInfo } from "../redux/actions/orderAction";
import Layout from "./Layout";

const PaymentSuccess = () => {
  const urlParams = queryString.parse(window.location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (urlParams.orderID) {
      // setChecked(urlParams.categories);
      console.log(urlParams.orderID);
      console.log(urlParams.currency);
      console.log(urlParams.amount);
      console.log(urlParams.PM);
      // id.split("_").pop();
      console.log(urlParams?.CARDNO?.split("X").pop());
      console.log(urlParams.CN);
      console.log(urlParams.TRXDATE);
      console.log(urlParams.BRAND);
      console.log(urlParams.IP);
      dispatch(
        savePaymentInfo({
          orderID: urlParams.orderID,
          currency: urlParams.currency,
          amount: urlParams.amount,
          card_no: urlParams.CARDNO.split("X").pop(),
          brand: urlParams.BRAND,
          ip: urlParams.IP,
          payable_date: moment(urlParams.TRXDATE).format("YYYY-MM-DD"),
        })
      );
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <div className="payment-content py-32 flex justify-center items-center">
        <div className="payment-thumb text-center">
          <img src={SuccessImg} className="mx-auto" alt="" />
          <h4 className="text-xl font-semibold text-black pt-10">
            Your Payment has been Successfully Paid!
          </h4>
          <p className="text-sm text-gray-500 font-normal pt-1">
            Thank you for purchasing your Payment was successfull
          </p>
          <Link to="/order">
            <button
              type="button"
              className="px-8 py-3 rounded-md text-white text-xs font-normal cursor-pointer bg-green-400 mt-6"
            >
              View Details
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentSuccess;
