import React from "react";

const FoodCateroryMenubar = ({
  foodCategories,
  handleToggle,
  foodCategoryOption,
  address,
}) => {
  return (
    <div className="pb-4">
      <h3 className="text-lg font-semibold text-black pb-4">
        Delivering to {address}
      </h3>
      <div className="grid xl:grid-cols-7 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-2 gap-4 ">
        {foodCategories.map((category) => (
          <div
            key={category.id}
            className={
              foodCategoryOption.indexOf(category.name) === -1
                ? "food-item bg-white px-6 py-3 rounded-md text-center border cursor-pointer"
                : "food-item bg-[#0DC1D9] px-6 py-3 rounded-md text-center border border-green-500 cursor-pointer"
            }
            onClick={() => handleToggle(category.name, "foodCategory")}
          >
            <img src={category.image} className="mx-auto h-12" alt="" />
            <h4 className="text-base text-black font-medium pt-5">
              {category.name}
            </h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FoodCateroryMenubar;
