import React from "react";
import Layout from "./Layout";
import CheckoutContent from "../components/checkout";

const checkout = () => {
  return (
    <Layout>
      <CheckoutContent />
    </Layout>
  );
};

export default checkout;
