import React from "react";
import { payCashOnDelivery } from "../../redux/actions/orderAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const CashDelivery = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="card-content shadow-lg mt-8 px-4 py-5 bg-white sm:p-6 rounded-md">
      <div className="grid lg:grid-cols-6 gap-6">
        <div className="col-span-6">
          <div className="">
            <h6 className="text-base font-medium text-black leading-3 pb-2">
              Cash On Deliverys
            </h6>
            <p className="text-sm text-gray-500">
              Cash on delivery (COD) is when a recipient pays for a good or
              service at the time of delivery.
            </p>
          </div>
        </div>
      </div>
      <div className="py-3 pt-6">
        <button
          onClick={() => dispatch(payCashOnDelivery(history))}
          type="button"
          className="inline-flex justify-center shadow-themeShadow text-sm font-medium rounded-md bg-gradient-to-r custom-btn2"
        >
          Pay Now
        </button>
      </div>
    </div>
  );
};

export default CashDelivery;
