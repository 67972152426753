import React from "react";
import { useSelector } from "react-redux";
import HomeNotificationList from "../../../components/admin/Home/HomeNotificationList";
import HomeVendorProduct from "../../../components/admin/Home/HomeVendorProduct";
import Layout from "../Layout/layout";

const Home = () => {
  const { vendorNotificatinos } = useSelector((state) => state.auth);
  return (
    <Layout>
      <div className="contianer">
        <div className="wrap_inner m-3">
          <HomeVendorProduct />
          <HomeNotificationList notificatinos={vendorNotificatinos} />
        </div>
      </div>
    </Layout>
  );
};

export default Home;
