import React, { useEffect, useState } from "react";
import Layout from "../Layout/layout";
import { Tooltip } from "@material-tailwind/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import ModalContainer from "../../../components/common/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  adminCustomerPayableInfoDelete,
  adminCustomerPayableInfoList,
} from "../../../redux/actions/AdminActions/adminOrderActions";
import Pagination from "../../../components/common/Pagination";

function CustomerPaymentInfo() {
  const { customerPayableInfo } = useSelector((state) => state.adminOrder);
  console.log(customerPayableInfo);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [search, setsearch] = useState("");
  const [deleleState, setDeleleState] = useState(null);

  const dispatch = useDispatch();

  const handleSearch = (e) => {
    setsearch(e.target.value);
    dispatch(adminCustomerPayableInfoList({ page: 1, search: e.target.value }));
  };

  useEffect(() => {
    dispatch(adminCustomerPayableInfoList());
  }, [dispatch]);

  function closeModal() {
    setIsOpen(false);
  }

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    dispatch(adminCustomerPayableInfoList({ page: currentPage }));
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const handleDelete = async () => {
    dispatch(adminCustomerPayableInfoDelete(deleleState));
    closeModal();
    setDeleleState(null);
  };

  const handleDeleteClick = (delete_id) => {
    console.log(delete_id);
    setIsOpen(true);
    setDeleleState(delete_id);
  };
  console.log(customerPayableInfo?.data?.order_number);
  return (
    <Layout>
      <div className="m-3">
        <p className="text-center">Customer Payment Informations</p>
        <div className="flex items-center">
          {/* search bar */}
          <div className="relative md:w-1/3 my-2">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              value={search}
              onChange={handleSearch}
              id="simple-search"
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
              placeholder="Search here.."
            />
          </div>
        </div>
        <div className="overflow-x-scroll">
          <table className="table-auto  w-full border-collapse custom-table-data my-2">
            <thead className="text-center">
              <tr className="bg-gray-800 text-gray-300 ">
                <th className="custom-table-data"> Customer</th>
                <th className="custom-table-data"> Order</th>
                <th className="custom-table-data"> Amount</th>
                <th className="custom-table-data"> Currency</th>
                <th className="custom-table-data"> Card no.</th>
                <th className="custom-table-data"> Brand</th>
                <th className="custom-table-data"> IP</th>
                <th className="custom-table-data"> Payable date</th>
                <th className="custom-table-data"> Status</th>
                <th className="custom-table-data"> Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {customerPayableInfo?.data?.map((info) => (
                <tr key={info.id}>
                  <td className="custom-table-data">
                    {info?.customer?.identifier}
                  </td>
                  <td className="custom-table-data">
                    {info?.order?.order_number}
                  </td>
                  <td className="custom-table-data">{info?.amount}</td>
                  <td className="custom-table-data">{info?.currency}</td>
                  <td className="custom-table-data">{info?.card_no}</td>
                  <td className="custom-table-data">{info?.brand}</td>
                  <td className="custom-table-data">{info?.ip}</td>
                  <td className="custom-table-data">{info?.payable_date}</td>
                  <td className="custom-table-data">
                    {info?.status ? "True" : "False"}
                  </td>
                  <td className="custom-table-data text-xl">
                    <Tooltip content="Delete">
                      <button onClick={() => handleDeleteClick(info?.id)}>
                        <RiDeleteBin6Line className="mx-2 text-red-500" />
                      </button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          pageCount={
            customerPayableInfo?.meta?.last_page > 0
              ? customerPayableInfo?.meta?.last_page
              : 0
          }
          handlePageClick={handlePageClick}
        />
      </div>

      <ModalContainer open={modalIsOpen} onRequestClose={closeModal}>
        <div id="modal-background">
          <div id="modal" className="w-80 md:w-full">
            <h2 className="my-3">Are you sure you want to delete this item?</h2>
            <div className=" text-sm flex gap-8">
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
}

export default CustomerPaymentInfo;
