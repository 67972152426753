import React from "react";
import img6 from "../../../images/restaurantPartner/10.png";
const PartnerwithUs = () => {
  return (
    <section className="md:mx-24 mb-12">
      <div className="relative text-white">
        <img src={img6} alt="" className="w-full h-36" />
        <div className="absolute top-7 xl:left-[38%] lg:left-80 md:left-48 left-16 text-center">
          <h1 className=" xl:text-xl text-lg">
            Not a British Marketplace partner yet?
          </h1>
          <button className="text-sm sm:text-xs bg-[#0DC1D9] rounded-md xl:px-10 xl:py-3 lg:px-8 lg:py-2 px-5 py-2 ">
            PARTNER WITH US
          </button>
        </div>
      </div>
    </section>
  );
};

export default PartnerwithUs;
