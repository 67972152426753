import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

//reducers
import userReducer from "./reducers/userReducer";
import restaurantReducer from "./reducers/restaurantReducer";
import { requestCart } from "./reducers/cartReducer";
import { commonReucers } from "./reducers/commonReducers";
import { OrderReducer } from "./reducers/orderReducer";
import adminUserReducer from "./reducers/adminReducers/adminUserReducer";
import adminRestaurantReducer from "./reducers/adminReducers/adminRestaurantReducers";
import adminMenuReducer from "./reducers/adminReducers/adminMenuReducers";
import adminOrderReducer from "./reducers/adminReducers/adminOrderReducers";
import adminSponsorReducer from "./reducers/adminReducers/adminSponsorReducers";
import adminMetaReducer from "./reducers/adminReducers/adminMetaReducers";
import { adminVoucherReducer } from "./reducers/adminReducers/adminVoucherReducer";
import vendorMenuReducer from "./reducers/vendorReducers/vendorMenuReducers";
import vendorOrderReducer from "./reducers/vendorReducers/vendorOrderReducers";
import { vendorVoucherReducer } from "./reducers/vendorReducers/vendorVoucherReducers";

import productReducer from "./reducers/productsReducers";

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  auth: userReducer,
  cart: requestCart,
  restaurant: restaurantReducer,
  common: commonReucers,
  order: OrderReducer,
  product: productReducer,
  adminUser: adminUserReducer,
  adminRestaurant: adminRestaurantReducer,
  adminMenu: adminMenuReducer,
  adminOrder: adminOrderReducer,
  adminSponsor: adminSponsorReducer,
  adminVoucher: adminVoucherReducer,
  adminMeta: adminMetaReducer,
  vendorMenu: vendorMenuReducer,
  vendorOrder: vendorOrderReducer,
  vendorVoucher: vendorVoucherReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;
