import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import { fetchFoodCategories } from "../../redux/actions/restaurantAction";
import HomeMenuItem from "./HomeMenuItem";
const HomeMenu = ({ handleMenuClick, setInput1Focus }) => {
  const { foodCategories, loading } = useSelector((state) => state.restaurant);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFoodCategories());
  }, [dispatch]);
  console.log({foodCategories});
  return (
    <section className="food-menu py-10 bg-[#EBF7FF]">
      <div className="container">
        <div className="section-header text-center md:pb-12 pb-6">
          <h3 className="md:text-3xl sm:text-2xl text-xl text-black font-medium">
            What's on the menu?
          </h3>
        </div>
        <div className="flex gap-4 pb-3">
          {/* grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-8 md:gap-16 */}
          <Marquee direction="left">

          {foodCategories
            ?.filter((item) => item.featured === false)
            .slice(0, 7)
            .map((category) => (
              <HomeMenuItem
                key={category.id}
                {...category}
                loading={loading}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </Marquee>
         
         
        </div>
        <div className="flex gap-4 mt-5">
          {/* grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-8 md:gap-16 */}
          <Marquee direction="right">

          {foodCategories
            ?.filter((item) => item.featured === false)
            .slice(7, 15)
            .map((category) => (
              <HomeMenuItem
                key={category.id}
                {...category}
                loading={loading}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </Marquee>
         
         
        </div>
      </div>
    </section>
  );
};

export default HomeMenu;
