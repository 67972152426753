import { Tooltip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ModalContainer from "../../../components/common/ModalContainer";
import Pagination from "../../../components/common/Pagination";
import {
    adminDeleteSponsor,
    adminSponsorListAction,
} from "../../../redux/actions/AdminActions/adminSponsorAction";
import Layout from "../Layout/layout";

function SponsorsList() {
  const { sponsorsList } = useSelector((state) => state.adminSponsor);
  console.log(sponsorsList);
  const history = useHistory();
  // sponsorsList.map((item) => console.log(item.id));
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setsearch] = useState("");
  const [status, setStatus] = useState("");
  const handleSearch = (e) => {
    setsearch(e.target.value);
    dispatch(
      adminSponsorListAction({
        search: e.target.value,
        status: status,
      })
    );
  };

  useEffect(() => {
    dispatch(adminSponsorListAction());
  }, [dispatch]);
  function closeModal() {
    setIsOpen(false);
  }
  const handleDeleteClick = (delete_id) => {
    setIsOpen(true);
    setSelectedId(delete_id);
  };
  const handleDelete = () => {
    dispatch(adminDeleteSponsor(selectedId, history));
    closeModal();
  };

  const handlePageClick = (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    dispatch(adminSponsorListAction({ page: currentPage }));
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    dispatch(
      adminSponsorListAction({
        page: 1,
        status: e.target.value,
        search: search,
      })
    );
  };

  // console.log(sponsorData);
  return (
    <Layout>
      <div className="m-3">
        <p className="text-center">Sponsor List</p>
        <div className="flex items-center">
          {/* search bar */}
          <div className="relative md:w-1/3 my-2">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              value={search}
              onChange={handleSearch}
              id="simple-search"
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
              placeholder="Search here.."
            />
          </div>
          {/*  status select box*/}
          <div className="mx-2">
            <div className="flex justify-center">
              <div className="">
                <select
                  value={status}
                  onChange={handleStatusChange}
                  className="form-select 
    text-gray-700
    bg-white 
    border border-solid border-gray-300
    rounded
    focus:ring-gray-900 focus:border-gray-900 "
                  aria-label="Default select example"
                >
                  <option value="">All</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-scroll">
          <table className="table-auto w-full border-collapse custom-table-data my-2">
            <thead className="text-center">
              <tr className="bg-gray-800 text-gray-300 ">
                <th className="custom-table-data"> Name</th>
                <th className="custom-table-data"> Image</th>
                <th className="custom-table-data">Status</th>
                <th className="custom-table-data"> Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {sponsorsList?.data?.map((sponsor) => (
                <tr key={sponsor?.id}>
                  <td className="custom-table-data">{sponsor?.name}</td>
                  <td className="custom-table-data md:h-[80px] md:w-[80px] h-[60px] w-[60px]">
                    <img src={sponsor?.image} alt="" />
                  </td>
                  <td className="custom-table-data">
                    {sponsor.status.toString().toUpperCase()}
                  </td>
                  <td className="custom-table-data text-xl ">
                    <div className="flex justify-center items-center">
                      <Tooltip content="Edit">
                        <Link to={`/admin/sponsor/edit/${sponsor?.id}`}>
                          <button>
                            {" "}
                            <AiOutlineEdit className="mx-2 text-yellow-500" />
                          </button>
                        </Link>
                      </Tooltip>

                      <Tooltip content="Delete">
                        <button onClick={() => handleDeleteClick(sponsor.id)}>
                          <RiDeleteBin6Line className="mx-2 text-red-500" />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                  <ModalContainer
                    open={modalIsOpen}
                    onRequestClose={closeModal}
                  >
                    <div id="modal-background">
                      <div id="modal" className="w-80 md:w-full">
                        <h2 className="my-3">
                          Are you sure you want to delete this item?
                        </h2>
                        <div className=" text-sm flex gap-8">
                          <button
                            className="rounded py-2 px-5 text-white mr-2 bg-green-400"
                            onClick={handleDelete}
                          >
                            Delete
                          </button>
                          <button
                            className="rounded py-2 px-5 text-white mr-2 bg-red-400"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalContainer>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          pageCount={
            sponsorsList?.meta?.last_page > 0
              ? sponsorsList?.meta?.last_page
              : 0
          }
          handlePageClick={handlePageClick}
        />
      </div>
    </Layout>
  );
}

export default SponsorsList;
