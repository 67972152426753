import React from "react";
import { NavLink } from "react-router-dom";

const FoodIngredientsSidebar = ({
  brand,
  setbrand,
  categories,
  setcategories,
}) => {
  const handleBrandFilter = (e) => {
    setbrand(e.target.checked);
    console.log(e.target.checked);
    console.log(e.target.value);
  };
  return (
    <div className="lg:w-1/5 w-full md:mb-0 mb-6 flex-shrink-0 flex flex-col">
      <button
        type="button"
        className="flex justify-between items-center pb-4 cursor-pointer"
      >
        <span className="text-lg text-black font-medium">Brand</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          className="h-6 w-6"
          fill="#0DC1D9"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div className="bg-white shadow-md lg:p-5 p-2 rounded-md">
        <ul>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref1"
                className="w-4 h-4 rounded-sm mr-2"
                value={brand}
                onChange={(e) => handleBrandFilter(e)}
              />
              <label
                htmlFor="ref1"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Pran
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref2"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="ref2"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Fresh
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref3"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="ref3"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Lucy
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref4"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="ref4"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Radhuni
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref5"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="ref5"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Ghani
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref6"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="ref6"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Tekka
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref6"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="ref6"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Rochon
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref6"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="ref6"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Aseel
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="ref6"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="ref6"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Royal Chef
              </label>
            </NavLink>
          </li>
        </ul>
      </div>

      <button
        type="button"
        className="flex justify-between items-center py-4 cursor-pointer"
      >
        <span className="text-lg text-black font-medium">Categories</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          className="h-6 w-6"
          fill="#0DC1D9"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div className="bg-white shadow-themeShadow lg:p-5 p-2 rounded-md">
        <ul className="">
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="res100"
                className="w-4 h-4 rounded-sm mr-2 text-[#0DC1D9]"
              />
              <label
                htmlFor="res100"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                2 Dine htmlFor £20
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="res2"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="res2"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                20% off local favourites
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="res3"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="res3"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                30% off local takeaways
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="res4"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="res4"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Aero Peppermint
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="res4"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="res4"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                African
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="res4"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="res4"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                BBQ
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="res4"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="res4"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                British
              </label>
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink to="">
              <input
                type="checkbox"
                name=""
                id="res4"
                className="w-4 h-4 rounded-sm mr-2"
              />
              <label
                htmlFor="res4"
                className="text-gray-700 lg:text-md text-sm cursor-pointer"
              >
                Canadian
              </label>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FoodIngredientsSidebar;
