import axios from "axios";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { API_URL } from "../settings/config";
import auth from "../util/auth";
import Layout from "./Layout";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  console.log(password.current);
  const [recoveryStep, setRecoveryStep] = useState("email");
  let token = auth.getToken();
  const config = {
    headers: { Authorization: token },
  };
  const submitMail = async (email) => {
    console.log({email});
    await axios
        .post(
          `${API_URL}/users/password/reset`,
          {
            email: email,
          },
          config
        )
        .then((res) => {
          console.log(res);
          toast.success("OTP sent!", { duration: 5000 });
          
        })
        .catch((err) =>  toast.error("Email/Phone not found", { duration: 5000 }));
  }
  const onSubmit = async (data) => {
    console.log(data);
   
    if (recoveryStep === "email") {
      submitMail(data?.email);
      setRecoveryStep("otp");
    }
    if (recoveryStep === "otp" && watch("code")) {
      await axios
        .post(
          `${API_URL}/users/password/reset/confirm`,
          {
            code: data.code,
            password: data.password,
          },
          config
        )
        .then((res) => {
          console.log(res);
          toast.success("Password changed successfully", { duration: 5000 });
        })
        .catch((err) =>  toast.error("Incorrect OTP", { duration: 5000 }));
    }
  };
  // setRecoveryStep("otp")
  // setRecoveryStep("newPassword");

  const [isViewed, setisViewed] = useState(false);
  const [isConfirmViewed, setisConfirmViewed] = useState(false);
  const togglePassword = () => {
    setisViewed(!isViewed);
  };
  const toggleConfirmPassword = () => {
    setisConfirmViewed(!isConfirmViewed);
  };
  return (
    <Layout loginHeader>
      <div className="">
        {/* font-body bg-login-bg bg-cover object-contain bg-center */}
        <div className="container ">
          <Toaster />
          <div className="my-20 flex justify-center ">
            <div className="w-[500px] shadow-[0px_0px_15px_rgba(46,49,146,0.08)] rounded-md py-8 ">
              <div>
                {recoveryStep === "email" && (
                  <>
                    <h3 className="text-2xl text-center">
                      Forgot your password?
                    </h3>
                    <p className="text-center text-[#464956] text-xs">
                      Enter your email or phone number and we'll send you a link
                      or code to reset your password
                    </p>
                  </>
                )}
                {recoveryStep === "otp" && (
                  <>
                    <h3 className="text-2xl text-center">
                      Verify your email/phone number
                    </h3>
                    <p className="text-center text-[#464956] text-xs">
                      Please enter your code
                    </p>
                  </>
                )}
                {/* {recoveryStep === "newPassword" && (
                  <>
                    <h3 className="text-2xl text-center">
                      Create new Password
                    </h3>
                    <p className="text-center text-[#464956] text-xs">
                      Please enter your new password
                    </p>
                  </>
                )} */}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="md:px-9 md:py-6 p-4 rounded"
                >
                  {recoveryStep === "email" && (
                    <div>
                      <div className="mb-4 w-full">
                        <input
                          className={
                            errors?.email
                              ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                              : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                          }
                          type="text"
                          placeholder="Enter Email / Phone number"
                          {...register("email", {
                            required: true,
                          })}
                        />
                        {errors?.email?.type === "required" && (
                          <p className="text-xs italic text-red-500 mt-1">
                            This field is required
                          </p>
                        )}
                      </div>
                      {/* <div className="mb-4 -mt-3">
                  {!Array.isArray(authError) && (
                    <p className="text-xs italic text-red-500">{authError}</p>
                  )}
                 
                </div> */}
                      <div className="mb-6 text-center">
                        <button
                          className="w-full px-5 py-3 font-bold text-white bg-[#0DC1D9] rounded-lg hover:bg-[#0DC1D9] focus:outline-none focus:shadow-outline "
                          type="submit"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  )}
                  {recoveryStep === "otp" && (
                    <>
                      <div className="flex items-center justify-between gap-3 mb-4">
                        <div className=" w-full">
                          <input
                            className={
                              errors?.code
                                ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                                : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            }
                            type="text"
                            placeholder="Enter otp"
                            {...register("code", {
                              required: true,
                            })}
                          />
                          {errors?.code?.type === "required" && (
                            <p className="text-xs italic text-red-500 mt-1">
                              This field is required
                            </p>
                          )}
                        </div>

                        {/* <div className=" text-center">
                          <button
                            className="w-full px-5 py-3 font-bold text-white bg-[#0DC1D9] rounded-lg hover:bg-[#0DC1D9] focus:outline-none focus:shadow-outline "
                            onClick={() =>
                              watch("code") && setRecoveryStep("newPassword")
                            }
                          >
                            Verify
                          </button>
                        </div> */}
                      </div>
                      <>
                      <div className="">
                        <div className="relative w-full mb-4">
                          <input
                            className={
                              errors?.password
                                ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                                : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            }
                            id="password"
                            type={isViewed ? "text" : "password"}
                            name="password"
                            placeholder="New Password"
                            {...register("password", {
                              required: true,
                              minLength: {
                                value: 8,
                                message: "Password must have at least 8 characters",
                              },
                            })}
                          />
                          <i
                            onClick={togglePassword}
                            className="absolute  inset-y-0 right-2 mt-3 mr-2"
                            // inset-y-0 right-2 mt-3 mr-2
                          >
                            {isViewed ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                // height={12}
                                // width={20}
                                viewBox="0 0 24 20"
                                className="w-6 h-4"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 20"
                                className="w-6 h-4"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                              // <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              // <path d="M7.59649 5.61678C5.78519 5.4664 4.241 4.65073 2.75941 3.48874C2.66759 3.60721 2.57577 3.73936 2.47144 3.85328C2.17512 4.18593 1.87462 4.50947 1.56996 4.833C1.42806 4.98338 1.22356 5.00616 1.06914 4.90591C0.91472 4.80566 0.83125 4.60516 0.885505 4.41377C0.91472 4.31352 0.981496 4.21783 1.04827 4.14036C1.39885 3.74848 1.75777 3.36115 2.12921 2.96014C1.99148 2.82344 1.84958 2.70496 1.72438 2.56826C1.19434 1.98043 0.66431 1.38804 0.142622 0.791092C-0.0577057 0.558694 -0.0410117 0.285284 0.155143 0.107567C0.347124 -0.070149 0.58084 -0.0245807 0.772821 0.221488C1.83706 1.58398 3.05573 2.74597 4.49141 3.63C5.38036 4.17682 6.3194 4.55959 7.33356 4.69174C8.32686 4.82389 9.29093 4.65073 10.2258 4.27707C11.7199 3.67557 12.9845 2.68218 14.1238 1.49284C14.5203 1.08273 14.8792 0.627046 15.2549 0.18959C15.3717 0.0528854 15.5011 -0.0291375 15.6722 0.0118739C15.8433 0.0528854 15.9477 0.17592 15.9894 0.358193C16.027 0.531353 15.9602 0.668058 15.86 0.795649C15.2841 1.54297 14.6539 2.23105 13.9653 2.85989C13.936 2.88724 13.9068 2.91458 13.8609 2.96014C14.224 3.35203 14.5788 3.73936 14.9377 4.12669C15.0504 4.24973 15.1338 4.38643 15.1046 4.56871C15.0754 4.74642 14.9836 4.86946 14.8208 4.9287C14.6497 4.99249 14.5078 4.9287 14.3909 4.8011C14.0404 4.42289 13.694 4.04467 13.3476 3.6619C13.3058 3.61633 13.2683 3.55709 13.2307 3.50241C11.77 4.65073 10.2175 5.47552 8.40615 5.62133C8.40615 5.89019 8.40615 6.14993 8.40615 6.40511C8.40615 6.78333 8.41033 7.15699 8.40615 7.5352C8.40198 7.80861 8.23086 8 7.99715 8C7.75926 8 7.59232 7.81317 7.59232 7.5352C7.59649 6.9018 7.59649 6.27296 7.59649 5.61678Z" fill="#B3B3B3"/>
                              // </svg>
                            )}
                          </i>
                          {errors?.password?.type === "required" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        You must specify a password
                      </p>
                    )}
                    {errors?.password?.type === "minLength" && (
                      <p className="text-xs italic text-red-500 mt-1">
                        {errors.password.message}
                      </p>
                    )}

                        
                        </div>
                        
                      </div>
                      <div className="">
                        <div className="relative w-full mb-4">
                          <input
                            className={
                              errors?.confirmPassword
                                ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                                : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            }
                            id="confirmPassword"
                            type={isConfirmViewed ? "text" : "password"}
                            placeholder="Confirm password"
                            {...register("confirmPassword", {
                              validate: (value) => 
                              
                              value === password.current || "The passwords do not match",
                            })}
                          />
                          <i
                            onClick={toggleConfirmPassword}
                            className="absolute  inset-y-0 right-2 mt-3 mr-2"
                            // inset-y-0 right-2 mt-3 mr-2
                          >
                            {isConfirmViewed ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                // height={12}
                                // width={20}
                                viewBox="0 0 24 20"
                                className="w-6 h-4"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 20"
                                className="w-6 h-4"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                              // <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              // <path d="M7.59649 5.61678C5.78519 5.4664 4.241 4.65073 2.75941 3.48874C2.66759 3.60721 2.57577 3.73936 2.47144 3.85328C2.17512 4.18593 1.87462 4.50947 1.56996 4.833C1.42806 4.98338 1.22356 5.00616 1.06914 4.90591C0.91472 4.80566 0.83125 4.60516 0.885505 4.41377C0.91472 4.31352 0.981496 4.21783 1.04827 4.14036C1.39885 3.74848 1.75777 3.36115 2.12921 2.96014C1.99148 2.82344 1.84958 2.70496 1.72438 2.56826C1.19434 1.98043 0.66431 1.38804 0.142622 0.791092C-0.0577057 0.558694 -0.0410117 0.285284 0.155143 0.107567C0.347124 -0.070149 0.58084 -0.0245807 0.772821 0.221488C1.83706 1.58398 3.05573 2.74597 4.49141 3.63C5.38036 4.17682 6.3194 4.55959 7.33356 4.69174C8.32686 4.82389 9.29093 4.65073 10.2258 4.27707C11.7199 3.67557 12.9845 2.68218 14.1238 1.49284C14.5203 1.08273 14.8792 0.627046 15.2549 0.18959C15.3717 0.0528854 15.5011 -0.0291375 15.6722 0.0118739C15.8433 0.0528854 15.9477 0.17592 15.9894 0.358193C16.027 0.531353 15.9602 0.668058 15.86 0.795649C15.2841 1.54297 14.6539 2.23105 13.9653 2.85989C13.936 2.88724 13.9068 2.91458 13.8609 2.96014C14.224 3.35203 14.5788 3.73936 14.9377 4.12669C15.0504 4.24973 15.1338 4.38643 15.1046 4.56871C15.0754 4.74642 14.9836 4.86946 14.8208 4.9287C14.6497 4.99249 14.5078 4.9287 14.3909 4.8011C14.0404 4.42289 13.694 4.04467 13.3476 3.6619C13.3058 3.61633 13.2683 3.55709 13.2307 3.50241C11.77 4.65073 10.2175 5.47552 8.40615 5.62133C8.40615 5.89019 8.40615 6.14993 8.40615 6.40511C8.40615 6.78333 8.41033 7.15699 8.40615 7.5352C8.40198 7.80861 8.23086 8 7.99715 8C7.75926 8 7.59232 7.81317 7.59232 7.5352C7.59649 6.9018 7.59649 6.27296 7.59649 5.61678Z" fill="#B3B3B3"/>
                              // </svg>
                            )}
                          </i>
                        {errors?.confirmPassword && (
                          <p className="text-xs italic text-red-500 mt-1">
                           The passwords do not match
                          </p>
                        )}
                        </div>

                      </div>
                      <div className="mb-6 text-center">
                        <button
                          className="w-full px-5 py-3 font-bold text-white bg-[#0DC1D9] rounded-lg hover:bg-[#0DC1D9] focus:outline-none focus:shadow-outline "
                          type="submit"
                        >
                          Create Account
                        </button>
                      </div>
                    </>
                      {/* <div className="flex justify-center my-5 ">
                        <p className="font-base text-sm mr-3">OTP Type</p>

                        <div class="flex items-center mr-4 mb-4">
                          <input
                            id="radio1"
                            type="radio"
                            name="radio"
                            class="hidden"
                            checked
                          />
                          <label
                            for="radio1"
                            class="flex items-center cursor-pointer"
                          >
                            <span class="w-4 h-4 inline-block mr-2 rounded-full border border-grey "></span>
                            SMS
                          </label>
                        </div>

                        <div class="flex items-center mr-4 mb-4">
                          <input
                            id="radio2"
                            type="radio"
                            name="radio"
                            class="hidden"
                          />
                          <label
                            for="radio2"
                            class="flex items-center cursor-pointer"
                          >
                            <span class="w-4 h-4 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                            Email
                          </label>
                        </div>
                      </div> */}
                      <div className="text-center text-sm">
                        <button
                        type="button"
                          className="text-[#464956]"
                          onClick={()=> submitMail(watch("email"))}
                        >
                          Resend Code
                        </button>
                      </div>
                    </>
                  )}
                  {/* {recoveryStep === "newPassword" && (
                    <>
                      <div className="">
                        <div className="relative w-full mb-4">
                          <input
                            className={
                              errors?.password
                                ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                                : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            }
                            id="password"
                            type={isViewed ? "text" : "password"}
                            name="password"
                            placeholder="New Password"
                            {...register("password", {
                              required: true,
                            })}
                          />
                          <i
                            onClick={togglePassword}
                            className="absolute  inset-y-0 right-2 mt-3 mr-2"
                            // inset-y-0 right-2 mt-3 mr-2
                          >
                            {isViewed ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                // height={12}
                                // width={20}
                                viewBox="0 0 24 20"
                                className="w-6 h-4"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 20"
                                className="w-6 h-4"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                              // <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              // <path d="M7.59649 5.61678C5.78519 5.4664 4.241 4.65073 2.75941 3.48874C2.66759 3.60721 2.57577 3.73936 2.47144 3.85328C2.17512 4.18593 1.87462 4.50947 1.56996 4.833C1.42806 4.98338 1.22356 5.00616 1.06914 4.90591C0.91472 4.80566 0.83125 4.60516 0.885505 4.41377C0.91472 4.31352 0.981496 4.21783 1.04827 4.14036C1.39885 3.74848 1.75777 3.36115 2.12921 2.96014C1.99148 2.82344 1.84958 2.70496 1.72438 2.56826C1.19434 1.98043 0.66431 1.38804 0.142622 0.791092C-0.0577057 0.558694 -0.0410117 0.285284 0.155143 0.107567C0.347124 -0.070149 0.58084 -0.0245807 0.772821 0.221488C1.83706 1.58398 3.05573 2.74597 4.49141 3.63C5.38036 4.17682 6.3194 4.55959 7.33356 4.69174C8.32686 4.82389 9.29093 4.65073 10.2258 4.27707C11.7199 3.67557 12.9845 2.68218 14.1238 1.49284C14.5203 1.08273 14.8792 0.627046 15.2549 0.18959C15.3717 0.0528854 15.5011 -0.0291375 15.6722 0.0118739C15.8433 0.0528854 15.9477 0.17592 15.9894 0.358193C16.027 0.531353 15.9602 0.668058 15.86 0.795649C15.2841 1.54297 14.6539 2.23105 13.9653 2.85989C13.936 2.88724 13.9068 2.91458 13.8609 2.96014C14.224 3.35203 14.5788 3.73936 14.9377 4.12669C15.0504 4.24973 15.1338 4.38643 15.1046 4.56871C15.0754 4.74642 14.9836 4.86946 14.8208 4.9287C14.6497 4.99249 14.5078 4.9287 14.3909 4.8011C14.0404 4.42289 13.694 4.04467 13.3476 3.6619C13.3058 3.61633 13.2683 3.55709 13.2307 3.50241C11.77 4.65073 10.2175 5.47552 8.40615 5.62133C8.40615 5.89019 8.40615 6.14993 8.40615 6.40511C8.40615 6.78333 8.41033 7.15699 8.40615 7.5352C8.40198 7.80861 8.23086 8 7.99715 8C7.75926 8 7.59232 7.81317 7.59232 7.5352C7.59649 6.9018 7.59649 6.27296 7.59649 5.61678Z" fill="#B3B3B3"/>
                              // </svg>
                            )}
                          </i>
                        </div>

                        {errors?.password?.type === "required" && (
                          <p className="text-xs italic text-red-500 mt-1">
                            You must specify a password
                          </p>
                        )}
                      </div>
                      <div className="">
                        <div className="relative w-full mb-4">
                          <input
                            className={
                              errors?.confirm_password
                                ? "w-full px-5 py-3 text-sm leading-tight text-gray-700 border border-red-500 rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                                : "w-full px-5 py-3 text-sm leading-tight text-gray-700 border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
                            }
                            id="confirm_password"
                            type={isConfirmViewed ? "text" : "password"}
                            name="confirm_password"
                            placeholder="Confirm password"
                            {...register("confirm_password", {
                              required: true,
                            })}
                          />
                          <i
                            onClick={toggleConfirmPassword}
                            className="absolute  inset-y-0 right-2 mt-3 mr-2"
                            // inset-y-0 right-2 mt-3 mr-2
                          >
                            {isConfirmViewed ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                // height={12}
                                // width={20}
                                viewBox="0 0 24 20"
                                className="w-6 h-4"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 20"
                                className="w-6 h-4"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                              // <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              // <path d="M7.59649 5.61678C5.78519 5.4664 4.241 4.65073 2.75941 3.48874C2.66759 3.60721 2.57577 3.73936 2.47144 3.85328C2.17512 4.18593 1.87462 4.50947 1.56996 4.833C1.42806 4.98338 1.22356 5.00616 1.06914 4.90591C0.91472 4.80566 0.83125 4.60516 0.885505 4.41377C0.91472 4.31352 0.981496 4.21783 1.04827 4.14036C1.39885 3.74848 1.75777 3.36115 2.12921 2.96014C1.99148 2.82344 1.84958 2.70496 1.72438 2.56826C1.19434 1.98043 0.66431 1.38804 0.142622 0.791092C-0.0577057 0.558694 -0.0410117 0.285284 0.155143 0.107567C0.347124 -0.070149 0.58084 -0.0245807 0.772821 0.221488C1.83706 1.58398 3.05573 2.74597 4.49141 3.63C5.38036 4.17682 6.3194 4.55959 7.33356 4.69174C8.32686 4.82389 9.29093 4.65073 10.2258 4.27707C11.7199 3.67557 12.9845 2.68218 14.1238 1.49284C14.5203 1.08273 14.8792 0.627046 15.2549 0.18959C15.3717 0.0528854 15.5011 -0.0291375 15.6722 0.0118739C15.8433 0.0528854 15.9477 0.17592 15.9894 0.358193C16.027 0.531353 15.9602 0.668058 15.86 0.795649C15.2841 1.54297 14.6539 2.23105 13.9653 2.85989C13.936 2.88724 13.9068 2.91458 13.8609 2.96014C14.224 3.35203 14.5788 3.73936 14.9377 4.12669C15.0504 4.24973 15.1338 4.38643 15.1046 4.56871C15.0754 4.74642 14.9836 4.86946 14.8208 4.9287C14.6497 4.99249 14.5078 4.9287 14.3909 4.8011C14.0404 4.42289 13.694 4.04467 13.3476 3.6619C13.3058 3.61633 13.2683 3.55709 13.2307 3.50241C11.77 4.65073 10.2175 5.47552 8.40615 5.62133C8.40615 5.89019 8.40615 6.14993 8.40615 6.40511C8.40615 6.78333 8.41033 7.15699 8.40615 7.5352C8.40198 7.80861 8.23086 8 7.99715 8C7.75926 8 7.59232 7.81317 7.59232 7.5352C7.59649 6.9018 7.59649 6.27296 7.59649 5.61678Z" fill="#B3B3B3"/>
                              // </svg>
                            )}
                          </i>
                        </div>

                        {errors?.confirm_password?.type === "required" && (
                          <p className="text-xs italic text-red-500 mt-1">
                            You must specify a password
                          </p>
                        )}
                      </div>
                      <div className="mb-6 text-center">
                        <button
                          className="w-full px-5 py-3 font-bold text-white bg-[#0DC1D9] rounded-lg hover:bg-[#0DC1D9] focus:outline-none focus:shadow-outline "
                          type="submit"
                        >
                          Create Account
                        </button>
                      </div>
                    </>
                  )} */}

                  {/* <div className="text-center text-sm">
                  <p className="text-[#888888]">
                   
                    <Link
                      className="inline-block align-baseline "
                      to={`register`}
                    >
                     Try another way
                    </Link>
                  </p>
                </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
