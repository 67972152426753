import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generalSettingsDetails,
  generalSettingsSave,
} from "../../../redux/actions/AdminActions/adminMetaActions";
import Layout from "../Layout/layout";
import toast, { Toaster } from "react-hot-toast";
import { ADMIN_GENERAL_SETTINGS_SAVE_CLEAR } from "../../../redux/types";

function GeneralSettings() {
  const { generalSettings, generalSettingsSave: generalSettingSaveSuccess } =
    useSelector((state) => state.adminMeta);
  console.log(generalSettings);
  const [siteName, setSiteName] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [footerEmail, setFooterEmail] = useState("");
  const [footerAddress, setFooterAddress] = useState("");
  const [footerPhone, setFooterPhone] = useState("");
  const [image1, setImage1] = useState(null);
  const [image1Preview, setimage1Preview] = useState("");
  const [image2, setImage2] = useState(null);
  const [image2Preview, setimage2Preview] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (generalSettings?.data?.website_name)
      setSiteName(generalSettings.data.website_name);
    if (generalSettings?.data?.footer_email)
      setFooterEmail(generalSettings.data.footer_email);
    if (generalSettings?.data?.footer_address)
      setFooterAddress(generalSettings.data.footer_address);
    if (generalSettings?.data?.footer_phone)
      setFooterPhone(generalSettings.data.footer_phone);
    if (generalSettings?.data?.facebook_url)
      setFacebookUrl(generalSettings.data.facebook_url);
    if (generalSettings?.data?.twitter_url)
      setTwitterUrl(generalSettings.data.twitter_url);
    if (generalSettings?.data?.instagram_url)
      setInstagramUrl(generalSettings.data.instagram_url);
    if (generalSettings?.data?.youtube_url)
      setYoutubeUrl(generalSettings.data.youtube_url);
    if (generalSettings?.data?.linkedIn_url)
      setLinkedInUrl(generalSettings.data.linkedIn_url);
    // if (generalSettings?.data?.website_icon1)
    //   setImage1(generalSettings.data.website_icon1);
    // if (generalSettings?.data?.website_icon2)
    //   setImage2(generalSettings.data.website_icon2);
  }, [generalSettings]);

  useEffect(() => {
    if (generalSettingSaveSuccess) {
      toast.success("Content settings saved successfully", { duration: 5000 });
      dispatch({ type: ADMIN_GENERAL_SETTINGS_SAVE_CLEAR });
    }
  }, [generalSettingSaveSuccess, dispatch]);

  useEffect(() => {
    dispatch(generalSettingsDetails());
  }, [dispatch]);

  const handleSiteName = (event) => {
    setSiteName(event.target.value);
    console.log(event.target.value);
  };
  const handleFacebookUrl = (event) => {
    setFacebookUrl(event.target.value);
  };
  const handleTwitterUrl = (event) => {
    setTwitterUrl(event.target.value);
  };
  const handleInstagramUrl = (event) => {
    setInstagramUrl(event.target.value);
  };
  const handleYoutubeUrl = (event) => {
    setYoutubeUrl(event.target.value);
  };
  const handleLinkedInUrl = (event) => {
    setLinkedInUrl(event.target.value);
  };
  const handleFooterEmail = (event) => {
    setFooterEmail(event.target.value);
  };
  const handleFooterAddress = (event) => {
    setFooterAddress(event.target.value);
  };
  const handleFooterPhone = (event) => {
    setFooterPhone(event.target.value);
  };

  if (image1) {
    console.log(image1);
    try {
      const reader = new FileReader();
      reader.readAsDataURL(image1);
      reader.onload = function () {
        setimage1Preview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }
  if (image2) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(image2);
      reader.onload = function () {
        setimage2Preview(reader.result);
      };
    } catch (e) {
      console.log(e);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("website_name", siteName);
    console.log(image1);
    if (image1) formData.append("website_icon1", image1, image1.name);
    if (image2) formData.append("website_icon2", image2, image2.name);
    formData.append("footer_email", footerEmail);
    formData.append("footer_address", footerAddress);
    formData.append("footer_phone", footerPhone);
    formData.append("facebook_url", facebookUrl);
    formData.append("twitter_url", twitterUrl);
    formData.append("instagram_url", instagramUrl);
    formData.append("youtube_url", youtubeUrl);
    formData.append("linkedIn_url", linkedInUrl);
    dispatch(generalSettingsSave(formData));
  };

  return (
    <Layout>
      <div className=" m-3 ">
        <p className="text-center mb-8">General settings</p>
        <Toaster />
        <form onSubmit={handleSubmit}>
          <div className="md:grid md:grid-cols-3 gap-2 pt-2">
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Website name
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={siteName}
                onChange={handleSiteName}
              />
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Facebook url
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={facebookUrl}
                onChange={handleFacebookUrl}
              />
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Twitter url
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={twitterUrl}
                onChange={handleTwitterUrl}
              />
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Instagram url
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={instagramUrl}
                onChange={handleInstagramUrl}
              />
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Youtube url
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={youtubeUrl}
                onChange={handleYoutubeUrl}
              />
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                LinkedIn url
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={linkedInUrl}
                onChange={handleLinkedInUrl}
              />
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Footer email
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={footerEmail}
                onChange={handleFooterEmail}
              />
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Footer address
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={footerAddress}
                onChange={handleFooterAddress}
              />
            </div>
            <div className="">
              <label htmlFor="" className=" text-gray-700">
                Footer phone
              </label>
              <input
                type="text"
                className="mt-1  block w-full shadow-sm  border-gray-300 rounded-md"
                value={footerPhone}
                onChange={handleFooterPhone}
              />
            </div>
            <div className="block sm:my-1">
              <label htmlFor="image" className=" text-gray-700">
                Image
              </label>
              {image1Preview ? (
                <img
                  src={image1Preview}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              {!image1Preview && generalSettings?.data?.website_icon1 ? (
                <img
                  src={generalSettings?.data?.website_icon1}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              <input
                type="file"
                name="image"
                id="image"
                onChange={(e) => setImage1(e.target.files[0])}
                className="mt-1  block w-1/3 border-gray-300 rounded-md"
              />
            </div>{" "}
            <div className="block sm:my-1">
              <label htmlFor="image" className=" text-gray-700">
                Image
              </label>
              {image2Preview ? (
                <img
                  src={image2Preview}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              {!image2Preview && generalSettings?.data?.website_icon2 ? (
                <img
                  src={generalSettings?.data?.website_icon2}
                  alt="preview"
                  className=" h-20 w-20 mr-3 rounded-full"
                />
              ) : null}
              <input
                type="file"
                name="image"
                id="image"
                onChange={(e) => setImage2(e.target.files[0])}
                className="mt-1  block w-1/3 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 gap-2 pt-2">
            <div className=" mt-2">
              <button className="bg-[#77C9F2] rounded text-white px-4 py-2 cursor-pointer">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default GeneralSettings;
