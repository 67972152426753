import {
  SET_SPONSOR,
  FETCH_ABOUT_US,
  SUBMIT_CONTACT_US,
  FETCH_PRIVACY_POLICY,
  SET_REVIEW_RATING_DATA,
  FETCH_ALL_CHAT_USERS,
  FETCH_SINGLE_CHAT_MESSAGES,
} from "../types";
// import axios from "axios";
import axios from "../../util/axios";
import { API_URL } from "../../settings/config";
import auth from "../../util/auth";

export const fetchSponsor = () => async (dispatch) => {
  await axios
    .get(`${API_URL}/sponsor/sponsor/`)
    .then((res) => dispatch({ type: SET_SPONSOR, payload: res.data }))
    .catch((err) => console.log(err));
};

export const fetchAboutUs = () => async (dispatch) => {
  await axios
    .get(`${API_URL}/meta/about/`)
    .then((res) => dispatch({ type: FETCH_ABOUT_US, payload: res.data.about }))
    .catch((err) => console.log(err));
};

export const submitContact = (data) => async (dispatch) => {
  await axios
    .post(`${API_URL}/meta/contact/`, data)
    .then((_) => dispatch({ type: SUBMIT_CONTACT_US }))
    .catch((err) => console.log(err));
};

export const fetchPrivacyPolicy = () => async (dispatch) => {
  await axios
    .get(`${API_URL}/meta/privacy-policy/`)
    .then((res) =>
      dispatch({ type: FETCH_PRIVACY_POLICY, payload: res.data.privacy_policy })
    )
    .catch((err) => console.log(err));
};

export const reviewRatingModal =
  (data = {}) =>
  async (dispatch) => {
    let token = auth.getToken();
    const config = { headers: { Authorization: token } };
    await axios
      .post(`${API_URL}/review/review-rating/`, data, config)
      .then((res) => {
        console.log(res.data);
        dispatch({ type: SET_REVIEW_RATING_DATA, payload: res.data });
      })
      .catch((err) => console.log(err));
  };

export const fetchChatusers = () => async (dispatch) => {
  let token = auth.getToken();
  const config = { headers: { Authorization: token } };
  await axios
    .get(`${API_URL}/chat/thread/`, config)
    .then((res) =>
      dispatch({
        type: FETCH_ALL_CHAT_USERS,
        payload: res.data,
      })
    )
    .catch((err) => console.log(err));
};

export const fetchChatMessages =
  ({ from_user, to_user }) =>
  async (dispatch) => {
    let token = auth.getToken();
    await axios
      .get(`${API_URL}/chat/messages/`, {
        params: {
          from_user: from_user,
          to_user: to_user,
        },
        headers: { Authorization: token },
      })
      .then((res) =>
        dispatch({
          type: FETCH_SINGLE_CHAT_MESSAGES,
          payload: res.data,
        })
      )
      .catch((err) => console.log(err));
  };
