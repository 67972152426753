import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { tConvert } from "../../../pages/order";
import {
  adminAssignDriver,
  driverSearch,
} from "../../../redux/actions/AdminActions/adminOrderActions";

function OrderDetailsInfo({
  orderDetails,
  handleDeleteClick,
  handleInvoiceClick,
  vendor,
}) {
  console.log(orderDetails);

  const dispatch = useDispatch();

  const handleAssignDriver = () => {
    console.log("enterrrrr");

    dispatch(
      driverSearch({ order: orderDetails?.id, driver_status: "SEARCH" })
    );

    console.log(
      orderDetails.shipping_address.country === "Bangladesh"
        ? String(orderDetails.shipping_address.postcode)
        : String(orderDetails.shipping_address.postcode).split(" ")[0]
    );

    dispatch(
      adminAssignDriver({
        customer_postcode:
          orderDetails.shipping_address.country === "Bangladesh"
            ? String(orderDetails.shipping_address.postcode)
            : String(orderDetails.shipping_address.postcode).split(" ")[0],
        customer_city:
          orderDetails.shipping_address.country === "Bangladesh"
            ? String(orderDetails.shipping_address.city)
            : String(orderDetails.shipping_address.state),
        customer_country:
          orderDetails.shipping_address.country === "Bangladesh" ? "BD" : "UK",
        // customer_country: String(orderDetails.shipping_address.country),
        customer_id: String(orderDetails.customer.id),
        customer_shipping_id: String(orderDetails.shipping_address.id),
        customer_order_id: String(orderDetails.id),
        customer_order_payment_type: String(orderDetails.payment_type),
        project_code: "bmp_food",
      })
    );
  };

  return (
    <div className="mr-3 shadow p-3 h-max w-1/2 lg:w-1/3">
      <p className="text-center mb-8">Order Details</p>
      <div className="grid grid-cols-1 gap-2">
        <p>
          Customer: <strong>{orderDetails?.customer?.identifier}</strong>
        </p>
        <p>
          Restaurant branch:{" "}
          <strong>{orderDetails?.restaurant_branch?.name}</strong>
        </p>
        <p>
          Shipping address:{" "}
          <strong>
            {orderDetails?.shipping_address?.others}{" "}
            {orderDetails?.shipping_address?.city}{" "}
            {orderDetails?.shipping_address?.state}{" "}
            {orderDetails?.shipping_address?.postcode}
          </strong>
        </p>
        <p>
          Order number: <strong>{orderDetails?.order_number}</strong>
        </p>
        <p>
          Vat: <strong>{orderDetails?.vat}</strong>
        </p>

        <p>
          Shipping price: <strong>{orderDetails?.shipping_price}</strong>
        </p>
        <p>
          Service fee: <strong>{orderDetails?.service_fee}</strong>
        </p>
        <p>
          Subtotal price: <strong>{orderDetails?.subtotal_price}</strong>
        </p>
        <p>
          Discount amount: <strong>{orderDetails?.discount_amount}</strong>
        </p>
        <p>
          Total price: <strong>{orderDetails?.total_price}</strong>
        </p>
        <p>
          Order status: <strong>{orderDetails?.order_status}</strong>
        </p>
        <p>
          Payment type: <strong>{orderDetails?.payment_type}</strong>
        </p>
        <p>
          Delivered at:{" "}
          <strong>
            {orderDetails?.delivered_at
              ? moment(orderDetails?.delivered_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )
              : null}{" "}
          </strong>
        </p>
        <p>
          Delivery time: <strong>{orderDetails?.delivery_time}</strong>
        </p>
        <p>
          Schedule time:{" "}
          <strong>
            {orderDetails?.schedule_time
              ? tConvert(orderDetails?.schedule_time)
              : null}
          </strong>
        </p>
        <p>
          Schedule date:{" "}
          <strong>
            {orderDetails?.schedule_date
              ? moment(orderDetails?.schedule_date).format("MMMM Do YYYY")
              : null}
          </strong>
        </p>
        <p className="flex">
          Rider instruction:{" "}
          <strong>
            <div
              dangerouslySetInnerHTML={{
                __html: orderDetails?.rider_instruction?.replace(
                  /\r\n|\r|\n/g,
                  "<br />"
                ),
              }}
            />
          </strong>
        </p>
        <p>
          Delivery option: <strong>{orderDetails?.delivery_option}</strong>
        </p>
        <p>
          Order delivery time:{" "}
          <strong>
            {orderDetails?.order_delivery_time
              ? moment(orderDetails?.order_delivery_time).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )
              : null}
          </strong>
        </p>
        <p>
          Cutlery: <strong>{orderDetails?.cutlery ? "True" : "False"}</strong>
        </p>
        <p>
          Active: <strong>{orderDetails?.active ? "True" : "False"}</strong>
        </p>
        <p>
          Ordered:{" "}
          <strong>{orderDetails?.is_ordered ? "True" : "False"}</strong>
        </p>
      </div>
      <div className="flex justify-center my-3">
        <Link
          to={
            vendor
              ? `/vendor/order/edit/${orderDetails?.id}`
              : `/admin/order/edit/${orderDetails?.id}`
          }
        >
          <button className="rounded py-2 px-2 text-white mr-2 bg-yellow-400">
            Edit
          </button>
        </Link>
        <button
          className="rounded py-2 px-2 text-white mr-2 bg-red-600"
          onClick={() => handleInvoiceClick(orderDetails?.id)}
        >
          Download Invoice
        </button>
        <button
          className="rounded py-2 px-2 mr-2 text-white  bg-red-600"
          onClick={() => handleDeleteClick(orderDetails?.id)}
        >
          Delete
        </button>

        {orderDetails?.driver_status === "SEARCH" ? (
          <button
            className="rounded py-2 px-2 text-white  bg-green-600"
            onClick={handleAssignDriver}
          >
            Seaech Again
          </button>
        ) : (
          <button
            className="rounded py-2 px-2 text-white  bg-green-600"
            onClick={handleAssignDriver}
          >
            Assign Driver
          </button>
        )}
      </div>
    </div>
  );
}

export default OrderDetailsInfo;
