import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PlaceHolderImg from "../images/placeholder.png";
import {
  fetchWishListRes,
  toggleWishlist,
} from "../redux/actions/restaurantAction";
import { IMAGE_URL } from "../settings/config";
import Layout from "./Layout";

function getLatLngFromLocalStorage() {
  return localStorage.getItem("latlng") ? localStorage.getItem("latlng") : "";
}

const Wishlist = () => {
  const { wishlist, wishlistToggleMessage } = useSelector(
    (state) => state.restaurant
  );
  

  const dispatch = useDispatch();

  useEffect(() => {
    let data = getLatLngFromLocalStorage();
    if (data) {
      let formateArr = data.split(",");
      let res = Object.assign(
        {},
        {
          lat: formateArr[0],
          lng: formateArr[1],
        }

        
      );
      dispatch(fetchWishListRes(res));
    } else {
      dispatch(fetchWishListRes());
    }
   
  }, [dispatch, wishlistToggleMessage]);

  const handleWishlistRemove = (id) => {
    dispatch(toggleWishlist(id));
  };

  return (
    <Layout>
      <section className="wishlist-area py-12">
        <div className="container">
          <div className="section-header">
            <h3 className="text-3xl font-semibold text-black">
              Recently Added
            </h3>
          </div>
          <div className="mt-12">
            <div className="grid lg:grid-cols-4 gap-8">
              {wishlist.length > 0
                ? wishlist?.map((item, index) => {
                    return (
                      <div className="wishlist-item text-center" key={index}>
                        <div className="item-thumb h-32 w-32 mx-auto relative">
                          {item?.restaurant?.image ? (
                            <Link to={`/restaurant/${item?.restaurant?.id}`}>
                              <img
                                src={`${IMAGE_URL}${item?.restaurant?.image}`}
                                className="rounded-full w-full h-full"
                                alt=""
                              />
                            </Link>
                          ) : (
                            <Link to={`/restaurant/${item?.restaurant?.id}`}>
                              <img
                                src={PlaceHolderImg}
                                className="rounded-full w-full h-full"
                                alt=""
                              />
                            </Link>
                          )}

                          <button
                            onClick={() =>
                              handleWishlistRemove(item?.restaurant?.id)
                            }
                            className="thumb-icon absolute top-0 right-0 bg-white p-2 rounded-md rounded-br-3xl shadow-themeShadow"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 fill-[#0DC1D9]"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="#fff"
                              strokeWidth="1"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="item-text bg-white shadow-themeShadow p-5 pt-16 rounded-md -mt-14">
                          <Link to={`/restaurant/${item?.restaurant?.id}`}>
                            <h4 className="text-base font-semibold text-black pt-4">
                              {item?.restaurant?.name}
                            </h4>
                          </Link>
                          <div className=" gap-1 text-sm font-normal text-gray-600 pb-2 truncate line-clamp-2 leading-[26px]">
                            {item?.restaurant?.categories.map((cat) => (
                              <div key={cat?.id}>{cat?.name} </div>
                            ))}
                          </div>
                          <div className="flex justify-center items-center pb-3">
                            <div className="rating-list">
                              <button type="button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="#FF953A"
                                >
                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                              </button>
                              <button type="button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="#FF953A"
                                >
                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                              </button>
                              <button type="button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="#FF953A"
                                >
                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                              </button>
                              <button type="button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="#FF953A"
                                >
                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                              </button>
                              <button type="button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="#CFCFCF"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="rating-text">
                              <h6 className="text-black text-xs font-semibold">
                                (4.8 Excellent)
                              </h6>
                            </div>
                          </div>
                          {item?.distance ? (
                            <div className="flex justify-center items-center text-xs text-gray-500 font-normal">
                              <p className="mr-4">
                                {item?.distance?.toFixed(2)}miles away
                              </p>
                              <p>
                                £{item?.restaurant?.delivery_charge} Delivery
                                Fee
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="bg-white shadow-themeShadow px-8 py-2 rounded-md inline-block mt-5">
                            {item?.restaurant?.min_delivery_time}-
                            {item?.restaurant?.max_delivery_time} min
                          </div>
                        </div>
                      </div>
                    );
                  })
                : "No Restaurant Found"}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Wishlist;
